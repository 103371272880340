import React from "react";
import { styled } from "@mui/material/styles";

const WrapContent = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const NotFoundPage = () => {
  return (
    <WrapContent className={'page'}>
        <img 
          width={600} 
          alt="not found" 
          src={`${process.env.PUBLIC_URL}/assets/background/error404.jpg`} 
        />
    </WrapContent>
  )
}

export default NotFoundPage;

