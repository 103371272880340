import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Typography, Box, Divider } from "@mui/material";

import Monthly from "./monthly";
// import Compare from "./compare";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const WrapTabs = styled("div")({
//   display: "flex",
//   gap: "8px",
// });

// const StyledTabButton = styled(Tab)(({ theme, selected }) => ({
//   backgroundColor: selected ? "#FFFFFF" : "#e0e0e0",
//   color: "#000000",
//   borderRadius: "16px",
//   minWidth: "100px",
//   padding: "8px 16px",
//   textTransform: "none",
//   fontWeight: "bold",
//   "&:hover": {
//     backgroundColor: "#bdbdbd",
//   },
// }));

const StyledTabPanel = styled(TabPanel)({
  "& .MuiBox-root": {
    padding: 0,
    marginTop: 16,
  },
});

const MoraleKpi = () => {
  const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <div className={`page dashboard-page`}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" color={"#ffffff"}>
          ภาพรวมสุขภาพใจ
        </Typography>

        {/* <WrapTabs>
          <StyledTabButton
            label="Monthly"
            {...a11yProps(0)}
            selected={value === 0}
            onClick={(event) => handleChange(event, 0)}
          />
          <StyledTabButton
            label="5Q Comparison"
            {...a11yProps(1)}
            selected={value === 1}
            onClick={(event) => handleChange(event, 1)}
          />
        </WrapTabs> */}
      </div>

      <Divider style={{ backgroundColor: "#ffffff", margin: "16px 0" }} />

      <StyledTabPanel value={value} index={0}>
        <Monthly />
      </StyledTabPanel>
      {/* <StyledTabPanel value={value} index={1}>
        <Compare />
      </StyledTabPanel> */}
    </div>
  );
};

export default MoraleKpi;
