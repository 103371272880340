import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";

//icon
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import {
  getAllCommunication,
  updateRewardCommunication,
} from "../../../../actions/communication";

const StyleCenterData = styled("div")({
  "& .AddOrRemoveCoin": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function DialogRewardCoin({
  open,
  idCommunication,
  idEmployees,
  handleClose,
}) {
  const dispatch = useDispatch();
  const [coin, setCoin] = useState(0);

  const handleAddReward = async () => {
    const res = await dispatch(
      updateRewardCommunication({
        reward: coin,
        idCommunication,
        idEmployees: idEmployees,
      })
    );
    if (res) {
      dispatch(getAllCommunication());
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"มอบเหรียญให้กับข้อเสนอแนะ"}
      </DialogTitle>
      <DialogContent>
        <StyleCenterData>
          <div className="AddOrRemoveCoin">
            <IconButton
              disabled={coin === 0 ? true : false}
              onClick={() => setCoin(coin - 1)}
            >
              <RemoveIcon />
            </IconButton>
            <Typography variant="h4">{coin}</Typography>
            <IconButton onClick={() => setCoin(coin + 1)}>
              <AddIcon />
            </IconButton>
          </div>
        </StyleCenterData>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          ปิด
        </Button>
        <Button variant="contained" color="primary" onClick={handleAddReward}>
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
}
