import React, { useState, useEffect } from "react";
import {
  Avatar,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  Button,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { Rating,Card } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { addBooking, getBookingByIdCoach } from "../../../../actions/book";
import { getUserProfile } from "../../../../actions/user";
import { useNavigate, Navigate, useParams, replace } from "react-router-dom";

import Calendar from "react-awesome-calendar";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { getCoachById } from "../../../../actions/coach";
import { MainCoin } from "../../shared/typeCoin";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import ButtonBlue from "../../shared/general/ButtonBlue";

dayjs.extend(isBetween);
dayjs.extend(utc);

const StyledAvatar = styled(Avatar)({
  height: 200,
  width: 200,
  borderBlockColor: "black",
});

const StyledCalendar = styled(Calendar)({
  "& .calendarWrapper": {
    display: "none",
  },
});


const StyledCard = styled(Card)({
  padding: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const Booking = (props) => {
  const {bookCoach,coachProfile} = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile
  );
  const [displaySnackbar, setDisplaySnackbar] = useState({ value: "", mode: "" });
  const dataBooking = {
    idCoach:coachProfile.idCoach ,
    bookDate: dayjs().add(1, 'day'),
    startTime: dayjs().add(1, 'day').startOf('hour'),
    endTime: dayjs().add(1, 'day').startOf('hour'),
    note: "",
    type: "online",
    place: "",
  };

  const validationSchema = yup.object().shape({
    bookDate: yup.date().min(dayjs().startOf('day').add(1, 'day')).required(),
    startTime: yup.date().required().test('unique-start-time', 'เวลาการจองซ้ำ กรุณาเลือกเวลาอื่น', function(value) {
      const { createError, path } = this;
      
      if(bookCoach){
        const isBooked = bookCoach.map((e)=>e.idBookingStatus ==1 || e.idBookingStatus==2).some(e => 
          dayjs(e.startTime).isSame(dayjs(value), 'hour')
        );
    
        if (isBooked) {
          return createError({ path, message: 'เวลาการจองซ้ำ กรุณาเลือกเวลาอื่น' });
        }
      }
      
      return true;
    }),
    endTime: yup.date().when("startTime", (startTime, schema) => {
      if(startTime) {
        return schema.min(dayjs(startTime).add(1, 'hour'), 'end time must be after than start time').required()
      }
    }),
    note: yup.string().max(100).required(),
    type: yup.string().oneOf(["online", "onsite"]).required(),
    place: yup.string().max(100).required(),
  });

  const { control, watch, getValues, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: dataBooking,
    resolver: yupResolver(validationSchema),
  });

  const bookDate = watch("bookDate");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  useEffect(() => {

  }, []);

  const timeDiffColorMark = (startTime, endTime) => {
    const timeDiff = dayjs(endTime).diff(dayjs(startTime), "hour");
    switch (timeDiff) {
      case 1:
        return "#1CCB9E";
      case 2:
        return "#E5D400";
      case 3:
        return "#E58B00";
      default:
        return "#E10000";
    }
  };

  const getEvents = () => {
    const bookCoachFuture = bookCoach.filter((item) =>
      dayjs().isBefore(item.startTime, "day")
    );

    const date = [];
    for (let i = 0; i < bookCoachFuture.length; i++) {
      if(bookCoachFuture[i].idBookingStatus==1){
        const tmpEvent = {
          id: bookCoachFuture[i].idBooking,
          color:"#E5D400" ,
          from: `${dayjs(bookCoachFuture[i].startTime).utc(true).format()}`,
          to: `${dayjs(bookCoachFuture[i].endTime).utc(true).format()}`,
          title: bookCoachFuture[i].note || 'มีการจอง(รออนุมัติ)',
        };
        date.push(tmpEvent);
      }else if(bookCoachFuture[i].idBookingStatus==2){
        const tmpEvent = {
          id: bookCoachFuture[i].idBooking,
          color:"#1CCB9E" ,
          from: `${dayjs(bookCoachFuture[i].startTime).utc(true).format()}`,
          to: `${dayjs(bookCoachFuture[i].endTime).utc(true).format()}`,
          title: bookCoachFuture[i].note || 'มีการจอง(อนุมัติ)',
        };
        date.push(tmpEvent);
      }
    }
    return date;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setDisplaySnackbar({ value, mode });
    setOpenSnackbar(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckDateBook = () => {
    const bookingInDatePick = bookCoach.filter((item) =>
      dayjs(item.startTime).isSame(bookDate, "day")
    );

    const checkBetweenStart = bookingInDatePick.some((item) =>
      dayjs(startTime).isBetween(dayjs(item.startTime), dayjs(item.endTime), "hour")
    );

    const checkBetweenEnd = bookingInDatePick.some((item) =>
      dayjs(endTime).isBetween(dayjs(item.startTime), dayjs(item.endTime), "hour")
    );

    const checkBookStart = bookingInDatePick.some((item) =>
      dayjs(item.startTime).isBetween(dayjs(startTime), dayjs(endTime), "hour")
    );

    const checkBookEnd = bookingInDatePick.some((item) =>
      dayjs(item.endTime).isBetween(dayjs(startTime), dayjs(endTime), "hour")
    );

    const checkSameStart = bookingInDatePick.some((item) => 
      dayjs(item.startTime).isSame(dayjs(startTime), "hour")
    );

    const checkSameEnd = bookingInDatePick.some((item) => 
      dayjs(item.endTime).isSame(dayjs(endTime), "hour")
    );

    if (
      !checkBetweenStart &&
      !checkBetweenEnd &&
      !checkBookStart &&
      !checkBookEnd &&
      !checkSameStart &&
      !checkSameEnd
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    const checkTime = handleCheckDateBook();
    if (checkTime) {
      handleClickOpen();
    } else {
      handleOpenSnackbar("โปรดเลือกเวลาที่ยังว่าง", "error");
    }
  };

  const handleConfirm = async () => {
    const values = getValues();
    const formData = {
      ...values,
      value: coachProfile.price * dayjs(values.endTime).diff(dayjs(values.startTime), "hour"),
    };
    const res = await dispatch(addBooking(formData));
    if (res) {
      handleClose();
      if(res.status == 200){
        handleOpenSnackbar("จองเวลาสำเร็จ", "success");
        navigate("/coaching/coachee-booking", { replace: true });
      }else{
        handleOpenSnackbar("เกิดข้อผิดพลาด", "error");
      }
    }else{
      handleOpenSnackbar("เกิดข้อผิดพลาด", "error");
    }
  };

  const getTotalMainCoin  = ()=>{
    let coin = 0;
    for(const data of userProfile.wallet){
      if(data.coinType == "main"){
        return data.amount;
      }
    }
  }

  return(
        <div id="appointment" >
            <StyledCard style={{ padding: "20px" ,marginBottom:"20px"}}>
              <StyledCalendar events={getEvents()} />
            </StyledCard>
              <StyledCard style={{padding:"20px"}}>
              <form  onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4} direction="row">
                  <Grid item xs={12} sm={5} md={3}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  วันที่
                  </Typography>
                    <Controller
                      name="bookDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          onChange={(newValue) => {
                            setValue("bookDate", newValue);
                            setValue("startTime",
                              dayjs(newValue)
                                .set("hour", startTime.get("hour"))
                                .set("minute", 0)
                                .set("second", 0)
                            );
                            setValue("endTime",
                              dayjs(newValue)
                                .set("hour", endTime.get("hour"))
                                .set("minute", 0)
                                .set("second", 0)
                            );
                          }}
                          minDate={dayjs().add(1, "day")}
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.bookDate,
                              helperText: errors.bookDate?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3.5} md={3}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  เวลาเริ่ม
                  </Typography>
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          onChange={(newValue) =>
                            setValue(
                              "startTime",
                              dayjs(bookDate)
                                .set("hour", newValue.get("hour"))
                                .set("minute", 0)
                                .set('second', 0)
                            )
                          }
                          views={["hours"]}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.startTime,
                              helperText: errors.startTime?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3.5} md={3}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  เวลาสิ้นสุด
                  </Typography>
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          onChange={(newValue) =>
                            setValue("endTime",
                              dayjs(bookDate)
                                .set("hour", newValue.get("hour"))
                                .set('minute', 0)
                                .set('second', 0)
                            )
                          }
                          minTime={dayjs(startTime).add(1, "hour")}
                          views={["hours"]}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              readOnly: true,
                              error: !!errors.endTime,
                              helperText: errors.endTime?.message,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  รูปแบบ
                  </Typography>
                    <FormControl fullWidth>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            error={!!errors.type}
                            placeholder="Type"
                          >
                            <MenuItem value={"online"}>Online</MenuItem>
                            <MenuItem value={"onsite"}>Onsite</MenuItem>
                          </Select>
                        )}
                      />
                    <FormHelperText>{errors.type && errors.type?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={9} md={12}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  สถานที่ / ลิงค์ประชุม
                  </Typography>
                    <Controller
                      name="place"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          error={!!errors.place}
                          helperText={errors.place?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography className="field-label" color="text.secondary" gutterBottom>
                  ปัญหาหรือเรื่องที่ต้องการเข้าโค้ช
                  </Typography>
                    <Controller
                      name="note"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          rows={4}
                          multiline
                          variant="outlined"
                          error={!!errors.note}
                          helperText={errors.note?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{}}>
                     <div style={{display:"flex", justifyContent:"end"}}>
                     <ButtonBlue
                      variant="contained"
                        type="submit"
                      >
                        ยืนยันการนัดหมาย
                      </ButtonBlue>
                     </div>
                  </Grid>
                </Grid>
              </form>
                </StyledCard>
              <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
            >
              <Alert severity={displaySnackbar.mode} onClose={handleCloseSnackbar}>
                {displaySnackbar.value}
              </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">
                {"จำนวนเหรียญที่ต้องจ่าย"}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {`ราคา ${coachProfile.price} x ${endTime.diff(startTime, "hour")} ชั่วโมง`}
                </Typography>
                <Typography>
                  {`ราคารวม: ${coachProfile.price * endTime.diff(startTime, "hour")}`}
                </Typography>
                <Typography>
                  {`จำนวนเหรียญที่มี: ${getTotalMainCoin()}`}
                </Typography>
                {coachProfile.price * endTime.diff(startTime, "hour") >
                  userProfile.coin && (
                  <Typography color={"error"}>จำนวนเหรียญไม่เพียงพอ</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>ยกเลิก</Button>
                <Button
                  variant="contained"
                  onClick={handleConfirm}
                  disabled={
                    coachProfile.price * endTime.diff(startTime, "hour") >
                    getTotalMainCoin()
                  }
                >
                  ยืนยัน
                </Button>
              </DialogActions>
            </Dialog>
        </div>
      )

};

export default Booking;
