import { jsPDF } from "jspdf";
import { font } from "./Prompt-Regular-normal";
import { fontBold } from "./Prompt-Bold-bold";
import { sortByAverage, sortByPositivePerception } from "./Report";

const pfdGenerater = (morale, gaugeImage, multiChartImage, doughnutChart) => {
    const doc = new jsPDF("p", "mm", "a4");
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    doc.addFileToVFS("Prompt-Regular-normal.ttf", font);
    doc.addFont("Prompt-Regular-normal.ttf", "Prompt-Regular", "normal");

    doc.addFileToVFS("Prompt-Bold-bold.ttf", fontBold);
    doc.addFont("Prompt-Bold-bold.ttf", "Prompt-Bold", "bold");

    const participant = morale.moraleResponse.participant.toString();
    const response = morale.moraleResponse.response.toString();
    const percentResponse = Math.round((morale.moraleResponse.response /morale.moraleResponse.participant) *100);
    const topic = [...morale.moraleTopicAvgAndBest];

    //PAGE 1
    if (gaugeImage) {
      //Header
      doc.setFontSize(24);
      doc.setFont("Prompt-Bold", "bold");
      doc.text("TPE REPORT 2022", 10, 20);
      doc.setFont("Prompt-Regular", "normal");
      doc.text("Dash Board", 10, 35);
      doc.setFontSize(16);
      doc.text("How Many Employees Are Engaged?", 10, 50);
      //border guage
      doc.roundedRect(10, 55, 190, 100, 1, 1);
      //gauage
      doc.addImage(gaugeImage, "JPEG", 15, 72, 80, 40);
      //range
      doc.setFontSize(12);
      doc.setFillColor("#EFFFFD");
      doc.roundedRect(100, 65, 100, 80, 1, 1, "F");
      doc.text("Global", 110, 75);
      doc.setFont("Prompt-Regular", "normal");
      doc.text("Bottom Quartile", 110, 83);
      doc.setFillColor("#EEEEEE");
      doc.rect(110, 85, 80, 5, "F");
      doc.setFillColor(255, 99, 132);
      doc.rect(110, 85, (54 * 80) / 100, 5, "F");
      doc.text("0 - 54%", 110, 96);
      doc.text("Moderate Zone", 110, 104);
      doc.setFillColor("#EEEEEE");
      doc.rect(110, 106, 80, 5, "F");
      doc.setFillColor(255, 206, 86);
      doc.rect(110 + (54 * 80) / 100, 106, (22 * 80) / 100, 5, "F");
      doc.text("54 - 76%", 110, 118);
      doc.text("Top Quartile", 110, 126);
      doc.setFillColor("#EEEEEE");
      doc.rect(110, 128, 80, 5, "F");
      doc.setFillColor(54, 162, 235);
      doc.rect(110 + (54 * 80) / 100 + (22 * 80) / 100, 128, (24 * 80) / 100, 5, "F");
      doc.text("76 - 100%", 110, 140);
      //space 15
      //participation
      doc.setFontSize(16);
      doc.setFont("Prompt-Bold", "bold");
      doc.text("Participation Rate", 10, 170);
      //space 5
      doc.roundedRect(10, 175, 190, 25, 1, 1);
      doc.setFontSize(12);
      doc.setFont("Prompt-Regular", "normal");
      doc.text("Completed ", 14, 185);
      doc.text(response, 48, 185);
      doc.text("Invited ", 14, 195);
      doc.text(participant, 48, 195);
      doc.setFillColor("#EEEEEE");
      doc.rect(70, 185, 100, 7, "F");
      doc.setFillColor("#800080");
      doc.rect(70, 185, percentResponse, 7, "F");
      doc.text(`${percentResponse}%`, 180, 190);
      //border page
    //   doc.setLineWidth(0.5);
    //   doc.roundedRect(10, 10, 190, 280, 1, 1)
    }

    //How Are We Scoring On Key Indices?
    let breakpage = 55;
    for (let i = 0; i < topic.length; i++) {
      const contentHeigth = topic[i].moraleQuestionAvgAndDistributionAcross.length * 30;
      if (contentHeigth > pageHeight - breakpage - 30 || i === 0) {
        doc.addPage();
        //Header
        doc.setFontSize(24);
        doc.setFont("Prompt-Bold", "bold");
        doc.text("Dash Board", 10, 35);
        doc.setFontSize(16);
        doc.text("How Are We Scoring On Key Indices?", 10, 50);
        breakpage = 55;
      }
      //left
      doc.setFontSize(12);
      doc.setFont("Prompt-Regular", "normal");
      doc.text(topic[i].questionTopic, 15, breakpage + 7);
      doc.addImage(doughnutChart[i], "JPEG", 0, breakpage + 9, 60, 30);

      //rigth
      doc.line(10, breakpage, 200, breakpage);
      doc.text("Dimension Questions", 70, breakpage + 7);
      for (let j = 0; j < topic[i].moraleQuestionAvgAndDistributionAcross.length; j++) {
        doc.setFillColor("#EFFFFD");
        doc.roundedRect(70, breakpage + 10, 128, 24, 1, 1, "F");
        doc.setFontSize(12);
        doc.setFont("Prompt-Bold", "bold");
        doc.text(75, breakpage + 20, `${Math.ceil(topic[i].moraleQuestionAvgAndDistributionAcross[j].average)}%`);
        doc.setFont("Prompt-Regular", "normal");
        const agilityDesc = doc.splitTextToSize(
          topic[i].moraleQuestionAvgAndDistributionAcross[j].question,
          100
        );
        doc.text(95, breakpage + 20, agilityDesc);
        breakpage = breakpage + 28;
      }
      breakpage += 17;
      doc.line(10, breakpage, 200, breakpage);
    }
    
    //What Is The Distribution Across Engagement Profiles
    doc.addPage();
    //Header
    doc.setFontSize(24);
    doc.setFont("helvetica", "bold");
    doc.text("Dash Board", 10, 35);
    doc.setFontSize(16);
    doc.text("What Is The Distribution Across Engagement Profiles?", 10, 50);
    doc.line(10, 55, 200, 55);
    //chart
    doc.addImage(multiChartImage, "JPEG", 10, 60, 190, 90);

    //What Are My Top Scoring Questions
    breakpage = 55;
    doc.addPage();
    //Header
    doc.setFontSize(24);
    doc.setFont("Prompt-Bold", "bold");
    doc.text("Dash Board", 10, 35);
    doc.setFontSize(16);
    doc.text("What Are My Top Scoring Questions?", 10, 50);
    doc.line(10, 55, 200, 55); //top
    doc.setFillColor("#EFFFFD");
    doc.roundedRect(10, breakpage + 2, 190, 224, 1, 1, "F");
    doc.setFontSize(10);
    doc.text("Rank", 15, breakpage + 7);
    doc.text("Item", 33, breakpage + 7);
    doc.text("Dimension", 116, breakpage + 7);
    doc.text("% Favorable", 161, breakpage + 7);
    
    const dataSortAvgRatio = sortByAverage(topic, "descending").slice(0, 9);
    for (let i = 0; i < dataSortAvgRatio.length; i++) {
      doc.setFillColor("#FFFFFF");  
      doc.rect(13, breakpage + 9, 15, 20, "F");
      doc.rect(31, breakpage + 9, 80, 20, "F");
      doc.rect(114, breakpage + 9, 42, 20, "F");
      doc.rect(159, breakpage + 9, 38, 20, "F");
      doc.setFontSize(12);
      doc.setFont("Prompt-Regular", "normal");
      doc.text(`${i + 1}`, 15, breakpage + 17);
      const item = doc.splitTextToSize(dataSortAvgRatio[i].question, 75);
      doc.text(item, 33, breakpage + 17);
      const dimension = doc.splitTextToSize(dataSortAvgRatio[i].questionTopic,36);
      doc.text(dimension, 116, breakpage + 17);
      doc.setFillColor("#EEEEEE");
      doc.rect(160, breakpage + 15, 36, 5, "F");
      doc.setFillColor(255, 99, 132);
      doc.rect(160, breakpage + 15, (dataSortAvgRatio[i].average * 36) / 100, 5, "F");
      doc.setTextColor("#FFFFFF")
      doc.text(`${Math.ceil(dataSortAvgRatio[i].average)}`, 175, breakpage + 19);
      doc.setTextColor("#000000")
      breakpage = breakpage + 24;
    }

    //What Should We Focus On To Drive Engagement
    const dataShouldFocus = topic
      .sort((a, b) => a.average - b.average)
      .slice(0, 5);

    breakpage = 55;
    for (let i = 0; i < dataShouldFocus.length; i++) {
      const contentHeigth = dataShouldFocus[i].moraleQuestionAvgAndDistributionAcross.length * 30;
      if (contentHeigth > pageHeight - breakpage - 30 || i === 0) {
        doc.addPage();
        //Header
        doc.setFontSize(24);
        doc.setFont("Prompt-Bold", "bold");
        doc.text("Dash Board", 10, 35);
        doc.setFontSize(16);
        doc.text("What Should We Focus On To Drive Engagement?", 10, 50);
        breakpage = 55;
      }
      doc.setFontSize(10);
      doc.setFont("Prompt-Bold", "bold");
      doc.text(dataShouldFocus[i].questionTopic, 12, breakpage + 7);
      doc.setFontSize(24);
      doc.text(`${Math.ceil(dataShouldFocus[i].average)}%`, 22, breakpage + 24);
      doc.setFontSize(10);
      doc.text("+4", 28, breakpage + 38);
      doc.setFont("Prompt-Regular", "normal");
      doc.text("Favorable", 22, breakpage + 30);
      doc.setFillColor("#EEEEEE");
      doc.rect(10, breakpage + 40, 40, 5, "F");
      doc.setFillColor(255, 99, 132);
      doc.rect(30, breakpage + 40, 4 * 2, 5, "F");
      doc.line(30, breakpage + 39, 30, breakpage + 40);
      //rigth
      doc.line(10, breakpage, 200, breakpage); //top
      doc.setFontSize(10);
      doc.text("Questions", 60, breakpage + 7);
      doc.text("% Favorable", 130, breakpage + 7);
      const textColumn = doc.splitTextToSize("Distance from Thailand best employers",30);
      doc.text(textColumn, 170, breakpage + 7);
      for (
        let j = 0;
        j < dataShouldFocus[i].moraleQuestionAvgAndDistributionAcross.length;
        j++
      ) {
        doc.setFillColor("#EFFFFD");
        doc.roundedRect(55, breakpage + 20, 145, 20, 1, 1, "F");
        const desc = doc.splitTextToSize(dataShouldFocus[i].moraleQuestionAvgAndDistributionAcross[j].question, 70);
        doc.text(desc, 60, breakpage + 28);
        doc.setFillColor("#EEEEEE");
        doc.rect(130, breakpage + 28, 20, 5, "F");
        doc.setFillColor(255, 99, 132);
        doc.rect(130,breakpage + 28, (dataShouldFocus[i].moraleQuestionAvgAndDistributionAcross[j].average * 20)/100, 5, "F");
        doc.text(155, breakpage + 32, `${Math.ceil(dataShouldFocus[i].moraleQuestionAvgAndDistributionAcross[j].average)}%`);
        doc.setFillColor("#EEEEEE");
        doc.rect(170, breakpage + 28, 26, 5, "F");
        doc.text("+5", 175, breakpage + 32); // PLUS FROM THAI BEST EMP
        doc.setFillColor(255, 99, 132);
        doc.rect(183, breakpage + 28, (5 * 13) / 10, 5, "F");
        doc.line(183, breakpage + 27, 183, breakpage + 34);
        breakpage = breakpage + 24;
      }
      breakpage += 32;
      doc.line(10, breakpage, 200, breakpage); //bottom
    }

    //Ranked focus item
    const dataRankFocus = sortByAverage(topic, "ascending").slice(0, 9);
    breakpage = 55;
    for (let i = 0; i < dataRankFocus.length; i++) {
      if (i % 9 === 0) {
        doc.addPage();
        //Header
        doc.setFontSize(24);
        doc.setFont("Prompt-Bold", "bold");
        doc.text("Dash Board", 10, 35);
        doc.setFontSize(16);
        doc.text("Ranked Focus Items", 10, 50);
        breakpage = 55;
        //column
        doc.line(10, breakpage, 200, breakpage); //top
        doc.setFontSize(10);
        doc.text("Rank", 15, breakpage + 7);
        doc.text("Item", 35, breakpage + 7);
        doc.text("Dimension", 100, breakpage + 7);
        doc.text("% Favorable", 130, breakpage + 7);
        const textColumn = doc.splitTextToSize("Distance from Thailand best employers", 30);
        doc.text(textColumn, 170, breakpage + 7);
      }
      doc.setFont("Prompt-Regular", "normal");
      doc.setFillColor("#EFFFFD");
      doc.roundedRect(10, breakpage + 20, 190, 20, 1, 1, "F");
      doc.text(`${i + 1}`, 15, breakpage + 30);
      const desc = doc.splitTextToSize(dataRankFocus[i].question, 60);
      doc.text(desc, 35, breakpage + 28);
      const dimension = doc.splitTextToSize(dataRankFocus[i].questionTopic, 30);
      doc.text(dimension, 100, breakpage + 28);
      doc.setFillColor("#EEEEEE");
      doc.rect(130, breakpage + 28, 20, 5, "F");
      doc.setFillColor(255, 99, 132);
      doc.rect(130, breakpage + 28, (dataRankFocus[i].average * 20)/100, 5, "F");
      doc.text(`${Math.ceil(dataRankFocus[i].average)}%`, 155, breakpage + 32);
      doc.setFillColor("#EEEEEE");
      doc.rect(170, breakpage + 28, 26, 5, "F");
      doc.text("+5", 175, breakpage + 32);
      doc.setFillColor(255, 99, 132);
      doc.rect(183, breakpage + 28, (5 * 13) / 10, 5, "F");
      doc.line(183, breakpage + 27, 183, breakpage + 34);
      breakpage = breakpage + 24;
      // }
    }

    //SAVE PDF
    doc.save(`${morale.moraleName}.pdf`);
};

export default pfdGenerater;