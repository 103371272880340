import React, { useEffect, useState,} from "react";
import { styled } from "@mui/material/styles";
import Pica from 'pica';
import {
  LinearProgress,
  Typography,
  Paper,
  Divider,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  IconButton,
  Drawer,
  Container,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Input,
  Tooltip,
  Menu,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import MoraleQuestionIcon from "./assets/question.png";
import Status from "./assets/checked.png";
import HeaderPage from "../../../shared/header/headerPage";
import MoraleQuestionsList from "./moraleLists";

import { useDispatch, useSelector } from "react-redux";
import { addMorale, getAllMorale, addMoraleParticipant,} from "../../../../../actions/moraleQuestion";
import {getAllQuestionTopic} from "../../../../../actions/questionTopic";
import { getAllEmployees } from "../../../../../actions/user";
import { getDepartment } from "../../../../../actions/department";
import MoraleFilter from "../../../shared/moraleFilter";
import { useNavigate,  useParams } from "react-router-dom";
import LoadingIcon from "../../../shared/general/loadingIcon";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    height: '800px',
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),   
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  //padding: '10px 20px',
  marginRight: '10px',
  width: '120px'
}));

const WrapCardQuestion = styled(Paper)({
  width: "100%",
  display: "flex",
  borderRadius: 15,
  marginBottom: 20,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

const AddQuestionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const WrapHeader = styled('div')({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
})

/*--------------------------------------------------------------------------------------------------------*/
const MoraleQuestion = ()  => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [moraleName, setMoraleName] = useState("");
  const [moraleCode, setMoraleCode] = useState("");
  const [moraleType, setMoraleType] = useState("");
  const [topic, setTopic] = useState("");
  const [questionStyle, setQuestionStyle] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [options, setOptions] = useState(["", "", "", "", ""]);
  const [answer, setAnswer] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [valueTarget, setValueTarget] = useState("company");
  const fixedOptionsDepartment = [];
  const fixedOptionsPerson = [];
  
  const [image, setImage] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('draft');
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [alignment, setAlignment] = useState("card");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const [modeSnackbar, setModeSnackbar] = useState("info")
  const [openPublic, setOpenPublic] = useState(false);
  const [filter, setFilter] = useState({
    quarter: true,
    year: true,
    published: true,
    draft: true,
  });
  const [questions, setQuestions] = useState([
    {
      topic: "",
      questionStyle: "",
      questionText: "",
    },
  ]); 

  const idCompany = 1;
  useEffect(() => {
    dispatch(getAllMorale());
    dispatch(getAllQuestionTopic(idCompany));
    dispatch(getDepartment());
    dispatch(getAllEmployees());
  }, [dispatch, idCompany]);
  
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: questionTopics, isFetching, isError } = useSelector((state) => state.questionTopic );
  const { result: moraleQuestions, isFetchingMorale } = useSelector((state) => state.moraleQuestions);
  const { result: department } = useSelector((state) => state.department);
  const { result: person } = useSelector((state) => state.users);
  const [filteredMoraleQuestions, setFilteredMoraleQuestions] = useState(moraleQuestions);

  useEffect(() => {
    console.log('questionTopics:', questionTopics);
  }, [questionTopics]);

  useEffect(() => {
    if (Array.isArray(moraleQuestions)) {
      setFilteredMoraleQuestions(moraleQuestions);
    }
  }, [moraleQuestions]);

  
  const handleClickDescription = (id) => {
    navigate("/admin/manage/morale-question/" + id);
  };

  const handleOpen = () => {
    setOpen(true);
    setQuestions([ 
      {
        topic: "",
        questionStyle: "",
        questionText: "",  
      },
    ]);    
  };

  const handleClose = () => {
    resetForm();
    resetCard();
    setOpen(false);
    dispatch(getAllMorale());
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedQuestionIndex(index); 
  };
  const handleMenuClose = () => {setAnchorEl(null);};

  const resetForm = () => {
    setMoraleName("");
    setMoraleCode("");
    setDescriptionText("");
    setMoraleType("");
    setTopic("");
    setQuestionStyle("");
    setQuestionText("");
    setOptions(["", "", "", "",""]);
    setAnswer("")
    setImage(null);
    setQuestions([]);
    setStartDate("");
    setEndDate("");
  };

  const resetCard = () => {
    setTopic("");
    setQuestionStyle("");
    setQuestionText("");
    setOptions(["", "", "", "",""]);
    setAnswer("")
  }

  const handleChangeMoraleName = (event) => {setMoraleName(event.target.value);};
  const handleChangeMoraleCode = (event) => {setMoraleCode(event.target.value);};
  const handleChangeDescription = (event) => {setDescriptionText(event.target.value);};

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {setImage(reader.result)};
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const pica = new Pica();
    const outputWidth = 350;
    const outputHeight = 275;
    const canvas = document.createElement('canvas');
    canvas.width = outputWidth;
    canvas.height = outputHeight;
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      try {
        await pica.resize(img, canvas);
        const resizedImage = await pica.toBlob(canvas, 'image/jpeg', 0.8);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result); // Set the resized image as a base64 string
        };
        reader.readAsDataURL(resizedImage);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    };
  };

  const handleResetImage = () => {setImage(null)};
  const isAddQuestionEnabled = moraleName && moraleType && topic && questionStyle;
  
  const handleSave = () => {
    if (moraleName.length > 0) {
      setOpenStatusDialog(true); // Open the Draft/Publish selection dialog
    }
  };
  const handleStatusSelect = (status) => {
    setSelectedStatus(status); 
    setOpenStatusDialog(false);
    handleAddQuestion(status);  
  };
  
  const handleAddQuestion = async (status) => { // connect will database for create question in selected topic
    const res = await dispatch(
      addMorale({ moraleName,moraleCode,descriptionText,image,moraleStatus: status, moraleType, startDate, endDate,
        questions: questions.map(q => ({
          ...q,
          questionTopic: q.topic,
          questionType: q.questionStyle,
          questionText: q.questionText,
        }))
      })
    );
    console.log("Response from addMorale:", res);
    
    
    if (res ) {  // Ensure moraleId was returned
      const moraleId = res.data.moraleId; 
      console.log("Morale and questions added successfully with ID:", moraleId);
  
      if (status === "published") {
        const participantResponse = await dispatch(
          addMoraleParticipant({
            moraleId, // Pass moraleId for publishing
            startDate,
            endDate,
            valueTarget,
            selectedDepartment: selectedDepartment.map((dept) => dept.idDepartment),
            selectedPerson: selectedPerson.map((person) => person.idEmployees),
          })
        );

        if (participantResponse.error) {
          throw new Error('Failed to add participants: ' + participantResponse.error);
        }
      }
  
      // Refresh morale list after adding
      dispatch(getAllMorale());
      handleClose();
      resetForm();
    } else {
      console.error("Failed to retrieve moraleId or add morale.");
    }
   
};

  const handleDuplicateQuestion = (index) => {
    const duplicatedQuestion = { ...questions[index] };
    setQuestions([...questions, duplicatedQuestion]);
    handleMenuClose();
  };
  
  const handleDeleteQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
    handleMenuClose();
  };
  

  const createQuestion = () => {
    const newQuestion = {
      topic: "",
      questionStyle: "",
      questionText: "",
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleQuestionChange = (index, key, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value; // Update the specific option
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].answer = value; // Update answer for open questions
    setQuestions(updatedQuestions);
  };

  const handleChangeValueTarget = (event) => {
    setValueTarget(event.target.value);
  };

  const handleClickOpenPublic = () => {
    setOpenPublic(true);
  };

  const handleClosePublic = () => {
    setOpenPublic(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setModeSnackbar(mode)
    setDisplaySnackbar(value)
    setOpenSnackbar(true);
  };

  const handleGoPublic = async (moraleId) => {
    if(valueTarget === 'department' && selectedDepartment.length < 1) {
      handleOpenSnackbar("โปรดเลือกหน่วยงาน", 'error');
    } else if(valueTarget === 'person' && selectedPerson.length < 1) {
      handleOpenSnackbar("โปรดเลือกพนักงาน", 'error');
    } else {
      const idDepartmentList = selectedDepartment.map(item => item.idDepartment)
      const idEmployeesList = selectedPerson.map(item => item.idEmployees)
      const res = await dispatch(addMoraleParticipant(
        { 
          idMorale: moraleId,
          startDate,
          endDate,
          valueTarget, 
          selectedDepartment: idDepartmentList, 
          selectedPerson: idEmployeesList,
        }
      ))
      
      if(res) {
        // handleStatusSelect("published")
        console.log("Published successfully:", res);
        // navigate("/admin/manage/morale-question");
      } else {
        console.error("Error publishing morale:", res);
      }
      return res;
    }
    
  };
  
  const questionTypes = ["Multiple choice", "Open question"];

  const filterMoraleQuestions = (moraleQuestions, filter) => {
    return moraleQuestions.filter((question) => {
      const matchesType =
        (filter.quarter && question.moraleType === "quarter") ||
        (filter.year && question.moraleType === "year");
      const matchesStatus =
        (filter.published && question.moraleStatus === "published") ||
        (filter.draft && question.moraleStatus === "draft");
  
      // If no filters are selected for type or status, include all questions
      const typeFilterApplied = filter.quarter || filter.year;
      const statusFilterApplied = filter.published || filter.draft;
  
      return (
        (!typeFilterApplied || matchesType) &&
        (!statusFilterApplied || matchesStatus)
      );
    });
  };

  const setFilterMorale = (newFilter) => {
    setFilter(newFilter);
    const result = filterMoraleQuestions(moraleQuestions, newFilter);
    setFilteredMoraleQuestions(result);
    setOpenFilter(false);
  };
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenFilter(!openFilter);
  };


  if (!Array.isArray(moraleQuestions) || moraleQuestions.length === 0) {
    return (
      <StyledRoot className={"page"}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full height of the viewport
            width: '100vw', // Full width of the viewport
            position: 'fixed', // Fixes the loading spinner in place
            top: 0,
            left: 0, 
          }}
        >
          <CircularProgress /> 
        </Box>
      </StyledRoot>
    );
    //return <Typography>No morale questions available.</Typography>; // Handle empty state
  }

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถาม Morale"} icon={MoraleQuestionIcon} />
        <div className={`wrap-button-add`}>
          <Stack  direction={'row'} spacing={{ xs: 1, sm: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpen}
            >
              New Question
            </Button>
            <FilterIconButton
              aria-label="filter"
              onClick={toggleDrawer}
              size="medium"
            >
              <FilterListIcon fontSize="medium" />
            </FilterIconButton>
          </Stack>
        </div>
      </WrapHeader>
      <StyledDivider />
      <StyledDialog 
        open={open} 
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') { handleClose();}
        }} maxWidth="lg" fullWidth sx={{ '& .MuiDialog-paper': { width: '90%', maxWidth: '1200px' }}}>
        <Box display="flex" justifyContent="space-between" pr={3} mb={-2}>
          <DialogTitle>
            <Typography variant="h4" sx={{pt:2, pl:2, fontWeight:600}}>New Question</Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <Box display="flex" gap={3} sx={{pl:2, pr:2}}>
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center" 
              border={image ? 'none' : '2px dashed lightgray'}
              borderRadius="8px"
              width="350px"
              height="275px"
              position="relative">
              {image ? (
                <>
                  <Box
                    component="img"
                    src={image}
                    alt="Uploaded"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
                  />
                  <IconButton
                    onClick={handleResetImage}
                    sx={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'rgba(255,255,255,0.8)' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    //sx={{ marginTop: '5px' }}
                  >
                    <CloudUploadIcon fontSize="large" sx={{color:"gray",'&:hover': {
                      color: '#EC5B6A', 
                    },}}/>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleImageUpload}
                    />
                  </IconButton>
                  <Typography>Click to upload</Typography>
                </>
              )}
            </Box>
            <Box flex={2} gap={2}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="subtitle2" style={{ marginBottom: '5px', color:'gray', fontSize:'12px' }} >TITLE</Typography>
                <TextField
                  fullWidth
                  placeholder="Morale Name"
                  variant="standard"
                  value={moraleName}
                  onChange={handleChangeMoraleName}
                  InputProps={{
                    sx: { fontSize: '22px',pt:3, ml:-3.5},
                  }}
                  sx={{
                    width: 250,
                    '& .MuiInput-underline:before': { borderBottom: 'none' }, 
                    '& .MuiInput-underline:after': { borderBottom: 'none' },  
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },  
                
                  }}
                />
                <Typography variant="subtitle2" style={{ marginBottom: '5px', color:'gray', fontSize:'12px' }} >CODE</Typography>
                <TextField
                  fullWidth
                  placeholder="Morale Code"
                  variant="standard"
                  value={moraleCode}
                  onChange={handleChangeMoraleCode}
                  InputProps={{
                    sx: { fontSize: '22px',pt:3, ml:-4},
                  }}
                  sx={{
                    width: 180,
                    '& .MuiInput-underline:before': { borderBottom: 'none' }, 
                    '& .MuiInput-underline:after': { borderBottom: 'none' },  
                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },  
                
                  }}
                />
              </Box>
              <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '5px', color:'gray', fontSize:'12px'   }}>DESCRIPTION</Typography>
              <TextField
                fullWidth
                placeholder="In this morale is about..."
                variant="standard"
                value={descriptionText}
                onChange={handleChangeDescription}
                sx={{
                  width: 470,
                  '& .MuiInput-underline:before': { borderBottom: 'none' }, 
                  '& .MuiInput-underline:after': { borderBottom: 'none' },  
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, 
                }}
              />
              <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '5px', color:'gray', fontSize:'12px'   }}>MORALE TYPE</Typography>
              <Box display="flex" spacing={2}>
                <Box item>
                  <StyledButton
                    variant={moraleType === "quarter" ? "contained" : "outlined"}
                    onClick={() => setMoraleType("quarter")}
                    sx={{ 
                      backgroundColor: moraleType === "quarter" ? '#EC5B6A' : 'transparent', // Fill color for selected button
                      color: moraleType === "quarter" ? 'white' : 'grey', // Text color changes based on selection
                      borderColor: moraleType === "quarter" ? 'transparent' : 'lightgray', // Border color for outlined button
                      '&:hover': {
                        backgroundColor: moraleType === "quarter" ? '#EC5B6A' : 'transparent', // Optional: change hover color
                        borderColor: moraleType === "quarter" ? 'transparent' : 'gray',
                      },
                    }}
                  >
                    Quarter
                  </StyledButton>
                </Box>
                <Box item>
                  <StyledButton
                    variant={moraleType === "year" ? "contained" : "outlined"}
                    onClick={() => setMoraleType("year")}
                    sx={{ 
                      backgroundColor: moraleType === "year" ? '#EC5B6A' : 'transparent', // Fill color for selected button
                      color: moraleType === "year" ? 'white' : 'gray', // Text color changes based on selection
                      borderColor: moraleType === "year" ? 'transparent' : 'lightgray', // Border color for outlined button
                      '&:hover': {
                        backgroundColor: moraleType === "year" ? '#EC5B6A' : 'transparent', // Optional: change hover color
                        borderColor: moraleType === "year" ? 'transparent' : 'gray',
                      },
                    }}
                  >
                    Year
                  </StyledButton>
                </Box>
              </Box>
              <Box display="flex" gap={3} sx={{ mt: 3.5 }} >
                <TextField
                  label="Start date and time"
                  type="datetime-local"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '10px', 
                      fontSize:'14px', height:'45px', 
                    },
                  }}
                />
                <TextField
                  label="End date and time"
                  type="datetime-local"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: '10px', // Custom border radius
                      fontSize:'14px', height:'45px'
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          
          <Box display="flex" justifyContent="space-between" sx={{ mt: 2, pr:2, mb:-2}} spacing={2} >
            <Box sx={{ display: 'flex', alignItems: 'center', pt:0.6}}>
              <Typography variant="h6" sx ={{ ml: 1, fontSize:'32'}}>Create question</Typography>
            </Box> 
          </Box>
          
          <Box display="grid" sx={{pl:2}}>
            {questions.map((question, index) => (
              <Box key={index} display="flex" sx={{ pr:2}}>
              <Paper elevation={3} sx={{ mt: 3, pl: 4, pr: 4, pb: 2, width: '100%' }}>
                <Box display="flex" alignItems="flex-end" justifyContent="space-between" >
                  <Typography variant="h6">Question {index + 1}</Typography>
                  <Box display="flex" gap={2}>
                  <TextField
                    select
                    variant="standard"
                    label={question.topic ? " " : "Select topic"}
                    value={question.topic}
                    onChange={(e) => handleQuestionChange(index, 'topic', e.target.value)}
                    InputLabelProps={{
                      sx: { textAlign: 'right', right: 30, left: 'unset' },
                    }}
                    sx={{
                      width: 280,
                      '& .MuiInput-underline:before': { borderBottom: 'none' },
                      '& .MuiInput-underline:after': { borderBottom: 'none' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '& .MuiSelect-select': { textAlign: 'right' },
                    }}
                  >
                    {isFetching ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : isError ? (
                      <MenuItem disabled>Error loading topics</MenuItem>
                    ) : (
                      questionTopics?.map((topicItem) => (
                        <MenuItem key={topicItem.idQuestionTopic} value={topicItem.questionTopic} sx={{ textAlign: 'right' }}>
                          {topicItem.questionTopic}
                        </MenuItem>
                      ))
                    )}
                  </TextField>

                  <TextField
                    select
                    label={question.questionStyle ? " " : "Select type"}
                    variant="standard"
                    value={question.questionStyle}
                    onChange={(e) => handleQuestionChange(index, 'questionStyle', e.target.value)}
                    InputLabelProps={{
                      sx: { textAlign: 'right', right: 30, left: 'unset' },
                    }}
                    sx={{
                      width: 150,
                      '& .MuiInput-underline:before': { borderBottom: 'none' },
                      '& .MuiInput-underline:after': { borderBottom: 'none' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '& .MuiSelect-select': { textAlign: 'right' },
                    }}
                  >
                    {questionTypes.map((option) => (
                      <MenuItem key={option} value={option} sx={{ textAlign: 'right' }}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Box>
                  
                </Box>

                {/* Row for Question Text, Icons, and Selects */}
                <Box display="flex" alignItems="center" gap={2} >
                  <TextField
                    placeholder="Question Text"
                    fullWidth
                    value={question.questionText}
                    onChange={(e) => handleQuestionChange(index, 'questionText', e.target.value)}
                    variant="outlined"
                    sx={{ flexGrow: 1,}} // Allow the question text field to grow
                  />

                  {/* Icons in the same row */}
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ mx: 1 , mr:-1}}>
                    <Tooltip title="Duplicate">
                      <IconButton onClick={() => handleDuplicateQuestion(index)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDeleteQuestion(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>  
                </Box>
              </Paper>
              
              </Box> 
            ))}  
            <Box display="flex" justifyContent="flex-start" mt={3}>
              <Button variant="outlined" onClick={createQuestion} color="primary"
                sx={{width:150, color:'#EC5B6A', borderColor:'#EC5B6A',
                  '&:hover': {
                  borderColor: '#EC5B6A', 
                },borderRadius: '10px',}}>
                Add Question
              </Button>
              
            </Box>  
          </Box>

       
        </DialogContent>  
        <Box display="flex" justifyContent="flex-end" pr={3} mb={2} >
         
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClose} 
              sx={{width:100, color:'gray', borderColor:'gray',
                '&:hover': {
                borderColor: 'darkgrey', 
                backgroundColor: 'rgba(128, 128, 128, 0.1)',
              },borderRadius: '10px',}}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              //onClick={moraleName.length > 0 ? handleAddQuestion : null}
              onClick={handleSave}
              sx={{width:100, backgroundColor:'#EC5B6A', '&:hover': {
                backgroundColor: '#EC5B6A', 
              }, borderRadius: '10px',}}
            >
              Save
            </Button>
          </DialogActions>
          <Dialog 
            PaperProps={{
              sx: {borderRadius: '10px', height:'360px', width:'550px', pl:'40px', pr:'40px' }     
            }} 
            open={openStatusDialog} onClose={() => setOpenStatusDialog(false)}>
            
            <DialogTitle sx={{ textAlign: 'center', mt:'20px' }}>Select Status</DialogTitle>
            <Box 
              display="flex" 
              justifyContent="center" 
            >
              <img
                src={Status}
                width={100}
                height={100}
                style={{
                  objectFit: 'cover', // Ensures the image maintains aspect ratio while filling the width/height
                }}
              />
            </Box>
            <DialogContent sx={{ textAlign: 'center'}} >
              <DialogContentText>
                Please select whether you want to save the morale as a draft or publish it.
              </DialogContentText>
              <Box display="flex" gap={2} justifyContent="center" mt={4}>
                <Button variant="outlined" onClick={() => handleStatusSelect('draft')}
                   sx={{width:150, color:'gray', borderColor:'gray',
                    '&:hover': {
                    borderColor: 'darkgrey', 
                    backgroundColor: 'rgba(128, 128, 128, 0.1)',
                  },borderRadius: '10px',}}>
                  Save as Draft
                </Button>
                <Button variant="contained" color="success" onClick={handleClickOpenPublic}
                  sx={{width:150, backgroundColor:'#EC5B6A', '&:hover': {
                    backgroundColor: '#EC5B6A', 
                  }, borderRadius: '10px',}}>
                  Publish
                </Button>
              </Box>
            </DialogContent>
            <Dialog open={openPublic} onClose={handleClosePublic} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"เผยแพร่"}</DialogTitle>
              <DialogContent>
                
                <FormControl component="fieldset">
                  <FormLabel component="legend">กลุ่มเป้าหมาย</FormLabel>
                  <RadioGroup aria-label="valueTarget" name="valueTarget" value={valueTarget} onChange={handleChangeValueTarget} row>
                    <FormControlLabel value="company" control={<Radio />} label="ทั้งบริษัท" />
                    <FormControlLabel value="department" control={<Radio />} label="รายหน่วยงาน" />
                    <FormControlLabel value="person" control={<Radio />} label="รายบุคคล" />
                  </RadioGroup>
                </FormControl>
                {valueTarget === "department" && (
                  <Autocomplete multiple id="fixed-tags-demo-department" value={selectedDepartment} onChange={(event, newValue) => {
                    setSelectedDepartment([...fixedOptionsDepartment, ...newValue.filter(option => fixedOptionsDepartment.indexOf(option) === -1)]);
                  }} options={department.filter(item => item.idDepartment)} getOptionLabel={(option) => option.departmentName}
                    renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                      <Chip label={option.departmentName} {...getTagProps({ index })} disabled={fixedOptionsDepartment.indexOf(option) !== -1} />
                    ))}
                    renderInput={(params) => <TextField style={{ width: "100%" }} {...params} label="ค้นหาหน่วยงาน" variant="outlined" placeholder="หน่วยงาน" />}
                  />
                )}
                {valueTarget === "person" && (
                  <Autocomplete multiple id="fixed-tags-demo-person" value={selectedPerson} onChange={(event, newValue) => {
                    setSelectedPerson([...fixedOptionsPerson, ...newValue.filter(option => fixedOptionsPerson.indexOf(option) === -1)]);
                  }} options={person} getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                    renderOption={(props, option) => <li {...props} key={option.idEmployees}>{`${option.firstname_TH} ${option.lastname_TH}`}</li>}
                    renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                      <Chip label={`${option.firstname_TH} ${option.lastname_TH}`} {...getTagProps({ index })} disabled={fixedOptionsPerson.indexOf(option) !== -1} />
                    ))}
                    renderInput={(params) => <TextField style={{ width: "100%" }} {...params} label="ค้นหาพนักงาน" variant="outlined" placeholder="พนักงาน" />}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClosePublic} color="primary">Cancel</Button>
                <Button onClick={() => handleStatusSelect("published")} color="primary" >Publish</Button>
              </DialogActions>
            </Dialog>

          </Dialog>
        </Box>    
      </StyledDialog>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <MoraleFilter filter={filter} setFilterMorale={setFilterMorale} />
      </Drawer>
      <div> 
       <MoraleQuestionsList value={filteredMoraleQuestions} />
      </div>

    </StyledRoot>
  );
}


export default MoraleQuestion;