import {
  // TRANSFER_HEART_IN_FETCHING,
  // TRANSFER_HEART_IN_FAILED,
  // TRANSFER_HEART_IN_SUCCESS,
  TRANSFER_HEART_OUT_FETCHING,
  TRANSFER_HEART_OUT_FAILED,
  TRANSFER_HEART_OUT_SUCCESS,
  // TRANSFER_COIN_IN_FETCHING,
  // TRANSFER_COIN_IN_FAILED,
  // TRANSFER_COIN_IN_SUCCESS,
  TRANSFER_COIN_OUT_MISSION_FETCHING,
  TRANSFER_COIN_OUT_MISSION_FAILED,
  TRANSFER_COIN_OUT_MISSION_SUCCESS,
  // TRANSFER_COIN_OUT_ACTIVITY_FETCHING,
  // TRANSFER_COIN_OUT_ACTIVITY_FAILED,
  // TRANSFER_COIN_OUT_ACTIVITY_SUCCESS,
  TRANSFER_COIN_OUT_COMMU_FETCHING,
  TRANSFER_COIN_OUT_COMMU_FAILED,
  TRANSFER_COIN_OUT_COMMU_SUCCESS,
  TRANSFER_COIN_OUT_MORALE_DAILY_FETCHING,
  TRANSFER_COIN_OUT_MORALE_DAILY_FAILED,
  TRANSFER_COIN_OUT_MORALE_DAILY_SUCCESS,
} from "./types";

import TransferService from "../services/transfer.service";

// export const getTransferHeartIn = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: TRANSFER_HEART_IN_FETCHING
//     });
//     const res = await TransferService.getTransferHeartIn();
//     if (res) {
//       dispatch({
//         type: TRANSFER_HEART_IN_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: TRANSFER_HEART_IN_FAILED,
//     });
//     console.log(error);
//   }
// };

export const getTransferHeartOut = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_HEART_OUT_FETCHING
    });
    const res = await TransferService.getTransferHeartOut();
    if (res) {
      dispatch({
        type: TRANSFER_HEART_OUT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TRANSFER_HEART_OUT_FAILED,
    });
    console.log(error);
  }
};

// export const getTransferCoinIn = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: TRANSFER_COIN_IN_FETCHING
//     });
//     const res = await TransferService.getTransferCoinIn();
//     if (res) {
//       dispatch({
//         type: TRANSFER_COIN_IN_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: TRANSFER_COIN_IN_FAILED,
//     });
//     console.log(error);
//   }
// };

export const getTransferCoinOutMission = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_COIN_OUT_MISSION_FETCHING
    });
    const res = await TransferService.getTransferCoinOutMission();
    if (res) {
      dispatch({
        type: TRANSFER_COIN_OUT_MISSION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TRANSFER_COIN_OUT_MISSION_FAILED,
    });
    console.log(error);
  }
};

// export const getTransferCoinOutActivity = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: TRANSFER_COIN_OUT_ACTIVITY_FETCHING
//     });
//     const res = await TransferService.getTransferCoinOutActivity();
//     if (res) {
//       dispatch({
//         type: TRANSFER_COIN_OUT_ACTIVITY_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: TRANSFER_COIN_OUT_ACTIVITY_FAILED,
//     });
//     console.log(error);
//   }
// };

export const getTransferCoinOutCommu = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_COIN_OUT_COMMU_FETCHING
    });
    const res = await TransferService.getTransferCoinOutCommu();
    if (res) {
      dispatch({
        type: TRANSFER_COIN_OUT_COMMU_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TRANSFER_COIN_OUT_COMMU_FAILED,
    });
    console.log(error);
  }
};

export const getTransferCoinOutMoraleDaily = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_COIN_OUT_MORALE_DAILY_FETCHING,
    });
    const res = await TransferService.getTransferCoinOutMoraleDaily();
    if (res) {
      dispatch({
        type: TRANSFER_COIN_OUT_MORALE_DAILY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TRANSFER_COIN_OUT_MORALE_DAILY_FAILED,
    });
    console.log(error);
  }
};
