import { httpClient } from "./httpClient";

const getFeedback = () => {
  return httpClient.get("feedback");
};

const addFeedback = (formData) => {
  return httpClient.post("feedback", formData);
};

export default {
  getFeedback,
  addFeedback,
};
