import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  Typography,
  Divider,
  OutlinedInput,
  BottomNavigation,
  BottomNavigationAction,
  Snackbar,
  Stack,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import HeaderPage from "../../shared/header/headerPage";
import BtnSendHeart from "./assets/btnSendHeart.jpg";
import SendHeartCoinHeader from "./assets/send.svg";
import { HeartCoin } from "../../shared/typeCoin";
import {
  addHeartTransfer,
  getHeartTransfer,
  updateHeartTransfer,
} from "../../../../actions/heartTransfer";
import SelectEmployees from "../../shared/general/selectEmployees";
import { getAllEmployees } from "../../../../actions/user";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapAllChat = styled("div")({
  marginTop: 20,
  display: "block",
});

const StyledAccordionDetails = styled(AccordionDetails)(({ amount }) => {
  return amount < 0
    ? {
        paddingTop: 0,
        flexDirection: "column",
      }
    : {
        paddingTop: 0,
        flexDirection: "column-reverse",
      };
});

const StyledAccordionSummary = styled(AccordionSummary)(({ bgcolor }) => {
  return { backgroundColor: bgcolor };
});

const WrapCardHeader = styled("div")({
  display: "flex",
  alignItems: "center",
});

const WrapScore = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  paddingRight: 16,
});

const WrapChatFriend = styled("div")({
  display: "flex",
  alignItems: "center",
  maxWidth: 450,
});

const StyledChatFriend = styled(Alert)({
  backgroundColor: "#e3e3e3",
  color: "#282828",
});

const StyledBtnChatMe = styled(Button)({
  marginTop: 10,
  marginLeft: "auto",
  maxWidth: 450,
});

const WrapChatMeReply = styled("div")({
  marginTop: 10,
  marginLeft: "auto",
  ["@media only screen and (min-width:768px)"]: {
    width: 350,
  },
  ["@media only screen and (min-width:992px)"]: {
    width: 500,
  },
  ["@media only screen and (min-width:1200px)"]: {},
});

const StyledChatMeSender = styled(Alert)({
  marginTop: 16,
  marginBottom: 5,
  marginLeft: "auto",
  maxWidth: 450,
});

const WrapBtnReply = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 10,
});

const WrapAllScore = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
});

const WrapBtnSendHeart = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0px",
  ["@media only screen and (min-width: 600px)"]: {
    margin: "16px 0px",
  },
  ["@media only screen and (min-width: 900px)"]: {
    margin: "24px 0px",
  },
});

const StyledBtnSendHeart = styled("div")({
  backgroundSize: "cover",
  height: 100,
  borderRadius: 8,
  backgroundPosition: "bottom",
  display: "flex",
  "& button": {
    width: "100%",
    color: "#FFFFFF",
    paddingLeft: "20%",
    fontSize: "1.5rem",
    ["@media only screen and (min-width: 600px)"]: {
      fontSize: "1.75rem",
    },
    ["@media only screen and (min-width:900px)"]: {
      fontSize: "2rem",
    },
  },
  ["@media only screen and (max-width: 600px)"]: {
    width: 200,
    height: 80,
  },
  ["@media only screen and (min-width: 600px)"]: {
    width: 280,
  },
  ["@media only screen and (min-width:900px)"]: {
    width: 300,
  },
  ["@media only screen and (min-width:1200px)"]: {
    width: 320,
  },
});

const WrapSectionSend = styled("div")({
  marginTop: 20,
});

const btnSendHeartScore = {
  minWidth: 130,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-around",
  padding: 8,
  borderRadius: 45,
  backgroundColor: "#7fd3ff",
};

const BtnSendHeartScore = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "score",
})(({ score }) => {
  return score
    ? {
        ...btnSendHeartScore,
        opacity: 0.4,
      }
    : {
        ...btnSendHeartScore,
      };
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 16,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 0,
});

const HeartTransfer = () => {
  const [mode, setMode] = useState("receiver");
  const [score, setScore] = useState(0);
  const [replyMode, setReplyMode] = useState(false);
  const [reply, setReply] = useState("");
  const [detail, setDetail] = useState("");
  const [expandedSender, setExpandedSender] = React.useState(false);
  const [openSend, setOpenSend] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [filterFriend, setFilterFriend] = React.useState("");
  const [filterScore, setFilterScore] = React.useState([1, 2, 3]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [modeSnackbar, setModeSnackbar] = useState("info");
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const [filterData, setFilterData] = React.useState([]);

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: heartTransfer } = useSelector((state) => state.heartTransfer);
  const { result: users } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getHeartTransfer());
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (heartTransfer) {
      setFilterData(heartTransfer);
    }
  }, [heartTransfer]);

  const handleClickOpenSend = () => {
    setOpenSend(true);
  };

  const handleCloseSend = () => {
    setOpenSend(false);
  };

  const handleChangeSender = (panel) => (event, isExpanded) => {
    setExpandedSender(isExpanded ? panel : false);
  };

  const handleChangeReplyMode = () => {
    setReplyMode(!replyMode);
  };

  const handleChangeReply = (event) => {
    setReply(event.target.value);
  };

  const handleChangeDetail = (event) => {
    setDetail(event.target.value);
  };

  const handleClickSelectScore = (score) => {
    setScore(score);
  };

  const handleChangFilterFriend = (event) => {
    setFilterFriend(event.target.value);
    const filterUser = users.filter(
      (user) =>
        user.firstname_TH
          .toLowerCase()
          .startsWith(event.target.value.toLowerCase()) ||
        user.lastname_TH
          .toLowerCase()
          .startsWith(event.target.value.toLowerCase())
    );
    const newValue = heartTransfer.filter((item) =>
      filterUser.some(
        (user) =>
          item.idReceiver === user.idEmployees ||
          item.idSender === user.idEmployees
      )
    );
    setFilterData(newValue);
  };

  const handleChangeFilterDate = (date) => {
    setSelectedDate(date);
    const newValue = heartTransfer.filter((item) =>
      dayjs(item.transferDate).isSame(date, "day")
    );
    setFilterData(newValue);
  };

  // const handleChangeFilterScore = (event) => {
  //   setFilterScore({
  //     ...filterScore,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const handleChangeFilterScore = (event) => {
    const {
      target: { value },
    } = event;
    setFilterScore(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOpenSnackbar = (value, modeSnackbar) => {
    setDisplaySnackbar(value);
    setModeSnackbar(modeSnackbar);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  useEffect(() => {
    if (heartTransfer) {
      const filterTransaction = heartTransfer.filter((item) =>
        filterScore.includes(item.value)
      );
      setFilterData(filterTransaction);
    }
  }, [filterScore, heartTransfer]);

  const handleSendHeart = async () => {
    if (score > userProfile.sendHeartBalance) {
      handleOpenSnackbar("หัวใจคงเหลือไม่เพียงพอส่ง", "error");
    } else if (
      selectedEmployee === "" ||
      score === 0 ||
      detail.trim().length === 0
    ) {
      handleOpenSnackbar("โปรดกรอกข้อมูลให้ครบ", "error");
    } else {
      const res = await dispatch(
        addHeartTransfer({
          score,
          detail,
          idReceiver: selectedEmployee.idEmployees,
        })
      );
      if (res) {
        dispatch(getHeartTransfer());
        handleOpenSnackbar("ส่งหัวใจสำเร็จ", "success");
        handleCloseSend();
      }
    }
  };

  const handleSendReply = async (idHeartTransfer) => {
    const res = await dispatch(
      updateHeartTransfer({
        idHeartTransfer: idHeartTransfer,
        reply: reply,
      })
    );
    if (res) {
      dispatch(getHeartTransfer());
      handleOpenSnackbar("เพิ่มข้อมูลสำเร็จ", "success");
    }
  };

  return (
    <StyledRoot className={"page"}>
      {userProfile && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <HeaderPage textLabel={"รับส่งหัวใจ"} icon={SendHeartCoinHeader} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography marginRight={1}>
              {`หัวใจที่เหลือ : ${userProfile.sendHeartBalance}/${userProfile.sendHeartQuota}`}
            </Typography>
            <HeartCoin width={24} />
          </div>
        </Stack>
      )}
      <Dialog
        open={openSend}
        onClose={handleCloseSend}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ส่งหัวใจ"}</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 16 }}>
            {/* <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              options={users.filter((option) => option.idEmployees !== currentUser.id)}
              getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
              value={selectedEmployee}
              onChange={(event, newValue) => {
                setselectedEmployee(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="เพื่อนร่วมงาน" />}
            /> */}
            <SelectEmployees handleChange={handleSelectEmployee} selectedCompany={null} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
            }}
          >
            <BtnSendHeartScore
              score={score === 3}
              onClick={() => {
                handleClickSelectScore(3);
              }}
            >
              <HeartCoin width={35} />
              <HeartCoin width={35} />
              <HeartCoin width={35} />
            </BtnSendHeartScore>
            <BtnSendHeartScore
              score={score === 2}
              onClick={() => {
                handleClickSelectScore(2);
              }}
            >
              <HeartCoin width={35} />
              <HeartCoin width={35} />
            </BtnSendHeartScore>
            <BtnSendHeartScore
              score={score === 1}
              onClick={() => {
                handleClickSelectScore(1);
              }}
            >
              <HeartCoin width={35} />
            </BtnSendHeartScore>
          </div>
          <WrapSectionSend>
            <TextField
              multiline
              rows={2}
              inputProps={{ maxLength: 100 }}
              fullWidth
              id="msg-to-friend"
              label="ข้อความแทนคำขอบคุณ..."
              variant="outlined"
              value={detail}
              onChange={handleChangeDetail}
            />
            <div style={{ marginTop: 5 }}>
              <Typography color="textSecondary">
                {`(${detail.length}/100)`}
              </Typography>
            </div>
          </WrapSectionSend>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSend} color="primary">
            {" "}
            ยกเลิก{" "}
          </Button>
          <Button onClick={handleSendHeart} color="primary" autoFocus>
            {" "}
            ส่งหัวใจ{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <StyledDivider />
      <WrapBtnSendHeart>
        <StyledBtnSendHeart style={{ backgroundImage: `url(${BtnSendHeart})` }}>
          <Button onClick={handleClickOpenSend}>ส่งหัวใจ</Button>
        </StyledBtnSendHeart>
      </WrapBtnSendHeart>

      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        <BottomNavigation
          showLabels
          value={mode}
          onChange={(event, newValue) => {
            setMode(newValue);
          }}
        >
          <BottomNavigationAction
            label="Receiver"
            value="receiver"
            icon={<MoveToInboxIcon />}
          />
          <BottomNavigationAction
            label="Sender"
            value="sender"
            icon={<OutboxIcon />}
          />
        </BottomNavigation>
      </div>

      <Grid
        container
        spacing={2}
        width={"80%"}
        justifyContent="center"
        alignSelf={"center"}
      >
        <Grid item xs={12} sm={12} md={4}>
          <FormControl
            style={{ width: "100%" }}
            variant="filled"
            color="secondary"
          >
            <FormLabel component="legend">ค้นหาชื่อ</FormLabel>
            <OutlinedInput
              id="standard-adornment-password"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={filterFriend}
              onChange={handleChangFilterFriend}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormLabel component="legend">ค้นหาวันที่</FormLabel>
          <DatePicker
            sx={{ width: "100%" }}
            views={["month", "day"]}
            format="DD MMMM"
            minDate={dayjs().startOf("year")}
            maxDate={dayjs().endOf("year")}
            value={selectedDate}
            onChange={handleChangeFilterDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {/* <StyledFormControl component="fieldset" fullWidth>
              <FormLabel component="legend">จำนวนหัวใจ</FormLabel>
              <FormGroup style={{ flexDirection: "row" }}>
                <FormControlLabel
                  style={{ marginRight: 10 }}
                  control={
                    <Checkbox
                      checked={filterScore.one}
                      onChange={handleChangeFilterScore}
                      name="one"
                    />
                  }
                  label={
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                    </div>
                  }
                />
                <FormControlLabel
                  style={{ marginRight: 10 }}
                  control={
                    <Checkbox
                      checked={filterScore.two}
                      onChange={handleChangeFilterScore}
                      name="two"
                    />
                  }
                  label={
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                    </div>
                  }
                />
                <FormControlLabel
                  style={{ marginRight: 10 }}
                  control={
                    <Checkbox
                      checked={filterScore.three}
                      onChange={handleChangeFilterScore}
                      name="three"
                    />
                  }
                  label={
                    <div style={{ display: "flex" }}>
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                      <HeartCoin width={25} />
                    </div>
                  }
                />
              </FormGroup>
            </StyledFormControl> */}
          <FormControl fullWidth sx={{ height: "auto" }}>
            <FormLabel component="legend">จำนวนหัวใจ</FormLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              label={"จำนวนหัวใจ"}
              value={filterScore}
              onChange={handleChangeFilterScore}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => (
                <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
                  {selected.map((value, index) => (
                    <Chip
                      color="error"
                      size="small"
                      key={value}
                      label={value}
                      icon={<FavoriteIcon />}
                    />
                  ))}
                </Stack>
              )}
            >
              <MenuItem key={"one"} value={1}>
                <Checkbox checked={filterScore.indexOf(1) > -1} />
                <div style={{ display: "flex" }}>
                  <HeartCoin width={25} />
                </div>
              </MenuItem>
              <MenuItem key={"two"} value={2}>
                <Checkbox checked={filterScore.indexOf(2) > -1} />
                <div style={{ display: "flex" }}>
                  <HeartCoin width={25} />
                  <HeartCoin width={25} />
                </div>
              </MenuItem>
              <MenuItem key={"three"} value={3}>
                <Checkbox checked={filterScore.indexOf(3) > -1} />
                <div style={{ display: "flex" }}>
                  <HeartCoin width={25} />
                  <HeartCoin width={25} />
                  <HeartCoin width={25} />
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <WrapAllChat>
        {heartTransfer &&
          users &&
          filterData
            .filter((item) => {
              return mode === "receiver"
                ? item.idReceiver === userProfile.idEmployees
                : item.idSender === userProfile.idEmployees;
            })
            .map((item, index) => {
              return (
                <Accordion
                  expanded={expandedSender === "panel" + index}
                  onChange={handleChangeSender("panel" + index)}
                  key={item.idHeartTransfer}
                >
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    bgcolor={mode === "receiver" ? "#CAFFBF" : "#FFADAD"}
                  >
                    <WrapCardHeader>
                      {mode === "receiver" ? (
                        <Avatar
                          style={{ marginRight: 16 }}
                          alt={"receiver image"}
                          src={item.sender_imageURL}
                        />
                      ) : (
                        <Avatar
                          style={{ marginRight: 16 }}
                          alt={"sender image"}
                          src={item.receiver_imageURL}
                        />
                      )}
                      <div>
                        <div style={{ display: "flex" }}>
                          <Typography>
                            {mode === "receiver"
                              ? `${item.sender_firstname_TH} ${item.sender_lastname_TH}`
                              : `${item.receiver_lastname_TH} ${item.receiver_lastname_TH}`}
                          </Typography>
                        </div>
                        <Typography variant="body2">
                          {dayjs(item.transferDate).format("D MMM YYYY")}
                        </Typography>
                      </div>
                    </WrapCardHeader>
                    <WrapAllScore>
                      <WrapScore>
                        <Typography style={{ fontSize: 36 }}>
                          {item.value}
                        </Typography>
                        <HeartCoin width={20} />
                      </WrapScore>
                    </WrapAllScore>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails amount={item.value}>
                    <div>
                      {mode === "sender" ? (
                        <StyledChatMeSender
                          icon={false}
                          variant="filled"
                          severity="info"
                        >
                          <Avatar
                            style={{ marginRight: 16 }}
                            alt={"receiver"}
                            src={item.receiver_imageURL}
                          />
                          {item.detail}
                        </StyledChatMeSender>
                      ) : (
                        <Fragment>
                          {item.reply ? (
                            <StyledChatMeSender
                              icon={false}
                              variant="filled"
                              severity="info"
                            >
                              <Avatar
                                style={{ marginRight: 16 }}
                                alt={"sender"}
                                src={item.sender_imageURL}
                              />
                              {item.reply}
                            </StyledChatMeSender>
                          ) : (
                            <div style={{ display: "flex" }}>
                              {!replyMode ? (
                                <StyledBtnChatMe
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleChangeReplyMode}
                                >
                                  {" "}
                                  ...คลิกที่นี่เพื่อตอบกลับ...{" "}
                                </StyledBtnChatMe>
                              ) : (
                                <WrapChatMeReply>
                                  <TextField
                                    inputProps={{ maxLength: 60 }}
                                    style={{ width: "100%" }}
                                    id="standard-basic"
                                    label="ข้อความ"
                                    onChange={handleChangeReply}
                                  />
                                  <WrapBtnReply>
                                    <div>
                                      <Typography color="textSecondary">
                                        {`(${reply.length}/60)`}
                                      </Typography>
                                    </div>
                                    <div>
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        style={{ marginRight: 10 }}
                                        startIcon={<DeleteIcon />}
                                        onClick={handleChangeReplyMode}
                                      >
                                        {" "}
                                        ลบ{" "}
                                      </Button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<SendIcon />}
                                        onClick={() =>
                                          handleSendReply(item.idHeartTransfer)
                                        }
                                      >
                                        {" "}
                                        ส่งข้อความ{" "}
                                      </Button>
                                    </div>
                                  </WrapBtnReply>
                                </WrapChatMeReply>
                              )}
                            </div>
                          )}
                        </Fragment>
                      )}
                      {mode === "receiver" && (
                        <Typography
                          style={{ textAlign: "right", fontSize: 14 }}
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          เหตุผล: ข้อความแทนคำขอบคุณ
                        </Typography>
                      )}
                    </div>
                    <WrapChatFriend>
                      {mode === "sender" ? (
                        <StyledChatFriend icon={false} variant="filled">
                          <Avatar
                            style={{ marginRight: 16 }}
                            alt={"receiver"}
                            src={item.receiver_imageURL}
                          />
                          {item.reply ? item.reply : "ยังไม่มีการตอบกลับ"}
                        </StyledChatFriend>
                      ) : (
                        <StyledChatFriend icon={false} variant="filled">
                          <Avatar
                            style={{ marginRight: 16 }}
                            alt={"sender"}
                            src={item.sender_imageURL}
                          />
                          {item.detail}
                        </StyledChatFriend>
                      )}
                    </WrapChatFriend>
                  </StyledAccordionDetails>
                </Accordion>
              );
            })}
      </WrapAllChat>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          sx={{ width: "100%" }}
          severity={modeSnackbar}
        >
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
};

export default HeartTransfer;
