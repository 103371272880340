import {
  MORALEQUESTION_KPI_FETCHING,
  MORALEQUESTION_KPI_FAILED,
  MORALEQUESTION_KPI_SUCCESS,
  MORALEQUESTION_KPI_ITEM_FETCHING,
  MORALEQUESTION_KPI_ITEM_FAILED,
  MORALEQUESTION_KPI_ITEM_SUCCESS,
  MORALEQUESTION_KPI_YEAR_FETCHING,
  MORALEQUESTION_KPI_YEAR_FAILED,
  MORALEQUESTION_KPI_YEAR_SUCCESS,
  MORALEQUESTION_KPI_YEAR_ITEM_FETCHING,
  MORALEQUESTION_KPI_YEAR_ITEM_FAILED,
  MORALEQUESTION_KPI_YEAR_ITEM_SUCCESS,
  MORALECOMPARE_FETCHING,
  MORALECOMPARE_FAILED,
  MORALECOMPARE_SUCCESS,
} from "./types";

import moraleKpiService from "../services/morale-kpi.service";

export const getAllMoraleKPI = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_KPI_FETCHING,
    });
    const res = await moraleKpiService.getAllMoraleKPI();
    if (res) {
      dispatch({
        type: MORALEQUESTION_KPI_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_KPI_FAILED,
    });
    console.log(err);
  }
};

export const getMoraleKPI = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_KPI_ITEM_FETCHING,
    });
    const res = await moraleKpiService.getMoraleKPI(query);
    if (res) {
      dispatch({
        type: MORALEQUESTION_KPI_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_KPI_ITEM_FAILED,
    });
  }
};

//MoraleCompare
export const getMoraleCompare = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALECOMPARE_FETCHING,
    });
    const res = await moraleKpiService.getMoraleCompare();
    if (res) {
      dispatch({
        type: MORALECOMPARE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALECOMPARE_FAILED,
    });
    console.log(err);
  }
};

//Morale KPI Year
export const getAllMoraleKPIYear = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_FETCHING,
    });
    const res = await moraleKpiService.getAllMoraleKPIYear(idCompany);
    if (res) {
      dispatch({
        type: MORALEQUESTION_KPI_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_FAILED,
    });
  }
};

export const getMoraleKPIYear = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_ITEM_FETCHING,
    });
    const res = await moraleKpiService.getMoraleKPIYear(query);
    if (res) {
      dispatch({
        type: MORALEQUESTION_KPI_YEAR_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_ITEM_FAILED,
    });
  }
};

export const getMoraleKPIDaily = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_FETCHING,
    });
    const res = await moraleKpiService.getMoraleKPIDaily(query);
    if (res) {
      dispatch({
        type: MORALEQUESTION_KPI_YEAR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALEQUESTION_KPI_YEAR_FAILED,
    });
  }
};