import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, TextField, Avatar, Box, Button, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "./../../../../actions/user";


const WrapDrawerFilter = styled('div')({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
  "& .wrapListUsers": {
    marginTop: 16,
  },
})

const AutocompleteListUsers = ({ setIdUser, setDate }) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: usersList } = useSelector((state) => state.users);

  const [selectedPerson, setSelectedPerson] = useState([]);
  const [filterDate ,setFilterDate] = useState(null)

  const fixedOptionsPerson = [];

  const handleChangeFilterDate = (date) => {
    setFilterDate(date);
  };

  const handleSearch = () => {
    const selectIdEmployee = selectedPerson.map(item => item.idEmployees)
    setIdUser(selectIdEmployee)
    setDate(filterDate)
  }

  const handleReset = () => {
    setFilterDate(null)
    setSelectedPerson([])
    setDate(null)
    setIdUser([])
  }
  
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <div>
      {usersList && (
        <WrapDrawerFilter>
          <DatePicker
            label="ค้นหาวันที่"
            value={filterDate}
            onChange={handleChangeFilterDate}
          />
          <div className={`wrapListUsers`}>
            <Autocomplete
              multiple
              id="fixed-tags-demo-person"
              value={selectedPerson}
              onChange={(event, newValue) => {
                setSelectedPerson([
                  ...fixedOptionsPerson,
                  ...newValue.filter(
                    (option) => fixedOptionsPerson.indexOf(option) === -1
                  ),
                ]);
              }}
              options={usersList.filter(user => user.idEmployees !== userProfile.idEmployees)}
              getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="32"
                    alt={`${option.firstname_TH}_${option.lastname_TH}`}
                    // src={`${process.env.REACT_APP_URL}image/profile/${params.image}`}
                    src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                  />
                  {`${option.firstname_TH} ${option.lastname_TH}`}
                </Box>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((params, index) => (
                  <Chip
                    avatar={
                      <Avatar
                        alt={`${params.firstname_TH}_${params.lastname_TH}`}
                        // src={`${process.env.REACT_APP_URL}image/profile/${params.image}`}
                        src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                      />
                    }
                    label={`${params.firstname_TH} ${params.lastname_TH}`}
                    {...getTagProps({ index })}
                    disabled={fixedOptionsPerson.indexOf(params) !== -1}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%" }}
                  {...params}
                  label="ค้นหาพนักงาน"
                  variant="outlined"
                  placeholder="ค้นหาพนักงาน"
                />
              )}
            />
          </div>
          <div className={`wrapBtnReset`}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleSearch}
            >
              search
            </Button>
            <Button 
              variant="outlined" 
              onClick={handleReset}
            >
              reset
            </Button>
          </div>
        </WrapDrawerFilter>
      )}
    </div>
  );
};

export default AutocompleteListUsers;
