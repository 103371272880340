import { httpClient } from "./httpClient";

const getAllpolls = () => {
  return httpClient.get("polls");
};

const getPoll = () => {
  return httpClient.get("poll");
};

const addPoll = (formData) => {
  return httpClient.post("poll", formData);
};

const updatePollVote = (formData) => {
  return httpClient.patch("poll-vote", formData);
};

const updatePollStatus = (formData) => {
  return httpClient.patch("poll-status", formData);
};

export default {
  getAllpolls,
  addPoll,
  updatePollVote,
  updatePollStatus,
  getPoll,
};
