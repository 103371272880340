import { httpClient } from "./httpClient";

const getCoachReviewByIdCoach = (idCoach) => {
  return httpClient.get("coach-review/" + idCoach);
};

const addCoachReview = (formData) => {
  return httpClient.post("coach-review", formData);
};

const deleteCoachReview = (idBooking) => {
  return httpClient.delete("coach-review/" + idBooking);
};

const getCoachReviewByIdBooking = (idBooking) => {
  return httpClient.get("booking-review/" + idBooking);
};

export default {
  getCoachReviewByIdCoach,
  addCoachReview,
  deleteCoachReview,
  getCoachReviewByIdBooking,
};
