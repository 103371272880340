import React from "react";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import {
  useCoinInReward,
  useCoinOutActivity,
} from "../../../../quries/coin";

const StyledText = styled(Typography)({
  color: "#808080cf",
  textTransform: "uppercase",
  fontSize: 16,
});

const WrapText = styled("div")({
  width: "100%",
  textAlign: "center",
});

const StyledTextH3 = styled(Typography)({
  color: "whitesmoke",
  fontSize: 90,
});

const StyledTextCoinType = styled(Typography)({
  color: "whitesmoke",
  textAlign: "center",
});

const StyledTextMonthYear = styled(Typography)({
  color: "whitesmoke",
  textTransform: "uppercase",
  margin: "0 10px",
});

const StyledTablePagination = styled(TablePagination)({
  color: "white",
  "& .MuiTablePagination-selectLabel": {
    color: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "white",
  },
  "& .MuiIconButton-root.Mui-disabled": {
    color: "white",
  },
});

const WrapHeaderSection = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  marginTop: 16,
});

const WrapHeaderSectionFilterDate = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
});

const StyledIconButton = styled(IconButton)({
  color: "whitesmoke",
  "&:hover": {
    backgroundColor: "#ffffff42",
  },
});

const StyledTableContainer = styled(TableContainer)({
  height: 408,
  backgroundColor: "transparent",
});

const StyledFormControlLabelRadio = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "whitesmoke",
  },
});

const StyledSumDivider = styled(Divider)({
  backgroundColor: "#ffffff21",
  marginTop: 16,
  marginBottom: 16,
  width: 2,
});

const StyledRadioGroup = styled(RadioGroup)({
  color: "whitesmoke",
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "whitesmoke",
    borderBottom: "1px solid #404040",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "whitesmoke",
    borderBottom: "1px solid #404040",
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}));

const WrapHeaderSumMonth = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 16,
});

const StyledDatePicker = styled(DatePicker)({
  '& .MuiOutlinedInput-root': {
    color: '#ffffff',
    '& fieldset': {
      borderColor: '#ffffff',
    },
    '&:hover fieldset': {
      borderColor: '#ffffff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiIconButton-root': {
    color: '#ffffff',
  }
});

const TableTransaction = ({ coinType }) => {
  const coinInReward = useCoinInReward({ idCoinType: coinType.idCoinType });
  const coinOutActivity = useCoinOutActivity({ idCoinType: coinType.idCoinType });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterDate, setFilterDate] = React.useState(dayjs());
  const [selectedView, setSelectedView] = React.useState("allMonth");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeView = (event) => {
    setSelectedView(event.target.value);
  };

  const handleChangeMonth = (action) => {
    if (action === 1) {
      if (dayjs().isSame(filterDate.add(1, 'month'), 'month')) {
        setFilterDate(dayjs())
      } else {
        setFilterDate(prev => prev.add(1, 'month'))
      }
    } else if (action === -1) {
      setFilterDate(prev => prev.subtract(1, 'month'))
    }
  };

  const sumMonthCoinOut = coinOutActivity?.data?.reduce((prev, curr) => {
    return dayjs(filterDate).isSame(curr.rewardDate, "month")
      ? curr.coin + prev
      : prev;
  }, 0);

  const rowsFilterOut = coinOutActivity?.data?.filter((value) => {
    if (selectedView === "allMonth") {
      if (dayjs(filterDate).isSame(value.rewardDate, "month")) {
        return value;
      }
    } else {
      if (dayjs(filterDate).isSame(value.rewardDate, "day")) {
        return value;
      }
    }
  });

  const sumMonthCoinIn = coinInReward?.data?.reduce((prev, curr) => {
    return dayjs(filterDate).isSame(curr.transactionDate, "month")
      ? curr.coin + prev
      : prev;
  }, 0);

  const rowsFilterIn = coinInReward?.data?.filter((value) => {
    if (selectedView === "allMonth") {
      if (dayjs(filterDate).isSame(value.transactionDate, "month")) {
        return value;
      }
    } else {
      if (dayjs(filterDate).isSame(value.transactionDate, "day")) {
        return value;
      }
    }
  });

  return (
    <>
      <Grid item xs={12} lg={6}>
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <StyledTextCoinType variant="h3">
            {coinType.coinName}
          </StyledTextCoinType>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <WrapText>
            <StyledText variant="subtitle1">OUT</StyledText>
            <StyledTextH3 variant="h3">{sumMonthCoinOut}</StyledTextH3>
          </WrapText>
          <WrapText>
            <StyledText variant="subtitle1">IN</StyledText>
            <StyledTextH3 variant="h3">{sumMonthCoinIn}</StyledTextH3>
          </WrapText>
          <StyledSumDivider orientation="vertical" flexItem />
        </div>
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <StyledText textAlign={"center"}>
            {filterDate.format("MMMM")}
          </StyledText>
        </div>
      </Grid>
      <Grid item xs={12}>
        <WrapHeaderSection>
          <Tooltip title="เดือนก่อน" aria-label="labelBeforeMonth">
            <StyledIconButton
              onClick={() => handleChangeMonth(-1)}
              aria-label="beforeMonth"
              size="small"
            >
              <KeyboardArrowLeftIcon fontSize="inherit" />
            </StyledIconButton>
          </Tooltip>
          <StyledTextMonthYear variant="h6">
            {filterDate.format("MMMM YYYY")}
          </StyledTextMonthYear>
          <Tooltip title="เดือนหน้า" aria-label="labelNextMonth">
            <span>
              <StyledIconButton
                onClick={() => handleChangeMonth(1)}
                aria-label="nextMonth"
                size="small"
                disabled={dayjs().isSame(filterDate, 'month')}
              >
                <KeyboardArrowRightIcon fontSize="inherit" />
              </StyledIconButton>
            </span>
          </Tooltip>
        </WrapHeaderSection>
        <div>
          <WrapHeaderSectionFilterDate>
            <FormControl component="fieldset">
              <StyledRadioGroup
                row
                aria-label="view"
                name="view"
                value={selectedView}
                onChange={handleChangeView}
              >
                <StyledFormControlLabelRadio
                  style={{ marginBottom: 0 }}
                  value="allMonth"
                  label="ทั้งเดือน"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": { color: "#f50057" },
                      }}
                    />
                  }
                />
                <StyledFormControlLabelRadio
                  style={{ marginBottom: 0 }}
                  value="day"
                  label="วันที่"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": { color: "#f50057" },
                      }}
                    />
                  }
                />
              </StyledRadioGroup>
            </FormControl>
            <StyledDatePicker
              value={filterDate}
              minDate={filterDate.startOf('month')}
              maxDate={dayjs()}
              onChange={(newValue) => setFilterDate(newValue)}
            />
          </WrapHeaderSectionFilterDate>
          <div>
            <WrapHeaderSumMonth>
              <Typography variant="h6" style={{ color: "#ffffff" }}>
                &nbsp;{"แจกออก"}
              </Typography>
            </WrapHeaderSumMonth>
            <StyledTableContainer component={Paper} elevation={0}>
              <Table size="small" aria-label="a dense table">
                <TableHead style={{ backgroundColor: "#282933" }}>
                  <TableRow>
                    <StyledTableCell>วันที่</StyledTableCell>
                    <StyledTableCell>จำนวน</StyledTableCell>
                    <StyledTableCell>กิจกรรม</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFilterOut
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {dayjs(row.rewardDate).format("D MMM YYYY")}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.coin}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={rowsFilterOut.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          <div>
            <WrapHeaderSumMonth>
              <Typography variant="h6" style={{ color: "#ffffff" }}>
                &nbsp;{"แลกเข้า"}
              </Typography>
            </WrapHeaderSumMonth>
            <StyledTableContainer component={Paper} elevation={0}>
              <Table size="small" aria-label="a dense table">
                <TableHead style={{ backgroundColor: "#282933" }}>
                  <TableRow>
                    <StyledTableCell>วันที่</StyledTableCell>
                    <StyledTableCell>จำนวน</StyledTableCell>
                    <StyledTableCell>ชื่อ-นามสกุล</StyledTableCell>
                    <StyledTableCell>หน่วยงาน</StyledTableCell>
                    <StyledTableCell>ของรางวัล</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFilterIn
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {dayjs(row.transactionDate).format("D MMM YYYY")}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.coin}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {`${row.firstname_TH} ${row.lastname_TH}`}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.departmentName}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={rowsFilterIn.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};

export default TableTransaction;
