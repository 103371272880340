import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  CardActionArea,
  TextField,
  Avatar,
} from "@mui/material";
import FeelingIcon from "../../shared/feelingIcon";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticManager } from "../../../../actions/analytic";
import { getHeartLeaderboard } from "../../../../actions/leaderboard";
import AnalyticDetail from "./AnalyticDetail";

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 40,
});

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 16,
  width: "100%",
  // ["@media only screen and (max-width:600px)"]: {
  //   flexDirection: "column",
  // },
});

const StyledCardMedia = styled(CardMedia)({
  width: 120,
  borderRadius: "50%",
  padding: 8,
});

const AnalyticList = () => {
  const [filterUser, setFilterUser] = useState("");
  // const [filterData, setFilterData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: analytic } = useSelector((state) => state.analytic);

  // Onload
  useEffect(() => {
    if (userProfile) {
      dispatch(getAnalyticManager(userProfile.idEmployees));
    }
  }, [userProfile]);

  const handleChangFilterUser = (event) => {
    setFilterUser(event.target.value);
  };

  const filterData = useMemo(() => {
    return (analytic || []).filter((user) =>
      user.firstname_TH.toLowerCase().includes(filterUser.toLowerCase()) ||
      user.lastname_TH.toLowerCase().includes(filterUser.toLowerCase())
  )}, [analytic, filterUser]);

  const handleClickOpen = (scrollType, user) => () => {
    setData(user);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setData(null);
  };

  const sumMoraleScore = (user) => {
    console.log(user)
    const sumMoraleQuarter =
      user.moraleAverageScore.reduce((acc, morale) => morale.average + acc, 0) /
      user.moraleAverageScore.length;
    return Math.round(
      (sumMoraleQuarter +
        user.moraleDailyAverageScore +
        user.assessmentScore) /
        3
    );
  };

  return (
    <>
      <WrapFilter>
        <Typography variant="h5">{`รายชื่อพนักงาน`}</Typography>
        <TextField
          label="ค้นหาชื่อ"
          value={filterUser}
          onChange={handleChangFilterUser}
        />
      </WrapFilter>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {analytic &&
          filterData.map((user, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card elevation={2}>
                  <CardActionArea onClick={handleClickOpen("paper", user)}>
                    <StyledCardContent>
                      <div style={{ marginBottom: 16 }}>
                        <Avatar
                          src={user.imageURL}
                          alt={"user image"}
                          sx={{ width: 80, height: 80 }}
                        />
                      </div>
                      <Typography variant="h6" textAlign={"center"} noWrap>
                        {`${user.firstname_TH} ${user.lastname_TH}`}
                      </Typography>
                      <Typography color="text.secondary" textAlign={"center"} noWrap>
                        {user.positionName}
                      </Typography>
                      <Typography color="text.secondary" textAlign={"center"} noWrap>
                        {user.companyName}
                      </Typography>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent="space-around"
                        alignItems="center"
                        marginTop={2}
                        gap={2}
                      >
                        <div style={{ width: 40, height: 40 }}>
                          {user.averageTotalScore === 100 ? (
                            <FeelingIcon feeling={"veryhappy"} />
                          ) : user.averageTotalScore >= 80 ? (
                            <FeelingIcon feeling={"happy"} />
                          ) : user.averageTotalScore >= 60 ? (
                            <FeelingIcon feeling={"littlehappy"} />
                          ) : user.averageTotalScore >= 40 ? (
                            <FeelingIcon feeling={"littlebad"} />
                          ) : user.averageTotalScore >= 20 ? (
                            <FeelingIcon feeling={"bad"} />
                          ) : (
                            <FeelingIcon feeling={"verybad"} />
                          )}
                        </div>
                        <Typography variant="h6">
                          {`Morale ${user.averageTotalScore}%`}
                        </Typography>
                      </Stack>
                    </StyledCardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {open && data && (
        <AnalyticDetail
          open={open}
          handleClose={handleClose}
          scroll={scroll}
          detail={data}
        />
      )}
    </>
  );
};

export default AnalyticList;
