import React, { useEffect, useState, Fragment } from "react";
import "./index.css";
import { styled } from '@mui/material/styles';
import {
  Typography,
  Divider,
  Grid,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  IconButton,
  Menu,
  List,
  ListItem,
  Avatar,
  Fade,
  TablePagination,
} from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "./../../../../actions/user";
import { getDepartment } from "./../../../../actions/department";

const WrapFilterStatusEmployee = styled('div')({
  marginTop: 16,
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  "& button": {
    borderRadius: 0,
  },
  "& .MuiToggleButton-root": {
    border: "none",
  },
})

const WrapFilter = styled('div')({
  margin: "16px 0",
})

const StyledAvatar= styled(Avatar)({
  width: 60,
  height: 60,
  marginRight: 8,
})

const StyledTextName = styled(Typography)({
  fontSize: 18,
})

const statusTag = {
  height: 22,
  minWidth: 22,
  borderRadius: 8,
  alignItems: "center",
  whiteSpace: "nowrap",
  display: "inline-flex",
  justifyContent: "center",
  padding: "0px 8px",
}
const WrapStatusTag = styled('div')(({ status }) => {
  return status === "terminate" 
    ? {
        ...statusTag,
        color: "rgb(183, 33, 54)",
        backgroundColor: "rgba(255, 72, 66, 0.16)",
    }
    : {
      ...statusTag,
      color: "rgb(34, 154, 22)",
      backgroundColor: "rgba(84, 214, 44, 0.16)",
    }
})

const StyledStatusTagLabel = styled(Typography)({
  lineHeight: 0,
  fontWeight: 700,
  fontSize: 14,
  textTransform: "capitalize",
})

const WrapFirstColumn = styled('div')({
  display: "flex",
  alignItems: "center",
})

const StyledTextOverFlow = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

const LabelAbout = styled(Typography)({ fontSize: 12 })
const TextAbout = styled(Typography)({ fontSize: 14 })
const StyledEditOutlinedIcon = styled(EditOutlinedIcon)({ marginRight: 16 })
const StyledDeleteOutlineOutlinedIcon = styled(DeleteOutlineOutlinedIcon)({ marginRight: 16 })

const EmployeeList = () => {
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: employeeList } = useSelector((state) => state.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterEmployee, setFilterEmployee] = useState({
    search: "",
    idDepartment: "all",
    site: "all",
    status: "all",
  });

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
  }, []);

  const handleChangeStatusEmployee = (event, newStatus) => {
    setPage(0);
    if (newStatus !== null) {
      setFilterEmployee({
        ...filterEmployee,
        ["status"]: newStatus,
      });
    }
  };

  const handleChangeFilterEmployee = (event) => {
    setPage(0);
    const name = event.target.name;
    setFilterEmployee({
      ...filterEmployee,
      [name]: event.target.value,
    });
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsFilter = () => {
    if (
      filterEmployee.search === "" &&
      filterEmployee.idDepartment === "all" &&
      filterEmployee.site === "all" &&
      filterEmployee.status === "all"
    ) {
      return employeeList;
    } else {
      let resultFilter = [...employeeList];

      if (filterEmployee.status !== "all") {
        resultFilter = resultFilter.filter(
          (item) => item.employmentStatus === filterEmployee.status
        );
      }

      if (filterEmployee.search.length > 0) {
        resultFilter = resultFilter.filter(
          (item) =>
            item.firstname_TH
              .toLowerCase()
              .includes(filterEmployee.search.toLowerCase()) ||
            item.lastname_TH
              .toLowerCase()
              .includes(filterEmployee.search.toLowerCase())
        );
      }

      if (filterEmployee.idDepartment !== "all") {
        resultFilter = resultFilter.filter(
          (item) => item.idDepartment === filterEmployee.idDepartment
        );
      }

      if (filterEmployee.site !== "all") {
        resultFilter = resultFilter.filter(
          (item) => item.location === filterEmployee.site
        );
      }
      return resultFilter;
    }
  };
  
  return (
    <div>
      <WrapFilterStatusEmployee>
        <StyledToggleButtonGroup
          value={filterEmployee.status}
          exclusive
          onChange={handleChangeStatusEmployee}
          aria-label="filter employee"
          size="small"
        >
          <ToggleButton value="all" aria-label="all" size="small">
            All Users
          </ToggleButton>
          <ToggleButton value="active" aria-label="active" size="small">
            Active Users
          </ToggleButton>
          <ToggleButton value="terminate" aria-label="terminate" size="small">
            Terminate Users
          </ToggleButton>
        </StyledToggleButtonGroup>
      </WrapFilterStatusEmployee>
      <WrapFilter>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-search">
                ค้นหาพนักงาน
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-weight"
                value={filterEmployee.search}
                onChange={handleChangeFilterEmployee}
                name="search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" edge="end" size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                หน่วยงาน
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filterEmployee.idDepartment}
                onChange={handleChangeFilterEmployee}
                label="หน่วยงาน"
                name="idDepartment"
              >
                <MenuItem value="all">
                  <em>ทุกหน่วยงาน</em>
                </MenuItem>
                {departmentList &&
                  departmentList.map((dep) => (
                    <MenuItem key={dep.idDepartment} value={dep.idDepartment}>
                      {dep.departmentName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                สถานที่ทำงาน
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={filterEmployee.site}
                onChange={handleChangeFilterEmployee}
                label="สถานที่ทำงาน"
                name="site"
              >
                <MenuItem value="all">
                  <em>ทุก Site</em>
                </MenuItem>
                <MenuItem value={"TPE - Rayong Site#1"}>TPE - Rayong Site#1</MenuItem>
                <MenuItem value={"TPE - Rayong Site#3"}>TPE - Rayong Site#3</MenuItem>
                <MenuItem value={"TPE - Rayong Site#7"}>TPE - Rayong Site#7</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </WrapFilter>
      <div>
        {employeeList && (
          <Fragment>
            <Typography variant="body2" color="textSecondary">
              {`รายการทั้งหมด (${rowsFilter().length})`}
            </Typography>
            <div>
              {rowsFilter()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((emp) => (
                  <div key={emp.idEmployees}>
                    <List>
                      <ListItem>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={4}>
                            <WrapFirstColumn>
                              <StyledAvatar
                                alt={emp.username}
                                src={`${process.env.REACT_APP_API_URL}image/profile/${emp.image}`}
                              />
                              <div style={{ width: "80%" }}>
                                <StyledTextName
                                  variant="body1"
                                >{`${emp.firstname_TH} ${emp.lastname_TH}`}</StyledTextName>
                                <StyledTextOverFlow
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {" — "}
                                  {emp.positionName}
                                  {","}
                                </StyledTextOverFlow>
                                <StyledTextOverFlow
                                  variant="body2"
                                  color="textSecondary"
                                  style={{ fontSize: 12 }}
                                >
                                  {emp.departmentName}
                                </StyledTextOverFlow>
                              </div>
                            </WrapFirstColumn>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container>
                              <Grid item xs={12} sm={4}>
                                <LabelAbout
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Mobile Number:{" "}
                                </LabelAbout>
                                <TextAbout
                                  variant="body1"
                                >
                                  {emp.telephoneMobile}
                                </TextAbout>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <LabelAbout
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Email:{" "}
                                </LabelAbout>
                                <TextAbout
                                  variant="body1"
                                >
                                  {emp.emailAddressBusiness}
                                </TextAbout>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <LabelAbout
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Work Location:{" "}
                                </LabelAbout>
                                <TextAbout
                                  variant="body1"
                                >
                                  {emp.workingLocation}
                                </TextAbout>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={1}
                          >
                            <WrapStatusTag status={emp.employmentStatus}>
                              <StyledStatusTagLabel>
                                {emp.employmentStatus}
                              </StyledStatusTagLabel>
                            </WrapStatusTag>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={1}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={handleClickMenu}
                              size="large">
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              elevation={0}
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              TransitionComponent={Fade}
                            >
                              <MenuItem onClick={handleCloseMenu}>
                                <StyledEditOutlinedIcon />
                                Edit
                              </MenuItem>
                              <MenuItem onClick={handleCloseMenu}>
                                <StyledDeleteOutlineOutlinedIcon />
                                Delete
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                    <Divider />
                  </div>
                ))}
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rowsFilter().length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default EmployeeList;
