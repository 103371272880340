import React, { useEffect } from "react";
import "date-fns";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
// Components
import { Typography, Divider, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// Icon
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import FastfoodIcon from "@mui/icons-material/Fastfood";
import FlightIcon from "@mui/icons-material/Flight";
import InfoIcon from "@mui/icons-material/Info";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PaletteIcon from "@mui/icons-material/Palette";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SellIcon from '@mui/icons-material/Sell';
import CardTravelIcon from "@mui/icons-material/CardTravel";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import PetsIcon from '@mui/icons-material/Pets';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CardRewardDetail from "./cardRewardDetail";
import { Link } from "react-router-dom";
import { useRewardType } from "../../../../../quries/reward";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const WrapRootSearch = styled(Paper)({
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginBottom: 10,
})

const StyledInputSearch = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}))

const StyledIconButtonSearch = styled(IconButton)({
  padding: 10,
})

const StyledCardMedia = styled(CardMedia)({
  height: 250,
  backgroundSize: "contain",
})

const WrapImageRewardItem = styled('div')({
  height: 100,
  width: 150,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
})

const StyledCard= styled(Card)({
  width: "100%",
  padding: 15,
  borderRadius: 8,
})

const WrapRewardCard = styled(Grid)({
  ["@media only screen and (max-width:768px)"]: {
    flexDirection: "column-reverse"
  },
})

const WrapRootCategory = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  backgroundColor: theme.palette.background.paper,
}))

const iconRewardType = {
  0: <DensitySmallIcon />,
  1: <FastfoodIcon />,
  2: <LocalGroceryStoreIcon />,
  3: <QueueMusicIcon />,
  4: <PaletteIcon />,
  5: <FlightIcon />,
  6: <LocalHospitalIcon />,
  7: <PetsIcon />,
  8: <ChildFriendlyIcon />,
}

export default function RewardCards({
  rewards,
  handleClickOpenEdit,
  // handleClickOpenDelete,
  handleClickOpenAddNewReward,
  valuesEdit,
  setValuesEdit,
}) {
  const [searchReward, setSearchReward] = React.useState("");
  const [idRewardType, setIdRewardType] = React.useState(0);
  const rewardType = useRewardType();

  const handleChange = (event, newValue) => {
    setIdRewardType(newValue);
  };

  const handleChangeSearchReward = (event) => {
    setSearchReward(event.target.value);
  };

  if (rewardType.isPending) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <WrapRootSearch
        variant="outlined"
        component="form"
      >
        <StyledInputSearch
          value={searchReward}
          onChange={handleChangeSearchReward}
          placeholder="ค้นหาของรางวัล"
          inputProps={{ "aria-label": "search reward" }}
        />
        <StyledIconButtonSearch
          type="submit"
          aria-label="search"
          size="large">
          <SearchIcon />
        </StyledIconButtonSearch>
        <Divider
          style={{
            height: 28,
            margin: 4,
          }}
          orientation="vertical"
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={'/admin/manage/reward/company-reward/create'}
        >
          เพิ่มรางวัล
        </Button>
      </WrapRootSearch>

      <WrapRewardCard container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <WrapRootCategory>
            <Paper position="static" color="default">
              <Tabs
                value={idRewardType}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                style={{ marginBottom: 16 }}
              >
                {[{ idRewardType: 0, rewardType: "ALL" },
                  ...rewardType.data
                ].map((item) => (
                  <Tab
                    key={item.idRewardType}
                    label={
                      <div style={{ display: "flex" }}>
                        {iconRewardType[item.idRewardType]}
                        <Typography style={{ marginTop: 4, marginLeft: 5 }}>
                          {item.rewardType}
                        </Typography>
                      </div>
                    }
                    {...a11yProps(item.idRewardType)}
                  />
                ))}
              </Tabs>
            </Paper>
            {[{ idRewardType: 0, rewardType: "ALL" }, ...rewardType.data].map(
              (item, index) => (
                <TabPanel value={idRewardType} index={index} key={index}>
                  <CardRewardDetail
                    idRewardType={item.idRewardType}
                    searchReward={searchReward}
                  />
                </TabPanel>
              )
            )}
          </WrapRootCategory>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {valuesEdit ? (
            <StyledCard>
              <StyledCardMedia
                image={valuesEdit.image}
                title="reward"
              />
              <CardContent style={{ padding: 10, paddingBottom: 0 }}>
                <Typography variant="h5">{valuesEdit.name}</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {valuesEdit.detail}
                </Typography>
                <div
                  style={{ display: "flex", marginTop: 5, padding: "5px 0" }}
                >
                  {/* <img
                    src={`${process.env.PUBLIC_URL}/images/assets/thai-baht2.png`}
                    width={24}
                  /> */}
                  <SellIcon style={{ color: "rgb(158 158 158)" }} />
                  <Typography style={{ paddingLeft: 5 }}>
                    {" "}
                    {`${valuesEdit.price} บาท/ชิ้น`}{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <EventIcon style={{ color: "rgb(158 158 158)" }} />
                  <Typography style={{ paddingLeft: 5 }}>
                    {" "}
                    {`${dayjs(valuesEdit.startDate).format('D MMM YYYY')} ถึง ${dayjs(valuesEdit.endDate).format('D MMM YYYY')}`}{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <CardTravelIcon style={{ color: "rgb(158 158 158)" }} />
                  <Typography style={{ paddingLeft: 5 }}>
                    <span style={{ fontWeight: 500 }}> {valuesEdit.quantity}</span> ชิ้นเหลือ
                    <span style={{ fontWeight: 500, color: "#f44336" }}>
                      {" "}
                      {`${valuesEdit.currentQuantity} ชิ้น`}
                    </span>{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <AccountCircleIcon style={{ color: "rgb(158 158 158)" }} />
                  <Typography style={{ paddingLeft: 5 }}>
                    {valuesEdit.rewardManager}
                  </Typography>
                </div>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <ContactPhoneIcon style={{ color: "rgb(158 158 158)" }} />
                  <Typography style={{ paddingLeft: 5 }}>
                    {valuesEdit.contact}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <img
                    src={`${process.env.PUBLIC_URL}/images/assets/coin.png`}
                    width="28"
                  /> */}
                  <SellIcon />
                  <Typography
                    style={{ fontSize: 28, paddingLeft: 5, lineHeight: 1.2 }}
                  >
                    {valuesEdit.price}
                  </Typography>
                </div>
              </CardContent>
              <CardActions>
                {/* <Button
                  startIcon={<DeleteIcon />}
                  size="small"
                  style={{ color: "#f44336", borderColor: "#f44336" }}
                  variant="outlined"
                  onClick={() => {
                    handleClickOpenDelete(valueDetail);
                  }}
                >
                  {" "}
                  ลบ{" "}
                </Button> */}
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => handleClickOpenEdit()}
                  style={{
                    backgroundColor: "#ffda00",
                    borderColor: "rgb(255, 218, 0)",
                    width: "100%",
                  }}
                >
                  {" "}
                  แก้ไขข้อมูล{" "}
                </Button>
              </CardActions>
            </StyledCard>
          ) : (
            <Paper
              elevation={valuesEdit ? 3 : 0}
              style={{ height: 500, backgroundColor: "#ddd" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <HelpOutlineIcon style={{ fontSize: 200, color: "#c5c5c5" }} />
                <Typography style={{ fontSize: 30, color: "#c5c5c5" }}>
                  ยังไม่ได้เลือกรายการ
                </Typography>
              </div>
            </Paper>
          )}
        </Grid>
      </WrapRewardCard>
    </>
  );
}
