import {
  AFFILIATE_ORGANIZATION_FETCHING,
  AFFILIATE_ORGANIZATION_FAILED,
  AFFILIATE_ORGANIZATION_SUCCESS,
} from "./types";
import companyService from "../services/company.service";

export const getAffiliateOrganization = () => async (dispatch) => {
  try {
    dispatch({
      type: AFFILIATE_ORGANIZATION_FETCHING,
    });
    const res = await companyService.getAffiliateOrganization();
    if (res) {
      dispatch({
        type: AFFILIATE_ORGANIZATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: AFFILIATE_ORGANIZATION_FAILED,
    });
  }
};