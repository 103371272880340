import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useUniRewardCart = () => {
  return useQuery({
    queryKey: ["rewardCart"],
    queryFn: async () => (await httpClient.get("uni-reward-cart")).data,
  });
};

export const useAddUniRewardCart = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => httpClient.post("uni-reward-cart", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rewardCart"] });
    },
  });
};

export const useUpdateUniRewardCart = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => httpClient.put("uni-reward-cart", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rewardCart"] });
    },
  });
};

export const useDeleteUniRewardCart = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (idUniRewardItem) => httpClient.delete(`uni-reward-cart/${idUniRewardItem}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["rewardCart"] });
    },
  });
};

export const useUniRewardOrder = () => {
  return useQuery({
    queryKey: ["rewardOrder"],
    queryFn: async () => (await httpClient.get(`uni-reward-order`)).data,
  });
};

export const useAddUniRewardOrder = () => {
  return useMutation({
    mutationFn: async (formData) => httpClient.post("uni-reward-order", formData),
  });
};
