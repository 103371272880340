import React, { useEffect, Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Skeleton from '@mui/material/Skeleton';
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../actions/user";
import { Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import { fieldToTextField } from "formik-mui";
import * as Yup from "yup";

const SectionOne = styled('div')({
  display: "inline-flex",
  width: "100%",
  height: '100%',
  justifyContent: "center",
})

const WrapAvatar = styled(Paper)({
  width: "fit-content",
  // borderRadius: "50%",
  // background: "linearGradient(to right, red, purple)",
  background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(45deg, slateblue, coral) border-box",
  border: "8px solid transparent",
})

const StyledAvatar = styled(Avatar)({
  width: 240,
  height: 240,
  objectFit: 'cover'
})

const SectionTwo = styled('div')({
  marginTop: 16,
})

const SectionTwoSkeleton = styled('div')({
  maxWidth: 500,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const StyledTextProfile = styled(Typography)({
  fontWeight: 300,
})

const StyledDivider = styled(Divider)({
  margin: "10px 0",
  backgroundColor: "#00000021",
})

const SectionThree = styled('div')({
  maxWidth: 650,
  margin: "auto",
  marginTop: 24,
  display: "flex",
  justifyContent: "space-around",
})

const WrapAboutTop = styled('div')({
  marginBottom: 32,
  display: "flex",
  alignItems: "center",
})

const WrapAboutBottom = styled('div')({
  display: "flex",
  alignItems: "center",
})

const InnerAbout = styled('div')({
  marginLeft: 8,
})

const TextIconAbout = styled(Typography)({
  opacity: 0.8,
})

const Main = styled(Paper)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(4),
  border: "1px solid #DFDFDF",
}))

const MainCenter = styled('div')(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  },
}))

const StyledBtnEditGroup = styled(IconButton)({
  border: "1px solid #ffc107",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#ffc107",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
});

const StyledRoot = styled('div')({
  marginTop: 8,
})

const singleLineTextField = (props) => (
  <TextField
    {...fieldToTextField(props)}
    variant={"outlined"}
    size={"small"}
    fullWidth
  />
);

const multiLineTextField = (props) => (
  <TextField
    {...fieldToTextField(props)}
    variant={"outlined"}
    size={"small"}
    fullWidth
    multiline
    rows={4}
  />
);

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({
    profile: "",
    education: [{ educationLevel: "", major: "", institute: "" }],
    experience: "",
    gap: "",
  });

  const validationSchema = Yup.object().shape({
    profile: Yup.string().required("Please enter profile"),
    education: Yup.array(
      Yup.object({
        educationLevel: Yup.string().required("Please enter degree"),
        major: Yup.string().required("Please enter major"),
        institute: Yup.string().required("Please enter university"),
      })
    ),
    experience: Yup.string().required("Please enter experience"),
    gap: Yup.string().required("Please enter gap"),
  });

  const handleEditMode = (mode) => {
    setData({
      profile: userProfile.profile,
      education: userProfile.education ? userProfile.education : [{ educationLevel: "", major: "", institute: "" }],
      experience: userProfile.experience,
      gap: userProfile.gap,
    })
    setEditMode(mode)
  }

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <StyledRoot className={'page'}>
      {userProfile ? (
        <div>
          <SectionOne>
            <WrapAvatar elevation={4}>
              <StyledAvatar 
                alt={`${userProfile.firstname_EN} ${userProfile.lastname_EN}`}
                variant="rounded"
                src={userProfile.imageProfile}
              />
            </WrapAvatar>
          </SectionOne>
          <SectionTwo>
            <Typography align="center" variant="h2">
              {`${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
            </Typography>
            <StyledTextProfile
              align="center"
              variant="h5"
              color="textSecondary"
            >
              {userProfile.positionName}
            </StyledTextProfile>
            <StyledTextProfile
              align="center"
              variant="h6"
              color="textSecondary"
            >
              {userProfile.departmentName}
            </StyledTextProfile>
          </SectionTwo>
          <SectionThree>
            <div>
              <WrapAboutTop>
                <PhoneIphoneOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <TextIconAbout
                    variant="body2"
                    color="textSecondary"
                  >
                    Mobile Number:{" "}
                  </TextIconAbout>
                  <Typography>{userProfile.telephoneMobile}</Typography>
                </InnerAbout>
              </WrapAboutTop>
              <WrapAboutBottom>
                <PersonPinCircleOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <TextIconAbout
                    variant="body2"
                    color="textSecondary"
                  >
                    Work Location:{" "}
                  </TextIconAbout>
                  <Typography>{userProfile.location}</Typography>
                </InnerAbout>
              </WrapAboutBottom>
            </div>
            <div>
              <WrapAboutTop>
                <AlternateEmailOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <TextIconAbout
                    variant="body2"
                    color="textSecondary"
                  >
                    Email:{" "}
                  </TextIconAbout>
                  <Typography>{userProfile.emailAddressBusiness}</Typography>
                </InnerAbout>
              </WrapAboutTop>
              <WrapAboutBottom>
                <BusinessCenterOutlinedIcon fontSize="large" color="primary" />
                <InnerAbout>
                  <TextIconAbout
                    variant="body2"
                    color="textSecondary"
                  >
                    Company:{" "}
                  </TextIconAbout>
                  <Typography>{userProfile.companyName}</Typography>
                </InnerAbout>
              </WrapAboutBottom>
            </div>
          </SectionThree>
          <StyledDivider />
            {/* {editMode ? (
              <Formik
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleEditUser(values);
                }}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FieldArray name="education">
                          {({ push, pop }) => (
                            <Fragment>
                              <Typography varaint="h4">
                                Education
                              </Typography>
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={() => {
                                    push({
                                      degree: "",
                                      major: "",
                                      university: "",
                                    });
                                  }}
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  style={{ margin: 4 }}
                                >
                                  <AddIcon fontSize="small" />
                                  Add
                                </Button>
                                <Button
                                  onClick={() => pop()}
                                  color="secondary"
                                  disabled={!(values.education.length > 1)}
                                  variant="contained"
                                  size="small"
                                  style={{ margin: 4 }}
                                >
                                  <RemoveIcon fontSize="small" />
                                  Remove
                                </Button>
                              </div>
                              {values.education.map((_, index) => (
                                <Grid
                                  container
                                  spacing={3}
                                  style={{ marginTop: 4 }}
                                  key={index}
                                >
                                  <Grid item xs={12} md={4}>
                                    <Field
                                      name={`education[${index}].educationLevel`}
                                      label="Education Level"
                                      component={singleLineTextField}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Field
                                      name={`education[${index}].major`}
                                      label="Major"
                                      component={singleLineTextField}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <Field
                                      name={`education[${index}].institute`}
                                      label="Institute"
                                      component={singleLineTextField}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            </Fragment>
                          )}
                        </FieldArray>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="profile"
                          label="Profile"
                          component={multiLineTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="experience"
                          label="Experience"
                          component={multiLineTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="gap"
                          label="gap"
                          component={multiLineTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                          >
                            Submit
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            color="error"
                            onClick={() => handleEditMode(false)}
                            >
                            Cancel
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            ) : (
              <Fragment>
                <Main style={{ marginTop: 50 }}>
                  <MainCenter>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="h5" component="h6" gutterBottom>
                        Profile
                      </Typography>
                      <StyledBtnEditGroup
                        onClick={() => handleEditMode(true)}
                        size="small"
                        aria-label="edit-name-tooltip"
                      >
                        <EditIcon />
                      </StyledBtnEditGroup>
                    </Stack>
                    <StyledDivider />
                    <Typography variant="subtitle1" component="p">
                      {userProfile.profile}
                    </Typography>
                  </MainCenter>
                </Main>
                <Main>
                  <MainCenter>
                    <Typography variant="h5" component="h6" gutterBottom>
                      Experience
                    </Typography>
                    <StyledDivider />
                    <Typography variant="subtitle1" component="p">
                      {userProfile.experience}
                    </Typography>
                  </MainCenter>
                </Main>
                <Main>
                  <MainCenter>
                    <Typography variant="h5" component="h6" gutterBottom>
                      Gap
                    </Typography>
                    <StyledDivider />
                    <Typography variant="subtitle1" component="p">
                      {userProfile.gap}
                    </Typography>
                  </MainCenter>
                </Main>
                <Main style={{ marginTop: 50 }}>
                  <MainCenter>
                    <Typography variant="h5" component="h6" gutterBottom>
                      Education
                    </Typography>
                    <StyledDivider />
                    {userProfile &&
                      userProfile.education &&
                      userProfile.education.map((item, idy) => (
                        <div key={idy}>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              style={{ paddingBottom: "20px" }}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: "#4A4A4A" }}
                                gutterBottom
                              >
                                Education level
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                {item.educationLevel}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              style={{ paddingBottom: "20px" }}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: "#4A4A4A" }}
                                gutterBottom
                              >
                                Major
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                {item.major}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              style={{ paddingBottom: "20px" }}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: "#4A4A4A" }}
                                gutterBottom
                              >
                                Institute
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                {item.institute}
                              </Typography>
                            </Grid>
                          </Grid>
                        <StyledDivider />
                      </div>
                    ))}
                  </MainCenter>
                </Main> 
              </Fragment>
            )} */}
          {/* <SectionFour>
            <WrapFollow>
              <InnerFollow>
                <Follow>
                  <StyledFollowCount align="center">
                    {Utils.numberWithCommas(userProfile.follower)}
                  </StyledFollowCount>
                  <StyledFollowLabel
                    align="center"
                    color="textSecondary"
                  >
                    Follower
                  </StyledFollowLabel>
                </Follow>
                <DividerVertical
                  orientation="vertical"
                  flexItem
                />
                <Follow>
                  <StyledFollowCount align="center">
                    {Utils.numberWithCommas(userProfile.following)}
                  </StyledFollowCount>
                  <StyledFollowLabel
                    align="center"
                    color="textSecondary"
                  >
                    Following
                  </StyledFollowLabel>
                </Follow>
              </InnerFollow>
            </WrapFollow>
          </SectionFour> */}
        </div>
      ) : (
        <Fragment>
          <SectionOne>
            <Skeleton variant="circular" width={200} height={200} />
          </SectionOne>
          <SectionTwoSkeleton>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="60%" />
          </SectionTwoSkeleton>
        </Fragment>
      )}
    </StyledRoot>
  );
};

export default ProfilePage;
