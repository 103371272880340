import React, { forwardRef, useEffect } from "react";
import { Bar, defaults } from "react-chartjs-2";

defaults.global.defaultFontSize = 24;

const bgColorChart = {
  id: "bgColorChart",
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext("2d");
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const labels = [
  "Actively Disengaged",
  "Passive",
  "Moderately Engaged",
  "Highly Engaged",
];

export const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Thailand Best Employers",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      // fill: false,
      data: [4, 9, 26, 61],
    },
    {
      type: "bar",
      label: "2022",
      backgroundColor: "rgb(75, 192, 192)",
      // borderColor: 'white',
      borderWidth: 2,
      data: [2, 5, 23, 70],
    },
  ],
};

const MultiChart = forwardRef((props, ref) => {
  const morale = props.morale;
  // const score = morale.moraleTopicAvgAndBest.find(
  //   (value) => value.idQuestionTopic === 1
  // ).moraleQuestionAvgAndDistributionAcross;

  const score = morale.moraleTopicAvgAndBest.length > 0 
    ? morale.moraleTopicAvgAndBest.find((value) => value.idQuestionTopic === 1).moraleQuestionAvgAndDistributionAcross
    : [];

  const highlyEngaged = Math.round(
    (score.reduce((acc, item) => acc + parseFloat(item.positivePerception), 0) / score.length)
  );
  const moderatelyEngaged = Math.round(
    (score.reduce((acc, item) => acc + parseFloat(item.positiveHesitance), 0) / score.length)
  );
  const passive = Math.round(
    (score.reduce((acc, item) => acc + parseFloat(item.negativeHesitance), 0) / score.length)
  );
  const activelyDisengaged = Math.round(
    (score.reduce((acc, item) => acc + parseFloat(item.negitivePerception), 0) / score.length)
  );

  return (
    <Bar
      // data={data}
      ref={ref}
      plugins={[bgColorChart]}
      data={{
        labels,
        datasets: [
          {
            type: "line",
            label: "Thailand Best Employers",
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            // fill: false,
            data: [4, 9, 26, 61],
          },
          {
            type: "bar",
            label: `${new Date(morale.startDate).getFullYear()}`,
            backgroundColor: "rgb(75, 192, 192)",
            // borderColor: 'white',
            borderWidth: 2,
            data: [
              activelyDisengaged,
              passive,
              moderatelyEngaged,
              highlyEngaged,
            ],
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: true,
      }}
    />
  );
});

export default MultiChart;
