import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HeaderPage from "../../shared/header/headerPage";
import { StyledDividerHeader } from "../../shared/dividerHeader";
import RewardTypeFilter from "../../shared/rewardTypeFilter";
import CardReward from "./cardReward";
import { Link } from "react-router-dom";
import { useUniRewards } from "../../../../quries/uniReward";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

function UniReward() {
  const [rewardType, setRewardType] = useState(0);
  const [valueSearch, setValueSearch] = useState("");
  const [filterName, setFilterName] = useState("");
  const { isPending, data } = useUniRewards();

  const handleChangeSearch = (event) => {
    setValueSearch(event.target.value);
  };

  const handleChangeRewardType = (value) => {
    setRewardType(value);
  };

  const handleClickSearch = () => {
    setFilterName(valueSearch);
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };

  if (isPending) {
    return <CircularProgress color="secondary" />
  }

  return (
    <StyledRoot className={"page"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeaderPage textLabel={"จัดการของรางวัล"} />
        <Button variant="contained" component={Link} to="new">
          เพิ่มสินค้า
        </Button>
        {/* <Button variant="contained" component={Link} to="create">
          เพิ่มของรางวัล
        </Button> */}
      </Stack>
      <StyledDividerHeader />
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "flex-end", lg: "center" }}
        marginTop={2}
        marginBottom={2}
        spacing={2}
      >
        <RewardTypeFilter rewardType={rewardType} handleChangeRewardType={handleChangeRewardType} />
        <FormControl>
          <OutlinedInput
            type="search"
            value={valueSearch}
            onChange={handleChangeSearch}
            placeholder="Search Reward"
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  onClick={handleClickSearch}
                  onMouseDown={handleMouseDownSearch}
                  edge="start"
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleClickSearch();
              }
            }}
          />
        </FormControl>
      </Stack>
      <Grid container spacing={3}>
        {data
          .filter((value) => {
            return value.name.toLowerCase().includes(filterName.toLowerCase());
          })
          .filter(
            (item) => rewardType === 0 || item.idRewardType === rewardType
          )
          .map((value) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CardReward {...value} />
              </Grid>
            );
          })}
      </Grid>
    </StyledRoot>
  );
}

export default UniReward;
