import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import "dayjs/locale/th";
import 'dayjs/locale/en-gb';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const theme = createTheme({
  palette: {
    text: {
      primary: "#212b36",
      secondary: "#919eab",
      third: "#637381",
    },
  },
  typography: {
    h1: {
      color: "#212b36",
    },
    h2: {
      color: "#212b36",
    },
    h3: {
      color: "#212b36",
    },
    h4: {
      color: "#212b36",
    },
    h5: {
      color: "#212b36",
    },
    h6: {
      color: "#212b36",
    },
    body1: {
      color: "#212b36",
    },
    body2: {
      color: "#212b36",
    },
    fontFamily: [
      "Poppins",
      "Prompt",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();