import {
    ALERT_DAILY_INSIGHT_OPEN,
    ALERT_DAILY_INSIGHT_CLOSE,
} from "./types";

export const alertDailyInsightOpen = () => (dispatch) => {
  dispatch({
    type: ALERT_DAILY_INSIGHT_OPEN,
  });
};

export const alertDailyInsightClose = () => (dispatch) => {
    dispatch({
      type: ALERT_DAILY_INSIGHT_CLOSE,
    });
  };
