import React, {
  useEffect,
  Fragment,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Divider,
  Button,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssessment,
  updateAssessment,
  updateAssessmentQuestion,
} from "../../../../../actions/assessment";

const WrapHeader = styled(Paper)({
  padding: 20,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .herder-text": {
    marginLeft: 10,
  },
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .herder-text": {
      marginLeft: 0,
      fontSize: 26,
      marginBottom: 16,
    },
  },
});

const StyledBtnPublic = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #1976d2",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#1976d2",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#1976d2",
    borderColor: "#1976d2",
  },
});

const StyledButton = styled(Button)({
  marginLeft: 8,
  color: "#FFF",
  backgroundColor: "#28a745",
  border: "1px solid #28a745",
  "&:hover": {
    border: "1px solid #28a745",
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "#28a745",
  },
});

const StyledBtnDelete = styled(IconButton)({
  marginLeft: 8,
  color: "#dc3545",
  "&:hover": {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#FFF",
  },
});

const StyledBtnEdit = styled(IconButton)({
  marginLeft: 8,
  color: "#ffc107",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
});

const StyledBtnDeleteGroup = styled(IconButton)({
  marginLeft: 8,
  color: "#dc3545",
  border: "1px solid #dc3545",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  "&:hover": {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#FFF",
  },
});

const StyledBtnEditGroup = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #ffc107",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#ffc107",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
});

const StyledTextFieldNewQuestion = styled(TextField)({
  marginBottom: 20,
  marginTop: 8,
  width: "100%",
  "& .MuiInputBase-root": {
    lineHeight: 1.6,
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 0,
});

const DialogOneTextField = (props) => {
  const displayTitle = () => {
    switch (props.mode) {
      case "add":
        return "เพิ่มหัวข้อใหม่";
      case "edit":
        return "แก้ไขหัวข้อ";
      default:
        return "แก้ไขชื่อ";
    }
  };

  const displayButton = () => {
    switch (props.mode) {
      case "add":
        return "บันทึกหัวข้อใหม่";
      case "edit":
        return "บันทึกแก้ไขหัวข้อ";
      default:
        return "บันทึกแก้ไข";
    }
  };
  const [topicName, setTopicName] = useState("");
  const dispatch = useDispatch();

  const handleChangeValueNewTopic = (event) => {
    setTopicName(event.target.value);
  };

  const handleClickAddTopic = async () => {
    if (props.mode === "editGroup") {
      const res = await dispatch(
        updateAssessment({
          idAssessment: parseInt(props.id),
          assessmentName: topicName,
        })
      );
      if (res) {
        dispatch(getAssessment(parseInt(props.id)));
      }
    } else if (props.mode === "edit") {
      // const res = await dispatch(
      //   updateMoraleQuestionTopic({
      //     idQuestionTopic: props.idQuestionTopic,
      //     idQuestionSet: parseInt(props.id),
      //     questionTopicName: topicName,
      //   })
      // );
      // if (res) {
      //   dispatch(getMoraleQuestion(parseInt(props.id)));
      // }
    } else if (props.mode === "add") {
      const res = await dispatch();
      // addMoraleQuestionTopic({
      //   idQuestionSet: parseInt(props.id),
      //   questionTopicName: topicName,
      // })
      if (res) {
        // dispatch(getMoraleQuestion(parseInt(props.id)));
      }
    }
    setTopicName("");
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-new-topic"
      aria-describedby="alert-dialog-description-new-topic"
    >
      <DialogTitle id="alert-dialog-title-new-topic">
        {displayTitle()}
      </DialogTitle>
      <DialogContent>
        <TextField
          style={{ width: "100%" }}
          id="new-topic"
          label=""
          variant="outlined"
          value={topicName}
          onChange={handleChangeValueNewTopic}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
        <Button onClick={handleClickAddTopic} color="primary">
          {displayButton()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogDelete = (props) => {
  // const handleDeleteQuestion = async () => {
  //   if (props.mode === "group") {
  //     const res = await dispatch(deleteMoraleQuestion(parseInt(props.id)));
  //     if (res) {
  //       dispatch(getMoraleQuestion(parseInt(props.id)));
  //       history.push("/admin/manage/morale-question");
  //     }
  //   } else if (props.mode === "topic") {
  //     const res = await dispatch(
  //       deleteMoraleQuestionTopic(parseInt(props.id), props.idQuestionTopic)
  //     );
  //     if (res) {
  //       dispatch(getMoraleQuestion(parseInt(props.id)));
  //     }
  //   } else if (props.mode === "question") {
  //     const res = await dispatch(deleteQuestion(props.idQuestion));
  //     if (res) {
  //       dispatch(getMoraleQuestion(parseInt(props.id)));
  //     }
  //   }
  //   props.onClose();
  // };
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-delete-group-name"
      aria-describedby="alert-dialog-description-delete-group-name"
    >
      <DialogTitle id="alert-dialog-title-delete-group-name">
        {"ลบ"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          คุณแน่ใจว่าจะลบ?{" "}
          <span style={{ color: "#f44336" }}>{props.displayDelete}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
        {/* <Button onClick={handleDeleteQuestion} color="primary">
          {" "}
          ลบ{" "}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

const DialogQuestion = forwardRef((props, ref) => {
  const { topic, mode, listTopic, id, idQuestion } = props;
  const dispatch = useDispatch();
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [dataEdit, setDataEdit] = React.useState({
    question: "",
    questionType: "",
  });

  const updateTopic = (topic) => {
    setSelectedTopic(topic);
  };

  const updateDataEdit = (dataEdit) => {
    setDataEdit(dataEdit);
  };

  useImperativeHandle(ref, () => {
    return {
      updateTopic: updateTopic,
      updateDataEdit: updateDataEdit,
    };
  });

  const handleChangeValueQuestion = (event) => {
    const name = event.target.name;
    setDataEdit({
      ...dataEdit,
      [name]: event.target.value,
    });
  };

  const handleClickQuestion = async () => {
    if (dataEdit.question.trim().length > 0) {
      if (mode === "add") {
        // const res = await dispatch(
        //   addQuestion({
        //     ...dataEdit,
        //     idQuestionTopic: selectedTopic,
        //     idQuestionSet: parseInt(id),
        //   })
        // );
        // if (res) {
        //   dispatch(getMoraleQuestion(parseInt(props.id)));
        // }
      } else if (mode === "edit") {
        const res = await dispatch(
          updateAssessmentQuestion({
            ...dataEdit,
          })
        );
        if (res) {
          dispatch(getAssessment(parseInt(props.id)));
        }
      }
      props.onClose();
    } else {
      props.handleOpenSnackbar("โปรดกรอกข้อมูลให้ครบ", "error");
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-new-topic"
      aria-describedby="alert-dialog-description-new-topic"
    >
      <DialogTitle id="alert-dialog-title-new-topic">
        {mode === "add" ? "เพิ่มคำถามใหม่" : "แก้ไขคำถาม"}
      </DialogTitle>
      <DialogContent>
        <StyledTextFieldNewQuestion
          id="new-topic"
          name="question"
          label="คำถาม"
          variant="outlined"
          multiline
          rows={4}
          value={dataEdit.question}
          onChange={handleChangeValueQuestion}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickQuestion} color="primary">
          {" "}
          {mode === "add" ? "บันทึกคำถามใหม่" : "บันทึกแก้ไขคำถาม"}{" "}
        </Button>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const Assessment360Descriptions = () => {
  // const history = useHistory()
  const navigate = useNavigate();
  const { id } = useParams();
  const ref = useRef(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openOneTextField, setOpenOneTextField] = React.useState(false);
  const [openQuestion, setOpenQuestion] = React.useState(false);
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [modeModal, setModeModal] = React.useState(null);
  const [displayDelete, setDisplayDelete] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");

  const handleClick = (topic) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedTopic(topic.idQuestionTopic);
    setDisplayDelete("หัวข้อ : " + topic.questionTopicName);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDelete = (mode) => {
    if (mode == "topic") {
      setModeModal(mode);
      setOpenDelete(true);
    } else if (mode == "group") {
      setModeModal(mode);
      // setDisplayDelete(moraleQuestionItem.questionName);
      setOpenDelete(true);
    } else {
      setModeModal(mode);
      setOpenDelete(true);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenQuestion = (mode) => {
    setModeModal(mode);
    setOpenQuestion(true);
  };

  const handleCloseQuestion = () => {
    setOpenQuestion(false);
    setSelectedTopic(null);
  };

  const handleClickOpenOneTextField = (mode) => {
    setModeModal(mode);
    setOpenOneTextField(true);
  };

  const handleCloseOneTextField = () => {
    setOpenOneTextField(false);
  };

  const handleClickDescription = (id) => {
    navigate("/admin/manage/editassessment360/" + id);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setDisplaySnackbar(value);
    setOpenSnackbar(true);
  };

  const dispatch = useDispatch();
  const { result: assessmentItem } = useSelector(
    (state) => state.assessmentItem
  );

  useEffect(() => {
    dispatch(getAssessment(parseInt(id)));
  }, []);

  return (
    <>
      {assessmentItem && (
        <StyledRoot className={"page"}>
          <div style={{ display: "flex" }}>
            <WrapHeader>
              <Typography variant="h4" className={`herder-text`}>
                {assessmentItem[0].assessmentName}
              </Typography>
              <div>
                {assessmentItem[0].numberOfUse === 0 && (
                  <Tooltip title="แก้ไข" aria-label="edit-name-tooltip">
                    <StyledBtnEditGroup
                      onClick={() => {
                        handleClickOpenOneTextField("editGroup");
                      }}
                      size="small"
                      aria-label="edit-name-tooltip"
                    >
                      <EditIcon />
                    </StyledBtnEditGroup>
                  </Tooltip>
                )}

                {/* {assessmentItem[0].numberOfUse === 0 && (
                  <Tooltip title="ลบ" aria-label="delete-name-tooltip">
                    <StyledBtnDeleteGroup
                      onClick={() => {
                        handleClickOpenDelete("group");
                      }}
                      size="small"
                      aria-label="delete-name-tooltip"
                    >
                      <DeleteForeverIcon />
                    </StyledBtnDeleteGroup>
                  </Tooltip>
                )} */}
              </div>
            </WrapHeader>
          </div>
          <div style={{ marginTop: 20, marginLeft: "auto" }}>
            <StyledButton
              onClick={() => handleClickDescription(id)}
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              สร้างแบบประเมิน
            </StyledButton>
          </div>
          <div>
            <Paper style={{ marginTop: 20, padding: 20 }}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></div>
                }
              >
                {assessmentItem &&
                  assessmentItem[0].questionList.map(
                    (question, indexQuestion) => {
                      return (
                        <Fragment key={question.idAssessment_Question}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Fragment>
                                  <Typography>
                                    {`${indexQuestion + 1}. ${
                                      question.question
                                    }`}
                                  </Typography>
                                  <Typography
                                    style={{ fontSize: 14 }}
                                    color="textSecondary"
                                  >
                                    <SubdirectoryArrowRightIcon
                                      style={{ fontSize: 14 }}
                                    />
                                    {`คำตอบ: ${
                                      assessmentItem[0].assessmentType
                                        ? "text"
                                        : assessmentItem[0].scale === 6
                                        ? "6 Scale"
                                        : "4 Scale"
                                    }`}
                                  </Typography>
                                </Fragment>
                              }
                            />
                            {assessmentItem[0].numberOfUse === 0 && (
                              <Tooltip title="แก้ไข" aria-label="edit-tooltip">
                                <StyledBtnEdit
                                  onClick={() => {
                                    // ref.current.updateTopic(topic.idQuestionTopic);
                                    ref.current.updateDataEdit(question);
                                    handleClickOpenQuestion("edit");
                                  }}
                                  size="small"
                                  aria-label="edit"
                                >
                                  <EditIcon />
                                </StyledBtnEdit>
                              </Tooltip>
                            )}

                            {/* <Tooltip title="ลบ" aria-label="delete-tooltip">
                            <StyledBtnDelete
                              onClick={() => {
                                setDisplayDelete(
                                  "คำถาม : " + question.question
                                );
                                handleClickOpenDelete("question");
                                setSelectedTopic(question.idQuestionTopic);
                              }}
                              size="small"
                              aria-label="delete"
                            >
                              <DeleteForeverIcon />
                            </StyledBtnDelete>
                          </Tooltip> */}
                          </ListItem>
                          <Divider component="li" />
                        </Fragment>
                      );
                    }
                  )}
              </List>
            </Paper>
          </div>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            elevation={1}
          >
            <MenuItem
              onClick={() => {
                ref.current.updateTopic(selectedTopic);
                ref.current.updateDataEdit({
                  question: "",
                  questionAnswerType: "",
                });
                handleClickOpenQuestion("add");
                handleClose();
              }}
            >
              {" "}
              <AddCircleIcon style={{ marginRight: 5 }} /> เพิ่มคำถาม{" "}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickOpenOneTextField("edit");
                handleClose();
              }}
            >
              {" "}
              <EditIcon style={{ marginRight: 5 }} /> แก้ไขหัวข้อ{" "}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickOpenDelete("topic");
                handleClose();
              }}
            >
              {" "}
              <DeleteIcon style={{ marginRight: 5 }} /> ลบหัวข้อ{" "}
            </MenuItem>
          </Menu>

          <DialogOneTextField
            id={id}
            idQuestionTopic={selectedTopic}
            open={openOneTextField}
            onClose={handleCloseOneTextField}
            mode={modeModal}
          />
          <DialogDelete
            id={id}
            idQuestionTopic={selectedTopic}
            open={openDelete}
            onClose={handleCloseDelete}
            mode={modeModal}
            displayDelete={displayDelete}
          />
          <DialogQuestion
            id={id}
            ref={ref}
            open={openQuestion}
            onClose={handleCloseQuestion}
            mode={modeModal}
            handleOpenSnackbar={handleOpenSnackbar}
          />
        </StyledRoot>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={"error"}
          sx={{ width: "100%" }}
        >
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Assessment360Descriptions;
