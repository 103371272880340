import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useCompanyRewardItemCost = ({ idCompanyRewardItem }) => {
  return useQuery({
    queryKey: ["company-reward", idCompanyRewardItem, "cost"],
    queryFn: async () => (await httpClient.get(`company-reward/${idCompanyRewardItem}/cost`)).data,
    refetchOnWindowFocus: false,
    enabled: !!idCompanyRewardItem,
  });
};

export const useAddCompanyReward = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (reward) => httpClient.post("company-reward", reward),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reward", "company-reward"] })
    },
  });
};

export const useAddCompanyRewardItemCost = ({ idCompanyRewardItem }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.post(`/company-reward-item/cost`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["reward", "company-reward-item", idCompanyRewardItem, "cost"],
      });
    },
  });
};

export const useAddCompanyRewardVariation = ({ idCompanyReward }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.post(`/company-reward/variation`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["reward", "company-reward", idCompanyReward],
      });
    },
  });
};
