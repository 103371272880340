import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

import { useDropzone } from "react-dropzone";
import * as Yup from "yup";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

import {
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  FormGroup,
  Checkbox,
  MenuItem,
  Select,
} from "@mui/material";
import { Fragment } from "react";

const DivRoot = styled('div')({
  margin: 24,
})

const WrapFirstSection = styled(Paper)({
  padding: "64px 24px",
})

const WrapSecondSection = styled(Paper)({
  padding: 24,
})

const WrapImageProfile = styled('div')({
  marginBottom: 56,
}) 

const WrapCaptionImage = styled('div')({
  marginTop: 16,
  textAlign: "center",
})

const StyledCaptionImage = styled(Typography)({
  color: "#212b36",
})

const StyledFormControlLabelSwitch = styled(FormControlLabel)({
  flexDirection: "row-reverse",
  margin: 0,
  width: "100%",
  "& .MuiFormControlLabel-label": {
    marginRight: "auto",
  },
})

const StyledLabelSwitch = styled(Typography)({
  fontSize: 14,
})

const WrapRow = styled('div')({
  marginBottom: 16,
})

const StyledUploadImage = styled('img')({
  width: "105%",
  zIndex: 8,
})

const placeholderImage = {
  width: "100%",
  height: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
}

const StyledPlaceholderImage = styled('div', {
  shouldForwardProp: (prop) => prop !== "length"
})(({ length }) => {
  return length !== 0 
    ? {
      ...placeholderImage, 
      color: "rgb(255, 255, 255)", 
      backgroundColor: "rgba(22, 28, 36, .72)",
    }
    : {
      ...placeholderImage, 
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)",
    }
})

const StyledTextImage = styled(Typography)({
  marginTop: 8, 
  backgroundColor:"transparent",
})

const EmployeeAddNew = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const { result: departmentList } = useSelector((state) => state.department);
  const [fileSelected, setFileSelected] = useState([]);
  const [check, setCheck] = useState(true);

  const phoneRegExp = /^0\d\d-\d\d\d-\d\d\d\d$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    position: Yup.string().required("Please enter position"),
    idDepartment: Yup.string().required("Please select department"),
    email: Yup.string().email().required("Enter valid email"),
    mobileNumber: Yup.string()
      .required("Please enter mobilephone")
      .matches(phoneRegExp, "Phone number is not valid"),
    site: Yup.string().required("Please select working location"),
  });

  const [dataEmployee, setDataEmployee] = useState({
    username: "",
    email: "",
    image: "",
    firstName: "",
    lastName: "",
    idDepartment: "",
    department: "",
    position: "",
    mobileNumber: "",
    workingLocation: "",
    site: "",
    status: true,
    company: "Thai Polyethylene Co., Ltd.",
    authorities: {
      user: true,
      manager: false,
      admin: false,
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <StyledUploadImage alt="employee" key={file.name} src={file.preview} />
  ));

  const handleClickSave = () => {
    console.log("handleClickSave");
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChangeActive = (event) => {
    setDataEmployee({
      ...dataEmployee,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeRole = (event) => {
    let tempDataEmployee = { ...dataEmployee };
    tempDataEmployee.authorities[event.target.name] = event.target.checked;
    setDataEmployee(tempDataEmployee);
  };

  const showForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <WrapRow>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="firstName"
                name="firstName"
                label="First name"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last name"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.lastName}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>
          </Grid>
        </WrapRow>
        <WrapRow>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="position"
                name="position"
                label="Position"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.position}
                error={touched.position && Boolean(errors.position)}
                helperText={touched.position && errors.position}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={touched.idDepartment && Boolean(errors.idDepartment)}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Department"
                  name="idDepartment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  value={values.idDepartment}
                >
                  {departmentList &&
                    departmentList.map((dep) => (
                      <MenuItem key={dep.id} value={dep.id}>
                        {dep.departmentName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {touched.idDepartment && errors.idDepartment}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </WrapRow>
        <WrapRow>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="mobileNumber"
                name="mobileNumber"
                label="Mobile Number"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.mobileNumber}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                helperText={touched.mobileNumber && errors.mobileNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
          </Grid>
        </WrapRow>
        <WrapRow>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={touched.site && Boolean(errors.site)}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Working Location
                </InputLabel>
                <Select
                  labelId="site"
                  id="site"
                  label="working Location"
                  name="site"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  value={values.site}
                >
                  <MenuItem value={1}>TPE - Rayong Site#1</MenuItem>
                  <MenuItem value={3}>TPE - Rayong Site#3</MenuItem>
                  <MenuItem value={7}>TPE - Rayong Site#7</MenuItem>
                </Select>
                <FormHelperText>{touched.site && errors.site}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="company"
                name="company"
                label="Company"
                value={dataEmployee.company}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </WrapRow>
        <WrapRow>
          <Grid container spacing={3}>
            <Grid item>
              <FormControl component="fieldset">
                <FormLabel component="legend">Role</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={dataEmployee.authorities.user}
                        onChange={handleChangeRole}
                        name="user"
                      />
                    }
                    label="User"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dataEmployee.authorities.mananger}
                        onChange={handleChangeRole}
                        name="manager"
                      />
                    }
                    label="Manager"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dataEmployee.authorities.admin}
                        onChange={handleChangeRole}
                        name="admin"
                      />
                    }
                    label="Admin"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </WrapRow>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleClickSave}
          >
            Create New Employee
          </Button>
        </div>
      </form>
    );
  };

  return (
    <DivRoot>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <WrapFirstSection elevation={3}>
            {/* <div>{thumbs}</div> */}
            <WrapImageProfile>
              <div {...getRootProps({ className: "dropzone" })}>
                <div className="inner-dropzone">
                  <input {...getInputProps()} />
                  <Fragment>{thumbs}</Fragment>
                  <StyledPlaceholderImage length={fileSelected.length}>
                    <AddAPhotoIcon />
                    <StyledTextImage variant="body2">
                      Upload Photo
                    </StyledTextImage>
                  </StyledPlaceholderImage>
                </div>
              </div>
              <WrapCaptionImage>
                <StyledCaptionImage variant="caption">
                  Allowed *.jpeg, *.jpg, *.png
                  <br />
                  max size of 3 MB
                </StyledCaptionImage>
              </WrapCaptionImage>
            </WrapImageProfile>
            <div>
              <StyledFormControlLabelSwitch
                control={
                  <Switch
                    checked={dataEmployee.status}
                    onChange={handleChangeActive}
                    name="status"
                    color="primary"
                  />
                }
                label={
                  <Fragment>
                    <StyledLabelSwitch
                      variant="h6"
                    >
                      Active Employee
                    </StyledLabelSwitch>
                    <StyledLabelSwitch
                      variant="body1"
                      color="textSecondary"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been
                    </StyledLabelSwitch>
                  </Fragment>
                }
              />
            </div>
          </WrapFirstSection>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <WrapSecondSection elevation={3}>
          </WrapSecondSection>
        </Grid>
      </Grid>
    </DivRoot>
  );
};

export default EmployeeAddNew;
