import {
    Grid,
    Typography,
    Card,
  } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../../editCss/editor.css";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addCoacheeRecord ,addCoachRecord,getAllCoacheeRecordByIdCoachee,getAllCoachRecordByIdCoach} from "../../../../actions/coachRecord"; 
import { useDispatch, useSelector } from "react-redux";




  const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });


  const GrowForm =(props)=> {
    const dispatch = useDispatch();
    const location = useLocation();
    const {setOpenForm,setSnackBarConfig,setModeSnackbar,setDisplaySnackbar,idBooking , tag} = props;


    const validationSchema = yup.object().shape({
        goal:yup.string().required().max(200),
        reality:yup.string().required().max(200),
        opportunity:yup.string().required().max(200),
        nextAction:yup.string().required().max(200),
        homework:yup.string().required().max(200),
    });
    
    const { control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues:{
            goal:"",
            reality:"",
            opportunity:"",
            nextAction:"",
            homework:""
        },
        resolver: yupResolver(validationSchema)
    })


    const onSubmit = async(data) => {
        const formData = {
            goal:data.goal,
            reality:data.reality,
            opportunity:data.opportunity,
            opportunity:data.opportunity,
            nextAction:data.nextAction,
            homework:data.homework,
            recordType:1,
            idBooking:idBooking
        }
        console.log(tag);     
        setOpenForm(false);
        const result = await dispatch(tag === "coach" ? addCoachRecord(formData) : addCoacheeRecord(formData));
        if(tag === "coach"){
            const { idCoachee } = location.state || {};
            dispatch(getAllCoacheeRecordByIdCoachee(idCoachee));
        }else{
            const { idCoach } = location.state || {};
            dispatch(getAllCoachRecordByIdCoach(idCoach))
        }
    
        if (result && result.status === 200) {
            setSnackBarConfig(true);
            setDisplaySnackbar("Successfully");
            setModeSnackbar("success");
        } else {
            setSnackBarConfig(true);
            setDisplaySnackbar("Unsuccessful");
            setModeSnackbar("error");
        }
      };
    

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledCard>
            <Grid item xs={12} paddingBottom={"20px"}>
            <Typography className="field-label" color="text.secondary" gutterBottom>
            Goal
            </Typography>
            <Controller
                control={control}
                name="goal"
                rules={{
                    required: true,
                }}
                render={({field})=> (
                    <TextFieldTheme
                    {...field}
                    multiline
                    minRows={3}
                    placeholder={"Goal"}
                    helperText={errors.goal?.message}  
                    error={!!errors.goal}  

                    />
                )}

            />
            </Grid>
            <Grid item xs={12} paddingBottom={"20px"}>
            <Typography className="field-label" color="text.secondary" gutterBottom>
            Reality
            </Typography>
            <Controller
                control={control}
                name="reality"
                rules={{
                    required: true,
                }}
                render={({field})=> (
                    <TextFieldTheme
                    {...field}
                    multiline
                    minRows={3}
                    placeholder={"Reality"}
                    helperText={errors.reality?.message}  
                    error={!!errors.reality}  
                    />
                )}

            />
            </Grid>
            <Grid item xs={12} paddingBottom={"20px"}>
            <Typography className="field-label" color="text.secondary" gutterBottom>
            opportunity
            </Typography>
            <Controller
                control={control}
                name="opportunity"
                rules={{
                    required: true,
                }}
                render={({field})=> (
                    <TextFieldTheme
                    {...field}
                    multiline
                    minRows={3}
                    placeholder={"opportunity"}
                    helperText={errors.opportunity?.message}  
                    error={!!errors.opportunity}  
                    />
                )}

            />
            </Grid>
            <Grid item xs={12} paddingBottom={"20px"}>
            <Typography className="field-label" color="text.secondary" gutterBottom>
            Next Action / Wrap UP
            </Typography>
            <Controller
                control={control}
                name="nextAction"
                rules={{
                    required: true,
                }}
                render={({field})=> (
                    <TextFieldTheme
                    {...field}
                        multiline
                        minRows={3}
                        placeholder={"Next Action/Wrap UP"}
                        helperText={errors.nextAction?.message}  
                        error={!!errors.nextAction}  
                    />
                )}

            />
            </Grid>
            <Grid item xs={12} paddingBottom={"20px"}>
            <Typography className="field-label" color="text.secondary" gutterBottom>
            homework
            </Typography>
            <Controller
                control={control}
                name="homework"
                rules={{
                    required: true,
                }}
                render={({field})=> (
                    <TextFieldTheme
                    {...field}
                        multiline
                        minRows={3}
                        placeholder={"homework"}
                        helperText={errors.homework?.message}  
                        error={!!errors.homework}  
                    />
                )}

            />
            </Grid>
            <Grid display={"flex"} justifyContent={"end"} item xs={12} container paddingTop={"20px"}>
            <ButtonBlue
              variant="contained"
              color="primary"
              type="submit"
              
            >
              บันทึก
            </ButtonBlue>
            </Grid>
        </StyledCard>
        </form>
    )
}
export default GrowForm;