import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  CardActionArea,
  Avatar,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)({
  margin: 24,
});

const StyledCardMedia = styled(CardMedia)({
  position: "relative",
  height: "100%",
  width: "100%",
  paddingTop: "80%",
});

const StyledGrid = styled(Grid)({
  marginTop: 2,
  marginBottom: 2,
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  left: "10%",
  top: "20px",
  backgroundColor: "#FFA523",
  boxShadow: "0px 0px 1.3px 1.3px rgb(255, 255, 255, 0.3)",
  textAlign: "center",
  overflowWrap: "break-word",
}));

const CardWithInsert = (props) => {
  const { insert, value, event, to } = props;
  return (
    <StyledCard>  
      <CardActionArea component={Link} to={to}>
        <StyledCardMedia
          // image={`${process.env.REACT_APP_API_URL}image/profile/${value.image}`}
          image={`${process.env.REACT_APP_API_URL}image/profile/1.jpg `}
          title="image"
        >
          {/* <StyledAvatar>{insert}</StyledAvatar> */}
        </StyledCardMedia>

        <CardContent>
          <Grid container>
            <StyledGrid item xs={12}>
              <Typography variant="h6">
                {`${value.firstname_TH} ${value.lastname_TH}`}
              </Typography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "400",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {`${value.positionName}`}
              </Typography>
            </StyledGrid>
            {event &&
              event.map((item, idx) => (
                <Grid key={idx} item xs={12} style={{ marginTop: 16 }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: "400",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default CardWithInsert;
