import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Typography, Paper, Tabs, Tab, Box, Divider } from "@mui/material";
import AnalyticList from "./analyticList";

const rand = () => Math.floor(Math.random() * 100);

const colorSet = [
  "#ff5100",
  "#ff9000",
  "#ebc400",
  "#9fc914",
  "#12b09b",
  "#127eb0",
  "#5053a3",
  "#6939a3",
  "#a3399e",
  "#a3395e",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTextHeader = styled(Typography)({
  marginTop: 8,
  marginBottom: 24,
})

const WrapTabs = styled(Paper)({
  backgroundColor: "#424242",
  color: "#FFFFFF",
  margin: "auto",
  width: 350,
})

const StyledTabPanel = styled(TabPanel)({
  "& .MuiBox-root":{
    padding: 0,
    marginTop: 16
  }
})

const StyledDivider = styled(Divider)({
    width: "100%",
    margin: "10px 0",
    backgroundColor: "#ffffff",
  })

const Analytic = () => {
  return (
    <div className={`page`}>
      <StyledTextHeader variant="h4">
        Analytic
      </StyledTextHeader>
      <StyledDivider />
      <AnalyticList />
    </div>
  );
};

export default Analytic;
