import React from "react";
import { Divider, Stack } from "@mui/material";
import QuestionTopic from "./questionTopic";
import AnswerScale from "./answerScale";

const QuestionConfig = ({ selectCompany }) => {
  return (
    <Stack spacing={4}>
      <AnswerScale selectCompany={selectCompany} />
      <Divider />
      <QuestionTopic selectCompany={selectCompany} />
    </Stack>
  );
};

export default QuestionConfig;
