import {
  HEART_LEADERBOARD_FETCHING,
  HEART_LEADERBOARD_FAILED,
  HEART_LEADERBOARD_SUCCESS,
  COIN_LEADERBOARD_FETCHING,
  COIN_LEADERBOARD_FAILED,
  COIN_LEADERBOARD_SUCCESS,
  MANAGER_HEART_LEADERBOARD_FETCHING,
  MANAGER_HEART_LEADERBOARD_FAILED,
  MANAGER_HEART_LEADERBOARD_SUCCESS,
  HEART_RANK_FETCHING,
  HEART_RANK_FAILED,
  HEART_RANK_SUCCESS,
} from "./types";

import leaderboardService from "../services/leaderboard.service";

export const getHeartLeaderboard = () => async (dispatch) => {
  try {
    dispatch({
      type: HEART_LEADERBOARD_FETCHING,
    });
    const res = await leaderboardService.getHeartLeaderboard();
    if (res) {
      dispatch({
        type: HEART_LEADERBOARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: HEART_LEADERBOARD_FAILED,
    });
    console.log(err);
  }
};

export const getCoinLeaderboard = () => async (dispatch) => {
  try {
    dispatch({
      type: COIN_LEADERBOARD_FETCHING,
    });
    const res = await leaderboardService.getCoinLeaderboard();
    if (res) {
      dispatch({
        type: COIN_LEADERBOARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COIN_LEADERBOARD_FAILED,
    });
    console.log(err);
  }
};

export const getManagerHeartLeaderboard = () => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_HEART_LEADERBOARD_FETCHING,
    });
    const res = await leaderboardService.getManagerHeartLeaderboard();
    if (res) {
      dispatch({
        type: MANAGER_HEART_LEADERBOARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_HEART_LEADERBOARD_FAILED,
    });
    console.log(err);
  }
};

export const getHeartLeaderboardRank = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: HEART_RANK_FETCHING,
    });
    const res = await leaderboardService.getHeartLeaderboardRank(idEmployees);
    if (res) {
      dispatch({
        type: HEART_RANK_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: HEART_RANK_FAILED,
    });
  }
};
