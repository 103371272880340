import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import ExposureIcon from '@mui/icons-material/Exposure';
import ListAltIcon from '@mui/icons-material/ListAlt';


const ManagerMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <ListAltIcon />,
      listItemText: "รายการรอนุมัติ",
      listLink: "/manager/waiting-approve",
      listKey:"waiting approve",
    },
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "มุมมองภาพรวม",
      listLink: "",
      listKey:"dashboard",
      collapse: [
        {
          listItemIcon: <ExposureIcon />,
          listItemText: "ภาพรวมสุขภาพใจ",
          listLink: "/manager/dashboard/morale-kpi",
          listKey:"moralekpi",
        },
      ],
    },
    // {
    //   listItemIcon: <DoneAllIcon />,
    //   listItemText: "Approved",
    //   listLink: "/manager/approved",
    //   listKey:"approved",
    // },
    {
      listItemIcon: <AssignmentIcon />,
      listItemText: "มอบหมายภารกิจ",
      listLink: "/manager/mission-assign",
      listKey:"missionAssign",
    },
    {
      listItemIcon: <QueryStatsIcon />,
      listItemText: "การวิเคราะห์",
      listLink: "/manager/analytic",
      listKey:"analytic",
    },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} menuRole={"Manager"} />
  );
};

export default ManagerMenu;
