import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "sonner";
import InputField from "../../../../../shared/inputForm/inputField";
import DateInputField from "../../../../../shared/inputForm/dateInputField";
import { useAddCompanyRewardItemCost } from "../../../../../../../quries/companyReward";

const validationSchema = yup.object().shape({
  transactionDate: yup.date().required("required"),
  // type: yup.string().oneOf(["buy", "sell"]).required("required"),
  quantity: yup
    .number()
    .integer()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .required("required"),
  price: yup
    .number()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .required("required"),
});

const DialogAddCompanyRewardCost = ({ open, handleClose, idCompanyRewardItem }) => {
  const addCompanyRewardItemCost = useAddCompanyRewardItemCost({ idCompanyRewardItem });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      transactionDate: dayjs(),
      // type: "",
      quantity: "",
      price: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    const formData = { ...values, idCompanyRewardItem };
    addCompanyRewardItemCost.mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  };

  const totalPrice = Number(watch("price")) * Number(watch("quantity"));

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"จัดการรายการสินค้า"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <DateInputField
                control={control}
                name="transactionDate"
                label="วันที่"
              />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel>ประเภท</InputLabel>
                    <Select
                      {...field}
                      label="ประเภท"
                      error={Boolean(fieldState.error)}
                    >
                      <MenuItem value={"buy"} color="success">
                        {"Buy"}
                      </MenuItem>
                      <MenuItem value={"sell"} color="error">
                        {"Sell"}
                      </MenuItem>
                    </Select>
                    {Boolean(fieldState.error) && (
                      <FormHelperText error>
                        {fieldState?.error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={4}>
              <InputField
                control={control}
                name="price"
                type="number"
                label="ราคา"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                control={control}
                name="quantity"
                type="number"
                label="จำนวน"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="ราคารวม"
                value={totalPrice.toFixed(2) || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            {"ยกเลิก"}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddCompanyRewardCost;
