import React, { useState } from "react";
import {
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingIcon from "../../../../../shared/general/loadingIcon";
import utils from "../../../../../../../utils";
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import DialogConfigStoreUniReward from "./dialogConfigUniReward";
import { useStoreUniRewardItemCost } from "../../../../../../../quries/reward";
import HeaderPage from "../../../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../../../shared/dividerHeader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledCardData = styled("div")({
  height: "100%",
  padding: "16px",
  borderRadius: "10px",
  backgroundColor: "#b17bdf" 
});

const CompanyUniRewardTransaction = () => {
  const { state } = useLocation();
  const { data, isPending } = useStoreUniRewardItemCost({ 
    idUniRewardItem: state.idUniRewardItem,
    idCompany: state.idCompany,
  });

  const [openDailogCost, setOpenDailogCost] = useState(false);
  const [selectReward, setSelectReward] = useState(false);

  const handleOpenDailogCost = (data) => {
    setOpenDailogCost(true)
    setSelectReward(data)
  }
  
  const handleCloseDailogCost = () => {
    setOpenDailogCost(false)
    setSelectReward(null)
  }

  if (isPending) {
    return <LoadingIcon />
  }

  const rewardData = data?.costs?.reduce(
    (acc, cur) => {
      acc.totalRedeem += Number(cur.redeem);
      acc.totalOnshelf += Number(cur.onShelf);
      acc.balanceQuantity += cur.quantity - cur.redeem;
      acc.totalValue += cur.price * cur.quantity;

      return acc;
    },
    { totalRedeem: 0, totalOnshelf: 0, balanceQuantity: 0, totalValue: 0 }
  );

  return (
    <div className={"page"}>
      <HeaderPage textLabel={"ข้อมูลของรางวัล"} />
      <StyledDividerHeader />
      <Container>
        <div style={{ marginBottom: "24px" }}>
          <Typography variant="h5">{data.name}</Typography>
          <Typography variant="h6">{`SKU: ${data.sku}`}</Typography>
          <Typography>
            {data.options.map((option) => option.value).join(", ")}
          </Typography>
        </div>
        <Grid container spacing={2} marginBottom={4}>
          <Grid item xs={6} sm={6} md={3}>
            <StyledCardData>
              <Typography variant="h6" color={"#ffffff"}>
                {"แลกทั้งหมด"}
              </Typography>
              <Typography variant="h6" color={"#ffffff"} textAlign={"end"}>
                {utils.numberWithCommas(rewardData.totalRedeem)}
              </Typography>
            </StyledCardData>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StyledCardData>
              <Typography variant="h6" color={"#ffffff"}>
                {"เปิดให้แลก"}
              </Typography>
              <Typography variant="h6" color={"#ffffff"} textAlign={"end"}>
                {utils.numberWithCommas(rewardData.totalOnshelf)}
              </Typography>
            </StyledCardData>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StyledCardData>
              <Typography variant="h6" color={"#ffffff"}>
                {"จำนวนคงเหลือ"}
              </Typography>
              <Typography variant="h6" color={"#ffffff"} textAlign={"end"}>
                {utils.numberWithCommas(rewardData.balanceQuantity)}
              </Typography>
            </StyledCardData>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <StyledCardData>
              <Typography variant="h6" color={"#ffffff"}>
                {"มูลค่ารวม"}
              </Typography>
              <Typography variant="h6" color={"#ffffff"} textAlign={"end"}>
                {utils.numberWithCommas(rewardData.totalValue)}
              </Typography>
            </StyledCardData>
          </Grid>
        </Grid>
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">วันที่</StyledTableCell>
                <StyledTableCell align="right">ราคา</StyledTableCell>
                <StyledTableCell align="right">จำนวน</StyledTableCell>
                <StyledTableCell align="right">มูลค่ารวม</StyledTableCell>
                <StyledTableCell align="center">แลกไปแล้ว</StyledTableCell>
                <StyledTableCell align="center">เปิดให้แลก</StyledTableCell>
                <StyledTableCell align="center">คงเหลือ</StyledTableCell>
                <StyledTableCell align="center">สถานะ</StyledTableCell>
                <StyledTableCell align="center">จัดการ</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.costs.map((item) => (
                <TableRow key={item.idOrderRewardItem}>
                  <StyledTableCell align="center">
                    {dayjs(item.createAt).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {utils.numberWithCommas(item.price)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {utils.numberWithCommas(item.quantity)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {utils.numberWithCommas(
                      Number(item.price * item.quantity).toFixed(2)
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.redeem ?? 0}</StyledTableCell>
                  <StyledTableCell align="center">{item.onShelf ?? 0}</StyledTableCell>
                  <StyledTableCell align="center">{item.quantity - item.redeem}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Chip
                      variant="outlined"
                      label={item.isActive ? "เปิดให้แลก" : "จัดเก็บ"}
                      color={item.isActive ? "success" : "error"}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton onClick={() => handleOpenDailogCost(item)}>
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {openDailogCost && (
          <DialogConfigStoreUniReward
            open={openDailogCost}
            handleClose={handleCloseDailogCost} 
            data={selectReward} 
          />
        )}
      </Container>
    </div>
  );
};

export default CompanyUniRewardTransaction;
