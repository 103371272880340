import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  Typography,
  Chip,
  Link as FileLink,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  Paper,
  Container,
  Alert,
} from "@mui/material";

import HeaderPage from "../../../pages/shared/header/headerPage";
import MoraleQuestionIcon from "./assets/question.png";
import defaultImage from "./assets/placeholder.png"
import { getMoraleUser } from "../../../../actions/moraleQuestion";


const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    
    //marginBottom: 36,
    // ["@media only screen and (max-width:600px)"]: {
    //   flexDirection: "column",
    // },
  },
});

const WrapHeader = styled('div')({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  // ["@media only screen and (max-width:600px)"]: {
  //   flexDirection: "column",
  //   "& .wrap-button-add": {
  //     marginTop: 8,
  //   },
  // },
})

const WrapCardQuestion = styled(Paper)({
  width: "100%",
  display: "flex",
  borderRadius: 15,
  marginBottom: 20,
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const StyledBtnDescDraft = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#f8de55",
  color: "#3C3C3C",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#f8de55",
    borderColor: "#f8de55",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnDescClosed = styled(Button)({
  border: 0,
  borderRadius: 50,
  padding: "4px 12px",
  fontSize: 14,
  backgroundColor: "#BBBBBB",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 20,
  right: 20,
  "&:hover": {
    backgroundColor: "#BBBBBB",
    borderColor: "#BBBBBB",
    boxShadow: "none",
  },
  ["@media only screen and (max-width:600px)"]: {
    position: "initial",
    marginTop: 16,
    width: "100%"
  },
})

const StyledBtnViewAnswer = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
    fontSize: 14,
    cursor: "pointer",
    color: myColor,
}))

const StyledPanelLeft = styled('div',
{ shouldForwardProp: (prop) => prop !== 'myBgColor' &&  prop !== 'myColor'})(
  ({ myBgColor, myColor }) => ({
    backgroundColor: myBgColor,
    width: "30%",
    padding: 20,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .questionName": {
      fontSize: 32,
      color: myColor,
    },
    "& .subQuestionName": {
      fontSize: 14,
      color: myColor,
    },
    "& .wrapViewAnswer": {
      marginTop: 8,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      color: myColor,
    },
    ["@media only screen and (max-width:600px)"]: {
      width: "100%",
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 15,
      "& .questionName": {
        fontSize: 24,
      },
      "& .subQuestionName": {
        fontSize: 16,
      },
    },
}))

const StyledPanelRight = styled('div')({
  width: "70%",
  padding: 20,
  color: "#2a265f",
  position: "relative",
  display: "flex",
  alignItems: "center",
  "& .inner-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .questionTopic":{
    display: "flex", alignItems: "baseline"
  },
  ["@media only screen and (max-width:600px)"]: {
    width: "100%",
    "& .inner-top": {
      flexDirection: "column",
      alignItems: "flex-end",
    },
    "& .questionTopic":{
      flexDirection: "column"
    }
  },
})

const StyledHeaderTopic = styled(Typography)({
  fontSize: 14,
  opacity: 0.6,
  color: "#000000",
  marginRight: 5,
})

const StyledContentTopic = styled(Typography)({
  fontSize: 16,
  color: "#3C3C3C",
  display: "flex",
  alignItems: "baseline",
})

const WrapQuestionStatusPublished = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
})

const StyledQuestionStatusPublished = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
    fontSize: 12,
    textAlign: "right",
    paddingTop: 5,
    textTransform: "uppercase",
    color: myColor,
}))

const StyledSecondTextTopic = styled(Typography)({
  marginLeft: 5,
  textTransform: "uppercase",
  fontSize: 14,
  ["@media only screen and (max-width:600px)"]: {
    marginLeft: 22
  }
})

const StyledQuestionNumber = styled(Typography,
  { shouldForwardProp: (prop) => prop !== 'myColor'})(
    ({ myColor }) => ({
      fontSize: 14, 
      fontStyle: "oblique",
      color: myColor,
}))

const WrapFilter = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
})

const AddQuestionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#2A265F",
  "&:hover": {
    backgroundColor: "#353078",
  },
});

// const StyledDivider = styled(Divider)({
//   borderWidth: "0px 0px thin",
//   borderColor: "#919eab52",
//   borderStyle: "dashed",
// });

export default function MoraleQuestion() {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: moraleUser } = useSelector(state => state.moraleUser)
  console.log('userProfile:',userProfile)

  useEffect(() => {
    dispatch(getMoraleUser(userProfile.idEmployees))
  }, [dispatch, userProfile]);

  const handleClickAnswerQuestion = (idMorale) => {
    navigate("/morale-question/" + idMorale);
  };
  console.log('moraleUser:',moraleUser)
  return (
    <StyledRoot className={`page`}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถามสุขภาพใจรายปี"} icon={MoraleQuestionIcon} />
      </WrapHeader>
      <Container sx={{ 
        maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }, // Adjust maxWidth for different screen sizes
        paddingTop: 5,
      }}>
      <Grid container spacing={2}>
        {moraleUser && moraleUser.map(value => {
          const numberOfQuestion = value.questionTopic.reduce((accumulator, topic) => accumulator + topic.questionList.length , 0);
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={value.idMorale} 
              sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center' 
              }}
            >
              <Card sx={{ 
                width: '100%',// Maximum width
                height: '520px',
                borderRadius: '12px', 
                boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
              }} key={value.idMorale}>
                <CardContent>
                <Box
                  sx={{
                    backgroundColor: 'gray',  // Color for the picture area
                    // height: { xs: '200px', sm: '250px' },
                    height: '250px',
                    borderRadius: '4px',
                    marginBottom: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}      
                >
                  <img
                  src={value.picture && value.picture.trim() !== '' ? value.picture : defaultImage} // Conditional rendering for the image
                  alt="Morale"
                  style={{
                    width: '100%', 
                    height: '100%', 
                    objectFit: 'cover', 
                  }}
                  />
                </Box>
                <Box  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{height:'45px', width:'100%'}}>
                        <Typography variant="h6" component="div" fontWeight="bold" style={{ lineHeight: 1 }}>
                          แบบสอบถาม {value.moraleName} {/* Morale Title */}
                        </Typography>
                      </Box>
                      <Box sx={{height:'30px'}}>
                      <Typography variant="body2" style={{ lineHeight: 1 }}>
                        รายละเอียด: {value.description}
                      </Typography>
                      </Box>
                      <Typography variant="body2">
                        จำนวนข้อ: {numberOfQuestion} ข้อ {`(${value.questionTopic.length} หัวข้อ)`}
                      </Typography>

                      <Typography variant="body2">
                        Start: {dayjs(value.startDate).format('DD MMM YYYY HH:MM')} {/* Start Date */}
                      </Typography>
                      <Typography variant="body2">
                        End: {dayjs(value.endDate).format('DD MMM YYYY HH:MM')} {/* End Date */}
                      </Typography>
                      
                  
                  <Grid item xs={12} display={'flex'} alignItems={'center'} gap={2} marginTop={1} >
                  <Box display="flex" height={20} flexDirection={"row"} width={'100%'}>
                          {value.isComplete
                            ? <Alert severity="success" sx={{ width: '100%', height:'50px', borderRadius:'10px', mt:'10px',}}>ตอบคำถามแล้ว</Alert>
                            : <Alert severity="warning" sx={{ width: '100%', height:'50px', borderRadius:'10px', mt:'10px'}}>ยังไม่ได้ตอบคำถาม</Alert>
                          }
                      
                          {
                            !value.isComplete && (
                              <Chip 
                                label={dayjs().isAfter(dayjs(value.endDate)) ? 'เลยกำหนด' : 'ตอบคำถาม'}
                                // label={'ตอบคำถาม'}
                                color="primary" 
                                variant={dayjs().isAfter(dayjs(value.endDate)) ? 'outlined' : 'filled'} // Change variant based on date
                                sx={{
                                  color: dayjs().isAfter(dayjs(value.endDate)) ? '#000' : '#fff', // Change text color based on state
                                  backgroundColor: dayjs().isAfter(dayjs(value.endDate)) ? 'transparent' : '#177ef4', // Background color for 'ตอบคำถาม'
                                  fontWeight: "500",
                                  width: '150px', height:'50px', borderRadius:'10px', mt:'10px', borderWidth:2, ml:'10px'
                                }} 
                                onClick={() =>handleClickAnswerQuestion(value.idMorale)}
                                disabled={dayjs().isAfter(dayjs(value.endDate))}
                              /> 
                             
                          )}
                          </Box>
                        </Grid>
                  
                      
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>     
    </Container>
  </StyledRoot>
  );
}