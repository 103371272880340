import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const DivRoot = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledImg = styled("img")({
  marginRight: 10,
});

const HeaderPage = ({ textLabel, icon, color }) => {
  return (
    <DivRoot>
      {icon && <StyledImg width={50} src={icon} />}
      <Typography variant="h4" color={color}>
        {textLabel}
      </Typography>
    </DivRoot>
  );
};

export default HeaderPage;
