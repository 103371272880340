import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "sonner";
import { useUpdateCompanyResetCoinBalance } from "../../../../../quries/company";

const validationSchema = yup.object({
  resetCoinBalanceMonth: yup.date().required(),
});

const DialogEditQuota = ({ open, handleClose, idCompany, data }) => {
  const { mutate } = useUpdateCompanyResetCoinBalance({ idCompany });
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      resetCoinBalanceMonth: data.resetCoinBalanceMonth ? dayjs().set("month", data.resetCoinBalanceMonth - 1) : null,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (values) => {
    mutate(
      { ...values, idCompany, resetCoinBalanceMonth: dayjs(values.resetCoinBalanceMonth).month() + 1 },
      {
        onSuccess: () => toast.success("สำเร็จ"),
        onError: () => toast.error("เกิดข้อผิดพลาด"),
        onSettled: () => handleClose(),
      }
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(handleEdit),
      }}
    >
      <DialogTitle>{"แก้ไข"}</DialogTitle>
      <DialogContent>
        <Controller
          name="resetCoinBalanceMonth"
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              sx={{ marginTop: "8px" }}
              label={"Month"}
              openTo="month"
              views={["month"]}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: Boolean(fieldState.error),
                  helperText: fieldState.error?.message,
                },
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ResetCoinBalance = ({ selectCompany, data }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography variant="h6">รีเช็ตกระเป๋าเหรียญ</Typography>
          <IconButton onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </div>
        <Typography>{`เดือน ${dayjs().set("month", data.resetCoinBalanceMonth - 1).format("MMMM")}`}</Typography>
      </Stack>
      {open && (
        <DialogEditQuota
          open={open}
          handleClose={handleClose}
          idCompany={selectCompany.idCompany}
          data={data}
        />
      )}
    </>
  );
};

export default ResetCoinBalance;
