import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StatusMenu = ({
  row,
  handleChangeStatusRedeem,
  cancelStatusRedeem,
  handleOpenDate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`actions-menu-${row.idRedeem}`}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        {row.idRedeemStatus !== 2 && (
          <MenuItem onClick={() => handleChangeStatusRedeem({ idRedeemStatus: 1, idRedeem: row.idRedeem })}>
            {"กำลังตรวจสอบ"}
          </MenuItem>
        )}
        <MenuItem onClick={() => handleOpenDate({ receivedDate: row.receivedDate, idRedeem: row.idRedeem })}>
          {"รอมารับรางวัล"}
        </MenuItem>
        <MenuItem onClick={() => handleChangeStatusRedeem({ idRedeemStatus: 3, idRedeem: row.idRedeem })}>
          {"รับรางวัลแล้ว"}
        </MenuItem>
        <MenuItem onClick={() => cancelStatusRedeem(row.idRedeem)}>
          {"ยกเลิกรายการ"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default StatusMenu;
