import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

const bgColorChart = {
  id: 'bgColorChart',
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
}

const DoughnutChart = ({ value, id, setDoughnutChart }) => {
  const data = {
    datasets: [
      {
        data: [value, (100-value)],
        backgroundColor: [
          "rgba(132, 255, 201, 0.5)",
          "rgba(189, 189, 189, 0.5)",
          
        ],
        borderColor: ["rgba(132, 255, 201, 1)", "rgba(189, 189, 189, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    cutoutPercentage: 70,
    tooltips: {
      enabled: false,
    },
    hover: {
      mode: null,
    },
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: `${value}%`,
            color: "rgb(68, 68, 68)",
            font: {
              size: 32,
              weight: "bold",
            },
          },
        ],
      },
    },
  };
  
  useEffect(() => {
    const temp = []
    for (let i = 1; i <= 15; i++) {
      temp.push(document.getElementById(i))
    }
    setDoughnutChart([...temp])
  }, [])

  return <Doughnut data={data} id={id} options={options} plugins={[ bgColorChart ]} />;
};

export default DoughnutChart;
