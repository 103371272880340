import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Alert,
  Avatar,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Snackbar,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const StyledImageDropZone = styled("div")({
  "& .dropzone-announcement": {
    width: 140,
    height: 140,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
});

const thumb = {
  display: "inline-flex",
  border: "1px solid #eaeaea",
  width: 140,
  height: 140,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  objectFit: "contained",
  width: "100%",
  height: "100%",
};

const MAX_SIZE = 500000; //500KB

const UpdateRewardImage = ({
  onChange,
  acceptFileType,
  title,
  error,
  value,
  id,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles.map((file) =>
      Object.assign(file, {
        imageURL: URL.createObjectURL(file),
        [id]: value?.[id],
      })
    )
    onChange(file[0]);
  }, [value]);

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: acceptFileType,
    maxSize: MAX_SIZE,
    onDrop,
    multiple: false,
  });

  const checkFileSize = fileRejections.length > 0 && fileRejections[0].file.size > MAX_SIZE;

  const handleRemove = () => {
    onChange({
      [id]: value?.[id],
      imageURL: null,
    });
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => [value]?.forEach((file) => URL.revokeObjectURL(file?.imageURL));
  }, []);

  const thumbs = value?.imageURL ? (
    <div style={thumb}>
      <div style={thumbInner}>
        <img
          alt={"reward"}
          src={value?.imageURL}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(value?.imageURL);
          }}
        />
      </div>
    </div>
  ) : null;

  return (
    <>
      <ImageList>
        <ImageListItem>
          <StyledImageDropZone className={!!error ? "error" : ""}>
            {thumbs ? (
              <aside onClick={open}>{thumbs}</aside>
            ) : (
              <div {...getRootProps({ className: "dropzone-announcement" })}>
                <input {...getInputProps()} />
                <Avatar variant="rounded">
                  <AddAPhotoIcon />
                </Avatar>
              </div>
            )}
          </StyledImageDropZone>
          <ImageListItemBar
            title={title}
            actionIcon={
              thumbs ? (
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about`}
                  onClick={handleRemove}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null
            }
          />
        </ImageListItem>
      </ImageList>
      {checkFileSize && (
        <p style={{ color: "#d32f2f" }}>ไฟล์มีขนาดเกิน 500 KB</p>
      )}
      {error && (
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {error?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UpdateRewardImage;
