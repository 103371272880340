import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Card, Container, Grid ,Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {   getBookingCoach} from "../../../../actions/book";
import loading from "../../assets/loading2.gif";
import CardWaitingCoach from "./component/card_wating_coach";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#ffffff",

});


const StyledCard = styled(Card)({
  padding: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const ApproveBooking = () => {
  const dispatch = useDispatch();
  const { result: bookCoachee ,isFetching:bookingLoadingStatus} = useSelector((state) => state.bookCoach);

  useEffect(() => {
    dispatch( getBookingCoach());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{margin:"24px 0"}}>
           รายชื่อโค้ชของฉัน
        </Typography>
        { bookingLoadingStatus?(
           <Box
           style={{
             width: "100%",
             display: "flex",
             justifyContent: "center",
             paddingTop: "40px",
           }}
         >
           <img width="120" alt="loading" src={loading} />
         </Box>
        ):(
          <StyledCard>
            {
              bookCoachee&& bookCoachee.length == 0? (
                <Box
                    style={{
                      minHeight: 200,
                      position: "sticky",
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>ไม่มีข้อมูล</Typography>
                  </Box>
    
              ):(
                <Grid container spacing={2}>
                  {bookCoachee && bookCoachee.map((val)=>(
                    <Grid item xs={12} sm={6} md={4} xl={4} key={val.idCoach}>
                      <CardWaitingCoach value={val} tagType={"fix-coach"} />
                    </Grid>
                  ))}
    
                </Grid>
              )
            }
          </StyledCard>
        )

        }
      </Container>
    </StyledRoot>
  );
};

export default ApproveBooking;
