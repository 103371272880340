import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  CardActions,
  CardMedia,
  Checkbox,
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Rating } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";

import { getAllCoach } from "../../../../actions/coach";
import { getUserProfile } from "../../../../actions/user";
import {
  addFavoriteCoach,
  deleteFavoriteCoach,
} from "../../../../actions/favoriteCoach";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const StyledCardMedia = styled(CardMedia)({
  position: "relative",
  height: "100%",
  width: "100%",
  paddingTop: "100%",
});

const Favorite = () => {
  const dispatch = useDispatch();
  const { result: coach } = useSelector((state) => state.coach);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [search, setSearch] = useState("");

  const onChangeSearch = (e) => {
    setSearch(e.target.value.toLocaleLowerCase());
  };

  useEffect(() => {
    dispatch(getAllCoach());
  }, []);

  const handleChangeFavoriteCoach = async (event) => {
    let res = null;
    if (event.target.checked) {
      res = await dispatch(
        addFavoriteCoach({
          idEmployees: currentUser.id,
          idCoach: event.target.value,
        })
      );
    } else {
      res = await dispatch(
        deleteFavoriteCoach({
          idCoach: event.target.value,
          idEmployees: currentUser.id,
        })
      );
    }
    if (res) {
      dispatch(getUserProfile(currentUser.id));
    }
  };

  return (
    <div className={`page`}>
      {userProfile && (
        <Container maxWidth="lg">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
            marginBottom={4}
            spacing={2}
          >
            <Typography variant="h4">โค้ชที่ชื่นชอบ</Typography>
            <TextField
              label="Search name"
              variant="outlined"
              size="small"
              onChange={onChangeSearch}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: "16px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Grid container spacing={5} direction="row">
            {coach &&
              coach
                .filter((item) => item.idCoach !== userProfile.idEmployees)
                .filter((item) =>
                  userProfile.favoriteCoach.some(
                    (val) => val === item.idCoach
                  )
                )
                .filter(
                  (item) =>
                    item.firstname_TH.toLocaleLowerCase().includes(search) ||
                    item.lastname_TH.toLocaleLowerCase().includes(search)
                )
                .map((val) => (
                  <Grid item xs={12} sm={6} md={4} xl={3} key={val.idEmployees}>
                    <StyledCard>
                      <CardActionArea component={Link} to={`/coaching/profile/${val.idEmployees}`}>
                        <div>
                          <StyledCardMedia
                            // image={`${process.env.REACT_APP_API_URL}image/profile/${val.image}`}
                            image={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                          >
                            <FormControlLabel
                              label=""
                              control={
                                <Checkbox
                                  checked={
                                    userProfile.favoriteCoach.find(
                                      (id) => id === val.idCoach
                                    )
                                      ? true
                                      : false
                                  }
                                  style={{
                                    position: "absolute",
                                    color: "#d32f2f",
                                    bottom: "88%",
                                    left: "88%",
                                    fontWeight: 20,
                                    backgroundColor: "white",
                                  }}
                                  icon={<FavoriteBorderIcon />}
                                  checkedIcon={<FavoriteIcon />}
                                  name="fovorite"
                                  value={val.idCoach}
                                  onChange={handleChangeFavoriteCoach}
                                />
                              }
                            />
                          </StyledCardMedia>
                        </div>
                        <CardContent>
                          <Typography component="h2" variant="h6">
                            {`${val.firstname_TH} ${val.lastname_TH}`}
                          </Typography>
                          <Typography
                            component="p"
                            variant="subtitle1"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {val.positionName}
                          </Typography>
                          <Rating
                            style={{ right: "2px" }}
                            value={val.rating}
                            readOnly
                            size="small"
                            precision={0.5}
                          />
                          <Typography component="p" variant="subtitle2">
                            {val.rating}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </StyledCard>
                  </Grid>
                ))}
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default Favorite;
