import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import loading from "../../assets/loading2.gif";
import { MainCoin } from "../../shared/typeCoin";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "../../manager/approveList/component/DialogConfirmReject";
import CardRequest from "./component/CardRequest";
import {
  Typography,
  Avatar,
  Chip,
  Box,
  useMediaQuery,
  Container,
  Card
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { getBookingByIdCoach, updateBooking } from "../../../../actions/book";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#f1f4f9",

});

const StyledCard = styled(Card)({
  padding: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});



const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const StyledChipTime = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  color: "#ffffff",
  backgroundColor: theme.palette.warning.main,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  "& .icon": {
    color: "inherit",
  },
  "&.book": {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`,
  },
  "&.approve": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
  "&.reject": {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.success": {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
  },
}));

const Status = (props) => {
  const { status } = props;
  let icon = null;

  if (status === "book") {
    icon = <InfoIcon className={"icon"} />;
  } else if (status === "approve") {
    icon = <DoneIcon className={"icon"} />;
  } else if (status === "reject") {
    icon = <CancelIcon className={"icon"} />;
  } else if (status === "success") {
    icon = <DoneAllIcon className={"icon"} />;
  }

  return (
    <StyledChip
      className={status}
      icon={icon}
      label={status}
      variant={"outlined"}
    />
  );
};


const BookingSummary = () => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [modeSnackbar, setModeSnackbar] = useState("info");
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const { result: bookCoach ,isFetching:isCoachLoading} = useSelector((state) => state.bookCoach);
  const handleCancel =()=>{
    setSelectedRows([]);
  }
  const handleClickAll =()=>{
    setSelectedRows(bookCoach);

  }


  
  const handleApprovalRequest = async(idBookingStatus,comment)=>{
    let formData =  {
        bookingList:selectedRows,
        idBookingStatus:idBookingStatus,
        comment:comment       
    }
    setIsOpenRejectDialog(false);
    const result = await dispatch(updateBooking(formData));
    if(result){
        setSelectedRows([]);
        dispatch(getBookingByIdCoach())
        if(result.status === 200){
            setOpenSnackbar(true);
            setDisplaySnackbar("Successfully")
            setModeSnackbar("success")
            
        }else{
            setOpenSnackbar(true);
            setModeSnackbar("error")
            setDisplaySnackbar("Unsuccessful")
        }

    }else{
        setOpenSnackbar(true);
        setModeSnackbar("error")
        setDisplaySnackbar("Unsuccessful")
    }

}
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState({
    totalPrice: "",
    type: "",
    place: "",
    note: "",
  });
  const [openOverlap, setOpenOverlap] = useState(false);
  const [dataBook, setDataBook] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);

  const handleOpenOverlap = () => {
    setOpenOverlap(true);
  };

  const handleCloseOverlap = () => {
    setOpenOverlap(false);
  };

  const handleClickOpen = (value) => {
    setDetail(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDetail({
      totalPrice: "",
      type: "",
      place: "",
      note: "",
    });
  };

  useEffect(() => {
    dispatch(getBookingByIdCoach());
  }, []);

  const handleCheckDateBook = (dataBooking) => {
    const checkDataStart = bookCoach.some((item) =>
      dayjs(dataBooking.startTime).isBetween(
        dayjs(item.startTime),
        dayjs(item.endTime)
      )
    );

    const checkDataEnd = bookCoach.some((item) =>
      dayjs(dataBooking.endTime).isBetween(
        dayjs(item.startTime),
        dayjs(item.endTime)
      )
    );

    const checkBookStart = bookCoach.some((item) =>
      dayjs(item.startTime).isBetween(
        dayjs(dataBooking.startTime),
        dayjs(dataBooking.endTime)
      )
    );

    const checkBookEnd = bookCoach.some((item) =>
      dayjs(item.endTime).isBetween(
        dayjs(dataBooking.startTime),
        dayjs(dataBooking.endTime)
      )
    );

    const checkStart = bookCoach.some((item) =>
      dayjs(item.startTime).isSame(dayjs(dataBooking.startTime))
    );

    const checkEnd = bookCoach.some((item) =>
      dayjs(item.endTime).isSame(dayjs(dataBooking.endTime))
    );
    if (
      !checkDataStart &&
      !checkDataEnd &&
      !checkBookStart &&
      !checkBookEnd &&
      !checkStart &&
      !checkEnd
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleReject = async () => {
    const res = await dispatch(updateBooking(dataBook));
    if (res) {
      dispatch(getBookingByIdCoach(currentUser.id));
    }
  };

  const mobileResponsive = useMediaQuery("(max-width:600px)");

  const colorStatus = ["warning", "secondary", "error", "success", "default"];


  const columns = [
    {
      name: "ชื่อ-สกุล"	,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.profileImageURL}
          />
          <Box flexGrow={1}>
            <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "วันที่จอง",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.bookDate).format("DD/MM/YYYY HH:mm")}
          </Typography>
          
        </Box>
      ),
    },
    {
      name: "วันที่เริ่มเรียน",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.startTime).format("DD/MM/YYYY HH:mm")}
          </Typography>
          
        </Box>
      ),
    },
    {
      name: "วันที่สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.endTime).format("DD/MM/YYYY HH:mm")}
          </Typography>              
        </Box>
      ),
    },
    {
      name: "ประเภท",
      headerTextAlign: "center",
      minWidth: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign:"center"}}>
          <Typography fontSize="14px">
            {row.type}
          </Typography>
        </Box>
      ),
    },
    {
      name: "สถานที่",
      headerTextAlign: "center",
      minWidth: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign:"center"}}>
          <Typography  fontSize="14px">
            {row.place}
          </Typography>
        </Box>
      ),
    },
    {
      name: "หมายเหตุ",
      minWidth: "150px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.note}
          </Typography>
        </Box>
      ),
    },
    {
        name: "ราคา(ทั้งหมด)",
        headerTextAlign: "center",
        minWidth: "150px",
        cellRender: (row) => (
          <Box sx={{textAlign:"center", display:"flex",alignItems:"center",justifyContent:"center"}}>
            <MainCoin width={32} />
            <Typography  fontSize="14px" marginLeft={"8px"}>
              x {row.totalPrice}
            </Typography>
          </Box>
        ),
      },
  ];

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom={2} style={{paddingTop:24}}>
          ตารางการนัดหมายโค้ช
        </Typography>
        <StyledCard>
        {isCoachLoading?(
           <Box
           style={{
             width: "100%",
             display: "flex",
             justifyContent: "center",
             alignItems:"center",
             paddingTop: "40px",
           }}
         >
           <img width="120" alt="loading" src={loading} />
         </Box>
        ):(
          <Box>
          {mobileResponsive && (
          <box display="flex"  justifyContent="flex-end" paddingBottom="24px">
          {Array.isArray(selectedRows) && selectedRows.length > 0 && (
              <StyledButtonCancel onClick={handleCancel} startIcon={<DeleteIcon />}>
              ยกเลิก
              </StyledButtonCancel>
          )}
          {!(
              Array.isArray(selectedRows) &&
              Array.isArray(bookCoach) &&
              selectedRows.length === bookCoach.length
          ) && (
              <ButtonBlue variant="contained" startIcon={<DoneAll />} onClick={handleClickAll}>
              เลือกทั้งหมด
              </ButtonBlue>
          )}
              </box>
          )}
          {selectedRows.length > 0 && (
               <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
               <ButtonBlue
                 style={{ marginRight: 8 }}
                 startIcon={<DoneAll />}
                 variant="contained"
                 onClick={() => handleApprovalRequest(2)}
               >
                 อนุมัติ {
                   selectedRows.length > 0
                     ? `${selectedRows.length} รายการ`
                     : ""
                 }
               </ButtonBlue>
               <ButtonBlue
                 startIcon={<DoneAll />}
                 variant="outlined"
                 onClick={() => setIsOpenRejectDialog(true)}
               >
                 ไม่อนุมัติ {
                   selectedRows.length > 0
                     ? `${selectedRows.length} รายการ`
                     : ""
                 }
               </ButtonBlue>
             </Box>
          )}
          {!mobileResponsive ? (
              <TableCustom
              columns={columns}
              rows={bookCoach  ?? []}
              canSelect
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          ):(
              <div>
                {bookCoach ? (
                  bookCoach.map((row) => (
                    <CardRequest
                      key={row.idRequestTime}
                      row={row}
                      mode={"bookCoach"}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />            ))
                ) : (
                  <div>
                    <Typography align="center">ไม่มีข้อมูล</Typography>
                  </div>
                )}
              </div>
            )}
             <DialogConfirmReject
                  rejectCount={selectedRows.length}
                  open={isOpenRejectDialog}
                  onClose={() => {
                  setIsOpenRejectDialog(false);
                  }}
                  handleSubmit={(comment) => {
                  handleApprovalRequest(3,comment);
                  }}
              />
        </Box>
        )}
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default BookingSummary;
