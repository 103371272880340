import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const DialogStatus = ({
  redeemSelected,
  openChangeDate,
  handleCloseDate,
  handleChangeStatusRedeem,
}) => {
  const [selectDate, setSelectDate] = useState(dayjs(redeemSelected.receivedDate) || dayjs());

  return (
    <Dialog
      open={openChangeDate}
      onClose={handleCloseDate}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">
        กำหนดวันที่รับรองของวัล
      </DialogTitle>
      <DialogContent>
        {redeemSelected.receivedDate && (
          <Typography>{`วันที่เดิม: ${dayjs(redeemSelected.receivedDate).format(
            "DD/MM/YYYY"
          )}`}</Typography>
        )}
        <DatePicker
          label="วันที่รับของรางวัล"
          value={selectDate}
          onChange={(newValue) => setSelectDate(newValue)}
          slotProps={{ textField: { margin: "normal" } }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleCloseDate}>
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleChangeStatusRedeem({
              idRedeem: redeemSelected.idRedeem,
              idRedeemStatus: 2,
              receivedDate: selectDate,
            });
          }}
        >
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogStatus;
