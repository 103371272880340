import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Popover,
  TextField,
} from "@mui/material";

const InputUrl = ({ anchorEl, handleClose, handleUrl }) => {
  const [url, setUrl] = useState("");
  const open = Boolean(anchorEl);

  const handleSetUrl = (value) => {
    handleUrl(value)
    setUrl('')
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Card>
        <CardContent>
          <TextField 
            label="URL"
            value={url}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
          />
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={() => handleSetUrl(url)}>Ok</Button>
          <Button onClick={() => handleSetUrl(null)}>Cancel</Button>
        </CardActions>
      </Card>
    </Popover>
  );
};

export default InputUrl