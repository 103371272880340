import React, { useEffect ,useState} from "react";
import { styled } from "@mui/material/styles";
import { Typography, Avatar, Chip, Container, Grid ,Box,Card, Stack,Snackbar,Alert} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import loading from "../../assets/loading2.gif";
import {  getCoachById } from "../../../../actions/coach";
import { getAllCoachRecordByIdCoach } from "../../../../actions/coachRecord";
import { MainCoin } from "../../shared/typeCoin";
import CardAppointmentCoach from "./component/CardAppointmentCoach"
import DetailProfileCoach from "../coach/component/detailProfileCoach";
const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    margin: "0px auto",
    minWidth: 350,
    minHeight: "100vh",
    color: "#ffffff",
    backgroundColor: "#f1f4f9",

});


const DetailCoach = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { idCoach } = location.state || {};
    const { result: coachProfile,isFetching:isCoachingLoadingState} = useSelector((state) => state.coachItem);
    const {result: recordes,isFetching: isRecordLoadingState} = useSelector((state)=>  state.coachRecord) 
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modeSnackbar, setModeSnackbar] = useState("info");
    const [displaySnackbar, setDisplaySnackbar] = useState("");

    useEffect(()=>{
        console.log(idCoach);
        dispatch(getAllCoachRecordByIdCoach(idCoach));
        dispatch(getCoachById(idCoach));

    },[])
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnackbar(false);
      };
    
    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                {
                    isCoachingLoadingState?
                    (
                        <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "40px",
                        }}
                      >
                        <img width="120" alt="loading" src={loading} />
                      </Box>
                    ):
                    (
                      coachProfile && (
                          <DetailProfileCoach coachProfile={coachProfile} />
                      )
                    )
                }
                <Typography variant="h5" sx={{margin:"20px 0"}}>
                    ประวัติการเข้าโค้ช
                </Typography>
                {
                    isRecordLoadingState?(
                        <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "40px",
                        }}
                      >
                        <img width="120" alt="loading" src={loading} />
                      </Box>
                    ):(
                        <Grid container spacing={2} > 
                           { recordes && recordes.map((record) =>(
                            <Grid item  xs={12} sm={6} md={6} xl={4} key={record.bookingId}>
                               <CardAppointmentCoach record={record}  setSnackBarConfig={setOpenSnackbar} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar}/>
                            </Grid>
                            ))}
                        </Grid>
                    )
                }

            </Container>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                sx={{ width: "100%" }}
                severity={modeSnackbar}
              >
          {displaySnackbar}
        </Alert>
      </Snackbar>
        </StyledRoot>
    )
}

export default DetailCoach;