import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import { useUniRewardSellHistory } from "../../../../../quries/uniReward";
import LoadingIcon from "../../../shared/general/loadingIcon";
import { StyledDividerHeader } from "../../../shared/dividerHeader";
import HeaderPage from "../../../shared/header/headerPage";
import utils from "../../../../../utils";
import FilterOrder from "./filterOrder";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderItem = ({ order }) => {
  const totalPrice = order.orderItem.reduce(
    (acc, curr) => acc + curr.quantity * curr.price,
    0
  );

  return (
    <Card>
      <CardHeader
        sx={{ bgcolor: "#071952" }}
        title={
          <>
            <div>
              <Typography color={"#ffffff"} textAlign={"start"}>
                {`รหัสคำสั่งซื้อ: ${order.idOrderReward}`}
              </Typography>
              <Typography color={"#ffffff"}>
                {`บริษัท: ${order.companyName}`}
              </Typography>
            </div>
            <Typography
              color={"white"}
            >{`${order.firstname_TH} ${order.lastname_TH}`}</Typography>
          </>
        }
        titleTypographyProps={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "right",
        }}
        subheader={
          <Typography variant="body2" color={"text.secondary"}>
            {dayjs(order.createAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <List>
          {order.orderItem.map((item) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="reward" src={item.imageURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      {`ตัวเลือก: ${item.variation
                        .map((item) => item.value)
                        .join(", ")}`}
                      <Typography variant="body2" color="text.primary">
                        {`จำนวน: ${item.quantity}`}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {`ราคา: ฿${utils.numberWithCommas(item.price)}`}
                      </Typography>
                    </>
                  }
                />
                <Typography variant="body2" color="text.primary">
                  {`฿${utils.numberWithCommas(item.price * item.quantity)}`}
                </Typography>
              </ListItem>
              <Divider component="li" />
            </>
          ))}
        </List>
        <Typography variant="h6" align="right">
          {`ราคารวม ฿${utils.numberWithCommas(totalPrice)}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

const UniRewardSaleHistory = () => {
  const { data, isPending } = useUniRewardSellHistory();
  const [filter, setFilter] = useState("");
  const [orderFiltered, setOrderFiltered] = useState([]);

  useEffect(() => {
    if (filter.trim().length === 0) {
      setOrderFiltered(data)
    } else {
      const filterData = data?.filter(
        (item) =>
          item.idOrderReward === Number(filter) ||
        item.firstname_TH.toLowerCase().includes(filter.toLowerCase()) ||
        item.lastname_TH.toLowerCase().includes(filter.toLowerCase()) ||
        item.companyName.toLowerCase().includes(filter.toLowerCase())
      );
      setOrderFiltered(filterData)
    }
  }, [filter])

  useEffect(() => {
    setOrderFiltered(data)
  }, [data])

  if (isPending) {
    return <LoadingIcon />
  }

  return (
    <StyledRoot className={"page"}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderPage textLabel={"ประวัติการขายสินค้า"} />
        </div>
        <StyledDividerHeader />
        <div style={{ marginBottom: "24px" }}>
          <FilterOrder setFilterValue={setFilter} />
        </div>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="ทั้งหมด" {...a11yProps(0)} />
          <Tab label="สินค้า" {...a11yProps(1)} />
          <Tab label="บริษัท" {...a11yProps(2)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          Item One
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel> */}
        <Stack spacing={4}>
          {orderFiltered?.map((order) => (
            <OrderItem order={order} key={order.idOrderReward} />
          ))}
        </Stack>
      </Container>
    </StyledRoot>
  );
};

export default UniRewardSaleHistory;
