import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function DateInputValue(props) {
  const { item, applyValue, focusElementRef } = props;

//   const ratingRef = React.useRef(null);
//   React.useImperativeHandle(focusElementRef, () => ({
//     focus: () => {
//       console.log(ratingRef.current);
//       ratingRef.current
//         .querySelector(`input[value="${item.value || ""}"]`)
//         .focus();
//     },
//   }));

  return (
    <DatePicker
    //   ref={ratingRef}
      minDate={dayjs().startOf("year")}
      maxDate={dayjs().endOf("year")}
      label={"month"}
      views={["month"]}
      value={item.value || dayjs()}
      onChange={(newValue) => {
        applyValue({ ...item, value: newValue });
      }}
    />
  );
}

const monthOnlyOperators = [
  {
    label: "is",
    value: "is",
    getApplyFilterFn: (filterItem) => {
      // if (
      //   !filterItem.columnField ||
      //   !filterItem.value ||
      //   !filterItem.operatorValue
      // ) {
      //   return null;
      // }
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params) => {
        return dayjs(params.value).isSame(dayjs(filterItem.value), "month");
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
];

export default monthOnlyOperators;
