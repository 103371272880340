import React from "react";
import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import dayjs from "dayjs";
import { SpecialIcon } from "../../shared/typeCoin";
import { useSelector } from "react-redux";
import {
  useUserCoinInTransaction,
  useUserCoinOutTransaction,
} from "../../../../quries/coin";

const Detail = ({ data }) => {
  if (data.idHeartTransfer) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>{"ส่งหัวใจ"}</Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item>
          <Typography color={"#ffffff"}>
            {`${data.sender_firstname_TH} ${data.sender_lastname_TH}`}
          </Typography>
        </Grid>
      </>
    );
  } else if (data.idActivity) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>{"กิจกรรม"}</Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item>
          <Typography color={"#ffffff"}>{data.name}</Typography>
        </Grid>
      </>
    );
  } else if (data.idCompanySendCoin) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>{"บริษัทส่งเหรียญ"}</Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item>
          <Typography color={"#ffffff"}>{data.name}</Typography>
        </Grid>
      </>
    );
  } else {
    return null;
  }
};

const CoinIn = ({ item }) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={6} md={1}>
        <Chip
          sx={{ fontSize: "1rem" }}
          label={item.amount}
          icon={<AddIcon />}
          color="success"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <SpecialIcon idCoinType={item.idCoinType} width={40} />
        <Typography variant="h6" color={"#ffffff"}>{item.coinName}</Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} sm={6} md={2}>
        <Typography color={"#ffffff"}>
          {dayjs(item.transactionDate).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Detail data={item} />
    </Grid>
  );
};

const CoinOut = ({ item }) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={6} md={1}>
        <Chip
          sx={{ fontSize: "1rem" }}
          label={item.amount}
          icon={<RemoveIcon />}
          color={"error"}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <SpecialIcon idCoinType={item.idCoinType} width={40} />
        <Typography variant="h6" color={"#ffffff"}>{item.coinName}</Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} sm={6} md={2}>
        <Typography color={"#ffffff"}>
          {dayjs(item.transactionDate).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} md={1.5}>
        <Typography fontWeight={600} color={"#ffffff"}>
          {"แลกรางวัล"}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      {item.options && (
        <Grid item xs={12} md={3} display={"flex"} gap={1}>
          <Typography color={"#ffffff"}>{item.name}</Typography>
          <Typography color={"#ffffff"}>
            {item.options.map((option) => option.value).join(", ")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const CoinTransaction = ({ startDate, endDate }) => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const coinIn = useUserCoinInTransaction({ idEmployees: userProfile?.idEmployees, startDate, endDate });
  const coinOut = useUserCoinOutTransaction({ idEmployees: userProfile?.idEmployees, startDate, endDate });

  if (coinIn.isPending || coinOut.isPending) {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <CircularProgress />
      </div>
    );
  }

  const data = [...coinIn?.data, ...coinOut?.data];

  return (
    <Stack spacing={2} marginTop={4}>
      {data.length > 0 ? (
        data.map((item) => (
          <Card sx={{ backgroundColor: "#000000", borderRadius: "1rem" }}>
            <CardContent>
              {item.type === "in" ? (
                <CoinIn item={item} />
              ) : (
                <CoinOut item={item} />
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="h5" color={"#ffffff"}>
          ไม่พบข้อมูล
        </Typography>
      )}
    </Stack>
  );
};

export default CoinTransaction;
