import { httpClient } from "./httpClient";

const getAllAppraisee = () => {
  return httpClient.get("appraisee");
};

const getAppraisee = (id) => {
  return httpClient.get("appraisee/" + id);
};

const getAppraiseeUser = () => {
  return httpClient.get("appraisee-user");
};

const addAppraisee = (formData) => {
  return httpClient.post('appraisee', formData);
};

const addAnswerAppraisee = (formData) => {
  return httpClient.post('appraisee-answer', formData);
};

const addOutsiderEmail = (email) => {
  return httpClient.post('outsider-email', {email});
}

export default {
  getAllAppraisee,
  getAppraisee,
  getAppraiseeUser,
  addAppraisee,
  addAnswerAppraisee,
  addOutsiderEmail,
};
