import {
    Grid,
    Typography,
    Card,
  } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import parse from 'html-react-parser';


const RecordDetail = (props)=>{
  const {record} = props;

    const StyledCard = styled(Card)({
        padding: 24,
        width:"100%",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: 20,
        "& .MuiCardContent-root": {
          padding: 24,
        },
        marginBottom:"24px"
      });

      const StyledBorder = styled("div")({
        width:"100%",
        borderRadius:"8px",
        border: '1px solid #ccc',
        backgroundColor: "#ffffff",
        padding:"16px",
        marginBottom:"20px"
      })
      return (
        <Grid container spacing={2}>
          <Grid item xs = {12} md ={6} xl ={6}>
            <StyledCard>
              <Typography className="field-label" color="black" gutterBottom>
                    บันทึกของ Coachee
              </Typography>
              {record.coachee_recordType != null ? (
                record.coachee_recordType == 1 ? (
                  <Grid container>
                    {[
                      { label: "Goal", value: record.coachee_goal },
                      { label: "Reality", value: record.coachee_reality },
                      { label: "Opportunity", value: record.coachee_opportunity },
                      { label: "Next Action", value: record.coachee_wrapUp },
                      { label: "Home Work", value: record.coachee_homework ?? "-" },
                    ].map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <Typography className="field-label" color="text.secondary" gutterBottom>
                          {item.label}
                        </Typography>
                        <StyledBorder>
                          <Typography className="field-label">
                            {item.value}
                          </Typography>
                        </StyledBorder>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid>
                    <Typography className="field-label" color="text.secondary" gutterBottom>
                        รายละเอียด</Typography>
                    <StyledBorder>
                    <Typography>
                      {parse(`<span>${record.coachee_normalform??"-"}</span>`)}
                    </Typography>

                    </StyledBorder>
                  </Grid>
                )
              ):(
                <div style={{padding:"24px",textAlign:"center"}}>
                  <Typography>ยังไม่มีการบันทึก</Typography>
                </div>
              )}
            </StyledCard>
          </Grid>
          <Grid item xs = {12} md ={6} xl ={6}>
            <StyledCard>
            <Typography className="field-label" color="black" gutterBottom>
                    บันทึกของ Coach
              </Typography>
              {
                record.isRecordCoach == 1 ? (
                  record.coach_recordType == 1 ? (
                    <Grid container>
                      {[
                        { label: "Goal", value: record.coach_goal },
                        { label: "Reality", value: record.coach_reality },
                        { label: "Opportunity", value: record.coach_opportunity },
                        { label: "Next Action", value: record.coach_wrapUp },
                        { label: "Home Work", value: record.coach_homework ?? "-" },
                      ].map((item, index) => (
                        <Grid item xs={12} key={index}>
                          <Typography className="field-label" color="text.secondary" gutterBottom>
                            {item.label}
                          </Typography>
                          <StyledBorder>
                            <Typography className="field-label">
                            {item.value??"-"}
                            </Typography>
                          </StyledBorder>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid>
                      <Typography className="field-label" color="text.secondary" gutterBottom>
                      รายละเอียด</Typography>
                      <StyledBorder>
                      <Typography>
                        {parse(`<span>${record.coach_normalform??"-"}</span>`)}
                      </Typography>

                    </StyledBorder>
                    </Grid>
                  )
                ):(
                  <div style={{padding:"24px",textAlign:"center"}}>
                    <Typography>ยังไม่มีการบันทึก</Typography>
                  </div>
                )
              }
            </StyledCard>
          </Grid>
        </Grid>
      );
      
}

export default RecordDetail;