import {
  HEART_SEND_MONTH_FETCHING,
  HEART_SEND_MONTH_FAILED,
  HEART_SEND_MONTH_SUCCESS,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HEART_SEND_MONTH_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case HEART_SEND_MONTH_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case HEART_SEND_MONTH_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
