import { httpClient } from "./httpClient";

const getAllMissions = () => {
  return httpClient.get("mission");
};

const addMission = (formData) => {
  return httpClient.post("mission", formData);
};

const deleteMission = (id) => {
  return httpClient.delete("mission", { data: id });
};

const updateMission = (idMission, formData) => {
  return httpClient.put(`mission/${idMission}`, formData);
};

const approveMission = (formData) => {
  return httpClient.patch("mission-approve", formData);
};

const getMissionByUserId = () => {
  return httpClient.get("mission-user");
};

const getMissionByCreateId = () => {
  return httpClient.get("mission-manager");
};

export default {
  getAllMissions,
  addMission,
  deleteMission,
  updateMission,
  approveMission,
  getMissionByUserId,
  getMissionByCreateId
};
