import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FirstBg from "./assets/Section1.png";
import SecondBg from "./assets/SecondBg.png";
import ThirdBgRight from "./assets/ThirdBgRight.png";
import FiveBg from "./assets/FiveBg.png";
import SixBg from "./assets/SixBg.png";
import { Typography, Avatar, Grid } from "@mui/material";

import DoneAllIcon from "@mui/icons-material/DoneAll";

import ScrollAnimation from "react-animate-on-scroll";
import LightSpeed from "react-reveal/LightSpeed";

import BtnStart from "./assets/btnStart.png";
import Ant1 from "./assets/ant1.png";
import Unicorn from "./assets/Unicorn-Mascot-6-HartFC.png";
// import Unicorn from "./assets/Unicorn-Mascot-6-Hart-FC.png";
import Coin13D from "./assets/coin3d-1.png";
import Vdo from "./assets/vdo.png";

import Item1 from "./assets/item1.png";
import Item2 from "./assets/item2.png";
import Item3 from "./assets/item3.png";
import Item4 from "./assets/item4.png";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DivRoot = styled('div')({
  width: "100%",
  "& .MuiTypography-root": {
    fontFamily: `'Francois One' !important`,
  },
})

const WrapFirstBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${FirstBg})`,
  height: 650,
  "& .wrapFirstContent": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    position: "relative",
    marginLeft: 148,
    ["@media only screen and (max-width:425px)"]: {
      marginLeft: 0,
    },
    "& .MuiTypography-root": {
      color: "#FFFFFF",
    },
    "& a": {
      marginTop: 20,
      marginBottom: 20,
      width: 197,
      height: 90,
      border: "none",
      backgroundImage: `url(${BtnStart})`,
      backgroundSize: "cover",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .wrap3D": {
      position: "absolute",
      right: 80,
      left: "auto",
      bottom: 240,
      animation: "$ant 3.5s ease-in-out 0s infinite normal ",
      width: '30vw',
      height: '30vh',
      ["@media only screen and (min-width:1500px)"]: {
        right: 160,
        bottom: 300,
      },
      ["@media only screen and (max-width:1440px)"]: {
        right: 160,
      },
      ["@media only screen and (max-width:1024px)"]: {
        right: 10,
        bottom: 100,
      },
      ["@media only screen and (max-width:425px)"]: {
        right: 10,
        bottom: 0,
      },
    },
    "& .wrapCoin13D": {
      position: "absolute",
      right: 76,
      bottom: 160,
      animation: "$ant 3.5s ease-in-out 0s infinite normal ",
      ["@media only screen and (max-width:740px)"]: {
        display: "none",
      },
    },
  },
})

const WrapSecondBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: 600,
  backgroundImage: `url(${SecondBg})`,
  "& .wrapSecondContent": {
    height: "100%",
    "& .left": {
      "& .left-2": {
        fontSize: 20,
        marginTop: 16,
      },
      ["@media only screen and (max-width: 600px)"]: {
        marginTop: 36,
        margin: "0 8px",
        textAlign: "center",
      },
      ["@media only screen and (min-width:600px)"]: {},
      ["@media only screen and (min-width:768px)"]: {
        marginTop: 72,
        margin: "auto",
        width: "70%",
        textAlign: "center",
        "& .left-2": {
          fontSize: 20,
          marginTop: 16,
          width: "100%",
        },
      },
      ["@media only screen and (min-width:992px)"]: {
        "& .left-2": {
          maxWidth: 450,
        },
        marginTop: 120,
        marginLeft: 56,
      },
    },
    "& .right": {
      "& .inner-right": {
        position: "relative",
        height: "100%",
        ["@media only screen and (max-width: 600px)"]: {
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          flexWrap: "wrap",
          padding: "20px 0",
          width: "100%",
          "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
            display: "flex",
            margin: 16,
          },
        },
        ["@media only screen and (min-width:600px)"]: {},
        ["@media only screen and (min-width:768px)"]: {
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          flexWrap: "wrap",
          padding: "20px 0",
          width: "80%",
          "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
            display: "flex",
            margin: 16,
          },
        },
        ["@media only screen and (min-width:992px)"]: {
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          flexWrap: "wrap",
          padding: "100px 0",
          width: "80%",
          "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
            display: "flex",
            margin: 16,
          },
        },
        ["@media only screen and (min-width:1200px)"]: {
          display: "block",
          justifyContent: "center",
          margin: 0,
          flexWrap: "wrap",
          padding: 0,
          width: "100%",
          "& .item1": {
            position: "absolute",
            top: 80,
            left: "30%",
          },
          "& .item2": {
            position: "absolute",
            top: 80,
            right: "30%",
          },
          "& .item3": {
            position: "absolute",
            top: 230,
            left: "20%",
          },
          "& .item4": {
            position: "absolute",
            top: 230,
            left: "42.5%",
          },
          "& .item5": {
            position: "absolute",
            top: 230,
            right: "20%",
          },
          "& .item6": {
            position: "absolute",
            top: 380,
            right: "30%",
          },
          "& .item7": {
            position: "absolute",
            top: 380,
            left: "30%",
          },
        },
      },
    },
  },
  ["@media only screen and (max-width: 600px)"]: {
    height: 900,
  },
  ["@media only screen and (max-width: 320px)"]: {
    height: 1000,
  },
})

const WrapAvatarAndBoost = styled('div')({
  textAlign: "center",
})

const WrapThirdBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  "& .MuiTypography-h3": {
    ["@media only screen and (max-width: 600px)"]: {
      padding: "16px 0",
      textAlign: "center",
    },
    ["@media only screen and (min-width:768px)"]: {
      padding: "16px 0",
      textAlign: "center",
    },
    ["@media only screen and (min-width:992px)"]: {
      paddingTop: 24,
      paddingLeft: 56,
      textAlign: "left",
    },
  },
  "& .wrapThirdContent": {
    "& .left": {
      display: "flex",
      justifyContent: "space-evenly",
      "& .img-ant1": {
        width: 200,
        ["@media only screen and (max-width: 600px)"]: {
          position: "absolute",
          right: 0,
          bottom: 28,
          width: 100,
        },
      },
      "& .left-1": {
        display: "flex",
        alignItems: "center",
        marginBottom: 28,
        "& .MuiTypography-root": {
          marginLeft: 16,
        },
      },
      ["@media only screen and (max-width: 600px)"]: {
        textAlign: "center",
        flexDirection: "row-reverse",
        position: "relative",
      },
      ["@media only screen and (min-width:600px)"]: {},
      ["@media only screen and (min-width:768px)"]: {},
      ["@media only screen and (min-width:992px)"]: {
        paddingTop: 40,
      },
    },
    "& .outer-right": {
      backgroundImage: `url(${ThirdBgRight})`,
      "& .right": {
        height: "100%",
        display: "flex",
        alignItems: "center",
        ["@media only screen and (max-width: 600px)"]: {
          justifyContent: "center",
        },
        ["@media only screen and (min-width:768px)"]: {
          width: "100%",
          justifyContent: "center",
        },
        "& .right-vdo": {
          ["@media only screen and (max-width: 600px)"]: {
            width: 350,
          },
          ["@media only screen and (max-width: 320px)"]: {
            width: 300,
          },
          ["@media only screen and (min-width:600px)"]: {},
          ["@media only screen and (min-width:768px)"]: {
            width: 400,
          },
          ["@media only screen and (min-width:992px)"]: {
            width: 350,
          },
          ["@media only screen and (min-width:1200px)"]: {
            width: 550,
          },
        },
      },
    },
  },
  ["@media only screen and (max-width: 600px)"]: {},
  ["@media only screen and (max-width: 320px)"]: {},
})

const WrapFourBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  "& .MuiTypography-h3": {
    padding: "32px 0",
  },
})

const WrapItemSectFour = styled('div')({
  maxWidth: 500,
  "& .text-2": {
    minHeight: 48,
    fontSize: 22,
  },
  "& .itemImageSectFour": {
    margin: "36px 0",
  },
})

const WrapFiveBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: 482,
  "& .wrapContentFive": {
    height: "100%",
    "& .left": {
      backgroundImage: `url(${FiveBg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    "& .innerContent": {
      height: "100%",
      maxWidth: 550,
      margin: "auto",
      "& .MuiTypography-h3": {
        paddingTop: 75,
        ["@media only screen and (max-width: 600px)"]: {
          paddingTop: 28,
        },
      },
      "& .MuiTypography-h6": {
        paddingTop: 24,
      },
    },
  },
  "& .MuiTypography-h4": {
    paddingLeft: 56,
  },
})

const WrapSixBg = styled('div')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: 565,
  backgroundImage: `url(${SixBg})`,
  "& .wrapContentSix": {
    height: "100%",
    "& .right": {
      height: "100%",
      color: "#FFFFFF",
      "& .inner-right": {
        display: "flex",
        flexDirection: "column",
        paddingTop: 100,
        maxWidth: 580,
        margin: "auto",
        "& .inner-right-1": {
          paddingTop: 26,
        },
        "& .inner-right-2": {
          marginTop: 24,
        },
        ["@media only screen and (min-width:768px)"]: {
          maxWidth: 500,
        },
      },
    },
  },
  ["@media only screen and (max-width: 600px)"]: {
    height: 650,
  },
})

const StyledAvatar = styled(Avatar)({
  width: 80,
  height: 80,
})

const AvatarAndBoost = ({ value, boost }) => {
  return (
    <WrapAvatarAndBoost>
      <Typography variant="subtitle1">{boost}</Typography>
      <StyledAvatar
        alt={value.username}
        src={`${process.env.REACT_APP_API_URL}image/profile/${value.image}`}
      />
    </WrapAvatarAndBoost>
  );
};
const Landing = () => {

  const users = [
    {
      id: 1,
      username: "admin",
      email: "admin@scg.com",
      password: "adminadmin",
      image: "1.jpg",
      firstName: "Tapmid",
      lastName: "Humphries",
      idDepartment: 13,
      department: "HR Business Partner",
      position: "Assistant Manager-HR Business Partner",
      mobileNumber: "065-556-3915",
      workingLocation: "TPE - Rayong Site#1",
      site: 1,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 29078,
      following: 84392,
      authorities: ["ROLE_USER", "ROLE_ADMIN"],
    },
    {
      id: 2,
      username: "manager",
      email: "manager@scg.com",
      password: "managermanager",
      image: "2.jpg",
      firstName: "Theodor",
      lastName: "O'Moore",
      idDepartment: 13,
      department: "HR Business Partner",
      position: "Manager-HR Business Partner",
      mobileNumber: "085-550-0949",
      workingLocation: "TPE - Rayong Site#1",
      site: 1,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 86753,
      following: 32454,
      authorities: ["ROLE_USER", "ROLE_MANAGER"],
    },
    {
      id: 3,
      username: "user",
      email: "user@scg.com",
      password: "useruser",
      image: "3.jpg",
      firstName: "Shaunna",
      lastName: "Richard",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "085-558-9673",
      workingLocation: "TPE - Rayong Site#3",
      site: 3,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 9732,
      following: 4356,
      authorities: ["ROLE_USER"],
    },
    {
      id: 4,
      username: "superuser",
      email: "mandeemc@domain.com",
      password: "superuser",
      image: "4.jpg",
      firstName: "Mandeep",
      lastName: "Mccray",
      idDepartment: 5,
      department: "Catalyst & Pilot Plant Production Department",
      position: "Catalyst Production Operator",
      mobileNumber: "095-557-3152",
      workingLocation: "TPE - Rayong Site#3",
      site: 3,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 9732,
      following: 4356,
      authorities: ["ROLE_USER", "ROLE_MANAGER", "ROLE_ADMIN"],
    },
    {
      id: 5,
      username: "alaynawo",
      email: "alaynawo@domain.com",
      password: "useruser",
      image: "5.jpg",
      firstName: "Alayna",
      lastName: "Wolfe",
      idDepartment: 2,
      department: "Quality Assurance & Quality Control Department",
      position: "Quality Control Technician - PAL",
      mobileNumber: "085-558-4021",
      workingLocation: "TPE - Rayong Site#3",
      site: 3,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 9732,
      following: 4356,
      authorities: ["ROLE_USER"],
    },
    {
      id: 6,
      username: "anishava",
      email: "anishava@domain.com",
      password: "useruser",
      image: "6.jpg",
      firstName: "Anisha",
      lastName: "Valdez",
      idDepartment: 12,
      department: "Safety, Occupational Health & Environment Department",
      position: "Occupational Health & Safety System Engineer",
      mobileNumber: "085-555-6279",
      workingLocation: "TPE - Rayong Site#3",
      site: 3,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 9732,
      following: 4356,
      authorities: ["ROLE_USER"],
    },
    {
      id: 7,
      username: "aedankn",
      email: "aedankn@domain.com",
      password: "useruser",
      image: "7.jpg",
      firstName: "Aedan",
      lastName: "Knights",
      idDepartment: 6,
      department: "HDPE 4 Production",
      position: "HDPE 4 Production Operator",
      mobileNumber: "095-554-4538",
      workingLocation: "TPE - Rayong Site#3",
      site: 3,
      status: "active",
      company: "Thai Polyethylene Co., Ltd.",
      follower: 9732,
      following: 4356,
      authorities: ["ROLE_USER"],
    },
  ];

  const { user: currentUser } = useSelector((state) => state.auth);
  if(currentUser) {
    // return <Redirect to="/home" />
    return <Navigate to="/home" />
  }

  return (
    <DivRoot>
      <WrapFirstBg>
        <div className={`wrapFirstContent`}>
          <ScrollAnimation animateIn="fadeIn" duration={5}>
            <ScrollAnimation
              animateIn="rubberBand"
              initiallyVisible={true}
              delay={500}
            >
              <Typography
                align="left"
                variant="h1"
                style={{ fontSize: 62, color: "#3a3a3a" }}
              >
                Boost Up{" "}
                <span variant="h1" style={{ fontSize: 72, color: "#ec8d35" }}>
                  10X
                </span>{" "}
                <br />
                Employee Engagement
                <br />& Culture
              </Typography>
              {/* <Typography style={{ color: "#3a3a3aad", fontSize: 28 }}>By Blockchain recognition coin and many amazing feature </Typography> */}
            </ScrollAnimation>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={1500}>
            <ScrollAnimation
              animateIn="wobble"
              initiallyVisible={true}
              delay={1500}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link to="/login"></Link>
              </div>
            </ScrollAnimation>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={1500}>
            <div className={`wrap3D`}>
              <img src={Unicorn} alt="ant" width="100%" height="auto" />
            </div>
            {/* <div className={`wrapCoin13D`}>
              <img src={Coin13D} alt="coin" width="80" />
            </div> */}
          </ScrollAnimation>
        </div>
      </WrapFirstBg>
      <WrapFourBg>
        <Typography variant="h3" align="center">
          If people is the most value of your company , Boost up
        </Typography>
        <Grid container align="center">
          <Grid item xs={12} sm={6}>
            <ScrollAnimation animateIn="fadeInLeft">
              <WrapItemSectFour>
                <Typography variant="h4" gutterBottom>
                  Reward & Recognition
                </Typography>
                <Typography
                  color="textSecondary"
                  className={`text-2`}
                  gutterBottom
                >
                  Hunting Coin for rewarding & Sending Heart for recignize
                  together
                </Typography>
                <img src={Item1} className={`itemImageSectFour`} />
              </WrapItemSectFour>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ScrollAnimation animateIn="fadeInRight">
              <WrapItemSectFour>
                <Typography variant="h4" gutterBottom>
                  Morale Health Check
                </Typography>
                <Typography
                  color="textSecondary"
                  className={`text-2`}
                  gutterBottom
                >
                  Create questionaire for Morale Check & Data analytic for
                  employee engagement
                </Typography>
                <img src={Item2} className={`itemImageSectFour`} />
              </WrapItemSectFour>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ScrollAnimation animateIn="fadeInLeft">
              <WrapItemSectFour>
                <Typography variant="h4" gutterBottom>
                  360 Degree Feedback
                </Typography>
                <Typography
                  color="textSecondary"
                  className={`text-2`}
                  gutterBottom
                >
                  Feedback is a gift culture by feedback feature & amaing 360
                  degree
                </Typography>
                <img src={Item3} className={`itemImageSectFour`} />
              </WrapItemSectFour>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ScrollAnimation animateIn="fadeInRight">
              <WrapItemSectFour>
                <Typography variant="h4" gutterBottom>
                  Coaching
                </Typography>
                <Typography
                  color="textSecondary"
                  className={`text-2`}
                  gutterBottom
                >
                  Find your best coach , appoint for coaching and coaching
                  record for improve people in your team
                </Typography>
                <img src={Item4} className={`itemImageSectFour`} />
              </WrapItemSectFour>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </WrapFourBg>
      <WrapFiveBg>
        <Grid container justifyContent="center" className={`wrapContentFive`}>
          <Grid item xs={12} sm={6} className={`left`}>
            <Typography variant="h4">Ours feature</Typography>
            <ScrollAnimation animateIn="rotateInDownLeft">
              <div className={`innerContent`}>
                <Typography variant="h3" align="center">
                  Amazing feature for your best experience
                </Typography>
                <Typography variant="h6" color="textSecondary" align="center">
                  We create modern interface for great experience not only user
                  part but admin self service also
                </Typography>
              </div>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </WrapFiveBg>
      <WrapSecondBg>
        <Grid container className={`wrapSecondContent`}>
          <Grid item xs={12} sm={12} md={6}>
            <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
              <div className={`left`}>
                <Typography variant="h3">
                  Gamification for connect all employees, <br /> Create Collaboration
                  and Communication
                </Typography>
              </div>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={`right`}>
            <div className={`inner-right`}>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={1750}
              >
                <div className={`item1`}>
                  <AvatarAndBoost value={users[0]} boost={`Leadership`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={750}
              >
                <div className={`item2`}>
                  <AvatarAndBoost value={users[1]} boost={`Recognition`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={250}
              >
                <div className={`item3`}>
                  <AvatarAndBoost value={users[2]} boost={`Feedback`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={1500}
              >
                <div className={`item4`}>
                  <AvatarAndBoost value={users[3]} boost={`Engagement`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={500}
              >
                <div className={`item5`}>
                  <AvatarAndBoost value={users[4]} boost={`Culture`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={1000}
              >
                <div className={`item6`}>
                  <AvatarAndBoost value={users[5]} boost={`Rewarding`} />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceIn"
                animateOnce={true}
                delay={1250}
              >
                <div className={`item7`}>
                  <AvatarAndBoost value={users[6]} boost={`Coaching`} />
                </div>
              </ScrollAnimation>
            </div>
          </Grid>
        </Grid>
      </WrapSecondBg>
      <WrapThirdBg>
        <LightSpeed left cascade>
          <Typography variant="h3" style={{}}>
            Benefits to your organization
          </Typography>
        </LightSpeed>

        <Grid container className={`wrapThirdContent`}>
          <Grid item xs={12} sm={12} md={7}>
            <LightSpeed left cascade>
              <div className={`left`}>
                <div>
                  <img className={`img-ant1`} src={Ant1} alt="ant1" />
                </div>
                <div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">Employee engagement</Typography>
                  </div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">Strong culture</Typography>
                  </div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">People Development</Typography>
                  </div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">Business success</Typography>
                  </div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">Employee experience</Typography>
                  </div>
                  <div className={`left-1`}>
                    <DoneAllIcon />
                    <Typography variant="h5">Digitize & Data driven</Typography>
                  </div>
                </div>
              </div>
            </LightSpeed>
          </Grid>
          <Grid item xs={12} sm={12} md={5} className={`outer-right`}>
            <div className={`right`}>
              <img className={`right-vdo`} src={Vdo} alt="vdo" />
            </div>
          </Grid>
        </Grid>
      </WrapThirdBg>
      <WrapSixBg>
        {/* <Typography>Ours Technology</Typography> */}
        <Grid container alignItems="center" className={`wrapContentSix`}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={10} className={`right`}>
            <div className={`inner-right`}>
              {/* <div className={`inner-right-1`}>
                <ScrollAnimation animateIn="flipInX" delay={200}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ color: "#fffb1f" }}
                    gutterBottom
                  >
                    Blockchain
                  </Typography>
                </ScrollAnimation>
                <ScrollAnimation animateIn="flipInX" delay={300}>
                  <Typography align="center" variant="h5" color={"#FFFFFF"}>
                    First HR Application which use “Blockchain” Technology adapt
                    to recognition coin process{" "}
                  </Typography>
                </ScrollAnimation>
              </div> */}
              <div className={`inner-right-2`}>
                <ScrollAnimation animateIn="flipInX" delay={400}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ color: "#d6ff55" }}
                    gutterBottom
                  >
                    Web Application
                  </Typography>
                </ScrollAnimation>
                <ScrollAnimation animateIn="flipInX" delay={500}>
                  <Typography align="center" variant="h5" color={"#FFFFFF"}>
                    We select high performance framework for create modern web
                    application for your good experience{" "}
                  </Typography>
                </ScrollAnimation>
              </div>
            </div>
          </Grid>
        </Grid>
      </WrapSixBg>
    </DivRoot>
  );
};

export default Landing;
