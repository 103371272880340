import { httpClient } from "./httpClient";

// const getFavorite = (idCoachee) => {
//   return httpClient.get(`favorite?idCoachee=${idCoachee}`);
// };

const getFavoriteCoach = (idEmployees) => {
  return httpClient.get(`favorite-coach/` + idEmployees);
};

const addFavoriteCoach = (formData) => {
  return httpClient.post("favorite-coach", formData);
};

const deleteFavoriteCoach = (payload) => {
  return httpClient.delete(`favorite-coach`, { data: payload });
};

export default {
  getFavoriteCoach,
  addFavoriteCoach,
  deleteFavoriteCoach,
};
