import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import "./index.css";
import {
  Divider,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Stack,
} from "@mui/material";

// Icons
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarsIcon from '@mui/icons-material/Stars';
import HeaderIcon from "./assets/score.svg";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment } from "../../../../actions/department";
import LeaderBoardHeart from "./LeaderBoardHeart";
import { getCoinLeaderboard, getHeartLeaderboard } from "../../../../actions/leaderboard";

const StyledDivider = styled(Divider)({
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
  backgroundColor: "#71c4cd",
});

export default function LeaderBoard() {
  const [view, setView] = useState("heart")

  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: heartLeaderboard } = useSelector((state) => state.heartLeaderboard);
  const { result: coinLeaderboard } = useSelector((state) => state.coinLeaderboard);

  useEffect(() => {
    const fecthData = () => {
      dispatch(getDepartment());
      dispatch(getCoinLeaderboard())
      dispatch(getHeartLeaderboard())
    }

    fecthData()
  }, []);

  return (
    <StyledRoot className={'page'}>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={"space-between"} spacing={1}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={HeaderIcon} alt="heart" width={45} />
          <Typography variant="h4" style={{ marginLeft: 10 }}>
            Leaderboard
          </Typography>
        </div>
        <BottomNavigation
          showLabels
          style={{ maxWidth: 160 }}
          value={view}
          onChange={(event, newValue) => {
            setView(newValue);
          }}
        >
          <BottomNavigationAction value={"heart"} label="Heart" icon={<FavoriteIcon />} />
          <BottomNavigationAction value={"coin"} label="Coin" icon={<StarsIcon />} />
        </BottomNavigation>
      </Stack>
      <StyledDivider />
      {heartLeaderboard && coinLeaderboard &&
        <LeaderBoardHeart
          data={view === "heart" ? heartLeaderboard : coinLeaderboard}
          type={view}
        />
      }
    </StyledRoot>
  );
}
