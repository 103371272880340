import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
} from "@mui/material";




import { getFixCoach,getCoachPopular, getCoachIsRegistered } from "../../../../actions/coach";


import FixCoach from "./component/fix_coach";
import JobGroup from "./component/jobgroup";


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Link } from "react-router-dom";
import {  getCategory } from "../../../../actions/category";
import PopularCoach from "./component/popular_coach";
import ButtonBlue from "../../shared/general/ButtonBlue";




const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  marginBottom:"5%",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#ffffff",});

const StyledGrid = styled(Grid)({
  textAlign: "start",
  marginTop:"50px",
  marginBottom:"20px"
});







const Home = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const {result : isRegistered} = useSelector((state)=> state.coachIsRegistered);






  
  useEffect(() => {
    dispatch(getFixCoach());
    dispatch(getCoachPopular());
    dispatch(getCategory());
    dispatch(getCoachIsRegistered());
  }, []);




  return (
    <StyledRoot className={"page"}>
      {userProfile && (
        <Container maxWidth="lg">
          {isRegistered && !isRegistered.isCoachRegistered&& (
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <ButtonBlue
                component={Link}
                to={'/coaching/register-coach'}
                color="secondary"
                variant="contained"
              >
                สมัครการเป็นโค้ช
              </ButtonBlue>
            </Stack>
          )}

            <StyledGrid item xs={12} >
              <Typography variant="h5">Fix Coach</Typography>
            </StyledGrid>

            <FixCoach/>







            <StyledGrid item xs={12} >
              <Typography variant="h5">Popular Coach</Typography>
            </StyledGrid>

            <PopularCoach/> 




            {/* <StyledGrid item xs={12} >
              <Typography variant="h5">เลือกตามกลุ่มงาน</Typography>
            </StyledGrid>
              <JobGroup/> */}
        </Container>
      )}
    </StyledRoot>
  );
};

export default Home;
