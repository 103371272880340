import React from "react";
import { useCoinType } from "../../../../quries/coin";
import { CircularProgress } from "@mui/material";

const HeartCoin = ({ width }) => {
  const { data: coinType, isPending } = useCoinType();
  if (isPending) {
    return <CircularProgress color="secondary" />
  }

  const data = coinType.find((item) => item.coinType === "heart");
  return <img src={data.imageURL} alt="heart" width={width} height={width} />;
};

export default HeartCoin;
