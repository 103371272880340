import {
  REWARDS_FETCHING,
  REWARDS_FAILED,
  REWARDS_SUCCESS,
  REWARD_ITEM_FETCHING,
  REWARD_ITEM_FAILED,
  REWARD_ITEM_SUCCESS,
  COMPANY_REWARD_FETCHING,
  COMPANY_REWARD_FAILED,
  COMPANY_REWARD_SUCCESS,

} from "./types";
import companyRewardService from "../services/companyReward.service";

export const getCompanyRewards = () => async (dispatch) => {
  try {
    dispatch({
      type: REWARDS_FETCHING,
    });
    const res = await companyRewardService.getCompanyRewards();
    if (res) {
      dispatch({
        type: REWARDS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARDS_FAILED,
    });
  }
};

export const getCompanyReward = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REWARD_ITEM_FETCHING,
    });
    const res = await companyRewardService.getCompanyReward(id);
    if (res) {
      dispatch({
        type: REWARD_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REWARD_ITEM_FAILED,
    });
  }
};

// export const getCompanyRewards = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: COMPANY_REWARD_FETCHING,
//     });
//     const res = await companyRewardService.getCompanyRewards(id);
//     if (res) {
//       dispatch({
//         type: COMPANY_REWARD_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: COMPANY_REWARD_FAILED,
//     });
//     console.log(error);
//   }
// };

export const addCompanyReward = (formData) => async (dispatch) => {
  try {
    const res = await companyRewardService.addCompanyReward(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: COMPANY_REWARD_FAILED,
    });
    console.log(error);
  }
};

export const updateCompanyReward = (id, formData) => async (dispatch) => {
  try {
    const res = await companyRewardService.updateCompanyReward(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: COMPANY_REWARD_FAILED,
    });
    console.log(error);
  }
};
