import { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import loading from "../../../assets/loading2.gif";
import CardCoach from "../component/card_coach";
import {
  Grid,
  Typography,
  Card,
  Pagination,
  Box
} from "@mui/material";




const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });


const PopularCoach = ()=>{
    const dispatch = useDispatch();
    const { result: coachPopular ,isFetching:isPopularCoachLoading} = useSelector((state) => state.coachPopular);
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(6);



    useEffect(()=>{
    },[])



    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = coachPopular?.slice(indexOfFirstCard, indexOfLastCard);
    const handleChange = (event, value) => {
        setCurrentPage(value);
      };
    
    return(
        <StyledCard>
        {isPopularCoachLoading ? (
        <Box
            style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
            }}
        >
            <img width="120" alt="loading" src={loading} />
        </Box>
        ) :  coachPopular&& coachPopular.length === 0 ? (
          <Box
          style={{
            minHeight: 200,
            position: "sticky",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>ไม่มี Popular Coach</Typography>
        </Box>
        ) : (
        
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"  }}>
            <Grid container spacing={4} sx={{marginBottom:"10px"}}>
            {coachPopular&&currentCards.map((val) => (
            <Grid item xs={12} sm={6} md={4} xl={4} key={val.idEmployees}>
                <CardCoach value={val} tagType={"popular-coach"} navigateTo = {"profile"} />
            </Grid>
            ))}
            </Grid>
            {
            coachPopular && coachPopular.length != 0 &&(
            <Pagination  count={Math.ceil(coachPopular.length/cardsPerPage)} color="primary"  onChange={handleChange} page={currentPage} />

            )
            }
        </div>
        )}
        </StyledCard>
    )



}

export default PopularCoach;

//               
