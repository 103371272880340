import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const WrapPercent = styled("div")({
  minWidth: 35,
});

const Title = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'myColor',
})(({ theme, myColor }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: myColor,
  },
}));

const ChampionIcon = styled(EmojiEventsIcon)(({ theme }) => ({
  color: "#ffb74d",
}));

const backgroundColor = [
  "rgba(255, 99, 132, 0.7)",
  "rgba(54, 162, 235, 0.7)",
  "rgba(255, 206, 86, 0.7)",
  "rgba(75, 192, 192, 0.7)",
  "rgba(153, 102, 255, 0.7)",
  "rgba(255, 159, 64, 0.7)",
];

const selectedColor = [
  
];

const ProgressChart = ({ chartData, sumScore, selectedQuestion }) => {
  const max = Math.max(...chartData.map((item) => item.score));
  return (
    <div>
      <Title>
        <Typography variant="h5">Score</Typography>
      </Title>
      <Box sx={{ flexGrow: 1 }}>
        {chartData.map((item, index) => {
          return (
            <Box display="flex" alignItems="center" p={2} gap={3} 
              key={index}
              sx={
                item.idPollQuestion === selectedQuestion
                  ? { borderRadius: "15px", backgroundColor: "#e9f1ff" }
                  : {}
              }
            >
              <Box width="100%">
                <Typography>{item.question}</Typography>
                <BorderLinearProgress
                  variant="determinate"
                  value={sumScore !== 0 ? (item.score / sumScore) * 100 : 0}
                  myColor={backgroundColor[index]}
                />
              </Box>
              <WrapPercent>
                {item.score === max && item.score !== 0 && <ChampionIcon />}
                {item.sumScore !== 0 ? (
                  <Typography variant="body2" color="text.secondary">
                    {`${Math.round((item.score / sumScore) * 100)}%`}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {`0%`}
                  </Typography>
                )}
              </WrapPercent>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default ProgressChart;
