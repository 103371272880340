import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Card
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Carousel from 'react-material-ui-carousel'
import { useLocation } from 'react-router-dom';


import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCoachReviewByIdCoach } from "../../../../actions/coachReview";
import { getCoachById } from "../../../../actions/coach";
import DetailProfileCoach from "../coach/component/detailProfileCoach";
import CardExperience from "../coach/component/card_experience";
import CardReview from "../coach/component/card_review";
import Booking from "../coachee/Booking";
import {  getBookingByIdCoach } from "../../../../actions/book";
import loading from "../../assets/loading2.gif"

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#f1f4f9",

});
const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
        padding: 24,
    },
});
const ViewProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { idCoach } = location.state || {};
  const { result: bookCoach } = useSelector((state) => state.bookCoach);
  const { result: coachReview } = useSelector((state) => state.coachReview);
  const { result: coachProfile ,isFetching:isProfileLoading} = useSelector((state) => state.coachItem);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(getCoachReviewByIdCoach(idCoach));
    dispatch(getCoachById(idCoach));
    dispatch(getBookingByIdCoach(idCoach));

  }, []);


  useEffect(() => {
    if (location.hash === '#appointment') {
        setTimeout(() => {
            const appointmentSection = document.getElementById('appointment');
            if (appointmentSection) {
                appointmentSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 200); 
    }
}, [location]);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
      <div style={{height:"10px"}}></div>
            {isProfileLoading ? (
              <StyledCard>
                 <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "40px",
                    }}
                  >
                    <img width="120" alt="loading" src={loading} />
                  </Box>
              </StyledCard>
          
      ) : (
          <>
              {coachProfile && (
                  <DetailProfileCoach coachProfile={coachProfile} />
              )}
              <div style={{ height: "10px" }}></div>
              {coachProfile && (
                  <CardExperience
                      experience={coachProfile.coachExperience}
                      isLoading={isProfileLoading}
                  />
              )}
          </>
      )}

        
        <div  style={{marginTop:"50px",marginBottom:"20px"}}>
        <Typography variant="h5" gutterBottom>นัดหมายโค้ช</Typography>
        <Divider />
        </div>
        {
          bookCoach && coachProfile&& userProfile && ( 
            <Booking  bookCoach = {bookCoach} coachProfile = {coachProfile}/>
          )
        }
        <div  style={{marginTop:"50px",marginBottom:"20px"}}>
        <Typography variant="h5" gutterBottom>
             ความคิดเห็น
            </Typography>
            <Divider />
        </div>
        {
          coachReview && coachReview.length != 0 ? (
            <Carousel
              autoPlay={false}
              indicators={true}
              navButtonsAlwaysVisible={false}
              animation="slide"
            >
              <Grid container spacing={2}>
              {coachReview.map((item, index) => (
                <Grid item xs={3}>
                  <CardReview key={index} review={item} />
                </Grid>
              ))}
              </Grid>
            </Carousel>
          ):(
            <Box sx={{textAlign:"center",height:"100px",alignItems:"center",display:"flex",justifyContent:"center"}}>
              <Typography>
                ไม่มีความคิดเห็น
              </Typography>
            </Box>
          )
        }
      </Container>
    </StyledRoot>
  );
};

export default ViewProfile;
