import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./component/DialogConfirmReject";
import CardRequest from "./component/CardRequest";
import { MainCoin } from "../../shared/typeCoin";
import { updateBooking,getBookingRequestApprove} from "../../../../actions/book";
import BookingList from "../../user/coachee/BookingList";




const StyledButtonCancel = styled(ButtonBlue)({
    backgroundColor: "#E793B8",
    borderRadius: "8px",
    color: "#FFFFFF",
    marginRight: 8,
    width: 130,
    "&:hover": {
      backgroundColor: "#DC6384",
    },
  });

const RequestCoaching = (props)=>{
    const mobileResponsive = useMediaQuery("(max-width:600px)");
    const [selectedRows, setSelectedRows] = useState([]);
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
    const { isFetching:isFetchingBookingCoach,result: bookingCoach } = useSelector((state) => state.bookCoach);
    const dispatch = useDispatch();
    const { setSnackBarConfig, setDisplaySnackbar, setModeSnackbar} = props;

    const handleCancel =()=>{
      setSelectedRows([]);
    }
    const handleClickAll =()=>{
      setSelectedRows(bookingCoach);

    }


    const handleApprovalRequest = async(idBookingStatus,comment)=>{
        let formData =  {
            bookingList:selectedRows,
            idBookingStatus:idBookingStatus,
            comment:comment       
        }
        setIsOpenRejectDialog(false);
        const result = await dispatch(updateBooking(formData));
        if(result){
            setSelectedRows([]);
            dispatch(getBookingRequestApprove())
            if(result.status === 200){
                setSnackBarConfig(true);
                setDisplaySnackbar("Successfully")
                setModeSnackbar("success")
                
            }else{
                setSnackBarConfig(true);
                setModeSnackbar("error")
                setDisplaySnackbar("Unsuccessful")
            }

        }else{
            setSnackBarConfig(true);
            setModeSnackbar("error")
            setDisplaySnackbar("Unsuccessful")
        }

    }
    const columns = [
        {
          name: "ชื่อ-สกุล"	,
          minWidth: "230px",
          width: "230px",
          cellRender: (row) => (
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  marginRight: "8px",
                  width: 40,
                  height: 40,
                  "& img": { objectFit: "contain" },
                }}
                src={row.profileImageURL}
              />
              <Box flexGrow={1}>
                <Typography>{row.firstname_TH} {row.lastname_TH}</Typography>
              </Box>
            </Box>
          ),
        },
        {
          name: "วันที่จอง",
          headerTextAlign: "center",
          minWidth: "200px",
          width: "200px",
          cellRender: (row) => (
            <Box sx={{ textAlign: "center" }}>
              <Typography>
              {dayjs(row.bookDate).format("DD/MM/YYYY HH:mm")}
              </Typography>
              
            </Box>
          ),
        },
        {
          name: "วันที่เริ่มเรียน",
          headerTextAlign: "center",
          minWidth: "200px",
          width: "200px",
          cellRender: (row) => (
            <Box sx={{ textAlign: "center" }}>
              <Typography>
              {dayjs(row.startTime).format("DD/MM/YYYY HH:mm")}
              </Typography>
              
            </Box>
          ),
        },
        {
          name: "วันที่สิ้นสุด",
          headerTextAlign: "center",
          minWidth: "200px",
          width: "200px",
          cellRender: (row) => (
            <Box sx={{ textAlign: "center" }}>
              <Typography>
              {dayjs(row.endTime).format("DD/MM/YYYY HH:mm")}
              </Typography>              
            </Box>
          ),
        },
        {
          name: "ประเภท",
          headerTextAlign: "center",
          minWidth: "150px",
          cellRender: (row) => (
            <Box sx={{textAlign:"center"}}>
              <Typography fontSize="14px">
                {row.type}
              </Typography>
            </Box>
          ),
        },
        {
          name: "สถานที่",
          headerTextAlign: "center",
          minWidth: "150px",
          cellRender: (row) => (
            <Box sx={{textAlign:"center"}}>
              <Typography  fontSize="14px">
                {row.place}
              </Typography>
            </Box>
          ),
        },
        {
          name: "หมายเหตุ",
          minWidth: "150px",
          cellRender: (row) => (
            <Box>
              <Typography color="text.third" fontSize="14px">
                {row.note}
              </Typography>
            </Box>
          ),
        },
        {
            name: "ราคา(ทั้งหมด)",
            headerTextAlign: "center",
            minWidth: "150px",
            cellRender: (row) => (
              <Box sx={{textAlign:"center", display:"flex",alignItems:"center",justifyContent:"center"}}>
                <MainCoin width={32} />
                <Typography  fontSize="14px" marginLeft={"8px"}>
                  x {row.totalPrice}
                </Typography>
              </Box>
            ),
          },
      ];
      return(
        <Box>
            {mobileResponsive && (
            <box display="flex"  justifyContent="flex-end" paddingBottom="24px">
            {Array.isArray(selectedRows) && selectedRows.length > 0 && (
                <StyledButtonCancel onClick={handleCancel} startIcon={<DeleteIcon />}>
                ยกเลิก
                </StyledButtonCancel>
            )}
            {!(
                Array.isArray(selectedRows) &&
                Array.isArray(bookingCoach) &&
                selectedRows.length === bookingCoach.length
            ) && (
                <ButtonBlue variant="contained" startIcon={<DoneAll />} onClick={handleClickAll}>
                เลือกทั้งหมด
                </ButtonBlue>
            )}
                </box>
            )}
            {selectedRows.length > 0 && (
                 <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
                 <ButtonBlue
                   style={{ marginRight: 8 }}
                   startIcon={<DoneAll />}
                   variant="contained"
                   onClick={() => handleApprovalRequest(2)}
                 >
                   อนุมัติ {
                     selectedRows.length > 0
                       ? `${selectedRows.length} รายการ`
                       : ""
                   }
                 </ButtonBlue>
                 <ButtonBlue
                   startIcon={<DoneAll />}
                   variant="outlined"
                   onClick={() => setIsOpenRejectDialog(true)}
                 >
                   ไม่อนุมัติ {
                     selectedRows.length > 0
                       ? `${selectedRows.length} รายการ`
                       : ""
                   }
                 </ButtonBlue>
               </Box>
            )}
            {!mobileResponsive ? (
                <TableCustom
                columns={columns}
                rows={bookingCoach  ?? []}
                canSelect
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ):(
                <div>
                  {bookingCoach ? (
                    bookingCoach.map((row) => (
                      <CardRequest
                        key={row.idRequestTime}
                        row={row}
                        mode={"bookingCoach"}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                      />            ))
                  ) : (
                    <div>
                      <Typography align="center">ไม่มีข้อมูล</Typography>
                    </div>
                  )}
                </div>
              )}
               <DialogConfirmReject
                    rejectCount={selectedRows.length}
                    open={isOpenRejectDialog}
                    onClose={() => {
                    setIsOpenRejectDialog(false);
                    }}
                    handleSubmit={(comment) => {
                    handleApprovalRequest(3,comment);
                    }}
                />
        </Box>
      )
}

export default RequestCoaching;