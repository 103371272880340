import { httpClient } from "./httpClient";

const addBooking = (formData) => {
  return httpClient.post("booking", formData);
};

const updateBooking = (formData) => {
  return httpClient.put("booking", formData);
};

const getBookingByIdCoachee = () => {
  return httpClient.get("booking-coachee");
};

const getBookingByIdCoach = (idCoach) => {
  return httpClient.get("booking-coach");
};


const getBookingRequestApprove =()=> {
  return httpClient.get("booking/approve")
}
const getBookingCoach =()=> {
  return httpClient.get("booking/coach")
}
// const getAllBookingByIdCoach = (idCoach) => {
//   return httpClient.get("all-booking-coach/" + idCoach);
// };

// const getApproveBookingByIdCoach = (idCoach) => {
//   return httpClient.get("approve-booking-coach/" + idCoach);
// };

export default {
  addBooking,
  updateBooking,
  getBookingByIdCoachee,
  getBookingByIdCoach,
  getBookingRequestApprove,
  getBookingCoach
  // getAllBookingByIdCoach,
  // getApproveBookingByIdCoach,
};
