import {
  COMMUNICATION_FETCHING,
  COMMUNICATION_FAILED,
  COMMUNICATION_SUCCESS,
} from "./types";
import communicationService from "../services/communication.service";

export const getAllCommunication = () => async (dispatch) => {
  try {
    dispatch({
      type: COMMUNICATION_FETCHING,
    });
    const res = await communicationService.getAllCommunication();
    if (res) {
      dispatch({
        type: COMMUNICATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COMMUNICATION_FAILED,
    });
  }
};

export const addCommunication = (formData) => async (dispatch) => {
  try {
    const res = await communicationService.addCommunication(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMMUNICATION_FAILED,
    });
    console.log(err);
  }
};

export const updateCommunication = (formData) => async (dispatch) => {
  try {
    const res = await communicationService.updateCommunication(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMMUNICATION_FAILED,
    });
  }
};

export const updateRewardCommunication = (formData) => async (dispatch) => {
  try {
    const res = await communicationService.updateRewardCommunication(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMMUNICATION_FAILED,
    });
    console.log(err);
  }
};

export const addCommunicationReply = (formData) => async (dispatch) => {
  try {
    const res = await communicationService.addCommunicationReply(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMMUNICATION_FAILED,
    });
    console.log(err);
  }
};

export const addLikeCommunication = (idCommunication) => async (dispatch) => {
  try {
    const res = await communicationService.addLikeCommunication(idCommunication);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({ type: COMMUNICATION_FAILED });
  }
};

export const deleteLikeCommunication = (idCommunication) => async (dispatch) => {
    try {
      const res = await communicationService.deleteLikeCommunication(idCommunication);
      if (res) {
        return res;
      }
    } catch (error) {
      dispatch({ type: COMMUNICATION_FAILED });
    }
  };
