import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useUniRewards = () => {
  return useQuery({
    queryKey: ["uni-reward"],
    queryFn: async () => (await httpClient.get("uni-reward")).data,
  });
};

export const useUniReward = ({ idUniReward }) => {
  return useQuery({
    queryKey: ["uni-reward", idUniReward],
    queryFn: async () => (await httpClient.get(`uni-reward/${idUniReward}`)).data,
    refetchOnWindowFocus: false,
  });
};

export const useAddUniReward = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.post("uni-reward", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uni-reward"] })
    },
  });
};

export const useUpdateUniReward = ({ idUniReward }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.put(`uni-reward/${idUniReward}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uni-reward"] })
    },
  });
};

export const useUpdateUniRewardStatus = ({ idUniReward }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.put("uni-reward", formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uni-reward", idUniReward] })
    },
  });
};

export const useUpdateUniRewardItem = ({ idUniRewardItem, idUniReward }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (formData) => httpClient.put(`uni-reward-item/${idUniRewardItem}`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["uni-reward", idUniReward] })
    },
  });
};

export const useUniRewardItemCost = ({ idUniRewardItem }) => {
  return useQuery({
    queryKey: ["uni-reward-item", idUniRewardItem, "cost"],
    queryFn: async () => (await httpClient.get(`uni-reward-item/${idUniRewardItem}/cost`)).data,
    refetchOnWindowFocus: false,
    enabled: !!idUniRewardItem,
  });
};

export const useStoreUniRewardItemCost = ({ idUniRewardItem }) => {
  return useQuery({
    queryKey: ["reward", "uni-reward-item", idUniRewardItem, "cost"],
    queryFn: async () => (await httpClient.get(`/reward/uni-reward-item/${idUniRewardItem}/cost`)).data,
    enabled: Boolean(idUniRewardItem),
  });
};

export const useAddUniRewardItemCost = ({ idUniRewardItem }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) =>
      await httpClient.post(`uni-reward-item/cost`, formData),
    onSuccess: () => {
     queryClient.invalidateQueries({ queryKey: ["uni-reward-item", idUniRewardItem, "cost"] });
    },
  });
};

export const useUniRewardSellHistory = () => {
  return useQuery({
    queryKey: ["uni-reward", "sell-history"],
    queryFn: async () => (await httpClient.get("uni-reward-sell/history")).data,
    refetchOnWindowFocus: false,
  });
};

export const useUniRewardsActive = () => {
  return useQuery({
    queryKey: ["uni-reward-active"],
    queryFn: async () => (await httpClient.get("uni-reward-active")).data,
  });
};

export const useUniRewardActive = ({ idUniReward }) => {
  return useQuery({
    queryKey: ["uni-reward-active", idUniReward],
    queryFn: async () => (await httpClient.get(`uni-reward-active/${idUniReward}`)).data,
  });
};

