import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Typography,
  Paper,
  Button,
  IconButton,
  Container,
  Chip,
  Avatar,
  Grid,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router";
// import DialogDetailAnnouncement from './dialogDetailAnnouncement';

import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/communication-svgrepo-com.svg";
import { MainCoin } from "../../shared/typeCoin";

//icon
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useDispatch, useSelector } from "react-redux";
import {
  addLikeCommunication,
  deleteLikeCommunication,
  getAllCommunication,
} from "../../../../actions/communication";
import dayjs from "dayjs";
import DialogDetailManageComment from "../../admin/communication/dialogReply";
import LoadingIcon from "../../shared/general/loadingIcon";
import { toast } from "sonner";
import { DatePicker } from "@mui/x-date-pickers";

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
  minWidth: 400,
  backgroundColor: "#f5f5f5",
  minHeight: "100dvh",
})

const StyledDivider = styled(Divider)({
  margin: "10px 0",
})

const WrapHeader = styled('div')({
  display: "flex",
  alignItems: "center",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
})

const Topic = ({
  idCommunication,
  detail,
  firstname_TH,
  lastname_TH,
  reward,
  replyList,
  LikeList,
  isSuccess,
  file,
  createDate,
  imageURL,
}) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogComment, setOpenDialogComment] = useState(false);

  const handleCloseDialogComment = () => {
    setOpenDialogComment(false)
  }

  const handleOpenDialogComment = () => {
    setOpenDialogComment(true)
  }
  
  const handleAddLikeCommunication = async () => {
    const res = await dispatch(addLikeCommunication(idCommunication))
    if (res && res.status === 201) {
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
    dispatch(getAllCommunication())
  };

  const handleDeleteLikeCommunication = async () => {
    const res = await dispatch(deleteLikeCommunication(idCommunication))
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
    dispatch(getAllCommunication())
  };

  return (
    <>
      {openDialogComment && (
        <DialogDetailManageComment
          idCommunication={idCommunication}
          open={openDialogComment}
          handleClose={handleCloseDialogComment}
          replyList={replyList}
        />
      )}
      <Paper elevation={2} style={{ height: "100%", padding: "16px", borderRadius: "8px" }}>
        <div 
          style={{ 
            height: "100%",
            display: "flex",
            flexDirection: "column", 
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Typography fontSize={18}>{detail}</Typography>
          <div>
            <Divider style={{ marginBottom: "8px" }} />
            <div 
              style={{ 
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px" 
              }}
            >
              <Chip
                variant="outlined"
                label={
                  isSuccess === 1
                    ? "complete"
                    : isSuccess === 0
                    ? "cancel"
                    : "pending"
                }
                color={
                  isSuccess === 1
                    ? "success"
                    : isSuccess === 0
                    ? "error"
                    : "warning"
                }
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                {reward && (
                  <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <MainCoin width={30} />
                    <Typography variant="h6">{reward}</Typography>
                  </div>
                )}
                {LikeList.some((item) => item.idEmployees === userProfile.idEmployees) ? (
                  <IconButton onClick={handleDeleteLikeCommunication}>
                    <ThumbUpIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleAddLikeCommunication}>
                    <ThumbUpIcon />
                  </IconButton>
                )}
                <Typography variant="h6">{LikeList.length}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Avatar alt="employee" src={imageURL} />
                <div>
                  <Typography>
                    {`${firstname_TH} ${lastname_TH}`}
                  </Typography>
                  <Typography fontSize={14} color={"text.secondary"}>
                    {dayjs(createDate).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {file && (
                  <IconButton LinkComponent={Link} target="_blank" href={file}>
                    <AttachFileIcon />
                  </IconButton>
                )}
                <Chip
                  clickable
                  color="primary"
                  label={"การตอบกลับ"}
                  onClick={handleOpenDialogComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
      
    </>
  );
};

export default function Board() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { result: communication, isFetching } = useSelector((state) => state.communication)
  const [filterDate, setFilterDate] = useState(dayjs());

  const handleClickCreateBoard = () => {
    navigate("/communication-create");
  };

  useEffect(() => {
    dispatch(getAllCommunication())
  },[dispatch])

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"บอร์ดพูดคุย"} icon={iconHeader} />
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickCreateBoard}
            >
              สร้างข้อเสนอแนะ
            </Button>
          </div>
        </WrapHeader>
        <StyledDivider />
        <Container>
          <div style={{ marginBottom: "16px" }}>
            <DatePicker
              value={filterDate}
              onChange={(newValue) => setFilterDate(newValue)}
              label={'เลือกเดือน'}
              views={['month']}
              minDate={dayjs().startOf('year')}
              maxDate={dayjs()}
            />
          </div>
          {isFetching ? (
            <LoadingIcon />
          ) : (
            <Grid container spacing={2}>
              {communication &&
                communication
                  .filter((item) =>
                    dayjs(item.createDate).isSame(filterDate, "month")
                  )
                  .map((item) => (
                    <Grid item xs={12} md={6}>
                      <Topic {...item} />
                    </Grid>
                  ))}
            </Grid>
          )}
      </Container>
    </StyledRoot>
  );
}
