import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  InputBase,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  Card,
  Avatar,
  Typography,
  Button,
  inputBaseClasses,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Search } from "@mui/icons-material";

import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { getCoachHistory } from "../../../../actions/coachHistory";

const StyledAvatar = styled(Avatar)({
  height: 70,
  width: 70,
  margin: 10,
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const monthMenu = [
  { name: "ทั้งหมด", value: 0 },
  { name: "มกราคม", value: 1 },
  { name: "กุมภาพันธ์", value: 2 },
  { name: "มีนาคม", value: 3 },
  { name: "เมษายน", value: 4 },
  { name: "พฤษภาคม", value: 5 },
  { name: "มิถุนายน", value: 6 },
  { name: "กรกฎาคม", value: 7 },
  { name: "สิงหาคม", value: 8 },
  { name: "กันยายน", value: 9 },
  { name: "ตุลาคม", value: 10 },
  { name: "พฤศจิกายน", value: 11 },
  { name: "ธันวาคม", value: 12 },
];

const History = () => {
  const dispatch = useDispatch();
  const { result: coachHistory } = useSelector((state) => state.coachHistory);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");
  const [month, setMonth] = useState(0);

  const onChangeSearch = (e) => {
    setSearch(e.target.value.toLocaleLowerCase());
  };

  const onChangeMonth = (e) => {
    setMonth(e.target.value);
  };

  const filteredData = useMemo(() => {
    if (coachHistory) {
      return coachHistory
        .filter((item) =>
          month === 0 ? item : dayjs(item.startTime).month() + 1 === month
        )
        .filter(
          (item) =>
            item.firstname_TH.toLocaleLowerCase().includes(search) ||
            item.lastname_TH.toLocaleLowerCase().includes(search)
        );
    }
  }, [month, search, coachHistory]);

  useEffect(() => {
    dispatch(getCoachHistory(currentUser.id));
  }, [dispatch, currentUser.id]);

  return (
    <div className={`page`}>
      {coachHistory && (
        <Container maxWidth="lg">
          <Grid container justifyContent="flex-end">
            <Grid item xs={12}>
              <Typography variant="h4">ประวัติการสอน</Typography>
              <TextField
                label="Search name"
                variant="outlined"
                size="small"
                style={{ margin: 10, float: "right" }}
                onChange={onChangeSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ fontSize: "16px" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl
                style={{
                  margin: 10,
                  float: "right",
                }}
              >
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={month}
                  onChange={onChangeMonth}
                  variant="outlined"
                  input={<BootstrapInput />}
                >
                  {monthMenu.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {filteredData.length === 0 ? (
            <Typography variant="h5" align="center">No data</Typography>
          ) : (
            filteredData.map((val) => (
              <Card
                key={val.idBooking}
                elevation={3}
                style={{ marginTop: 16, padding: 8 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={2}>
                    <Stack direction="row" justifyContent={"center"}>
                      <StyledAvatar
                        // src={`${process.env.REACT_APP_API_URL}image/profile/${item.image}`}
                        src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Stack alignItems={"center"}>
                      <Typography variant="h6">
                        {`${val.firstname_TH} ${val.lastname_TH}`}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {val.positionName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Stack alignItems={"center"}>
                      <Typography variant="subtitle1">วันที่เรียน</Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 400 }}
                      >
                        {dayjs(val.startTime).format("DD/MM/YYYY")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} marginTop={1}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        component={Link}
                        to={`/coaching/save-coaching/${val.idBooking}`}
                        variant="contained"
                        color="primary"
                      >
                        ดูประวัติการสอน
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            ))
          )}
        </Container>
      )}
    </div>
  );
};

export default History;
