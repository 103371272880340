import React from 'react';
import { Grid, CardActionArea, Typography, Avatar, Chip } from '@mui/material';
import dayjs from 'dayjs';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { styled } from "@mui/material/styles";

const MyMissionCard = ({ value, setCurrentMission, colorStatus }) => {

    const StyledCard = styled(Card)({
        width: "100%",
        height: "100%",
        borderRadius: 8,
        position: "relative",
      });
      
       const StyledTitle = styled(Typography)({
        fontSize: "24px",
      });
      
       const StyledCardContent = styled(CardContent)({
        color: "#FFFFFF",
        display: "flex",
        width: "100%",
        height: "100%",
        borderRadius: 8,
        flexDirection: "column",
      });
      const WrapStatus = styled("div")({
        display: "flex",
        alignItems: "center",
        "& div": {
          margin: 8,
        },
      });


  return (
    <Grid item xs={12} sm={6} md={6} xl={6}>
      <StyledCard>
        <CardActionArea onClick={() => setCurrentMission(value)}>
          <StyledCardContent>
            <StyledTitle gutterBottom>
              <FormatQuoteIcon
                fontSize={"large"}
                style={{ transform: "scale(-1, 1)" }}
              />
              {value.missionTitle}
              <FormatQuoteIcon fontSize={"large"} />
              <Typography fontSize={"16px"} gutterBottom>
                {value.missionDetail}
              </Typography>
            </StyledTitle>
            <WrapStatus>
              <Typography
                mt={2}
                fontSize={"16px"}
                color="textSecondary"
                gutterBottom
              >
                {"สถานะ:"}
              </Typography>
              <Chip
                label={value.missionStatus}
                color={colorStatus[value.idMissionStatus]}
              />
            </WrapStatus>

            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography fontSize={"16px"} color="textSecondary">
                {"วันสิ้นสุด:"}
              </Typography>
              <Typography fontSize={"16px"} gutterBottom>
                {dayjs(value.missionDate).format("D MMM YYYY ")}
              </Typography>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Typography fontSize={"16px"} color="textSecondary">
                {"มอบหมายโดย:"}
              </Typography>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Avatar alt="employee" src={value.assigner_imageURL} />
                <Typography gutterBottom>
                  {`${value.assigner_firstname_TH} ${value.assigner_lastname_TH}`}
                </Typography>
              </div>
            </div>
          </StyledCardContent>
        </CardActionArea>
      </StyledCard>
    </Grid>
  );
};

export default MyMissionCard;
