import React, {  useState } from "react";
import {
  Button,
  styled,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useCoinType } from "../../../../quries/coin";

const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
  "& .wrapListUsers": {
    marginTop: 16,
  },
});

const CoinFilter = ({ setFilter }) => {
  const { data, isPending } = useCoinType();

  const initialFilter = data?.map((item) => ({
    idCoinType: item.idCoinType,
    coinName: item.coinName,
    isCheck: false,
  }))

  const [coinSelected, setCoinSelected] = useState(initialFilter);

  const handleChange = (event) => {
    let temp = [...coinSelected]
    const index = temp.findIndex((element) => element.idCoinType === Number(event.target.name))
    temp[index] = {
      idCoinType: temp[index].idCoinType,
      coinName: temp[index].coinName,
      isCheck: event.target.checked,
    }
    setCoinSelected([...temp]);
  };

  const handleSearch = () => {
    const temp = coinSelected.filter(item => item.isCheck).map(coin => coin.idCoinType)
    setFilter(temp);
  };

  const handleReset = () => {
    setCoinSelected(initialFilter);
  };

  if (isPending) {
    return <CircularProgress />
  }

  return (
    <div>
      <WrapDrawerFilter>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">ประเภทเหรียญ</FormLabel>
          <FormGroup>
            {coinSelected?.map(item => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={item.idCoinType}
                    checked={item.isCheck}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={item.coinName}
              />
            ))}
          </FormGroup>
        </FormControl>
        <div className={`wrapBtnReset`}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            search
          </Button>
          <Button variant="outlined" onClick={handleReset}>
            reset
          </Button>
        </div>
      </WrapDrawerFilter>
    </div>
  );
};

export default CoinFilter;
