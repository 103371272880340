import React, { useEffect ,useState} from "react";
import { styled } from "@mui/material/styles";
import { Typography, Avatar, Chip, Container, Grid ,Box,Card, Stack,Snackbar,Alert} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import loading from "../../assets/loading2.gif";
import { getCoach } from "../../../../actions/coach";
import { getAllCoacheeRecordByIdCoachee } from "../../../../actions/coachRecord";
import {getProfileCoacheeByIdCoachee} from "../../../../actions/coach"
import dayjs from "dayjs";
import { MainCoin } from "../../shared/typeCoin";
import CardAppointmentCoach from "../coachee/component/CardAppointmentCoach";



const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    margin: "0px auto",
    minWidth: 350,
    minHeight: "100vh",
    color: "#ffffff",
    backgroundColor: "#f1f4f9",

});

const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });

//coachee-list/detail
const DetailCoachee = ()=>{
    const location = useLocation();
    const dispatch = useDispatch();
    const { idCoachee } = location.state || {};
    const {result: recordes,isFetching: isRecordLoadingState} = useSelector((state)=>  state.coachRecord) 
    const {result: coacheeProfile,isFetching: isCoacheeLoading} = useSelector((state)=>  state.coacheeProfile) 
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modeSnackbar, setModeSnackbar] = useState("info");
    const [displaySnackbar, setDisplaySnackbar] = useState("");

    useEffect(()=>{
        console.log(idCoachee);
        dispatch(getAllCoacheeRecordByIdCoachee(idCoachee));
        dispatch(getProfileCoacheeByIdCoachee(idCoachee));
    },[])
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpenSnackbar(false);
      };
    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
              {
                isCoacheeLoading ? (
                  <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box> 
                ):(
                  coacheeProfile && (
                    <StyledCard sx={{marginBottom:"20px"}}>
                                <Grid container sx={{alignItems:"center"}}>
                                <Grid item xs={12} sm={12} md={3} sx={{padding:"24px"}}>
                                    <div style={{display:"flex",justifyContent:"center",padding:"16px"}}>
                                        <div style={{
                                            display:"inline-flex", 
                                            justifyContent:"center",
                                            alignItems:"center",
                                            padding:"8px",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                            borderRadius:"50%"
                                            }}>
                                        <Avatar
                                            src={coacheeProfile.profileImageURL}
                                            style={{ width: 220, height: 220 }} 
                                            />
                                        </div>
                                    </div>
                                    </Grid>
                                   <Grid item sm={12} md={12-3} sx={{padding:"24px 24px"}}>
                                   <Typography variant="h4">
                                        {coacheeProfile.firstname_TH} {coacheeProfile.lastname_TH}
                                    </Typography>
                                    <Typography className="field-label" color="text.secondary" marginBottom={"20px"}>
                                        {coacheeProfile.positionName}
                                    </Typography>
                                    <Typography className="field-label" color="text" marginBottom={"5px"}>
                                        อีเมล
                                    </Typography>
                                    <Typography className="field-label" color="text.secondary" marginBottom={"20px"}>
                                        {coacheeProfile.email}
                                    </Typography>
                                    <Typography className="field-label" color="text" marginBottom={"5px"}>
                                        เบอร์โทรศัพท์
                                    </Typography>
                                    <Typography className="field-label" color="text.secondary" marginBottom={"20px"}>
                                        {coacheeProfile.telephoneMobile}
                                    </Typography>
                                    <Typography className="field-label" color="text" marginBottom={"5px"}>
                                        จำนวนครั้งที่เข้าโค้ช
                                    </Typography>
                                    <Typography className="field-label" color="text.secondary" marginBottom={"20px"}>
                                        {coacheeProfile.total_booking}
                                    </Typography>
                                   </Grid>
                                </Grid>
                            </StyledCard>
                  )
                )
              }

            {
                    isRecordLoadingState?(
                        <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "40px",
                        }}
                      >
                        <img width="120" alt="loading" src={loading} />
                      </Box>
                    ):(
                        <Grid container spacing={2} > 
                           { recordes && recordes.map((record) =>(
                            <Grid item  xs={12} sm={6} md={6} xl={4} >
                               <CardAppointmentCoach record={record} key={record.bookingId} setSnackBarConfig={setOpenSnackbar} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar} tag="coach"/>
                            </Grid>
                            ))}
                        </Grid>
                    )
                }

            </Container>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                sx={{ width: "100%" }}
                severity={modeSnackbar}
              >
          {displaySnackbar}
        </Alert>
      </Snackbar>
        </StyledRoot>
    )

}

export default DetailCoachee;