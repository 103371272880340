import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)({
  borderRadius: 8,
  height: "100%",
});

const StyledCardMedia = styled(CardMedia)({
  height: 160,
  objectFit: "contain",
});

function CardReward({ data }) {
  return (
    <StyledCard elevation={3}>
      <CardActionArea
        style={{ height: "100%" }}
        component={Link}
        to={`detail/${data.idUniReward}`}
      >
        <StyledCardMedia component="img" image={data.imageURL} alt={data.name} />
        <CardContent>
          <Typography variant="h6" fontSize={16} noWrap>
            {data.name}
          </Typography>
          <Typography variant="h6" fontSize={16} noWrap>
            {`จำนวนคงเหลือ: ${data.balanceQuantity}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
}

export default CardReward;