import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CampaignIcon from '@mui/icons-material/Campaign';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PollIcon from '@mui/icons-material/Poll';
import SmsIcon from '@mui/icons-material/Sms';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const UserMenu = (props) => {

  const dataListMenu = [
    {
      listItemIcon: <HomeOutlinedIcon />,
      listItemText: "หน้าหลัก",
      listLink: "/home",
      listKey:"home",
    },
    {
      listItemIcon: <AccountBalanceWalletOutlinedIcon />,
      listItemText: "กระเป๋าเหรียญ",
      listLink: "/wallet",
      listKey:"wallet",
    },
    {
      listItemIcon: <SwapHorizIcon />,
      listItemText: "รายการเหรียญ",
      listLink: "/transaction",
      listKey:"transaction",
    },
    {
      listItemIcon: <LoyaltyOutlinedIcon />,
      listItemText: "ส่งหัวใจ",
      listLink: "/send-heart",
      listKey:"send-heart",
    },
    {
      listItemIcon: <ChatOutlinedIcon />,
      listItemText: "ฟีดแบค",
      listLink: "/feedback",
      listKey:"feedback",
    },
    {
      listItemIcon: <HowToRegIcon />,
      listItemText: "กิจกรรมของฉัน",
      listLink: "/my-activity",
      listKey:"myActivity",
    },
    {
      listItemIcon: <EmojiPeopleIcon />,
      listItemText: "สมัครกิจกรรม",
      listLink: "/activity",
      listKey:"activity",
    },
    {
      listItemIcon: <ShoppingCartOutlinedIcon />,
      listItemText: "รางวัลของฉัน",
      listLink: "/my-rewards",
      listKey:"myreward",
    },
    {
      listItemIcon: <FastfoodOutlinedIcon />,
      listItemText: "แลกของรางวัล",
      listLink: "/reward",
      listKey:"reward",
    },
    {
      listItemIcon: <AssignmentIcon />,
      listItemText: "ภารกิจของฉัน",
      listLink: "/my-mission",
      listKey:"mymission",
    },
    {
      listItemIcon: <ChatBubbleIcon />,
      listItemText: "การสื่อสาร",
      listLink: "",
      listKey:"communication",
      collapse: [
        {
          listItemIcon: <CampaignIcon />,
          listItemText: "ประกาศข่าว",
          listLink: "/announcement",
          listKey:"announcement",
        },
        {
          listItemIcon: <ChatIcon />,
          listItemText: "พูดคุยเสนอแนะ",
          listLink: "/communication",
          listKey:"chatboard",
        },
      ],
    },
    {
      listItemIcon: <SmsIcon />,
      listItemText: "คำถามสุขภาพใจรายวัน",
      listLink: "/my-morale-daily",
      listKey:"morale-daily",
    },
    {
      listItemIcon: <SentimentVerySatisfiedOutlinedIcon />,
      listItemText: "คำถามสุขภาพใจประจำปี",
      listLink: "/morale-question",
      listKey:"morale-question",
    },
    {
      listItemIcon: <SentimentVerySatisfiedOutlinedIcon />,
      listItemText: "ประเมิน 360 องศา",
      listLink: "/assessment360",
      listKey:"user-assessment-360",
    },
    {
      listItemIcon: <StarsOutlinedIcon/>,
      listItemText: "ตารางคะแนน",
      listLink: "/leaderboard",
      listKey:"leaderboard",
    },
    {
      listItemIcon: <PollIcon />,
      listItemText: "โหวตโพล",
      listLink: "/my-poll",
      listKey:"mypoll",
    },
    {
      listItemIcon: <ConnectWithoutContactIcon />,
      listItemText: "โค้ชชิ่ง",
      listLink: "",
      listKey:"coaching",
      collapse: [

        //coach
        {
          listItemIcon: null,
          listItemText: "Coach",
          listLink: "/coaching/coachor",
          listKey:"coach-menu",
        },
        {
          listItemIcon: <Filter1Icon />,
          listItemText: "คำขอนัดหมายโค้ช",
          listLink: "/coaching/coach-booking",
          listKey:"coach-booking",
        },
        {
          listItemIcon: <Filter1Icon />,
          listItemText: "รายชื่อผู้เรียน",
          listLink: "/coaching/coachee-list",
          listKey:"list-coachee",
        },

        // coachee
        {
          listItemIcon: null,
          listItemText: "Coachee",
          listLink: "/coaching/coachor",
          listKey:"coachor",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "โค้ช",
          listLink: "/coaching",
          listKey:"coacheemy",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "ตารางนัดหมายโค้ช",
          listLink: "/coaching/coachee-booking",
          listKey:"coachee-booking",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "รายชื่อโค้ชของฉัน",
          listLink: "/coaching/coachee-booking-approve",
          listKey:"coachee-booking-approve",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "ค้นหาโค้ช",
          listLink: "/coaching/search-coach",
          listKey:"coachee-search",
        },
      ],
    },
  ];

  return (
    <ListMenu dataListMenu={dataListMenu} />
  );
};

export default UserMenu;
