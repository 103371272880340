import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../shared/inputForm/inputField";

const DialogSendCoin = ({ open, handleAddSubmit, handleClose, users }) => {
  const validationSchema = Yup.object().shape({
    receiver: Yup.array().min(1).required("Required"),
    idCoinType: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required("Required"),
    amount: Yup.number().integer().min(1).required("Required"),
    detail: Yup.string().max(200).required("Required"),
  });

  const { control, handleSubmit, reset, formState: { isSubmitSuccessful } } = useForm({
    defaultValues: {
      receiver: [],
      idCoinType: null,
      amount: 0,
      detail: "",
    },
    resolver: yupResolver(validationSchema)
  })

  const handleSendCoin = async (values) => {
    const formData = {
      employees: values.receiver.map((item) => item.idEmployees),
      idCoinType: values.idCoinType, 
      amount: values.amount, 
      detail: values.detail, 
    };
    handleAddSubmit(formData);
  }

  useEffect(() => {
    reset({
      receiver: [],
      idCoinType: null,
      amount: 0,
      detail: "",
    })
  }, [isSubmitSuccessful])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>ส่งเหรียญ</DialogTitle>
      <form onSubmit={handleSubmit(handleSendCoin)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="receiver"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={users}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.idEmployees}>
                          {`${option.firstname_TH} ${option.lastname_TH}`}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="เลือกผู้รับเหรียญ"
                        error={Boolean(fieldState.error)}
                        helperText={Boolean(fieldState.error) && fieldState.error.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller 
                control={control}
                name="idCoinType"
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={Boolean(fieldState.error)}
                  >
                  <InputLabel id="demo-simple-select-outlined-label">
                    ประเภทเหรียญ
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทเหรียญ"
                  >
                    <MenuItem value={2}>SCGC</MenuItem>
                    <MenuItem value={3}>Pony</MenuItem>
                    <MenuItem value={4}>Unicorn</MenuItem>
                    <MenuItem value={5}>Centaur</MenuItem>
                    <MenuItem value={6}>CSR</MenuItem>
                  </Select>
                  <FormHelperText>
                    {Boolean(fieldState.error) && fieldState.error.message}
                  </FormHelperText>
                </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField 
                control={control} 
                name={"amount"} 
                type={"number"}
                label="จำนวน"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField 
                control={control} 
                name={"detail"} 
                fullWidth
                multiline
                rows={3}
                label="รายละเอียด"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button type="submit" variant="contained" color="success">
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSendCoin;
