import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Drawer,
  IconButton,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import RankingStand from "./assets/ranking.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarsIcon from "@mui/icons-material/Stars";

const SectionOne = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
  maxWidth: 550,
  margin: "auto",
  position: "relative",
  height: 465,
});

const SectionTwo = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  "& button": {
    outline: "none",
  },
  margin: "auto",
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
});

const SectionThree = styled("div")({
  margin: "auto",
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
});

const SectionFour = styled("div")({
  marginTop: 48,
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  borderRadius: 4,
});

const WrapCardList = styled(Paper)({
  margin: "6px 0",
  padding: "4px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#ffffff62",
  border: "none",
});

const CardList = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
});

const CardListOne = styled("div")({
  paddingLeft: 16,
  ["@media only screen and (max-width: 600px)"]: {
    paddingLeft: 0,
    width: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ["@media only screen and (min-width:600px)"]: {
    width: 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ["@media only screen and (min-width:768px)"]: {
    width: 100,
    display: "flex",
    flexDirection: "row",
  },
  ["@media only screen and (min-width:992px)"]: {
    width: 100,
    display: "flex",
    flexDirection: "row",
  },
});

const CardListTwo = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "& .inner-list-two-name": {
    ["@media only screen and (max-width: 600px)"]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
});

const StyledTextIndex = styled(Typography)({
  fontFamily: "Oswald",
  fontWeight: 500,
  fontSize: 26,
  color: "#929292",
});

const WrapIndexChange = styled("div")(({ rank }) => {
  return rank === "up"
    ? {
        width: 65,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 18,
      }
    : {
        width: 65,
        display: "flex",
        alignItems: "center",
        flexDirection: "column-reverse",
        marginTop: 18,
      };
});

const WrapIndexChangeSame = styled("div")({
  width: 65,
  height: 35,
  marginBottom: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledAvatar = styled(Avatar)({
  marginRight: 16,
  marginLeft: 16,
  width: 50,
  height: 50,
});

const StyledTextName = styled(Typography)({
  fontSize: 20,
  color: "#3a3a3a",
  ["@media only screen and (max-width: 600px)"]: {
    textAlign: "center",
    fontSize: 16,
  },
});

const StyledTextDepartment = styled(Typography)({
  fontSize: 16,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ["@media only screen and (max-width: 600px)"]: {
    width: 132,
    textAlign: "center",
  },
  ["@media only screen and (min-width:600px)"]: {
    width: 180,
  },
  ["@media only screen and (min-width:768px)"]: {
    width: "100%",
  },
});

const StyledTextRankIndex = styled(Typography)(({ rank }) => {
  return rank === "up"
    ? {
        fontSize: 18,
        lineHeight: 0,
        fontWeight: 600,
        color: "#4caf50",
      }
    : {
        fontSize: 18,
        lineHeight: 0,
        fontWeight: 600,
        color: "#f44336",
      };
});

const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)({
  color: "#4caf50",
});

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)({
  color: "#f44336",
});

const WrapTextScore = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  paddingRight: 16,
  ["@media only screen and (max-width: 600px)"]: {
    alignItems: "center",
  },
});

const WrapTextScoreTop3 = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
});

const WrapPagination = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: 16,
  "& .pagination": {
    "& button": {
      outline: "none",
    },
  },
});

const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapListUsers": {
    marginBottom: 8,
  },
  "& .wrapBtnReset": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const WrapAvatarFirst = styled("div")({
  width: 165,
  height: 165,
  marginTop: 20,
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: 50,
  },
});

const WrapAvatarSecond = styled("div")({
  width: 140,
  height: 140,
  marginTop: 70,
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: 95,
  },
});

const WrapAvatarThird = styled("div")({
  width: 120,
  height: 120,
  marginTop: 126,
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: 150,
  },
});

const circle1 = {
  position: "absolute",
  boxSizing: "border-box",
  width: "85%",
  height: "85%",
  borderWidth: 3,
  borderStyle: "solid",
  borderRadius: "50%",
  transition: "all 1.5s ease-in-out",
};

const circle2 = {
  position: "absolute",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  borderWidth: 3,
  borderStyle: "solid",
  borderRadius: "50%",
  transition: "all 1.5s ease-in-out",
  top: 0,
  left: 0,
};

const Circle1AvatarSecond = styled("div")({
  ...circle1,
  top: 8,
  left: 8,
  borderColor: "#d0d0ca #d0d0ca #d0d0ca transparent",
});

const Circle2AvatarSecond = styled("div")({
  ...circle2,
  borderColor: "#d0d0ca transparent #d0d0ca #d0d0ca",
});

const Circle1AvatarFirst = styled("div")({
  ...circle1,
  top: 9,
  left: 10,
  borderColor: "#ffcb3d #ffcb3d #ffcb3d transparent",
});

const Circle2AvatarFirst = styled("div")({
  ...circle2,
  borderColor: "#ffcb3d transparent #ffcb3d #ffcb3d",
});

const Circle1AvatarThird = styled("div")({
  ...circle1,
  top: 7,
  left: 7,
  borderColor: "#d76248 #d76248 #d76248 transparent",
});

const Circle2AvatarThird = styled("div")({
  ...circle2,
  borderColor: "#d76248 transparent #d76248 #d76248",
});

const CoinIcon = ({ type }) => {
  if (type === "heart") {
    return <FavoriteIcon fontSize="small" style={{ color: "#ff6243" }} />
  } else {
    return <StarsIcon fontSize="small" style={{ color: "#E6A91D" }} />
  }
}

const LeaderBoardHeart = ({ data, type }) => {
  const [page, setPage] = React.useState(1);
  const [openFilter, setOpenFilter] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  return (
    <div>
      {data && (
        <div style={{ width: "100%" }}>
          <SectionOne>
            <WrapAvatarSecond className={'avatarSecond'}>
              <div style={{ position: "relative", width: 108, height: 108 }}>
                <Circle1AvatarSecond className={'circle-1'}></Circle1AvatarSecond>
                <Circle2AvatarSecond className={'circle-2'}></Circle2AvatarSecond>
                <img
                  style={{ borderRadius: "50%", width: "100%", padding: 14 }}
                  src={data[1]?.imageURL}
                  alt="userImage"
                />
              </div>
              <div>
                <Typography align="center" variant="subtitle1">
                  {`${data[1]?.firstname_TH} ${data[1]?.lastname_TH}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  align="center"
                  variant="body2"
                  style={{ fontSize: 14 }}
                >
                  {data[1]?.departmentName}
                </Typography>
                <WrapTextScoreTop3>
                  <Typography style={{ fontSize: 40, lineHeight: 1.0 }}>
                    {data[1]?.amount}
                  </Typography>
                  <CoinIcon type={type} />
                </WrapTextScoreTop3>
              </div>
            </WrapAvatarSecond>
            <WrapAvatarFirst className={'avatarFirst'}>
              <div style={{ position: "relative", width: 130, height: 130 }}>
                <Circle1AvatarFirst className={'circle-1'}></Circle1AvatarFirst>
                <Circle2AvatarFirst className={'circle-2'}></Circle2AvatarFirst>
                <img
                  style={{ borderRadius: "50%", width: "100%", padding: 16 }}
                  src={data[0]?.imageURL}
                  alt="userImage"
                />
              </div>
              <div>
                <Typography align="center">
                  {`${data[0]?.firstname_TH} ${data[0]?.lastname_TH}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  align="center"
                  variant="body2"
                  style={{ fontSize: 14 }}
                >
                  {data[0]?.departmentName}
                </Typography>
                <WrapTextScoreTop3>
                  <Typography style={{ fontSize: 40, lineHeight: 1.0 }}>
                    {data[0]?.amount}
                  </Typography>
                  <CoinIcon type={type} />
                </WrapTextScoreTop3>
              </div>
            </WrapAvatarFirst>
            <WrapAvatarThird className={'avatarThird'}>
              <div style={{ position: "relative", width: 90, height: 90 }}>
                <Circle1AvatarThird className={'circle-1'}></Circle1AvatarThird>
                <Circle2AvatarThird className={'circle-2'}></Circle2AvatarThird>
                <img
                  style={{ borderRadius: "50%", width: "100%", padding: 14 }}
                  src={data[2]?.imageURL}
                  alt="userImage"
                />
              </div>
              <div>
                <Typography align="center">
                  {`${data[2]?.firstname_TH} ${data[2]?.lastname_TH}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  align="center"
                  variant="body2"
                  style={{ fontSize: 14 }}
                >
                  {data[2]?.departmentName}
                </Typography>
                <WrapTextScoreTop3>
                  <Typography style={{ fontSize: 40, lineHeight: 1.0 }}>
                    {data[2]?.amount}
                  </Typography>
                  <CoinIcon type={type} />
                </WrapTextScoreTop3>
              </div>
            </WrapAvatarThird>
            <img
              alt="RankingStand"
              src={RankingStand}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: 550,
                position: "absolute",
                bottom: 0,
              }}
            />
          </SectionOne>
          <SectionTwo>
            <IconButton aria-label="filter" onClick={toggleDrawer} size="large">
              <FilterListIcon fontSize="large" />
            </IconButton>
          </SectionTwo>
          <SectionThree>
            {data.slice(3).map((value, indexItem) => {
              return (
                <WrapCardList key={indexItem} variant="outlined">
                  <CardList>
                    <CardListOne>
                      <StyledTextIndex>{indexItem + 4}</StyledTextIndex>
                    </CardListOne>
                    <CardListTwo>
                      <div
                        className={`inner-list-two-name`}
                        style={{ display: "flex" }}
                      >
                        <StyledAvatar alt={value.title} src={value.imageURL} />
                        <div>
                          <StyledTextName variant="body1">
                            {`${value.firstname_TH} ${value.lastname_TH}`}
                          </StyledTextName>
                          <StyledTextDepartment
                            variant="body1"
                            color="textSecondary"
                          >
                            {value.departmentName}
                          </StyledTextDepartment>
                        </div>
                      </div>
                      <WrapTextScore>
                        <Typography style={{ fontSize: 36 }}>
                          {value.amount}
                        </Typography>
                        <CoinIcon type={type} />
                      </WrapTextScore>
                    </CardListTwo>
                  </CardList>
                </WrapCardList>
              );
            })}
          </SectionThree>
          {/* <SectionFour>
            <Typography align="center" variant="h5">
              อันดับที่ทั้งหมด
            </Typography>
            {data.slice(10, 20).map((value, indexItem) => {
              return (
                <WrapCardList key={indexItem} variant="outlined">
                  <CardList>
                    <CardListOne>
                      <StyledTextIndex>{indexItem + 11}</StyledTextIndex>
                    </CardListOne>
                    <CardListTwo>
                      <div
                        className={`inner-list-two-name`}
                        style={{ display: "flex" }}
                      >
                        <StyledAvatar alt="userImage" src={value.imageURL} />
                        <div>
                          <StyledTextName variant="body1">
                            {`${value.firstname_TH} ${value.lastname_TH}`}
                          </StyledTextName>
                          <StyledTextDepartment
                            variant="body1"
                            color="textSecondary"
                          >
                            {value.departmentName}
                          </StyledTextDepartment>
                        </div>
                      </div>
                      <WrapTextScore>
                        <Typography style={{ fontSize: 36 }}>
                          {value.amount}
                        </Typography>
                        <CoinIcon type={type} />
                      </WrapTextScore>
                    </CardListTwo>
                  </CardList>
                </WrapCardList>
              );
            })}
            <WrapPagination>
              <Pagination
                count={3}
                page={page}
                onChange={handleChange}
                className={`pagination`}
              />
            </WrapPagination>
          </SectionFour> */}
        </div>
      )}
      {/* {openFilter && (
        <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
          <WrapDrawerFilter>
            <div className={`wrapListUsers`}>
              <StyledAutocomplete
                id="combo-box-user"
                options={data}
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ค้นหารายชื่อ"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={`wrapListUsers`}>
              <StyledAutocomplete
                id="combo-box-department"
                options={departmentList}
                getOptionLabel={(option) => option.departmentName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ค้นหารายหน่วยงาน"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={`wrapBtnReset`}>
              <Button variant="outlined">reset</Button>
              <Button variant="contained" color="primary">
                search
              </Button>
            </div>
          </WrapDrawerFilter>
        </Drawer>
      )} */}
    </div>
  );
};

export default LeaderBoardHeart;
