import React, { useState, useEffect } from "react";
import 'date-fns';
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';

// Components
import Paper from '@mui/material/Paper';
import { Typography, Divider } from "@mui/material";

import { Fragment } from "react";

const StyledImageRewardItem = styled('div')({
  height: 100,
  minWidth: 200,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center"
})

const ActivityTable = ({ data, handleClickOpenEdit, handleClickOpenDelete }) =>  {
  const EditIcon = () => {
    return (
      <img alt="activity" src={`${process.env.PUBLIC_URL}/images/assets/edit.png`} width={24} />
    );
  }

  const DeleteIcon = () => {
    return (
      <img alt="activity" src={`${process.env.PUBLIC_URL}/images/assets/delete.png`} width={24} />
    );
  }

  useEffect(() => {

  }, []);

  return (
    <Fragment>
      {/* <MaterialTable
        title=""
        columns={[
          {
            title: 'รายการกิจกรรม', field: 'name',
            width: 300,
            cellStyle: {
              width: 300,
              minWidth: 300
            },
            headerStyle: {
              width: 300,
              minWidth: 300
            },
          },
          {
            title: 'เหรียญ', field: 'reward',
            width: 50,
            cellStyle: {
              width: 50,
              minWidth: 50
            },
            headerStyle: {
              width: 50,
              minWidth: 50
            },
          },
          {
            title: 'รับสม้คร(คน)', field: 'register',
            width: 120,
            cellStyle: {
              width: 120,
              minWidth: 120
            },
            headerStyle: {
              width: 120,
              minWidth: 120
            },
          },
          {
            title: 'ลงทะเบียน(คน)', field: 'enroll',
            width: 120,
            cellStyle: {
              width: 120,
              minWidth: 120
            },
            headerStyle: {
              width: 120,
              minWidth: 120
            },
          },
          {
            title: 'ระยะเวลาลงทะเบียน', field: 'enrollDate',
            width: 200,
            cellStyle: {
              width: 200,
              minWidth: 200
            },
            headerStyle: {
              width: 200,
              minWidth: 200
            },
            render: (item, rows) => (
              <div>
                <Typography style={{ fontSize: 14 }} variant="subtitle1">{item.enrollDate}</Typography>
              </div>
            ),
          },
        ]}
        data={data}
        actions={[
          {
            icon: 'add',
            tooltip: 'เพิ่มรางวัล',
            isFreeAction: true,
            onClick: (event) => alert("You want to add a new row")
          },
          {
            icon: () => <EditIcon />,
            tooltip: 'แก้ไขรางวัล',
            iconProps: { style: {} },
            onClick: (event, rowData) => handleClickOpenEdit(rowData)
          },
          rowData => ({
            icon: () => <DeleteIcon />,
            tooltip: 'ลบรางวัล',
            iconProps: { style: { color: "rgb(244, 67, 54)" } },
            onClick: (event, rowData) => handleClickOpenDelete(rowData),
          })
        ]}
        options={{
          actionsColumnIndex: -1
        }}
        detailPanel={rowData => {
          return (
            <div style={{ padding: 10, display: "flex" }}>
              <StyledImageRewardItem
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/assets/maxresdefault.jpg)`, }}>
              </StyledImageRewardItem>
              <div>
                <div style={{ display: "flex" }}>
                  <Typography color="textSecondary" style={{ fontSize: 14, minWidth: 100 }}>วันที่จัดกิจกรรรม</Typography>
                  <Typography style={{ fontSize: 14 }}>{rowData.date}</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography color="textSecondary" style={{ fontSize: 14, minWidth: 100 }}>รายละเอียด</Typography>
                  <Typography style={{ fontSize: 14 }}>{rowData.detail}</Typography>
                </div>
              </div>
            </div>
          )
        }}
      /> */}
    </Fragment>
  );
}
export default ActivityTable;