import React, { useState } from "react";
import {
  TextField,
  Button,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
  "& .wrapListUsers": {
    marginTop: 16,
  },
});

const DateFilter = ({ setFilterDate }) => {
  const [filter, setFilter] = useState(null);

  const handleSearch = () => {
    setFilterDate(filter);
  };

  const handleReset = () => {
    setFilterDate(null);
    setFilter(dayjs());
  };

  return (
    <WrapDrawerFilter>
      <DatePicker
        views={["year", "month"]}
        label="Year and Month"
        value={filter}
        onChange={(newValue) => {
          setFilter(newValue);
        }}
        minDate={dayjs('2022-01-01')}
        maxDate={dayjs('2029-12-01')}
      />
      <div className={`wrapBtnReset`}>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          search
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          reset
        </Button>
      </div>
    </WrapDrawerFilter>
  );
};

export default DateFilter;
