import {
  ANNOUNCEMENT_FETCHING,
  ANNOUNCEMENT_FAILED,
  ANNOUNCEMENT_SUCCESS,
  TRANSFER_COIN_OUT_ANNOUNCEMENT_FETCHING,
  TRANSFER_COIN_OUT_ANNOUNCEMENT_FAILED,
  TRANSFER_COIN_OUT_ANNOUNCEMENT_SUCCESS,
} from "./types";

import announcementService from "../services/announcement.service";

export const getAllAnnouncement = () => async (dispatch) => {
  try {
    dispatch({
      type: ANNOUNCEMENT_FETCHING,
    });
    const res = await announcementService.getAllAnnouncement();
    if (res) {
      dispatch({
        type: ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const getUserAnnouncement = () => async (dispatch) => {
  try {
    dispatch({
      type: ANNOUNCEMENT_FETCHING,
    });
    const res = await announcementService.getUserAnnouncement();
    if (res) {
      dispatch({
        type: ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
  }
};

export const getAnnouncementReward = () => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_COIN_OUT_ANNOUNCEMENT_FETCHING,
    });
    const res = await announcementService.getAnnouncementReward();
    if (res) {
      dispatch({
        type: TRANSFER_COIN_OUT_ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: TRANSFER_COIN_OUT_ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const addAnnouncement = (formData) => async (dispatch) => {
  try {
    const res = await announcementService.addAnnouncement(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};

export const addAnnouncementReward = (formData) => async (dispatch) => {
  try {
    const res = await announcementService.addAnnouncementReward(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ANNOUNCEMENT_FAILED,
    });
    console.log(err);
  }
};