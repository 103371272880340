import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Card,
  Chip,
  LinearProgress,
  Stack,
  Button,
  Grid,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllMoraleDaily } from "../../../../../actions/moraleDaily";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";
import LoadingIcon from "../../../shared/general/loadingIcon";

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledCard = styled(Card)({
  height: "100%",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  width: "28%",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  marginLeft: "auto",
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const colorBGCardHeader = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  const idModTen = id % 10;
  return colorBGCardHeader[idModTen];
};

const MoraleHistoryDaily = ({ selectCompany }) => {
  const [insightList, setInsightList] = useState([]);

  const handleSelectInsight = (id) => {
    if (id === 0) {
      setInsightList([]);
    } else {
      if (insightList.some((value) => value === id)) {
        setInsightList(insightList.filter((value) => value !== id));
      } else {
        setInsightList([...insightList, id]);
      }
    }
  };

  const dispatch = useDispatch();
  const { result: moraleDaily, isFetching } = useSelector(
    (state) => state.moraleDaily
  );
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    if (selectCompany) {
      dispatch(getAllMoraleDaily(selectCompany.idCompany));
      dispatch(getAllQuestionTopic(selectCompany.idCompany));
    }
  }, [selectCompany]);

  return (
    <>
      {questionTopic && (
        <div style={{ marginBottom: "24px" }}>
          <Typography gutterBottom variant="h6">
            หัวข้อคำถาม
          </Typography>
          <StyledChip
            clickable
            label={"ALL"}
            color={insightList.length === 0 ? "primary" : "default"}
            onClick={() => handleSelectInsight(0)}
          />
          {questionTopic.map((data) => (
            <StyledChip
              key={data.idQuestionTopic}
              clickable
              label={data.questionTopic}
              color={
                insightList.includes(data.idQuestionTopic)
                  ? "primary"
                  : "default"
              }
              onClick={() => handleSelectInsight(data.idQuestionTopic)}
            />
          ))}
        </div>
      )}
      {isFetching ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          {moraleDaily &&
            moraleDaily
              .filter((data) => {
                return insightList.length > 0
                  ? insightList.includes(data.idQuestionTopic)
                  : data;
              })
              .map((data) => {
                const numberOfAnswer = data.participantList.filter(
                  (item) => item.answer !== null
                ).length;
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <StyledCard elevation={2} key={data.idMoraleDaily}>
                      <div style={{ display: "flex" }}>
                        <WrapCardHeaderInsight
                          style={{
                            backgroundColor: getBgCard(data.idQuestionTopic),
                          }}
                        >
                          <Typography style={{ color: "#ffffff" }}>
                            {data.questionTopic}
                          </Typography>
                          <WrapCardHeaderInsightImage>
                            <img
                              alt="questionImg"
                              width={24}
                              height={24}
                              src={`${process.env.REACT_APP_API_URL}image/insight/h${data.idQuestionTopic}.png`}
                            />
                          </WrapCardHeaderInsightImage>
                        </WrapCardHeaderInsight>
                        <WrapCardHeaderInsight2>
                          <StyledCardHeaderInsightIcon>
                            {data.reward}
                          </StyledCardHeaderInsightIcon>
                          <Typography style={{ paddingLeft: 5 }}>
                            COIN
                          </Typography>
                        </WrapCardHeaderInsight2>
                      </div>
                      <div style={{ padding: "16px" }}>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h5"
                          gutterBottom
                        >
                          {data.question}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          color="textSecondary"
                          variant="subtitle1"
                        >
                          {`ประเภทคำตอบ: ${
                            data.questionType === "text"
                              ? "ข้อความ"
                              : "ตัวเลือก"
                          }`}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`เริ่มต้น: ${dayjs(data.startDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`สิ้นสุด: ${dayjs(data.endDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                      </div>
                      <div style={{ padding: "16px" }}>
                        <LinearProgress
                          sx={{ borderRadius: "40px", height: "8px" }}
                          variant="determinate"
                          value={
                            (numberOfAnswer * 100) / data.participantList.length
                          }
                        />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body2" color={"#3C3C3C"}>
                            {`ประเมินแล้ว ${numberOfAnswer}/${data.participantList.length} คน`}
                          </Typography>
                          <Button
                            component={Link}
                            to={`/admin/manage/moraleDaily/${data.idMoraleDaily}`}
                          >
                            ดูคำตอบ
                          </Button>
                        </Stack>
                      </div>
                    </StyledCard>
                  </Grid>
                );
              })}
        </Grid>
      )}
    </>
  );
};

export default MoraleHistoryDaily;
