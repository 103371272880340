import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  Avatar,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";

import Link from "@mui/material/Link";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import "./style.css";

import CoinHeaderIcon from "./assets/coin.svg";
import HeartHeaderIcon from "./assets/give-love.svg";
import RewardBG from "./assets/reward1.png";
import RewardIcom from "./assets/gift.png";
import ActivityIcon from "./assets/protest.png";
import LeaderBoardBg from "./assets/rank.png";
// Components
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";
import ManagerLeaderBoard from "./ManagerLeaderBoard";
import AllLeaderBoard from "./AllLeaderBoard";
import CardReward from "./../../shared/cardReward";
import CardActivity from "./../../shared/cardActivity";
import {
  alertDailyInsightOpen,
  alertDailyInsightClose,
} from "../../../../actions/alertDailyInsight";
import { getRewards, getRewardsActive, getRewardsBestSeller } from "../../../../actions/reward";
import { getActivityHot } from "../../../../actions/activity";
import { addMoraleDailyAnswer, getMoraleDailyByUserId } from "../../../../actions/moraleDaily";
import { getHeartReceiveYear } from "../../../../actions/heartTransfer";
// import runOneSignal from './onesignal';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useRewardsActive } from "../../../../quries/reward";

const WrapIconWallet = styled('div')({
  marginRight: 20,
})

const WrapTextWallet = styled('div')({
  paddingLeft: 20,
})

const StyledTextWallet = styled(Typography)({
  fontSize: 80,
  lineHeight: 1,
  color: "#565656",
})

const StyledTextSecondWallet = styled(Typography)({
  fontSize: 14,
  lineHeight: 1,
  color: "#808080",
  textAlign: "left",
  paddingLeft: 20,
})

const WrapHeaderSection = styled('div')({
    //minWidth: 475,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 1300,
    margin: "auto",
    width: "100%",
    paddingTop: 30,
    paddingBottom: 35,
})

const HeaderRewardActivity = styled('div')({
  marginBottom: 16,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .inner-header-reward-activity-left": {
    display: "flex",
    alignItems: "center",
    ["@media only screen and (max-width: 600px)"]: {
      marginBottom: 8,
    },
  },
  ["@media only screen and (max-width: 600px)"]: {
    flexDirection: "column",
    marginBottom: 24,
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
  ["@media only screen and (min-width:1200px)"]: {},
})

const BackgroundRank = styled('div')({
  backgroundImage: `url(${LeaderBoardBg})`,
  backgroundSize: "cover",
})

const StyledDialogContent = styled(DialogContent)({
  width: 340,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .MuiTypography-h6": {
    fontSize: 24,
  },
})

const WrapAnsChoice = styled('div')({
  display: "flex",
  flexDirection: "column",
})

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
})

const StyledButtonAns = styled(Button)({
  width: 182,
  margin: 4,
  border: "none",
  backgroundColor: "#FFFFFF7A",
})

const insightAns = [
  {
    text: "Very Happy",
    value: false,
  },
  {
    text: "Happy",
    value: false,
  },
  {
    text: "Little Happy",
    value: false,
  },
  {
    text: "Little Bad",
    value: false,
  },
  {
    text: "Bad",
    value: false,
  },
  {
    text: "Very Bad",
    value: false,
  },
];

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: heartReceiveYear } = useSelector((state) => state.heartReceiveYear);
  // const { result: rewardBestSeller } = useSelector((state) => state.rewardBestSeller);
  // const { result: rewards } = useSelector((state) => state.reward);
  const { result: activityHot } = useSelector((state) => state.activityHot);
  const { result: moraleDailyUser } = useSelector((state) => state.moraleDailyUser);
  const { data: rewards } = useRewardsActive({
    idCompany: userProfile?.idCompany,
    select: (data) => data.slice(0, 4),
  });

  const [answer, setAnswer] = useState("");
  const [checkAnonymous, setCheckAnonymous] = React.useState(false);
  const [openDailyInsight, setOpenDailyInsight] = useState(false);
  const [dataInsight, setDataInsight] = useState(null);

  const handleChangeBgInsight = () => {
    switch (dataInsight.idQuestionTopic) {
      case 1:
        return "#F1C232";
      case 2:
        return "#6AA84F";
      case 3:
        return "#CC0000";   
      case 4:
        return "#3C78D8";
      case 5:
        return "#D64E91";
      default:
        return "#FFF";
    }
  };

  const ImgInsight = () => {
    switch (dataInsight.idQuestionTopic) {
      case 1:
        return H1;
      case 2:
        return H2;
      case 3:
        return H3;
      case 4:
        return H4;
      case 5:
        return H5;
      case 6:
        return H6;
      case 7:
        return H7;
      case 8:
        return H8;
      case 9:
        return H9;
      case 10:
        return H10;
      default:
        break;
    }
  };

  useEffect(() => {
    async function fetchData() {
      // runOneSignal();
      dispatch(getHeartReceiveYear())
      dispatch(getActivityHot())
      // dispatch(getRewardsBestSeller())
      if (localStorage.getItem("notification") === "null") {
        const result = await dispatch(getMoraleDailyByUserId()).then((result) => {
          return JSON.parse(JSON.stringify(result));
        });
        if(result) {
          console.log(result)
          setDataInsight(result[0]);
          setOpenDailyInsight(true);
        }
      }
    }
    fetchData();
  }, [dispatch]);

  const handleClickRedeem = () => {
    navigate('/reward');
  };

  const handleClickSendHeart = () => {
    navigate('/send-heart');
  };

  const handleClickSeeMoreActivity = () => {
    navigate('/activity');
  };

  const handleCloseDailyInsight = () => {
    setOpenDailyInsight(false);
  };

  const handleOpenDailyInsight = () => {
    setOpenDailyInsight(true);
  };

  const handleChangeAnonymous = (event) => {
    setCheckAnonymous(event.target.checked);
  };

  const handleChangeAnswer = (event) => {
    setAnswer(event.target.value);
  };

  const handleClickAnswer = async (insightText) => {
    dispatch(alertDailyInsightClose())
    localStorage.setItem("notification", insightText);

    const res = await dispatch(
      addMoraleDailyAnswer({
        answer: insightText,
        idEmployees: currentUser.idEmployees,
        idMoraledaily: dataInsight.idMoraledaily,
        reward: dataInsight.reward,
      })
    );
    if(res) {
      dispatch(getMoraleDailyByUserId());
      handleCloseDailyInsight()
    }
  };

  const BtnAnsInsight = ({ insightValue, insightText, score, btnIndex }) => {
    return (
      <StyledButtonAns
        variant={"contained"}
        onClick={() => handleClickAnswer(insightText)}
      >
        {" "}
        <div style={{ display: "flex" }}>
          <Typography variant="button">{insightText}</Typography>
          <StyledCoinChoice>{score}</StyledCoinChoice>
        </div>
      </StyledButtonAns>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="dashboard-header">
        <div style={{ width: 650 }}>
          <Typography
            className="dashboard-header-text"
            variant="h3"
            style={{ lineHeight: 1.5 }}
          >
            {" "}
            ส่งหัวใจของคุณเพื่อชื่นชมคนที่คุณประทับใจได้เลย{" "}
          </Typography>
          <Typography
            className="dashboard-header-text"
            variant="subtitle2"
            style={{ lineHeight: 1.5, padding: "0px 8px" }}
          >
            {" "}
            เพราะการชื่นชมคือหัวใจของการสร้างความสัมพันธ์ในองค์กร
            ส่งหัวใจให้คนที่คนที่คุณประทับใจ หรือ
            รับหัวใจจากคนอื่นๆที่ประทับใจคุณ ไปจนถึงการสะสมเหรียญจากกิจกรรมต่างๆ
            และ นำไปแลกของรางวัลมากมาย{" "}
          </Typography>
            <Grid container spacing={3} justifyContent="center">
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Paper elevation={0} className="wallet">
                  <div style={{ paddingTop: 15, paddingBottom: 10 }}>
                    <StyledTextSecondWallet
                      variant="body1"
                    >
                      {" "}
                      เหรียญทองที่ได้รับ{" "}
                    </StyledTextSecondWallet>
                  </div>
                  {userProfile && 
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <WrapTextWallet>
                        <StyledTextWallet variant="h2">
                        {userProfile?.wallet
                          ? userProfile.wallet.find((item) => item.coinType === "main")?.amount
                          : 0}
                        </StyledTextWallet>
                      </WrapTextWallet>
                      <WrapIconWallet>
                        <img
                          className="img-coin-wallet"
                          alt="coin-wallet"
                          src={CoinHeaderIcon}
                          width={70}
                        />
                      </WrapIconWallet>
                    </div>
                  }
                  <Chip
                    color="primary"
                    style={{ margin: 10, marginTop: 20 }}
                    label="แลกของรางวัล"
                    onClick={handleClickRedeem}
                    deleteIcon={<PlayCircleOutlineIcon />}
                  />
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Paper elevation={0} className="wallet">
                  <div style={{ paddingTop: 15, paddingBottom: 10 }}>
                    <StyledTextSecondWallet
                      variant="body1"
                    >
                      {" "}
                      หัวใจที่ได้รับ{" "}
                    </StyledTextSecondWallet>
                  </div>
                  {heartReceiveYear && 
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <WrapTextWallet>
                        <StyledTextWallet variant="h2">
                          {heartReceiveYear[0].heartReceiveYear 
                          ? heartReceiveYear[0].heartReceiveYear
                          : 0}
                        </StyledTextWallet>
                      </WrapTextWallet>
                      <WrapIconWallet>
                        <img
                          className="img-heart-wallet"
                          alt="heart-wallet"
                          src={HeartHeaderIcon}
                          width={70}
                        />
                      </WrapIconWallet>
                    </div>
                  }
                  {userProfile &&
                    <Chip
                      color="primary"
                      style={{ margin: 10, marginTop: 20 }}
                      label={`ส่งหัวใจ (${userProfile.sendHeartBalance}/${userProfile.sendHeartQuota})`}
                      onClick={handleClickSendHeart}
                      onDelete={handleClickSendHeart}
                      deleteIcon={<PlayCircleOutlineIcon />}
                    />
                  }
                </Paper>
              </Grid>
            </Grid>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${RewardBG})`,
          backgroundSize: "cover",
        }}
      >
        <WrapHeaderSection>
          <HeaderRewardActivity>
            <div className={`inner-header-reward-activity-left`}>
              <img src={RewardIcom} width="45" />
              <Typography
                variant="h4"
                style={{ paddingLeft: 5, paddingTop: 5 }}
              >
                แลกของรางวัล
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickRedeem}
              >
                ดูเพิ่มเติม
              </Button>
            </div>
          </HeaderRewardActivity>
          <Grid container spacing={3}>
            {rewards &&
              rewards.map((value) => (
                <Grid item xs={12} sm={6} md={4}>
                  <CardReward data={value} />
                </Grid>
              ))}
          </Grid>
        </WrapHeaderSection>
        <WrapHeaderSection
          style={{ paddingTop: 0 }}
        >
          <HeaderRewardActivity>
            <div className={`inner-header-reward-activity-left`}>
              <img src={ActivityIcon} width="45" />
              <Typography
                variant="h4"
                style={{ paddingLeft: 5, paddingTop: 5 }}
              >
                กิจกรรมน่าสนใจ
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickSeeMoreActivity}
              >
                ดูเพิ่มเติม
              </Button>
            </div>
          </HeaderRewardActivity>
          <Grid container spacing={3}>
            {activityHot &&
              activityHot.map((value) => (
                <Grid item xs={12} sm={6} md={4}>
                  <CardActivity value={value} />
                </Grid>
              ))}
          </Grid>
        </WrapHeaderSection>
      </div>
      <div style={{ backgroundColor: "#ddd" }}>
        <WrapHeaderSection>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4">MANAGER LEADERBOARD </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {`- ${dayjs().format('MMMM YYYY')} -`}
            </Typography>
          </div>
          <ManagerLeaderBoard />
        </WrapHeaderSection>
      </div>
      <BackgroundRank>
        <WrapHeaderSection>
          <AllLeaderBoard />
        </WrapHeaderSection>
      </BackgroundRank>
      {dataInsight && dataInsight.answer === null && (
        <Dialog
          open={openDailyInsight}
          onClose={handleOpenDailyInsight}
          aria-labelledby="alert-dialog-title-insight"
          aria-describedby="alert-dialog-description-insight"
        >
          <StyledDialogContent
            style={{ backgroundColor: handleChangeBgInsight() }}
          >
            <img
              style={{ marginBottom: 10 }}
              src={ImgInsight()}
              alt=""
              width={80}
            />
            <Typography variant="h6" align="center" gutterBottom>
              {dataInsight.question}
            </Typography>
            {dataInsight.questionType === "choice" ? (
              <WrapAnsChoice>
                {insightAns.map((item, index) => (
                  <BtnAnsInsight
                    btnIndex={index}
                    insightValue={item.value}
                    insightText={item.text}
                    score={dataInsight.reward}
                  />
                ))}
                <Link
                  component="button"
                  variant="body2"
                  style={{ color: "#ffffff", marginTop: 16 }}
                  onClick={handleCloseDailyInsight}
                >
                  {"Skip >>"}
                </Link>
              </WrapAnsChoice>
            ) : (
              <div>
                <div>
                  <TextField
                    style={{ width: "100%", backgroundColor: "#FFF" }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    multiline
                    rows={4}
                    value={answer}
                    onChange={handleChangeAnswer}
                  />
                  <FormControlLabel
                    style={{ width: "100%" }}
                    control={
                      <Checkbox
                        checked={checkAnonymous}
                        onChange={handleChangeAnonymous}
                        name="checkedB"
                        color="default"
                      />
                    }
                    label="ส่งโดยไม่ระบุชื่อ"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <StyledButtonAns
                    onClick={() => handleClickAnswer(answer)}
                  >
                    <div style={{ display: "flex", width: 100 }}>
                      <Typography variant="button">ส่งคำตอบ</Typography>
                      <StyledCoinChoice>
                        {dataInsight.reward}
                      </StyledCoinChoice>
                    </div>
                  </StyledButtonAns>
                  <Link
                    component="button"
                    variant="body2"
                    style={{ color: "#ffffff", marginTop: 16 }}
                    onClick={handleCloseDailyInsight}
                  >
                    {"Skip >>"}
                  </Link>
                </div>
              </div>
            )}
          </StyledDialogContent>
        </Dialog>
      )}
    </div>
  );
}
