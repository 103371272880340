import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  addCommunicationReply,
  getAllCommunication,
} from "../../../../actions/communication";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { toast } from "sonner";

export default function DialogDetailManageComment({ idCommunication, open, handleClose, replyList }) {
  const dispatch = useDispatch();
  const [reply, setReply] = useState("");
  console.log(idCommunication)
  const handleAddReply = async () => {
    const res = await dispatch(addCommunicationReply({ idCommunication, reply }));
    if (res && res.status === 201) {
      toast.success("สำเร็จ")
      dispatch(getAllCommunication());
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>การตอบกลับ</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={1} marginTop={2} marginBottom={1} alignItems={"flex-end"}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Reply"
            variant="outlined"
            value={reply}
            onChange={(event) => setReply(event.target.value)}
            inputProps={{ maxLength: 190 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleAddReply}
          >
            ตอบกลับ
          </Button>
        </Stack>
        {replyList.length === 0 ? (
          <Typography>ไม่มีการตอบกลับ</Typography>
        ) : (
          <Stack spacing={1}>
            {replyList.map((item) => (
              <Paper
                key={item.idCommunicationReply}
                elevation={2}
                style={{ padding: "16px", borderRadius: "4px" }}
              >
                <Typography>{item.reply}</Typography>
                <Typography style={{ textAlign: "right" }}>
                  {dayjs(item.replyDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </Paper>
            ))}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
