import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  styled,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import HeaderPage from "../../../../../shared/header/headerPage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RowConfig from "./rowConfig";
import TiptapDetail from "../../../../../shared/reward/tiptapDetail";
import DialogVariation from "./dialogVariation";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

function CompanyRewardDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectImage, setSelectImgae] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDailog = () => {
    setOpenDialog(false);
  }

  const handleChangeImage = (urlImage) => {
    setSelectImgae(urlImage);
  };

  useEffect(() => {
    if (!state?.idCompanyReward) {
      return navigate("/admin/manage/reward", { replace: true })
    }
  }, [])

  return (
    <StyledRoot className={"page"}>
      {state?.idCompanyReward && (
        <Container>
          <HeaderPage textLabel={"รายละเอียดของรางวัล"} />
          <StyledDivider />
          <Grid container spacing={4} marginBottom={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{ border: "solid", borderWidth: 1, borderColor: "#bdbdbd" }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt={state.name}
                  src={selectImage ? selectImage : state.imageURL}
                />
              </Box>
              <ImageList
                //   sx={{ width: 500, height: 450 }}
                cols={5}
                //   rowHeight={164}
              >
                {[{ imageURL: state.imageURL }, ...state.images].map((item, index) => (
                  <Box
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      borderColor: "#bdbdbd",
                    }}
                    onClick={() => handleChangeImage(item)}
                    key={index}
                  >
                    <ImageListItem>
                      <img
                        src={item.imageURL}
                        alt={"reward"}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box>
                ))}
              </ImageList>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                component={Link}
                to={`/admin/manage/reward/company-reward/edit/${state.idCompanyReward}`} 
                startIcon={<EditIcon />}
              >
                แก้ไขรายละเอียด
              </Button>
              <TiptapDetail detail={state.detail} />
            </Grid>
            {/* {state.options.length > 0 && (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">ตัวเลือก</Typography>
                  <IconButton
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
                {state.options.map((item, index) => (
                  <Typography key={index}>{item.name}</Typography>
                ))}
              </Grid>
            )}
            <DialogVariation /> */}
          </Grid>
          <RowConfig />
        </Container>
      )}
    </StyledRoot>
  );
}

export default CompanyRewardDetail;
