import { httpClient } from "./httpClient";

const getTransferHeartIn = () => {
  return httpClient.get("transfer-heart-in");
};

const getTransferHeartOut = () => {
  return httpClient.get("transfer-heart-out");
};

const getTransferCoinIn = () => {
  return httpClient.get("transfer-coin-in");
};

const getTransferCoinOutMission = () => {
  return httpClient.get("transfer-coin-out-mission");
};

const getTransferCoinOutActivity = () => {
  return httpClient.get("transfer-coin-out-activity");
};

const getTransferCoinOutCommu = () => {
  return httpClient.get("transfer-coin-out-commu");
};

const getTransferCoinOutMoraleDaily = () => {
  return httpClient.get("transfer-coin-out-morale-daily");
};

export default {
  getTransferHeartIn,
  getTransferHeartOut,
  getTransferCoinIn,
  getTransferCoinOutMission,
  getTransferCoinOutActivity,
  getTransferCoinOutCommu,
  getTransferCoinOutMoraleDaily,
};
