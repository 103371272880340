import React from "react";
import { CircularProgress, Divider, Stack } from "@mui/material";
import HeartQuota from "./heartQuota";
import AssignMissionCoinQuota from "./assignMissionQuota";
import { useCompanyCoinQuota } from "../../../../../quries/company";
import ResetCoinBalance from "./resetCoinBalance";

const CoinQuota = ({ selectCompany }) => {
  const { data, isPending } = useCompanyCoinQuota({ idCompany: selectCompany?.idCompany });

  if (isPending) {
    return <CircularProgress />
  }

  return (
    <Stack spacing={4}>
      <HeartQuota selectCompany={selectCompany} data={data} />
      <Divider />
      <AssignMissionCoinQuota selectCompany={selectCompany} data={data} />
      <Divider />
      <ResetCoinBalance selectCompany={selectCompany} data={data} />
    </Stack>
  );
};

export default CoinQuota;
