import React from "react";
import { useCoinType } from "../../../../quries/coin";
import { CircularProgress } from "@mui/material";

const SpecialIcon = ({ idCoinType, width }) => {
  const { data: coinType, isPending } = useCoinType();
  if (isPending) {
    return <CircularProgress color="secondary" />;
  }

  if (Number(idCoinType)) {
    const data = coinType.find((item) => item.idCoinType === idCoinType);
    return (
      <img
        src={data?.imageURL}
        alt={data?.coinName}
        width={width}
        height={width}
      />
    );
  } else {
    return null;
  }
};

export default SpecialIcon;
