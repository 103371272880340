import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyleButtonBlue = styled(Button)(({ variant, color }) => ({
  borderRadius: 8,
  "&:hover": {
    borderRadius: 8,
  },
  ...(variant === "contained" && {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  }),
  ...(variant === "contained" && color === "error" && {
    borderColor: "#f15e5e",
    color: "#ffffff",
    backgroundColor: "#f15e5e",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#cb4a4a",
      backgroundColor: "#cb4a4a",
    },
  }),
  ...(variant === "outlined" && {
    borderColor: "#007afe",
    backgroundColor: "transparent",
    color: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#0046b7",
    },
  }),
  ...(variant === "text" && {
    backgroundColor: "transparent",
    color: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderRadius: 8,
      backgroundColor: "#1976d20a",
    },
  }),
  ...(variant === "text" &&
    color === "secondary" && {
      backgroundColor: "transparent",
      color: "#919eab",
      borderRadius: 8,
      "&:hover": {
        borderRadius: 8,
        backgroundColor: "#1976d20a",
      },
    }),
}));

const ButtonBlue = (props) => {
  return <StyleButtonBlue {...props}>{props.children}</StyleButtonBlue>;
};

export default ButtonBlue;
