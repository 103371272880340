import { useEffect ,useState} from "react";
import { getFixCoach } from "../../../../../actions/coach";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import loading from "../../../assets/loading2.gif";
import CardCoach from "./card_coach";
import {
  Grid,
  Typography,
  Card,
  Box
} from "@mui/material";
const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });


const FixCoach = ()=>{
    const dispatch = useDispatch();
    const { result: coachFix ,isFetching:coachFixLoading} = useSelector((state) => state.coachFix);


    useEffect(()=>{
    },[])


    


    return(
        <StyledCard>
        {coachFixLoading ? (
           <Box
             style={{
               width: "100%",
               display: "flex",
               justifyContent: "center",
               paddingTop: "40px",
             }}
           >
             <img width="120" alt="loading" src={loading} />
           </Box>
         ) : (
           coachFix&& coachFix.length === 0 ? (
            <Box
            style={{
              minHeight: 200,
              position: "sticky",
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>ไม่มี Fix Coach</Typography>
          </Box>
           ) : (

             <Grid  container spacing={4} marginBottom={"20px"} >
             {coachFix&&coachFix.map((val) => (
               <Grid item xs={12} sm={6} md={4} xl={4} key={val.idEmployees}>
                 <CardCoach value={val} tagType={"fix-coach"}navigateTo= {"profile"} />
               </Grid>
             ))}
             
           </Grid>
             
           )
         )}
        </StyledCard>
    )
}

export default FixCoach;