import React, { useEffect } from "react";
import HeaderPage from "../../shared/header/headerPage";
import CompanySendCoinGrid from "./CompanySendCoinGrid";
import { Button, Divider, Stack, styled } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GridOnIcon from '@mui/icons-material/GridOn';
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import {
  addCompanySendCoin,
  deleteCompanySendCoin,
  getCompanySendCoin,
} from "../../../../actions/companySendCoin";
import DialogSendCoin from "./DialogSendCoin";
import DialogExcelSendCoin from "./DialogExcelSendCoin";
import { toast } from "sonner";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const CompanySendCoin = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: users } = useSelector((state) => state.users);
  const { result: companySendCoin } = useSelector((state) => state.companySendCoin);

  const [open, setOpen] = React.useState(false);
  const [openExcel, setOpenExcel] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseExcel = () => {
    setOpenExcel(false);
  };

  const handleClickOpenExcel = () => {
    setOpenExcel(true);
  };

  const deleteSendCoin = async (id) => {
    const res = await dispatch(deleteCompanySendCoin(id));
    if (res.status === 200) {
      dispatch(getCompanySendCoin());
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  const handleAddSubmit = async (formData) => {
    const res = await dispatch(addCompanySendCoin(formData));
    if (res.status === 201) {
      dispatch(getCompanySendCoin());
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
    handleClose()
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getCompanySendCoin());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        spacing={2}
      >
        <HeaderPage textLabel={"ส่งเหรียญ"} />
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={handleClickOpen}
          >
            ส่งเหรียญ
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<UploadFileIcon />}
            onClick={handleClickOpenExcel}
          >
            อัพโหลดไฟล์ CSV
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<GridOnIcon />}
            href={`${process.env.REACT_APP_API_URL}files/send-coin.csv`}
            download
          >
            ตัวอย่างไฟล์ CSV
          </Button>
        </Stack>
      </Stack>
      <StyledDivider />
      {companySendCoin && (
        <CompanySendCoinGrid
          data={companySendCoin}
          deleteSendCoin={deleteSendCoin}
        />
      )}
      {users && (
        <>
          <DialogExcelSendCoin
            users={users}
            handleAddSubmit={handleAddSubmit}
            openExcel={openExcel}
            handleCloseExcel={handleCloseExcel}
          />
          <DialogSendCoin
            users={users}
            handleAddSubmit={handleAddSubmit}
            open={open}
            handleClose={handleClose}
          />
        </>
      )}
    </StyledRoot>
  );
};

export default CompanySendCoin;
