import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/user";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  addActivityRegister,
  getActivityParticipant,
} from "../../../../../actions/activity";
import { toast } from "sonner";

const DialogAddParticipant = ({ open, handleClose, idActivity }) => {
  const dispatch = useDispatch();
  const { result: employeeList } = useSelector((state) => state.users);
  const { result: activityParticipant } = useSelector((state) => state.activityParticipant);
  const [idEmployeesActivity, setIdEmployeesActivity] = useState([])
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const handleAddActivityRegister = async () => {
    const formData = selectedEmployee.map((item) => ({
      idEmployees: item.idEmployees,
      idActivity,
    }));

    const res = await dispatch(addActivityRegister(formData));
    if (res && res.status === 201) {
      toast.success("สำเร็จ");
      dispatch(getActivityParticipant(idActivity));
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
    handleClose();
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (activityParticipant) {
      setIdEmployeesActivity(activityParticipant.map((person) => person.idEmployees))
    }
  }, [activityParticipant]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"เพิ่มผู้เข้าร่วมกิจกรรม"}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          options={
            employeeList?.filter(
              (emp) => !idEmployeesActivity.includes(emp.idEmployees)
            ) || []
          }
          value={selectedEmployee}
          onChange={(event, newValue) => {
            setSelectedEmployee(newValue);
          }}
          isOptionEqualToValue={(option, value) =>
            option.idEmployees === value.idEmployees
          }
          getOptionLabel={(option) =>
            `${option.firstname_TH} ${option.lastname_TH}`
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.idEmployees}>
              {`${option.firstname_TH} ${option.lastname_TH}`}
            </li>
          )}
          style={{ marginTop: 8 }}
          renderInput={(params) => (
            <TextField {...params} label="เลือกผู้เข้าร่วมกิจกรรม" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          ยกเลิก
        </Button>
        <Button
          onClick={handleAddActivityRegister}
          variant="contained"
          color="primary"
          disabled={selectedEmployee.length === 0}
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAddParticipant;
