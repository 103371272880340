import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "../../../shared/inputForm/inputField";
import { toast } from "sonner";
import { useUpdateCompanySendHeartQuota } from "../../../../../quries/company";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const validationSchema = yup.object({
  sendHeartQuota: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1)
    .max(250)
    .nullable()
    .integer()
    .required(),
  resetHeartQuotaType: yup.string().oneOf(["year", "month"]),
  resetHeartQuotaMonth: yup.date().when("resetHeartQuotaType", {
    is: (type) => type === "year",
    then: (schema) => schema.required(),
  }).nullable(),
});

const DialogEditHeartQuota = ({ open, handleClose, idCompany, data }) => {
  const { mutate } = useUpdateCompanySendHeartQuota({ idCompany });
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      sendHeartQuota: data.sendHeartQuota ? data.sendHeartQuota : "",
      resetHeartQuotaType: data.resetHeartQuotaType ? data.resetHeartQuotaType : "month",
      resetHeartQuotaMonth: data.resetHeartQuotaMonth ? dayjs().set("month", data.resetHeartQuotaMonth - 1) : null,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEdit = async (values) => {
    mutate(
      {
        ...values,
        idCompany,
        resetHeartQuotaMonth: values.resetHeartQuotaMonth
          ? dayjs(values.resetHeartQuotaMonth).get("month") + 1
          : null,
      },
      {
        onSuccess: () => toast.success("สำเร็จ"),
        onError: () => toast.error("เกิดข้อผิดพลาด"),
        onSettled: () => handleClose(),
      }
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(handleEdit),
      }}
    >
      <DialogTitle>{"แก้ไข"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <InputField
            control={control}
            name={"sendHeartQuota"}
            type="number"
            label="โควต้าเหรียญหัวใจต่อเดือน"
            style={{ marginTop: "8px" }}
          />
          <Controller
            name="resetHeartQuotaType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>รูปแบบรีเซ็ตโควต้า</FormLabel>
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    label="ทุกสิ้นเดือน"
                  />
                  <FormControlLabel
                    value="year"
                    control={<Radio />}
                    label="รอบปี"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          {watch("resetHeartQuotaType") === "year" && (
            <Controller
              name="resetHeartQuotaMonth"
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label={"Month"}
                  openTo="month"
                  views={["month"]}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: Boolean(fieldState.error),
                      helperText: fieldState.error?.message,
                    },
                  }}
                />
              )}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const HeartQuota = ({ selectCompany, data }) => {
  const [open, setOpen] = useState(false);
  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography variant="h6">โควต้าเหรียญหัวใจ</Typography>
          <IconButton onClick={handleOpenEdit}>
            <EditIcon />
          </IconButton>
        </div>
        <Typography>{`รูปแบบ ${data.resetHeartQuotaType === "month" ? "ทุกสิ้นเดือน" : "เลือกเดือน"}`}</Typography>
        {data.resetHeartQuotaType === "year" && (
          <Typography>{`เดือน ${dayjs().set("month", data.resetHeartQuotaMonth - 1).format("MMMM")}`}</Typography>
        )}
        <Typography>{`จำนวน ${data.sendHeartQuota} เหรียญ`}</Typography>
      </Stack>
      {open && (
        <DialogEditHeartQuota
          open={open}
          handleClose={handleClose}
          idCompany={selectCompany.idCompany}
          data={data}
        />
      )}
    </>
  );
};

export default HeartQuota;
