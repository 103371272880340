import React, { useState } from "react";
import {
  Button,
  Chip,
  Container,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingIcon from "../../../shared/general/loadingIcon";
import utils from "../../../../../utils";
import { useUniRewardItemCost } from "../../../../../quries/uniReward";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import HeaderPage from "../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../shared/dividerHeader";
import DialogCost from "./dialogCost";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledCardData = styled("div")({
  padding: "16px",
  borderRadius: "10px",
  backgroundColor: "#b17bdf" 
})

const UniRewardTransaction = () => {
  const { id } = useParams();
  const { data, isPending } = useUniRewardItemCost({ idUniRewardItem: id });
  const [openDailogCost, setOpenDailogCost] = useState(false);

  const handleOpenDailogCost = () => {
    setOpenDailogCost(true)
  }
  
  const handleCloseDailogCost = () => {
    setOpenDailogCost(false)
  }

  if (isPending) {
    return (
      <StyledRoot className={"page"}>
        <LoadingIcon />
      </StyledRoot>
    );
  }

  const buySellData = data?.costs?.reduce((acc, cur) => {
    const price = cur.price * cur.quantity +
    (cur.price * cur.quantity * (cur.vat / 100))

    if (cur.type === "buy") {
      acc.totalBuy += price
      acc.balanceQuantity += cur.quantity
    }
    if (cur.type === "sell") {
      acc.totalSell += price
      acc.balanceQuantity -= cur.quantity
    }

    return acc
  }, { totalBuy: 0, totalSell: 0, balanceQuantity: 0 });

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"ข้อมูลการซื้อ-ขาย"} />
      <StyledDividerHeader />
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "24px",
          }}
        >
          <div>
            <Typography variant="h5">{data.name}</Typography>
            <Typography variant="h6">{`SKU: ${data.sku}`}</Typography>
            <Typography>
              {data.options.map((option) => option.value).join(", ")}
            </Typography>
          </div>
          <Button variant="contained" onClick={handleOpenDailogCost}>
            เพิ่มข้อมูลซื้อ-ขาย
          </Button>
        </div>
        <div style={{ display: "flex", gap: "16px", marginBottom: "24px" }}>
          <StyledCardData>
            <Typography variant="h6" color={"#ffffff"}>
              {`ซื้อทั้งหมด: ${utils.numberWithCommas(buySellData.totalBuy)}`}
            </Typography>
          </StyledCardData>
          <StyledCardData>
            <Typography variant="h6" color={"#ffffff"}>
              {`ขายทั้งหมด: ${utils.numberWithCommas(buySellData.totalSell)}`}
            </Typography>
          </StyledCardData>
          <StyledCardData>
            <Typography variant="h6" color={"#ffffff"}>
              {`จำนวนคงเหลือ: ${utils.numberWithCommas(buySellData.balanceQuantity)}`}
            </Typography>
          </StyledCardData>
        </div>
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">วันที่</StyledTableCell>
                <StyledTableCell align="center">ประเภท</StyledTableCell>
                <StyledTableCell align="right">ราคา</StyledTableCell>
                <StyledTableCell align="center">VAT</StyledTableCell>
                <StyledTableCell align="right">จำนวน</StyledTableCell>
                <StyledTableCell align="right">มูลค่ารวม</StyledTableCell>
                {/* <StyledTableCell align="right">จำนวนคงเหลือ</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.costs.map((item, index) => (
                <TableRow key={item.idUniRewardCost}>
                  <StyledTableCell align="center">
                    {dayjs(item.transactionDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.type === "sell" ? (
                      <Chip variant="outlined" color="error" label={"Sell"} />
                    ) : (
                      <Chip variant="outlined" color="success" label={"Buy"} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {utils.numberWithCommas(item.price)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.vat ? `${item.vat}%` : "ไม่มี"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      color: item.type === "sell" ? "#d32f2f" : "#2e7d32",
                    }}
                  >
                    {utils.numberWithCommas(item.quantity)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {utils.numberWithCommas(
                      Number(
                        item.price * item.quantity +
                          (item.price * item.quantity * (item.vat / 100))
                      ).toFixed(2)
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="right">
                    {data.costs.length - 1 !== index
                      ? utils.numberWithCommas(data.costs[index - 1].quantity - item.quantity)
                      : utils.numberWithCommas(item.quantity)
                    }
                  </StyledTableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {openDailogCost && (
          <DialogCost 
            open={openDailogCost}
            handleClose={handleCloseDailogCost} 
            idUniRewardItem={id} 
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default UniRewardTransaction;
