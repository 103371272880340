import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const StyledCard = styled(Card)({
  height: 240,
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.25,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
});

const StyledCardMedia = styled(CardMedia)({
  position: "absolute",
  height: "100%",
  width: "100%",
  padding: 8,
});

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: "relative",
  color: "#ffffff",
  textAlign: "center",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  height: "100%",
}));

const AbsoluteCard = (props) => {
  const { value, tagType } = props;

  return (
    <StyledCard>
      <CardActionArea style={{ height: "100%" }}>
        <StyledCardMedia
          component="img"
          image={value.image}
        />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <StyledCardContent>
          <Typography
            variant="h5"
            style={{
              // display: "flex",
              // justifyContent: "center",
              color: "inherit",
              fontWeight: 700,
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
            }}
          >
            {value.name}
          </Typography>
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default AbsoluteCard;
