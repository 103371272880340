import React, { useRef, useEffect, useState } from "react";
import { useMapsLibrary, useMap } from "@vis.gl/react-google-maps";

export const PlaceAutocompleteClassic = ({ onPlaceSelect }) => {
  const map = useMap();
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  const onSetPlace = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    map.panTo({ lat, lng });
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
      onSetPlace(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input 
        ref={inputRef} 
        style={{ height: "40px", fontSize: "16px" }} 
        placeholder="ค้นหาสถานที่" 
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      />
    </div>
  );
};
