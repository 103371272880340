import {
  USERPROFILE_FETCHING,
  USERPROFILE_FAILED,
  USERPROFILE_SUCCESS,
  COACH_PROFILE_FETCHING,
  COACH_PROFILE_FAILED,
  COACH_PROFILE_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  USER_COIN_FETCHING,
  USER_COIN_FAILED,
  USER_COIN_SUCCESS,
  USER_COIN_QUOTA_FETCHING,
  USER_COIN_QUOTA_FAILED,
  USER_COIN_QUOTA_SUCCESS,
} from "./types";

import UserService from "../services/user.service";

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USERPROFILE_FETCHING,
    });
    const res = await UserService.getUserProfile();
    if (res) {
      dispatch({
        type: USERPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERPROFILE_FAILED,
    });
  }
};

export const getCoachProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_PROFILE_FETCHING,
    });
    const res = await UserService.getUserProfile(id);
    if (res) {
      dispatch({
        type: COACH_PROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_PROFILE_FAILED,
    });
    console.log(err);
  }
};

export const getAllEmployees = (mode, search) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await UserService.getAllEmployees(mode, search);
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
  }
};

// export const getUserCoin = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: USER_COIN_FETCHING,
//     });
//     const res = await UserService.getUserCoin(id);
//     if (res) {
//       dispatch({
//         type: USER_COIN_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: USER_COIN_FAILED,
//     });
//     console.log(err);
//   }
// };

export const getAllUserCoin = (query) => async (dispatch) => {
  try {
    dispatch({
      type: USER_COIN_FETCHING,
    });
    const res = await UserService.getAllUserCoin(query);
    if (res) {
      dispatch({
        type: USER_COIN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_COIN_FAILED,
    });
  }
};

export const getAllCoinQuota = (query) => async (dispatch) => {
  try {
    dispatch({
      type: USER_COIN_QUOTA_FETCHING,
    });
    const res = await UserService.getAllCoinQuota(query);
    if (res) {
      dispatch({
        type: USER_COIN_QUOTA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_COIN_QUOTA_FAILED,
    });
  }
};

export const updateSendHeartQuota = (type, formData) => async (dispatch) => {
  try {
    const res = await UserService.updateSendHeartQuota(type, formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: USER_COIN_QUOTA_FAILED,
    });
  }
};

export const updateMissionCoinQuota = (type, formData) => async (dispatch) => {
  try {
    const res = await UserService.updateMissionCoinQuota(type, formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: USER_COIN_QUOTA_FAILED,
    });
  }
};


