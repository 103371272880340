import coachReviewService from "../services/coach-review.service";
import {
  COACH_REVIEW_FETCHING,
  COACH_REVIEW_FAILED,
  COACH_REVIEW_SUCCESS,
  COACH_REVIEW_ITEM_FETCHING,
  COACH_REVIEW_ITEM_FAILED,
  COACH_REVIEW_ITEM_SUCCESS,
} from "./types";

export const getCoachReviewByIdCoach = (idBooking) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_REVIEW_FETCHING,
    });
    const res = await coachReviewService.getCoachReviewByIdCoach(idBooking);
    if (res) {
      dispatch({
        type: COACH_REVIEW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_REVIEW_FAILED,
    });
    console.log(err);
  }
};

export const getCoachReviewByIdBooking = (idBooking) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_REVIEW_ITEM_FETCHING,
    });
    const res = await coachReviewService.getCoachReviewByIdBooking(idBooking);
    if (res) {
      dispatch({
        type: COACH_REVIEW_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_REVIEW_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const addCoachReview = (formData) => async (dispatch) => {
  try {
    const res = await coachReviewService.addCoachReview(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_REVIEW_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const deleteCoachReview = (idBooking) => async (dispatch) => {
  try {
    const res = await coachReviewService.deleteCoachReview(idBooking);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: COACH_REVIEW_ITEM_FAILED,
    });
    console.log(err);
  }
};
