import React from "react";
import * as Yup from "yup";

import {
  Grid,
  Typography,
  Button,
  Paper,
  styled,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import UploadFile from "../../shared/uploadFile/uploadFile";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAnnouncement } from "../../../../actions/announcement";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  marginTop: 60,
  minWidth: 400,
})

export default function CreateAnnouncement() {
  const dispatch = useDispatch()
  // const history = useHistory()
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/admin/announcement')
  };

  const dataAnnouncement = {
    topic: '',
    file: '',
    startDate: dayjs(),
    endDate: dayjs().add(1, 'day'),
    coin: 0,
  };

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Please enter Topic"),
    startDate: Yup.date().min(dayjs().startOf('day'), 'Start date must be later than current date'),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(dayjs(startDate).add(1, "day"), 'End date must be later than start date');
    }),
    coin: Yup.number().min(1).integer().required("Please enter number of coin"),
  });

  const onSubmit = async (values) => {
    const formData = new FormData()
    for(const key in values) {
      formData.append(key, values[key])
    }
    const res = await dispatch(addAnnouncement(formData))
    if(res) {
      handleOpen()
    }
  }

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: dataAnnouncement,
    resolver: yupResolver(validationSchema),
  })

  return (
    <StyledRoot className={'page'}>
      <Typography variant="h4" align="center">
        สร้างประกาศ
      </Typography>
      <Paper 
        style={{ 
          padding : 24,
          margin: "auto",
          marginTop: 32,
          maxWidth: 800
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="topic"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    name="topic"
                    label="หัวข้อ"
                    error={errors.topic}
                    helperText={errors.topic?.message}
                  />
                )}
              />
            </Grid>  
            <Grid item xs={12}>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <UploadFile
                    {...field}
                    acceptFileType={"image/jpeg, image/png, application/pdf"}
                    acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf"}
                    name={"ไฟล์รายละเอียด"}
                    multiple={false}
                  />
                )} 
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    fullWidth
                    label="วันเริ่มประกาศ"
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>  
            <Grid item xs={12} sm={4}>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="วันสิ้นสุดประกาศ"
                    minDate={dayjs(watch("startDate")).add(1, 'day')}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid> 
            <Grid item xs={12} sm={4}>
              <Controller
                name="coin"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    label="Number of coin"
                    error={errors.coin}
                    helperText={errors.coin?.message}
                  />
                )}
              />
            </Grid>  
          </Grid>
          <div style={{ textAlign: 'right', marginTop: 16 }}>
            <Button
              type='submit'
              variant="contained"
              color="primary"
            >
              ยืนยัน
            </Button>
          </div>
        </form>
      </Paper>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"สร้างประกาศสำเร็จ"}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </StyledRoot>
  );
}
