import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HeaderPage from "../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../shared/dividerHeader";
import TipTap from "../../../shared/tiptapEditor";
import SwitchInput from "../../../shared/inputForm/switchInput";
import UpdateRewardImage from "../../../shared/uploadReward/updateRewardImage";
import { useDispatch, useSelector } from "react-redux";
import { updateUniReward } from "../../../../../actions/uniReward";
import { useNavigate, useParams } from "react-router-dom";
import VariationField from "./variationFiled";

import { DevTool } from "@hookform/devtools";
import TableExistVariation from "./tableExistVariation";
import { useRewardType } from "../../../../../quries/reward";
import InputField from "../../../shared/inputForm/inputField";
import LoadingIcon from "../../../shared/general/loadingIcon";
import { useUniReward, useUpdateUniReward } from "../../../../../quries/uniReward";
import { toast } from "sonner";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  borderRadius: 8,
});

const defaultValues = {
  name: "",
  detail: "",
  idRewardType: "",
  price: 0,
  quantity: 0,
  sku: "",
  images: [null, null, null, null],
  mainImage: { image: '' },
  // openVariation: false,
  // addOptionImage: false,
  // variation: [{ name: "", options: [{ name: "", imageURL: null }] }],
  // attributes: [{ option1: "", option2: "", price: "", quantity: "", sku: "" }],
};

function EditUniReward() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rewardType = useRewardType();
  const { data: reward, isPending } = useUniReward({ idUniReward: id })
  const { mutate } = useUpdateUniReward({ idUniReward: id });
  // const { result: reward } = useSelector((state) => state.reward);

  yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required(),
    idRewardType: yup.number().required(),
    mainImage: yup
      .mixed()
      .test(
        "required",
        "โปรดเพิ่มรูปภาพหลัก",
        (value) => value?.imageURL
      ),
    images: yup.array(),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const images = watch("images");
  console.log(images)

  const handleEditUniReward = async (data) => {
    data.mainImage = data.mainImage instanceof File ? data.mainImage : null
    
    const formData = new FormData();
    for (const property in data) {
      if (property === "images") {
        data[property].forEach((file) => formData.append(property, file));
        formData.append('imagesReward', JSON.stringify(data[property]));
      } else if (Array.isArray(data[property])) {
        formData.append(property, JSON.stringify(data[property]));
      } else {
        formData.append(property, data[property]);
      }
    }

    mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => navigate(`/uni-team/reward/detail/${id}`),
    })

    // delete data.items
    // const formData = new FormData();
    // for (const property in data) {
    //   if (property === "images") {
    //     data[property]
    //       .filter((item) => item instanceof File)
    //       .forEach((file) => formData.append(property, file));
    //     formData.append('imagesReward', JSON.stringify(data[property]));
    //   } else if (Array.isArray(data[property])) {
    //     formData.append(property, JSON.stringify(data[property]));
    //   } else {
    //     formData.append(property, data[property]);
    //   }
    // }

    // data["variation"][0].option
    //   .filter((item) => item.image instanceof File)
    //   .forEach((file) => formData.append("variationImages", file.image));

    // // for (var pair of formData.entries()) {
    // //   console.log(pair[0] + ", " + pair[1]);
    // // }

    // // const res = await dispatch(updateUniReward(formData));
    // // if (res) {
    // //   navigate(`/uni-team/reward/detail/${id}`);
    // // }
  };

  useEffect(() => {
    if (reward) {
      reset({
        name: reward.name,
        idRewardType: reward.idRewardType,
        detail: reward.detail,
        mainImage: { imageURL: reward.imageURL },
        images: [
          ...reward.images,
          ...Array(4 - reward.images.length).map(item => ({ imageURL: null })),
        ],
        // openVariation: reward?.options?.length > 0 ? true : false,
        // variation: reward.options,
        // attributes: reward.items,
      });
    }
  }, [reward]);

  // useEffect(() => {
  //   dispatch(getUniReward(id));
  // }, []);

  if (isPending) {
    return <LoadingIcon />
  }

  console.log(errors)

  return (
    <StyledRoot className={"page"}>
      <DevTool control={control} />
      <HeaderPage textLabel={"แก้ไขรายละเอียดสินค้า"} />
      <StyledDividerHeader />
      {reward && rewardType.data && (
        <Container maxWidth="lg">
          <Alert severity="warning" style={{ marginBottom: 16 }}>
            <AlertTitle>คำเตือน</AlertTitle>
            การแก้ไขข้อมูลสินค้าจะส่งผลเปลี่ยนแปลงต่อข้อมูลสินค้าใน
            <strong>ประวัติการซื้อ</strong>
          </Alert>
          <form onSubmit={handleSubmit(handleEditUniReward)}>
            <Stack spacing={2}>
              <StyledCard elevation={4}>
                <CardHeader title="ข้อมูลสินค้า" />
                <CardContent>
                  <Grid container spacing={2} marginTop={0}>
                    <Grid item xs={12} sm={8}>
                      <InputField
                        control={control}
                        name="name"
                        label="ชื่อสินค้า"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="idRewardType"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <InputLabel>ประเภทของรางวัล</InputLabel>
                            <Select
                              {...field}
                              label="ประเภทของรางวัล"
                              error={Boolean(fieldState.error)}
                            >
                              {rewardType.data.map((item) => (
                                <MenuItem value={item.idRewardType}>
                                  {item.rewardType}
                                </MenuItem>
                              ))}
                            </Select>
                            {Boolean(fieldState.error) && (
                              <FormHelperText error>
                                {fieldState?.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>รายละเอียด</InputLabel>
                      <Controller
                        name="detail"
                        control={control}
                        render={({ field }) => (
                          <TipTap
                            detail={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Controller
                        name="sku"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="SKU"
                            fullWidth
                            error={!!errors.sku}
                            helperText={errors.sku?.message}
                          />
                        )}
                      />
                    </Grid> */}
                  </Grid>
                </CardContent>
              </StyledCard>
              <StyledCard elevation={4}>
                <CardHeader title={"รูปภาพสินค้า"} />
                <CardContent>
                  <Typography>{"ขนาดไฟล์ภาพต้องไม่เกิน 500 KB"}</Typography>
                  <ImageList cols={5}>
                  <Grid item xs={2}>
                    <Controller
                      name={'mainImage'}
                      control={control}
                      render={({ field }) => (
                        <UpdateRewardImage
                          {...field}
                          acceptFileType={"image/jpeg, image/png, image/webp"}
                          error={errors.mainImage}
                          title={"รูปภาพหลัก"}
                          id={"idUniReward"}
                        />
                      )}
                    />
                  </Grid>
                  {images &&
                    images.map((field, index) => (
                      <Grid item xs={2} key={index}>
                        <Controller
                          name={`images.${index}`}
                          control={control}
                          render={({ field }) => (
                            <UpdateRewardImage
                              {...field}
                              acceptFileType={"image/jpeg, image/png, image/webp"}
                              error={errors.images}
                              title={ "รูปภาพเสริม"}
                              id={"idUniRewardImage"}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  </ImageList>
                </CardContent>
              </StyledCard>
              <StyledCard>
                {/* <CardHeader
                  title={"ข้อมูลการขาย *(หากปิดจะทำการลบข้อมูลตัวเลือกของสินค้า)"}
                  action={
                    <SwitchInput
                      name="openVariation"
                      control={control}
                      label={"เพิ่มตัวเลือกสินค้า"}
                    />
                  }
                /> */}
                {/* {watch("openVariation") && (
                  <CardContent>
                    <Typography gutterBottom>
                      ตัวเลือกสินค้าที่มีอยู่แล้ว
                    </Typography>
                    <TableExistVariation items={reward.items} />
                    <VariationField {...{ control, errors, items: reward.items }} />
                  </CardContent>
                )} */}
              </StyledCard>
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                <Button type="button" variant="outlined">
                  ยกเลิก
                </Button>
                <Button type="submit" variant="contained">
                  บันทึกการแก้ไข
                </Button>
              </Stack>
            </Stack>
          </form>
        </Container>
      )}
    </StyledRoot>
  );
}

export default EditUniReward;
