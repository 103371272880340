import React, { useEffect } from "react";
import { Container, Grid, Typography, styled ,Box,Card} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getCoacheeByIdCoach } from "../../../../actions/coach";
import loading from "../../assets/loading2.gif";
import CardCoachee from "./component/card_coachee";
const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#f1f4f9",

});
const StyledCard = styled(Card)({
  padding: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});
const ListCoachee = () => {
  const dispatch = useDispatch();
  const { result: coachee ,isFetching:isLoadingCoachee} = useSelector((state) => state.coachee);

  useEffect(() => {
    dispatch(getCoacheeByIdCoach());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{margin:"24px 0"}}>
          รายชื่อผู้เรียน</Typography>
          {
            isLoadingCoachee ?(
              <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
              }}
            >
              <img width="120" alt="loading" src={loading} />
            </Box>
            ):(
              <StyledCard>
                {
                   coachee && coachee.length == 0? (
                    <Box
                    style={{
                      minHeight: 200,
                      position: "sticky",
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>ไม่มีข้อมูล</Typography>
                  </Box>
                  ):(
                    <Grid container spacing={2}>
                    {coachee && coachee.map((val)=>(
                      <Grid item xs={12} sm={6} md={4} xl={4} key={val.idEmployees}>
                        <CardCoachee value={val}  />
                      </Grid>
                    ))}
      
                  </Grid>
                  )
                }
              </StyledCard>
            )
          }
      </Container>
    </StyledRoot>
  );
};

export default ListCoachee;

/*
{coachee && coachee.length === 0 ? (
            <Typography variant="h5" align="center">No data</Typography>
          ) : (
            <Grid container spacing={1} direction="row">
              {coachee && coachee.map((val) => (
                <Grid item xs={12} sm={6} md={4} key={val.idEmployees}>
                  <CardWithInsert
                    value={val}
                    event={[
                      `Coaching-Time: ${val.coachTime}`,
                      `Tel: ${val.telephoneMobile}`,
                    ]}
                    to={`/coaching/profile/${val.idEmployees}`}
                  />
                </Grid>
              ))}
            </Grid>
          )}
*/