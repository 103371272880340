import React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import { MainCoin } from "../typeCoin";

const StyledCard = styled(Card)({
  margin: 24,
  borderRadius: 8,
});

const StyledCardMedia = styled(CardMedia)({
  position: "relative",
  height: "100%",
  width: "100%",
  paddingTop: "100%",
});

const StyledGrid = styled(Grid)({
  marginTop: 2,
  marginBottom: 2,
});

const NormalCard = (props) => {
  const { value, tagType } = props;

  return (
    <StyledCard>
      {/* <CardActionArea component={Link} to={`ViewProfile/${value.idEmployees}`}> */}
      <CardActionArea component={Link} to={`/coaching/profile/10068989`}>
        <StyledCardMedia
          image={`${process.env.REACT_APP_API_URL}image/profile/${value.image}`}
          // image={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
          title="image Nut"
        />
        <CardContent>
          <Grid container>
            <StyledGrid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "400",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {`${value.firstname_TH} ${value.lastname_TH}`}
              </Typography>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: "400",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {`${value.positionName}`}
              </Typography>
            </StyledGrid>
            <Grid item xs={12} marginTop={2}>
              <Stack direction={"row"} spacing={1}>
                <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
                <Typography variant="subtitle2">{`${value.rating}`}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="subtitle2" style={{ fontWeight: "400" }}>
                  ค่าสอน / ชั่วโมง
                </Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Typography variant="subtitle2" style={{ fontWeight: "400" }}>
                    {value.price}
                  </Typography>
                  <MainCoin width={24} />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default NormalCard;
