import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import HeaderPage from "../../../shared/header/headerPage";
import { useDispatch, useSelector } from "react-redux";
import {
  addPoll,
  getAllPolls,
  updatePollStatus,
} from "../../../../../actions/poll";
import { getAllEmployees } from "../../../../../actions/user";

import BgPage from "./assets/pexels-gradienta-7130536.jpg";
import ProgressChart from "./ProgressChart";
import * as yup from "yup";
import DateFilter from "../../../shared/dateFilter";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../shared/inputForm/inputField";

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
});

const WrapPoll = styled("div")({
  display: "block",
  margin: "0px auto",
  width: "100%",
  ["@media only screen and (min-width:1200px)"]: {
    width: "60%",
  },
});

const StyledCard = styled(Card)({
  borderRadius: 16,
  marginBottom: 40,
  width: "100%",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapStatus = styled('div')({
  display: "flex",
  alignItems: "center",
});

const AddMissionButton = styled(Button)({
  marginRight: 8,
  fontSize: 20,
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  marginTop: 60,
  minWidth: 350,
  minHeight: "calc(100vh - 60px)",
  backgroundImage: `url(${BgPage})`,
});

const DialogDelete = (props) => {
  const dispatch = useDispatch();

  const handleUpdatePollStatus = async () => {
    const res = await dispatch(
      updatePollStatus({
        idPoll: props.idPoll,
        status: "close",
      })
    );
    if (res) {
      dispatch(getAllPolls());
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-delete-group-name"
      aria-describedby="alert-dialog-description-delete-group-name"
    >
      <DialogTitle id="alert-dialog-title-delete-group-name">
        {"ลบ"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          คุณแน่ใจว่าจะปิดโหวต?{" "}
          <span style={{ color: "#f44336" }}>{props.displayClose}</span>
        </DialogContentText>
        <span style={{ color: "#f44336" }}>{props.idPollSelected}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdatePollStatus} color="primary">
          {" "}
          ปิดโหวต{" "}
        </Button>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Poll = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialogClose, setOpenDialogClose] = useState(false);
  const [idPollSelected, setIdPollSelected] = useState(null);
  const [displayClose, setDisplayClose] = useState(null);
  const [dateFiiter, setDateFiiter] = useState(null);

  const dispatch = useDispatch();
  const { result: poll } = useSelector((state) => state.poll);
  const { result: person } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAllPolls());
    dispatch(getAllEmployees());
  }, []);

  const validationSchema = yup.object().shape({
    poll: yup.string().required("Poll name is required"),
    questions: yup
      .array()
      .of(
        yup.object().shape({
          question: yup
            .string()
            .trim()
            .min(1, "option is required")
            .required("Required"),
        })
      )
      .required("Must have option")
      .min(2, "Minimum of 2 option"),
    person: yup.array().min(1).required("Person is required"),
    endDate: yup
      .date()
      .min(
        new Date(new Date().setHours(0, 0, 0, 0) + 86400000),
        "End date has to be after than current date"
      ),
  });

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      poll: "",
      questions: [{ question: "" }, { question: "" }],
      mode: "select",
      person: [],
      endDate: dayjs().add(1, 'day'),
    },
    resolver: yupResolver(validationSchema)
  })

  const { fields, append, remove } = useFieldArray({ control, name: "questions" })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDialogClose = () => {
    setOpenDialogClose(false);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleAddPoll = async (values) => {
    const voterId = values.person.map((item) => item.idEmployees);
    const res = await dispatch(
      addPoll({
        ...values,
        person: voterId,
        status: "open",
      })
    );
    if (res) {
      dispatch(getAllPolls());
      setOpen(false);
    }
  };

  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex"}}>
        <HeaderPage textLabel={"Poll Admin"} color={"#000000"} />
      </div>
      <StyledDivider />
      <WrapFilter>
        <AddMissionButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          New Poll
        </AddMissionButton>
        <FilterIconButton aria-label="filter" onClick={toggleDrawer} size="large" >
          <FilterListIcon fontSize="large" />
        </FilterIconButton>
      </WrapFilter>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <DateFilter setFilterDate={setDateFiiter}/>
      </Drawer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Poll</DialogTitle>
        <form onSubmit={handleSubmit(handleAddPoll)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputField
                  control={control}
                  name={"poll"}
                  fullWidth
                  label="Poll"
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  {fields.map((item, index) => (
                    <Stack key={item.id} direction={"row"}>
                      <Controller 
                        control={control}
                        name={`questions.${index}.question`}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={`Option ${index + 1}`}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                          />
                        )}
                      />
                      <IconButton
                        aria-label="delete"
                        disabled={fields.length === 2}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  ))}
                </Stack>
                <Button
                  disabled={fields.length > 5}
                  onClick={() => append({ question: "" })}
                >
                Add Option
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="mode"
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Voter</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        onChange={(event) => {
                          if (event.target.value === "all") {
                            setValue("person", person);
                          } else {
                            setValue("person", []);
                          }
                          field.onChange(event.target.value)
                        }}
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="All"
                        />
                        <FormControlLabel
                          value="select"
                          control={<Radio />}
                          label="Select"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              {watch("mode") === "all" ? null : (
                <Grid item xs={12}>
                  <Controller 
                    control={control}
                    name="person"
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={person}
                        onChange={(event, value) => field.onChange(value)}
                        isOptionEqualToValue={(option, value) =>
                          option.idEmployees === value.idEmployees
                        }
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            {...params}
                            label="พนักงาน"
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error && fieldState.error.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Controller 
                  control={control}
                  name="endDate"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      label="End date"
                      minDate={dayjs().add(1, 'day')}
                      slotProps={{
                        textField: {
                          error: Boolean(fieldState.error),
                          helperText: fieldState?.error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="success">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <WrapPoll>
        {poll &&
          poll
            .filter((item) =>
              dateFiiter
                ? dayjs(item.endDate).isSame(dateFiiter, "month")
                : item
            )
            .map((value) => {
              return (
                <StyledCard variant="outlined" key={value.idPoll}>
                  <CardContent>
                    <Typography variant="h4" align="center" gutterBottom>
                      {value.poll}
                    </Typography>
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={12}>
                        <ProgressChart
                          chartData={value.questionList}
                          sumScore={value.voted}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack 
                          direction="row" 
                          alignItems={"center"}
                          justifyContent={"space-around"}
                        >
                          <div>
                            <Typography color="textSecondary">
                              {`End : ${dayjs(value.endDate).format("D MMM YYYY ")}`}
                            </Typography>
                            {dayjs().isAfter(dayjs(value.endDate)) && (
                              <Chip label="สิ้นสุดวันโหวตแล้ว" color="error" size="small" />
                            )}
                          </div>
                          <WrapStatus>
                            <Typography color="textSecondary" marginRight={1}>
                              Status :
                            </Typography>
                            {value.status === "open" ? (
                              <Chip label="open" color="success" />
                            ) : (
                              <Chip label="close" color="error" />
                              )}
                          </WrapStatus>
                          <Chip
                            color="secondary"
                            label={`Vote ${(value.voted).toFixed(2)}%`}
                            icon={<HowToVoteIcon />}
                          />
                          {value.status === "open" && 
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setOpenDialogClose(true);
                                setIdPollSelected(value.idPoll);
                                setDisplayClose(value.poll);
                              }}
                            >
                              Close
                            </Button>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledCard>
              );
            })}
      </WrapPoll>
      <DialogDelete
        idPoll={idPollSelected}
        displayClose={displayClose}
        open={openDialogClose}
        onClose={handleCloseDialogClose}
      />
    </StyledRoot>
  );
};

export default Poll;
