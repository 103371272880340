import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useSelector } from "react-redux";
import { SpecialIcon } from "../../shared/typeCoin";
import InputField from "../../shared/inputForm/inputField";
import { useCoinBudget, useCoinType, useUpdateCoinBudget } from "../../../../quries/coin";
import { toast } from "sonner";
import utils from "../../../../utils";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: "#ffffff",
});

const schema = yup.object().shape({
  coinType: yup.object().nullable().required("Required"),
  budget: yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer()
    .min(1)
    .nullable()
    .required("Required"),
  totalCoin: yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .integer()
    .min(1)
    .nullable()
    .required("Required"),
})

const BudgetCoin = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { data, isPending } = useCoinBudget({ idCompany: userProfile?.idCompany });
  const updateCoinBudget = useUpdateCoinBudget({ idCompany: userProfile?.idCompany });
  const coinType = useCoinType();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      coinType: null,
      budget: '',
      totalCoin: '',
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (values) => {
    updateCoinBudget.mutate(
      {
        ...values,
        idCoinType: values.coinType.idCoinType,
      },
      {
        onSuccess: () => toast.success("สำเร็จ"),
        onError: () => toast.error("เกิดข้อผิดพลาด"),
        onSettled: () => handleClose()
      }
    );
  };

  if (isPending || coinType.isPending) {
    return <CircularProgress />;
  }

  const totalBudget = data.reduce((acc, cur) => {
    return acc + cur.budget
  }, 0)
    

  return (
    <div className={"page dashboard-page"}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color={"#ffffff"}>
          งบประมาณเหรียญรางวัล
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<SettingsIcon />} 
          onClick={handleClickOpen}
        >
          ตั้งค่า
        </Button>
      </div>
      <StyledDivider />
      <Container>
        <TableContainer 
          component={Paper} 
          sx={{ 
            maxHeight: 600, 
            backgroundColor: "#000000", 
            border: "1px solid #2e2e2e", 
          }}
        >
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                  เหรียญ
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                  Budget
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                  Value per token
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                  Total token
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#000000", color: "#ffffff", fontSize: "1rem", borderColor: "#2e2e2e" }}>
                  Total in system
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((coin) => (
                <TableRow
                  key={coin.idCoinType}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ borderColor: "#2e2e2e" }}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <SpecialIcon idCoinType={coin.idCoinType} width={40} />
                      <Typography color={"#ffffff"}>{coin.coinName}</Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {utils.numberWithCommas(coin.budget || 0)}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {coin.totalCoin > 0
                      ? utils.numberWithCommas(coin.budget / coin.totalCoin)
                      : 0}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {utils.numberWithCommas(coin.totalCoin || 0)}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}>
                    {utils.numberWithCommas(coin.coinInSystem || 0)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="right" colSpan={5}>
                  <Typography variant="h6" color={"#ffffff"}>
                    {`Total budget: ${utils.numberWithCommas(totalBudget)}`}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {open && (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>ตั้งค่าเหรียญ</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
                <Controller
                  control={control}
                  name={"coinType"}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      sx={{ minWidth: 200 }}
                      options={coinType.data}
                      onChange={(event, value) => field.onChange(value)}
                      isOptionEqualToValue={(option, value) => option.idCoinType === value.idCoinType}
                      getOptionLabel={(option) => option.coinName}
                      renderOption={(props, option) => {
                        return (
                          <Box {...props} component="li" sx={{ display: "flex", gap: "1rem" }}>
                            <SpecialIcon idCoinType={option.idCoinType} width={40} />
                            <Typography>{option.coinName}</Typography>
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState?.error?.message}
                          label="Coin"
                        />
                      )}
                    />
                  )}
                />
                <InputField
                  control={control} 
                  name={"budget"}
                  label={"Budget"}
                />
                <InputField 
                  control={control}
                  name={"totalCoin"}
                  label={"จำนวนเหรียญ"}
                />
              </div>
              <Typography variant="h6">
                {`มูลค่าต่อเหรียญ: ${
                  watch("totalCoin")
                    ? utils.numberWithCommas(watch("budget") / watch("totalCoin"))
                    : 0
                }`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                ยกเลิก
              </Button>
              <Button type="submit" variant="contained">
                ยืนยัน
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
};

export default BudgetCoin;
