import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { getManagerHeartLeaderboard } from "../../../../actions/leaderboard";

const DivRoot = styled("div")({
  width: "100%",
  display: "flex",
  padding: 15,
  height: 500,
  backgroundColor: "#ddd",
  justifyContent: "space-between",
  alignItems: "flex-end",
  overflow: "auto",
});

const StyledRankManager = styled(Paper)({
  backgroundColor: "#ff3d00",
  padding: 7,
  borderTopLeftRadius: 35,
  borderTopRightRadius: 35,
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "0 5px",
});

const LargeImage = styled(Avatar)({
  width: 55,
  height: 55,
});

const ManagerLeaderBoard = (props) => {
  const dispatch = useDispatch();
  const { result: managerHeartLeaderboard } = useSelector((state) => state.managerHeartLeaderboard);

  useEffect(() => {
    dispatch(getManagerHeartLeaderboard());
  }, []);

  return (
    <DivRoot>
      {managerHeartLeaderboard &&
        managerHeartLeaderboard.map((item, key) => (
          <StyledRankManager
            key={key}
            elevation={3}
            style={{ 
              height: `${(item.sumHeart / managerHeartLeaderboard[0].sumHeart * 76) + 24}%`,
              width: '80px', 
            }}
          >
            <LargeImage alt="profile" src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`} />
            <div>
              <Typography fontSize={12} color={"#FFFFFF"} width={'72px'} noWrap>
                {item.firstname_TH}
              </Typography>
              <Typography fontSize={12} color={"#FFFFFF"}>
                {item.sumHeart}
              </Typography>
            </div>
          </StyledRankManager>
        ))}
    </DivRoot>
  );
};

export default ManagerLeaderBoard;
