import React, { useState, useEffect, Fragment, forwardRef, useRef, useImperativeHandle } from "react";
import { styled } from '@mui/material/styles';
import {
  Typography,
  Paper,
  Divider,
  Button,
  IconButton,
  Chip,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PublicIcon from "@mui/icons-material/Public";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  getMorale, 
  updateMorale, 
  deleteMorale,
  addMoraleQuestion, 
  updateMoraleQuestion, 
  deleteMoraleQuestion,
  addMoraleParticipant,
} from "../../../../../actions/moraleQuestion";
import {
  addQuestion, 
  deleteQuestionTopic, 
  getAllQuestion, 
  getAllQuestionTopic, 
} from "../../../../../actions/questionTopic";
import { getAllEmployees } from "../../../../../actions/user";
import { getDepartment } from "../../../../../actions/department";

const WrapHeader = styled(Paper)({
  padding: 20,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .herder-text": {
    marginLeft: 10,
  },
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .herder-text": {
      marginLeft: 0,
      fontSize: 26,
      marginBottom: 16,
    },
  },
})

const StyledBtnPublic = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #1976d2",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#1976d2",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#1976d2",
    borderColor: "#1976d2",
  },
})

const StyledButton = styled(Button)({
  marginLeft: 8,
  color: "#FFF",
  backgroundColor: "#28a745",
  border: "1px solid #28a745",
  "&:hover": {
    border: "1px solid #28a745",
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "#28a745",
  },
})

const StyledBtnDelete = styled(IconButton)({
  marginLeft: 8,
  color: "#dc3545",
  "&:hover": {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#FFF",
  },
})

const StyledBtnDeleteGroup = styled(IconButton)({
  marginLeft: 8,
  color: "#dc3545",
  border: "1px solid #dc3545",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  "&:hover": {
    backgroundColor: "#dc3545",
    borderColor: "#dc3545",
    color: "#FFF",
  },
})

const StyledBtnEditGroup = styled(IconButton)({
  marginLeft: 8,
  border: "1px solid #ffc107",
  backgroundColor: "rgba(25, 118, 210, 0.04)",
  color: "#ffc107",
  "&:hover": {
    color: "#FFF",
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
  },
})

const StyledTextFieldNewQuestion = styled(TextField)({
  marginBottom: 20,
  marginTop: 8,
  width: "100%",
  "& .MuiInputBase-root": {
    lineHeight: 1.6,
  },
})

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 0,
})

const DialogOneTextField = (props) => {
  const questionTopic = props.questionTopic
  const displayTitle = () => {
    switch (props.mode) {
      case "add":
        return "เพิ่มหัวข้อใหม่";
      case "edit":
        return "แก้ไขหัวข้อ (จะแก้ไขในชุดคำถามอื่นด้วย)";
      default:
        return "แก้ไขชื่อ";
    }
  };

  const displayButton = () => {
    switch (props.mode) {
      case "add":
        return "บันทึกหัวข้อใหม่";
      case "edit":
        return "บันทึกแก้ไขหัวข้อ";
      default:
        return "บันทึกแก้ไข";
    }
  };
  const [topicName, setTopicName] = useState("")
  const dispatch = useDispatch()

  const handleChangeValueNewTopic = (event) => {
    setTopicName(event.target.value)
  }
  
  const handleClickAddTopic = async () => {
    if(props.mode === "editGroup") {
      const res = await dispatch(
        updateMorale({
          idMorale: parseInt(props.id),
          moraleName: topicName,
        })
      )
      if(res) {
        dispatch(getMorale(parseInt(props.id)));
      }
    } 
    // else if(props.mode === "edit") {
    //   const res = await dispatch(
    //     updateQuestionTopic({
    //       idQuestionTopic: props.idQuestionTopic,
    //       questionTopic: topicName,
    //     })
    //   );
    //   if(res) {
    //     dispatch(getMorale(parseInt(props.id)));
    //   }
    // }
    // else if(props.mode === "add") {
    //   const isTopicExist = questionTopic.some(
    //     (topic) => (topic.questionTopic).trim().toLowerCase() === topicName.trim().toLowerCase()
    //   );
    //   if(isTopicExist) {
    //     props.handleOpenSnackbar('มีหัวข้ออยู่แล้ว', 'error')
    //   } else if(topicName.trim().length === 0){
    //     props.handleOpenSnackbar('โปรดกรอกชื่อหัวข้อ', 'error')
    //   } else {
    //     const res =  await dispatch(
    //       addQuestionTopic({
    //         questionTopic: topicName,
    //       })
    //     );
    //     if(res) {
    //       dispatch(getAllQuestionTopic());
    //       props.handleOpenSnackbar('เพิ่มหัวข้อเรียบร้อย', 'success')
    //       setTopicName("");
    //     }
    //   }
    // }
    props.onClose()
  };

  return (
    <>
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title-new-topic"
        aria-describedby="alert-dialog-description-new-topic"
      >
        <DialogTitle id="alert-dialog-title-new-topic">
          {displayTitle()}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ width: "100%" }}
            id="new-topic"
            label=""
            variant="outlined"
            value={topicName}
            onChange={handleChangeValueNewTopic}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {" "}
            ยกเลิก{" "}
          </Button>
          <Button onClick={handleClickAddTopic} color="primary">
            {displayButton()}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogDelete = (props) => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const navigate = useNavigate();
  const handleDeleteQuestion = async () => {
    if(props.mode === "group") {
      const res = await dispatch(deleteMorale(parseInt(props.id)));
      if(res) {
        navigate("/admin/manage/morale-question");
      }
    } 
    else if(props.mode === "topic") {
      const res = await dispatch(
        deleteQuestionTopic(parseInt(props.id), props.idQuestionTopic))
      if(res) {
        dispatch(getMorale(parseInt(props.id)));
      } 
    }
    else if(props.mode === "question") {
      const res = await dispatch(deleteMoraleQuestion(props.idMoraleQuestion))
      if(res) {
        dispatch(getMorale(parseInt(props.id)));
      } 
    }
    props.onClose()
  }
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-delete-group-name"
      aria-describedby="alert-dialog-description-delete-group-name"
    >
      <DialogTitle id="alert-dialog-title-delete-group-name">
        {"ลบ"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          คุณแน่ใจว่าจะลบ?{" "}
          <span style={{ color: "#f44336" }}>{props.displayDelete}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
        <Button onClick={handleDeleteQuestion} color="primary">
          {" "}
          ลบ{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogQuestion = forwardRef((props, ref) => {
  const { topic, mode, listTopic, id, idQuestion } = props;
  const dispatch = useDispatch()
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [dataEdit, setDataEdit] = React.useState({
    question: "",
    questionType: "",
  });

  const updateTopic = (topic) => {
    setSelectedTopic(topic);
  };

  const updateDataEdit = (dataEdit) => {
    setDataEdit(dataEdit);
  };

  useImperativeHandle(ref, () => {
    return {
      updateTopic: updateTopic,
      updateDataEdit: updateDataEdit,
    };
  });

  const handleChangeValueQuestion = (event) => {
    const name = event.target.name;
    setDataEdit({
      ...dataEdit,
      [name]: event.target.value,
    });
  };
  
  const handleClickQuestion = async () => {
    if(selectedTopic > 0 && 
      dataEdit.question.trim().length > 0 &&
      dataEdit.questionType !== "") {
      if(mode === "add") {
        const res = await dispatch(
          addQuestion({
            ...dataEdit,
            idQuestionTopic: selectedTopic,
          })
        );
        if(res) {
          dispatch(getMorale(parseInt(props.id)));
          props.handleOpenSnackbar('เพิ่มคำถามแล้ว', 'success')
        }
      } else if(mode === "edit") {
        const res = await dispatch(
          updateMoraleQuestion({
            ...dataEdit,
            idMoraleQuestion: idQuestion,
          })
        );
        if(res) {
          dispatch(getMorale(parseInt(props.id)));
        }
      }
      props.onClose()
    } else {
      props.handleOpenSnackbar('โปรดกรอกข้อมูลให้ครบ', 'error')
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title-new-topic"
        aria-describedby="alert-dialog-description-new-topic"
      >
        <DialogTitle id="alert-dialog-title-new-topic">
          {mode === "add" ? "เพิ่มคำถามใหม่" : "แก้ไขคำถาม"}
        </DialogTitle>
        <DialogContent>
          {mode === "add" &&
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginBottom: 20, marginTop: 8 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                เลือกหัวข้อ
              </InputLabel>
              <Select
                disabled={topic != null && mode === "add"}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.topic == null ? selectedTopic : props.topic}
                label="เลือกหัวข้อ"
                onChange={(event) => updateTopic(event.target.value)}
              >
                {listTopic.slice(1).map((value) => {
                  return (
                    <MenuItem key={value.idQuestionTopic} value={value.idQuestionTopic}>
                      {value.questionTopic}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          } 
          <StyledTextFieldNewQuestion
            id="new-topic"
            name="question"
            label="คำถาม"
            variant="outlined"
            multiline
            rows={4}
            value={dataEdit.question}
            onChange={handleChangeValueQuestion}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">ประเภทคำตอบ</FormLabel>
            <RadioGroup
              row
              aria-label="questionType"
              name="questionType"
              value={dataEdit.questionType}
              onChange={handleChangeValueQuestion}
            >
              <FormControlLabel
                value="choice"
                control={<Radio />}
                label="ตัวเลือก"
              />
              <FormControlLabel
                value="text"
                control={<Radio />}
                label="ช่องว่าง"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {" "}
            ยกเลิก{" "}
          </Button>
          <Button onClick={handleClickQuestion} color="primary">
            {" "}
            {mode === "add" ? "บันทึกคำถามใหม่" : "บันทึกแก้ไขคำถาม"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const DialogSelectQuestion = (props) => {
  const { id, moraleQuestionItem } = props;
  const dispatch = useDispatch()
  const [selectedQuestion, setSelectedQuestion] = React.useState('');
  const [selectedTopic, setSelectedTopic] = React.useState('');
  const { result: questionList } = useSelector((state) => state.question);

  const updateTopic = (topic) => {
    setSelectedTopic(topic);
  };

  const updateQuestion = (question) => {
    setSelectedQuestion(question);
  };
  
  const handleSelectQuestion = async () => {
    if(selectedQuestion !== '') {
      const res = await dispatch(
        addMoraleQuestion({
          idQuestion: selectedQuestion,
          idMorale: parseInt(id),
        })
      );
      if(res) {
        dispatch(getMorale(parseInt(props.id)));
        dispatch(getAllQuestion())
        props.onClose()

      }
    } else {
      props.handleOpenSnackbar('โปรดเลือกคำถาม', 'error')
    }
  };

  const questionInMorale = moraleQuestionItem.questionTopic
    .map((topic) => topic.questionList)
    .flat()
    .map((value) => value.idQuestion);
  
  useEffect(() => {
    dispatch(getAllQuestion())
  }, [])

  return (
    <>
    {questionList && <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title-new-topic"
        aria-describedby="alert-dialog-description-new-topic"
      >
        <DialogTitle id="alert-dialog-title-new-topic">
          เลือกคำถาม
        </DialogTitle>
        <DialogContent>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginBottom: 20, marginTop: 8 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              เลือกหัวข้อ
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedTopic}
              label="เลือกหัวข้อ"
              onChange={(event) => {
                updateTopic(event.target.value)
                updateQuestion('')
              }}
            >
              {questionList.slice(1)
                .filter(item => item.questionList.length > 0)
                .map((value) => {
                  return (
                    <MenuItem key={value.idQuestionTopic} value={value.idQuestionTopic}>
                      {value.questionTopic}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginBottom: 20, marginTop: 8 }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              เลือกคำถาม
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              disabled={selectedTopic === ""}
              value={selectedQuestion}
              label="เลือกคำถาม"
              onChange={(event) => updateQuestion(event.target.value)}
            >
              {selectedTopic !== "" &&
                questionList
                  .find((item) => item.idQuestionTopic === selectedTopic)
                  .questionList.map((question, index) => (
                    <MenuItem 
                      key={index} 
                      value={question.idQuestion} 
                      disabled={questionInMorale.includes(question.idQuestion)}
                    >
                      <Typography marginRight={1}>
                        {question.question}
                      </Typography>
                      <Chip
                        variant={'outlined'}
                        label={question.questionType}  
                        color={question.questionType === 'choice' ? 'primary' : 'secondary'}
                      />
                    </MenuItem>
                  ))
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {" "}
            ยกเลิก{" "}
          </Button>
          <Button onClick={handleSelectQuestion} color="primary">
            {" "}
            เพิ่มคำถาม{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>}
  </>
  );
};

const MoraleDescriptions = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const ref = useRef(null);
  const [openPublic, setOpenPublic] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openOneTextField, setOpenOneTextField] = React.useState(false);
  const [openQuestion, setOpenQuestion] = React.useState(false);
  const [openSelectQuestion, setOpenSelectQuestion] = React.useState(false);
  const [selectedDepartment, setSelectedDepartment] = React.useState([]);
  const [selectedPerson, setSelectedPerson] = React.useState([]);
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [modeModal, setModeModal] = React.useState(null);
  const [displayDelete, setDisplayDelete] = React.useState(null);
  const fixedOptionsDepartment = [];
  const fixedOptionsPerson = [];
  const [valueTarget, setValueTarget] = React.useState("company");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [idQuestion, setIdQuestion] = useState(null)
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day'))
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const [modeSnackbar, setModeSnackbar] = useState("info")

  const handleClick = (topic) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedTopic(topic.idQuestionTopic);
    setDisplayDelete("หัวข้อ : " + topic.questionTopic);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeValueTarget = (event) => {
    setValueTarget(event.target.value);
  };

  const handleClickOpenPublic = () => {
    setOpenPublic(true);
  };

  const handleClosePublic = () => {
    setOpenPublic(false);
  };
  // const handleChangeStartDate = (date) => {
  //   setStartDate(dayjs(date).format("YYYY-MM-DD HH:mm"))
  // }

  // const handleChangeEndDate = (date) => {
  //   setEndDate(dayjs(date).format("YYYY-MM-DD HH:mm"))
  // }

  const handleClickOpenDelete = (mode) => {
    if (mode === "topic") {
      setModeModal(mode);
      setOpenDelete(true);
    } else if (mode === "group") {
      setModeModal(mode);
      setOpenDelete(true);
    } else {
      setModeModal(mode);
      setOpenDelete(true);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenQuestion = (mode) => {
    setModeModal(mode);
    setOpenQuestion(true);
  };

  const handleCloseQuestion = () => {
    setOpenQuestion(false);
    setSelectedTopic(null);
  };

  const handleClickOpenOneTextField = (mode) => {
    setModeModal(mode);
    setOpenOneTextField(true);
  };

  const handleCloseOneTextField = () => {
    setOpenOneTextField(false);
  };

  const handleClickOpenSelectQuestion = () => {
    setOpenSelectQuestion(true);
  };

  const handleCloseSelectQuestion = () => {
    setOpenSelectQuestion(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setModeSnackbar(mode)
    setDisplaySnackbar(value)
    setOpenSnackbar(true);
  };

  const dispatch = useDispatch();
  const { result: moraleQuestionItem } = useSelector((state) => state.moraleQuestionItem);
  const { result: department } = useSelector((state) => state.department);
  const { result: person } = useSelector((state) => state.users);
  const { result: questionTopic } = useSelector((state) => state.questionTopic);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (userProfile) {
      dispatch(getMorale(parseInt(id)));
      dispatch(getAllQuestionTopic(userProfile.idCompany))
      dispatch(getDepartment());
      dispatch(getAllEmployees());
    }
  }, []);

  const handleGoPublic = async () => {
    if(valueTarget === 'department' && selectedDepartment.length < 1) {
      handleOpenSnackbar("โปรดเลือกหน่วยงาน", 'error');
    } else if(valueTarget === 'person' && selectedPerson.length < 1) {
      handleOpenSnackbar("โปรดเลือกพนักงาน", 'error');
    } else {
      const idDepartmentList = selectedDepartment.map(item => item.idDepartment)
      const idEmployeesList = selectedPerson.map(item => item.idEmployees)
      const res = await dispatch(addMoraleParticipant(
        { 
          idMorale: id,
          startDate,
          endDate,
          valueTarget, 
          selectedDepartment: idDepartmentList, 
          selectedPerson: idEmployeesList,
        }
      ))
      if(res) {
        navigate("/admin/manage/morale-question");
      }
    }
  };

  return (
    <>
      {moraleQuestionItem && (
        <StyledRoot className={'page'}>
          <div style={{ display: "flex" }}>
            <WrapHeader>
              <Typography variant="h4" className={`herder-text`}>
                {moraleQuestionItem.moraleName}
              </Typography>
              <div>
                {moraleQuestionItem.moraleStatus === "draft" &&
                  moraleQuestionItem.questionTopic.length > 0 && (
                    <Tooltip title="เผยแพร่" aria-label="public-tooltip">
                      <StyledBtnPublic
                        onClick={handleClickOpenPublic}
                        size="small"
                        aria-label="public-tooltip"
                      >
                        <PublicIcon />
                      </StyledBtnPublic>
                    </Tooltip>
                  )}
                <Dialog
                  open={openPublic}
                  onClose={handleClosePublic}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"เผยแพร่"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction="row" spacing={2} paddingTop={2} paddingBottom={2}>
                      <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        // onChange={handleChangeStartDate}
                        minDate={dayjs()}
                      />
                      <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        // onChange={handleChangeEndDate}
                        minDate={dayjs(startDate).add(1, 'day')}
                        slotProps={{
                          textField: {
                            error: startDate >= endDate,
                            helperText: startDate >= endDate && "End date has to be after start date",
                          },
                        }}
                      />
                    </Stack>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">กลุ่มเป้าหมาย</FormLabel>
                      <RadioGroup
                        aria-label="valueTarget"
                        name="valueTarget"
                        value={valueTarget}
                        onChange={handleChangeValueTarget}
                        row
                      >
                        <FormControlLabel
                          value="company"
                          control={<Radio />}
                          label="ทั้งบริษัท"
                        />
                        <FormControlLabel
                          value="department"
                          control={<Radio />}
                          label="รายหน่วยงาน"
                        />
                        <FormControlLabel
                          value="person"
                          control={<Radio />}
                          label="รายบุคคล"
                        />
                      </RadioGroup>
                    </FormControl>
                    {valueTarget === "department" && (
                      <div>
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo-department"
                          value={selectedDepartment}
                          onChange={(event, newValue) => {
                            setSelectedDepartment([
                              ...fixedOptionsDepartment,
                              ...newValue.filter(
                                (option) =>
                                  fixedOptionsDepartment.indexOf(option) === -1
                              ),
                            ]);
                          }}
                          options={department.filter(item => item.idDepartment)}
                          getOptionLabel={(option) => option.departmentName}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={option.departmentName}
                                {...getTagProps({ index })}
                                disabled={
                                  fixedOptionsDepartment.indexOf(option) !== -1
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              style={{ width: "100%" }}
                              {...params}
                              label="ค้นหาหน่วยงาน"
                              variant="outlined"
                              placeholder="หน่วยงาน"
                            />
                          )}
                        />
                      </div>
                    )}
                    {valueTarget === "person" && (
                      <div>
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo-person"
                          value={selectedPerson}
                          onChange={(event, newValue) => {
                            setSelectedPerson([
                              ...fixedOptionsPerson,
                              ...newValue.filter(
                                (option) =>
                                  fixedOptionsPerson.indexOf(option) === -1
                              ),
                            ]);
                          }}
                          options={person}
                          getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                          renderOption={(props, option) => (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          )}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={`${option.firstname_TH} ${option.lastname_TH}`}
                                {...getTagProps({ index })}
                                disabled={
                                  fixedOptionsPerson.indexOf(option) !== -1
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              style={{ width: "100%" }}
                              {...params}
                              label="ค้นหาพนักงาน"
                              variant="outlined"
                              placeholder="พนักงาน"
                            />
                          )}
                        />
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClosePublic} color="primary">
                      {" "}
                      cancel{" "}
                    </Button>
                    <Button onClick={handleGoPublic} color="primary" disabled={startDate >= endDate}>
                      {" "}
                      publish{" "}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Tooltip title="แก้ไข" aria-label="edit-name-tooltip">
                  <StyledBtnEditGroup
                    onClick={() => {
                      handleClickOpenOneTextField("editGroup");
                    }}
                    size="small"
                    aria-label="edit-name-tooltip"
                  >
                    <EditIcon />
                  </StyledBtnEditGroup>
                </Tooltip>
                {moraleQuestionItem.moraleStatus !== 'published' &&
                  <Tooltip title="ลบ" aria-label="delete-name-tooltip">
                    <StyledBtnDeleteGroup
                      onClick={() => {
                        setDisplayDelete(moraleQuestionItem.moraleName);
                        handleClickOpenDelete("group");

                      }}
                      size="small"
                      aria-label="delete-name-tooltip"
                      >
                      <DeleteForeverIcon />
                    </StyledBtnDeleteGroup>
                  </Tooltip>
                }
              </div>
            </WrapHeader>
          </div>
          {moraleQuestionItem.moraleStatus === 'draft' && 
            <div style={{ marginTop: 20, marginLeft: "auto" }}>
              {/* <StyledButton
                onClick={() => {
                  handleClickOpenOneTextField("add");
                }}
                variant="contained"
                color="primary"
                startIcon={<FormatListBulletedIcon />}
                >
                เพิ่มหัวข้อ
              </StyledButton> */}
              <StyledButton
                onClick={() => {
                  setSelectedTopic(null);
                  ref.current.updateTopic("");
                  ref.current.updateDataEdit({
                    question: "",
                    questionType: "",
                  });
                  handleClickOpenQuestion("add");
                }}
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon />}
              >
                เพิ่มคำถาม
              </StyledButton>
              <StyledButton
                onClick={handleClickOpenSelectQuestion}
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon />}
              >
                เลือกคำถาม
              </StyledButton>
            </div>
          }
          <div>
            {moraleQuestionItem.questionTopic && moraleQuestionItem.questionTopic.map((topic, index) => {
              return (
                <Paper key={index} style={{ marginTop: 20, padding: 20 }}>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {moraleQuestionItem.moraleStatus === 'draft' && 
                          topic.questionTopic !== "Engagement" ? (
                            <Chip
                              style={{
                                backgroundColor: moraleQuestionItem.moraleStatus === "draft" ? "#f8de55" 
                                : moraleQuestionItem.moraleStatus === "published" ? "#2A265F"
                                : "#BBBBBB",
                                color:
                                moraleQuestionItem.moraleStatus === "draft"
                                    ? "#3C3C3C"
                                    : "#FFFFFF",
                                fontWeight: 500,
                              }}
                              label={
                                topic.questionTopic === null ? "None" : topic.questionTopic
                              }
                              deleteIcon={<SettingsIcon style={{ color: "#FFF" }} />}
                              onDelete={handleClick(topic)}
                            />
                          ) : (
                            <Chip
                              style={{
                                backgroundColor: moraleQuestionItem.moraleStatus === "draft" ? "#f8de55" 
                                : moraleQuestionItem.moraleStatus === "published" ? "#2A265F"
                                : "#BBBBBB",
                                color:
                                moraleQuestionItem.moraleStatus === "draft"
                                    ? "#3C3C3C"
                                    : "#FFFFFF",
                                fontWeight: 500,
                              }}
                              label={
                                topic.questionTopic === null ? "None" : topic.questionTopic
                              }
                            />
                          )
                        }
                      </div>
                    }
                  >
                    {topic.questionList && topic.questionList.map((question, indexQuestion) => {
                      return (
                        <Fragment key={question.idQuestion}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Fragment>
                                  <Typography>{`${indexQuestion + 1}. ${
                                    question.question
                                  }`}</Typography>
                                  <Typography
                                    style={{ fontSize: 14 }}
                                    color="textSecondary"
                                  >
                                    <SubdirectoryArrowRightIcon
                                      style={{ fontSize: 14 }}
                                    />{" "}
                                    คำตอบ: {question.questionType}
                                  </Typography>
                                </Fragment>
                              }
                            />
                            {moraleQuestionItem.moraleStatus === 'draft' && (
                              topic.questionTopic !== "Engagement" && 
                              <>
                                <Tooltip title="ลบ" aria-label="delete-tooltip">
                                  <StyledBtnDelete
                                    onClick={() => {
                                      setDisplayDelete(
                                        "คำถาม : " + question.question
                                        );
                                      handleClickOpenDelete("question");
                                      setIdQuestion(question.idMoraleQuestion)
                                    }}
                                    size="small"
                                    aria-label="delete"
                                  >
                                    <DeleteForeverIcon />
                                  </StyledBtnDelete>
                                </Tooltip>
                              </> 
                            )}
                          </ListItem>
                          <Divider component="li" />
                        </Fragment>
                      );
                    })}
                  </List>
                </Paper>
              );
            })}
          </div>
          {moraleQuestionItem.moraleStatus === 'draft' && 
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              elevation={1}
            >
              <MenuItem
                onClick={() => {
                  handleClickOpenOneTextField("edit");
                  handleClose();
                }}
                >
                {" "}
                <EditIcon style={{ marginRight: 5 }} /> แก้ไขหัวข้อ{" "}
              </MenuItem>
            </Menu>
          }
          {questionTopic &&
            <DialogOneTextField
              id={id}
              idQuestionTopic={selectedTopic}
              questionTopic={questionTopic}
              open={openOneTextField}
              onClose={handleCloseOneTextField}
              handleOpenSnackbar={handleOpenSnackbar}
              mode={modeModal}
            />
          }
          <DialogDelete
            id={id}
            idQuestionTopic={selectedTopic}
            idMoraleQuestion={idQuestion}
            open={openDelete}
            onClose={handleCloseDelete}
            mode={modeModal}
            displayDelete={displayDelete}
          />
          {/* {questionTopic && */}
            <DialogQuestion
              id={id}
              idQuestion={idQuestion}
              ref={ref}
              open={openQuestion}
              onClose={handleCloseQuestion}
              mode={modeModal}
              listTopic={questionTopic}
              handleOpenSnackbar={handleOpenSnackbar}
            />
          {/* } */}
          <DialogSelectQuestion
            id={id}
            open={openSelectQuestion}
            moraleQuestionItem={moraleQuestionItem}
            onClose={handleCloseSelectQuestion}
            handleOpenSnackbar={handleOpenSnackbar}
          />
        </StyledRoot>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} 
        anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={modeSnackbar} sx={{ width: '100%' }}>
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MoraleDescriptions;
