import React, { useState, useEffect, Fragment, forwardRef, useRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Typography,
    Paper,
    Divider,
    Button,
    IconButton,
    Box,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    CircularProgress,
    MenuItem,
    Chip,
    Card,
    CardContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItem,
    ListItemText,
    Avatar,
    Grid,
  } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import MoraleQuestionIcon from "./assets/question.png";
import HeaderPage from "../../../shared/header/headerPage";
import { 
    getMorale, 
    updateMorale, 
    deleteMorale,
    addMoraleQuestion, 
    updateMoraleQuestion, 
    deleteMoraleQuestion,
    addMoraleParticipant,
  } from "../../../../../actions/moraleQuestion";
  import {
    addQuestion, 
    deleteQuestionTopic, 
    getAllQuestion, 
    getAllQuestionTopic, 
  } from "../../../../../actions/questionTopic";
  import { getAllEmployees } from "../../../../../actions/user";
  import { getDepartment } from "../../../../../actions/department";

const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: 15,
    marginTop: 60,
    margin: "0px auto",
    minWidth: 350,
});

const StyledDivider = styled(Divider)({
    width: "100%",
    margin: "10px 0",
});

const StyledBtnDeleteGroup = styled(IconButton)({
    marginLeft: 8,
    color: "#dc3545",
    border: "1px solid #dc3545",
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "&:hover": {
      backgroundColor: "#dc3545",
      borderColor: "#dc3545",
      color: "#FFF",
    },
})

const StyledBtnEditGroup = styled(IconButton)({
    marginLeft: 8,
    border: "1px solid #177ef4",
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    color: "#177ef4",
    "&:hover": {
        color: "#FFF",
        backgroundColor: "#177ef4",
        borderColor: "#177ef4",
    },
})

  
const MoraleQuestionPage = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [newMoraleName, setNewMoraleName] = useState('');
    // const [answers, setAnswers] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [updatedMoraleQuestionItem, setUpdatedMoraleQuestionItem] = useState(null);
    const [openIndices, setOpenIndices] = useState([]);

  const handleClick = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
   
    useEffect(() => {
        // Fetch the questions or other data related to this specific morale ID
        console.log('Fetching questions for morale ID:', {id});
    }, [id]);
    
    const { result: questionList } = useSelector((state) => state.question);
    const { result: moraleQuestionItem } = useSelector((state) => state.moraleQuestionItem);
    const { result: department } = useSelector((state) => state.department);
    const { result: person } = useSelector((state) => state.users);
    const { result: questionTopic } = useSelector((state) => state.questionTopic);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    
    const handleClickOpenOneTextField = () => {
        setNewMoraleName(moraleQuestionItem.moraleName); // Initialize with current name
        setOpenEditDialog(true); // Open the dialog
    };
 
    const handleSaveMoraleName = async () => {
        try {
          await dispatch(updateMorale(id, { moraleName: newMoraleName }));
          setOpenEditDialog(false); // Close dialog
          dispatch(getMorale(id));  // Refresh morale details
        } catch (error) {
          console.error('Failed to update morale name:', error);
        }
    };
    const handleDeleteMorale = async () => {
        if (moraleQuestionItem.moraleStatus === 'draft') {
          try {
            await dispatch(deleteMorale(id));  // Call API to delete
            setOpenDeleteDialog(false);
            setOpenSuccessDialog(true);
            setTimeout(() => {
                setOpenSuccessDialog(false);
                navigate(-1);  
            }, 3000);
            
          } catch (error) {
            console.error('Failed to delete morale:', error);
          }
        } else {
          console.log("Cannot delete a published morale.");
        }
    };

    useEffect(() => {
        dispatch(getAllQuestion())
       
    }, [dispatch])

    useEffect(() => {
        console.log('List of question:', questionList);
      }, [{questionList}]);

    // const questionInMorale = moraleQuestionItem.questionTopic
    // .map((topic) => topic.questionList)
    // .flat()
    // .map((value) => value.idQuestion);
  
   
    
    useEffect(() => {
        console.log('moraleQuestion:', moraleQuestionItem);
    }, [moraleQuestionItem]);
    

    useEffect(() => {
      if (userProfile) {
        dispatch(getMorale(id));
        dispatch(getAllQuestionTopic(userProfile.idCompany))
        dispatch(getDepartment());
        dispatch(getAllEmployees());
      }
    }, [dispatch,id, userProfile]);

      

    if (!moraleQuestionItem) {
    return (
        <StyledRoot className={"page"}>
        <Box
            sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full height of the viewport
            width: '100vw', // Full width of the viewport
            position: 'fixed', // Fixes the loading spinner in place
            top: 0,
            left: 0, 
            }}
        >
            <CircularProgress /> {/* Loading spinner */}
        </Box>
        </StyledRoot>
    );}
    
    return (
        <StyledRoot className={"page"}>
            <Box 
                sx={{ 
                display: 'flex', 
                alignItems: 'center', // Vertically center the items
                justifyContent: 'space-between',
                marginBottom: '16px' // Add some space below the header
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => navigate(-1)} aria-label="go back">
                        <ArrowBackIosIcon />
                    </IconButton>
                    <HeaderPage textLabel= {`แบบสอบถาม ${moraleQuestionItem.moraleName}`} icon={MoraleQuestionIcon} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="แก้ไข" aria-label="edit-name-tooltip">
                        <StyledBtnEditGroup
                            onClick={handleClickOpenOneTextField}
                            size="small"
                            aria-label="edit-name-tooltip"
                        >
                            <EditIcon />
                    </StyledBtnEditGroup>
                    </Tooltip>
                    {moraleQuestionItem.moraleStatus !== 'published' &&
                    <Tooltip title="ลบ" aria-label="delete-name-tooltip">
                        <StyledBtnDeleteGroup
                        onClick={() => setOpenDeleteDialog(true)}
                        size="small"
                        aria-label="delete-name-tooltip"
                        >
                        <DeleteForeverIcon />
                        </StyledBtnDeleteGroup>
                    </Tooltip>
                    }
                </Box> 
            </Box>
            <StyledDivider />
            
            {/* <Typography>Questions for Morale ID: {id}</Typography> */}
            { moraleQuestionItem?.questionTopic?.length > 0 ? (
                moraleQuestionItem.questionTopic.map((topic, topicIndex) => (
                <Card key={topicIndex} sx={{ marginBottom:'10px', borderRadius: '10px', boxShadow: 3, pt: '10px', pl: '20px', pr: '20px' }}>
                    <CardContent>
                        <Chip
                        label={topic.questionTopic || 'Unknown Topic'}
                        sx={{
                            fontSize: '16px',
                            backgroundColor: '#342D68', // Deep blue background
                            color: 'white',
                            borderRadius: '20px',
                            padding: '5px 10px',
                            // marginBottom: 2,
                        }}
                        />
                        {/* Iterate through the questionList for each topic */}
                        {topic.questionList?.length > 0 ? (
                            topic.questionList.map((question, questionIndex) => (
                            <Fragment key={questionIndex}>
                                <ListItem>
                                    <ListItemButton
                                        onClick={() => handleClick(questionIndex)} // Toggle based on question index
                                        style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        }}
                                    >
                                        <ListItemText primary={
                                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 500 }}>
                                                {questionIndex + 1}. {question.question || 'No question text'}
                                            </Typography>
                                        } />
                                        <Tooltip title="View answers">Answers</Tooltip>
                                        {openIndices.includes(questionIndex) ? (<ExpandLess />) : (<ExpandMore />)}
                                    </ListItemButton>
                                </ListItem>
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: '14px', color: '#8C8C8C', pl:4 }}> คำตอบ: {question.questionType || 'Unknown'}
                                </Typography>
                                <Collapse
                                    in={openIndices.includes(questionIndex)}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <ListItem component="div" disablePadding>
                                        <Box sx={{pl: 2, marginLeft: "auto",textAlign: "right", width: '100%', }}>
                                            {question.answersAndParticipants?.length > 0 ? (
                                                question.answersAndParticipants.map((answer, answerIndex) => (
                                                    <ListItem key={answerIndex}>
                                                        <Grid container sx={{ alignItems: "center", justifyContent:'space-between' }}>
                                                            <Grid item xs={6} >
                                                                <ListItemText
                                                                    primary={`${answer.employeeName || "No appraiser"}:`}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sx={{ display: "flex", textAlign: "right", pr:3}}>
                                                                <ListItemText
                                                                    primary={`${answer.answer || "No answer provided"}`}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                ))
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">
                                                    No answers available.
                                                </Typography>
                                            )}
                                        </Box>
                                    </ListItem>
                                </Collapse>
                                <StyledDivider />
                            </Fragment>
                            ))
                        ) : (
                        <Typography variant="body2" color="textSecondary">
                            No questions available for this topic.
                        </Typography>
                        )}
                    </CardContent>
                </Card>
                ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                    No topics available.
                    </Typography>
                )
            }
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="xl" PaperProps={{ style: { width: '800px' } }}>
                <DialogTitle>Edit Morale Name</DialogTitle>
                <DialogContent>
                    <TextField
                    autoFocus
                    margin="dense"
                    label="Morale Name"
                    fullWidth
                    value={newMoraleName}
                    onChange={(e) => setNewMoraleName(e.target.value)}
                    placeholder="Enter new morale name"
                    variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} variant='outlined'
                        sx={{
                            // backgroundColor:'#f43548',
                            // color:  '#fff',
                            width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
                        }} >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSaveMoraleName}
                        color="primary"
                        disabled={!newMoraleName.trim()} 
                        sx={{
                            backgroundColor:'#177ef4',
                            '&:hover': {
                                backgroundColor: '#147cf4', // Darker shade on hover
                            },
                            color:  '#fff', 
                            width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
                            
                        }} >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} maxWidth="xl" PaperProps={{ style: { width: '800px' } }}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete <strong>{moraleQuestionItem.moraleName}</strong>? 
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} variant='outlined'  
                        sx={{
                            width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
                        }} >
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteMorale} color='error'
                        sx={{
                            backgroundColor:'#f43548',
                            '&:hover': {
                                backgroundColor: '#f43548', // Darker shade on hover
                            },
                            color:  '#fff',
                            width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
                        }} >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}  maxWidth="xl" PaperProps={{ style: { width: '800px' } }}>
                <DialogContent
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                <CheckCircleIcon color="success" sx={{ fontSize: 100 }} />
                <Typography variant="h6" color="success" mt={2}>
                    Delete Success
                </Typography>
                </DialogContent>
            </Dialog>
        </StyledRoot>
    );
};

export default MoraleQuestionPage;