import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Divider,
  styled,
  FormControl,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from '@mui/icons-material/Send';

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import ContactUsImg from "./assets/contactus.png";

const StyledRoot = styled("div")({
  width: "100%",
  padding: 24,
})

const WrapText = styled("div")({
  padding: 8,
  width: "80%",
  "& .MuiTextField-root": {
    width: "100%",
  },
})

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiInputBase-formControl": {
    width: "100%",
    "& .MuiSelect-root": {
      padding: "18.5px 14px !important",
    },
  },
})

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};


const PasswordField = ({ isSubmitting, values, handleChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%", marginTop: 8 }}
      error={error}
      fullWidth
      margin="normal"
    >
      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
      <OutlinedInput
        disabled={isSubmitting}
        label="Password"
        name="password"
        id="password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large">
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

const ContactUsPage = (props) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    //anyNameFunction();
  }, []);

  return (
    <StyledRoot>
      <Typography variant="h2" align="center">
        Contact us!
      </Typography>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <img
            src={ContactUsImg}
            alt="ant"
            style={{ width: "80%", display: "flex", margin: "auto" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WrapText>
            <TextField
              id="outlined-password-input"
              label="ชื่อ-สกุล"
              autoComplete="current-password"
              variant="outlined"
            />
          </WrapText>
          <WrapText>
            <TextField
              id="outlined-password-input"
              label="E-mail"
              autoComplete="current-password"
              variant="outlined"
            />
          </WrapText>
          <WrapText>
            <TextField
              id="outlined-password-input"
              label="เบอร์โทรศัพท์"
              autoComplete="current-password"
              variant="outlined"
            />
          </WrapText>
          <WrapText>
            <TextField
              id="outlined-password-input"
              label="บริษัท"
              autoComplete="current-password"
              variant="outlined"
            />
          </WrapText>
          <div style={{ width: "80%", padding: 8 }}>
            <StyledFormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                จำนวนพนักงาน
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={age}
                onChange={handleChange}
                label="จำนวนพนักงาน"
              >
                <MenuItem value={10}>น้อยกว่า 100</MenuItem>
                <MenuItem value={20}>100 - 300</MenuItem>
                <MenuItem value={30}>300 - 500</MenuItem>
                <MenuItem value={30}>มากกว่า 500</MenuItem>
              </Select>
            </StyledFormControl>
            <Button style={{ marginTop: 32 }}  color="primary" variant="contained" endIcon={<SendIcon />}>
              ส่งข้อความ
            </Button>
          </div>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default ContactUsPage;
