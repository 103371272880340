import React from "react";
import { CircularProgress } from "@mui/material";
import { useCoinType } from "../../../../quries/coin";

const MainCoin = ({ width }) => {
  const { data: coinType, isPending } = useCoinType();
  if (isPending) {
    return <CircularProgress color="secondary" />
  }
  
  const data = coinType.find((item) => item.coinType === "main")
  return <img src={data.imageURL} alt="coin" width={width} height={width} />;
};

export default MainCoin;
