import {
    COACH_REGISTERED_FETCHING,
    COACH_REGISTERED_FAILED,
    COACH_REGISTERED_SUCCESS,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case COACH_REGISTERED_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case COACH_REGISTERED_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case COACH_REGISTERED_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  