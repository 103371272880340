import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SpecialIcon } from "../../../../../shared/typeCoin";
import DialogConfig from "./dialogConfig";
import utils from "../../../../../../../utils";
import { useCompanyRewardById } from "../../../../../../../quries/reward";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StatusMenu = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDailog, setOpenDailog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDailog = () => {
    setOpenDailog(true);
    setAnchorEl(null);
  };

  const handleCloseDailog = () => {
    setOpenDailog(false);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={handleOpenDailog}>
          {"แก้ไขของรางวัล"}
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/admin/manage/reward/company-reward/cost`}
          state={{
            idCompanyRewardItem: row.idCompanyRewardItem,
            idCompany: row.idCompany,
          }}
        >
          {"ข้อมูลการซื้อขาย"}
        </MenuItem>
      </Menu>
      {openDailog && (
        <DialogConfig
          open={openDailog}
          handleClose={handleCloseDailog}
          selectReward={row}
        />
      )}
    </>
  );
};

function RowConfig() {
  const { state } = useLocation() 
  const { data: companyReward } = useCompanyRewardById({ idCompanyReward: state?.idCompanyReward });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align={"center"}>SKU</StyledTableCell>
            <StyledTableCell align={"center"}>ตัวเลือก</StyledTableCell>
            <StyledTableCell align={"center"}>แลกไปแล้ว/จำนวนทั้งหมด</StyledTableCell>
            <StyledTableCell align={"center"}>เปิดให้แลก</StyledTableCell>
            <StyledTableCell align={"center"}>เหรียญ</StyledTableCell>
            <StyledTableCell align={"center"}>ระยะเวลาแลก</StyledTableCell>
            {/* <StyledTableCell align={"center"}>สถานะ</StyledTableCell> */}
            <StyledTableCell align={"center"}>ตั้งค่า</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyReward?.map((data) => (
            <TableRow
              key={data.idCompanyRewardItem}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell align="center">
                {data.sku}
              </StyledTableCell>
              <StyledTableCell align="center">
                {data.options.length > 0
                  ? data?.options.map((option) => option.value).join(", ")
                  : "ไม่มี"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {`${utils.numberWithCommas(data.redeem)} / ${utils.numberWithCommas(data.totalQuantity)}`}
              </StyledTableCell>
              <StyledTableCell align="center">
                {utils.numberWithCommas(data.onShelf)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {data?.coins?.length > 0 ? (
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    marginBottom={1}
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    {data?.coins?.map((coin) => (
                      <div key={coin.idCoinType}>
                        <SpecialIcon idCoinType={coin.idCoinType} width={20} />
                        <Typography>{coin.amount}</Typography>
                      </div>
                    ))}
                  </Stack>
                ) : (
                  <Typography>ไม่ได้กำหนด</Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography>
                  {data.startDate && data.endDate
                    ? `${dayjs(data.startDate).format("DD/MM/YYYY")} ${dayjs(data.endDate).format("DD/MM/YYYY")}`
                    : "ไม่ได้กำหนด"}
                </Typography>
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                <Switch checked={Boolean(data.isActive)} />
              </StyledTableCell> */}
              <StyledTableCell align="center">
                <StatusMenu row={data} />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RowConfig;
