import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "sonner";
import { useAddUniRewardItemCost } from "../../../../../quries/uniReward";
import InputField from "../../../shared/inputForm/inputField";
import DateInputField from "../../../shared/inputForm/dateInputField";

const validationSchema = yup.object().shape({
  transactionDate: yup.date().required("required"),
  type: yup.string().oneOf(["buy", "sell"]).required("required"),
  quantity: yup
    .number()
    .integer()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .required("required"),
  price: yup
    .number()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .required("required"),
  vat: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .oneOf([0, 7])
    .required("required"),
});

const DialogCost = ({ open, handleClose, idUniRewardItem }) => {
  const addUniRewardItemCost = useAddUniRewardItemCost({ idUniRewardItem });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      transactionDate: dayjs(),
      type: "",
      quantity: "",
      price: "",
      vat: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    const formData = { ...values, idUniRewardItem };
    addUniRewardItemCost.mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  };

  const totalPrice = Number(watch("price")) + Number(watch("price")) * (Number(watch("vat")) / 100);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"จัดการรายการสินค้า"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <DateInputField
                control={control}
                name="transactionDate"
                label="วันที่"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel>ประเภท</InputLabel>
                    <Select
                      {...field}
                      label="ประเภท"
                      error={Boolean(fieldState.error)}
                    >
                      <MenuItem value={"buy"} color="success">
                        {"Buy"}
                      </MenuItem>
                      <MenuItem value={"sell"} color="error">
                        {"Sell"}
                      </MenuItem>
                    </Select>
                    {Boolean(fieldState.error) && (
                      <FormHelperText error>
                        {fieldState?.error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                control={control}
                name="quantity"
                type="number"
                label="จำนวน"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                control={control}
                name="price"
                type="number"
                label="ราคา"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="vat"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel>{"VAT"}</InputLabel>
                    <Select
                      {...field}
                      label="VAT"
                      error={Boolean(fieldState.error)}
                    >
                      <MenuItem value={0}>{"ไม่มี"}</MenuItem>
                      <MenuItem value={7}>{"7%"}</MenuItem>
                    </Select>
                    {Boolean(fieldState.error) && (
                      <FormHelperText error>
                        {fieldState?.error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="ราคารวม"
                value={totalPrice.toFixed(2) || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            {"ยกเลิก"}
          </Button>
          <Button type="submit" variant="contained">
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCost;
