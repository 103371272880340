import { httpClient } from "./httpClient";

const getCompanyRedeemTransaction = (idCompany) => {
  return httpClient.get(`redeem-transaction/${idCompany}`);
};

const addRedeemTransaction = (formData) => {
  return httpClient.post("redeem-transaction", formData);
};

const updateRedeemTransaction = (formData) => {
  return httpClient.patch("redeem-transaction", formData);
};

const cancelRedeemTransaction = (formData) => {
  return httpClient.patch("redeem-transaction-cancel", formData);
};

const getMyRedeemReward = () => {
  return httpClient.get("my-redeem-reward");
};

export default {
  getCompanyRedeemTransaction,
  addRedeemTransaction,
  updateRedeemTransaction,
  cancelRedeemTransaction,
  getMyRedeemReward,
};
