import React, { Fragment, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Toaster } from "sonner";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Header, AdminMenu, ManagerMenu, UserMenu } from "./components/layouts";
import Routers from "./Routers";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import {
  alertDailyInsightOpen,
  alertDailyInsightClose,
} from "./actions/alertDailyInsight";
import UniTeamMenu from "./components/layouts/menu/uniTeam/uniTeamMenu";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: "0 !important",
  },
}));

const drawerWidth = 280;

const drawerUseStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  bannerOpen: {
    backgroundImage:
      // "url(" +
      // process.env.PUBLIC_URL +
      // "/images/background_menu.jpg" +
      // ")"
      `url(${process.env.PUBLIC_URL}/images/background_menu.jpg)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  toolbar: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-end",
    backgroundColor: "transparent",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const DrawerContainer = ({ open }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const isUser =
    currentUser?.idRole === 1 ||
    currentUser?.idRole === 3 ||
    currentUser?.idRole === 5;
  const isManager = currentUser?.idRole === 3 || currentUser?.idRole === 5;
  const isAdmin = currentUser?.idRole === 4 || currentUser?.idRole === 5;
  const isUniTeam = currentUser?.idRole === 9;

  const classes = drawerUseStyles();
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      className={clsx({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.bannerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}></div>
      {isUser && (
        <Fragment>
          <Divider />
          <UserMenu />
        </Fragment>
      )}
      {isManager && (
        <Fragment>
          <Divider />
          <ManagerMenu />
        </Fragment>
      )}
      {isAdmin && (
        <Fragment>
          <Divider />
          <AdminMenu />
        </Fragment>
      )}
      {isUniTeam && (
        <Fragment>
          <Divider />
          <UniTeamMenu />
        </Fragment>
      )}
    </Drawer>
  );
};

const Navigater = () => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header handleDrawerOpen={handleDrawerOpen} />
      <DrawerContainer open={open} />
    </>
  );
};

function App(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isOpen: isOpenAlertDailyInsight } = useSelector(
    (state) => state.alertDailyInsight
  );
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleCloseDailyInsight = () => {
    dispatch(alertDailyInsightClose);
  };

  const handleOpenDailyInsight = () => {
    dispatch(alertDailyInsightOpen);
  };

  // const isNotLanding = () => {
  //   if (window.location.pathname === '/landing') {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <BrowserRouter>
      <Toaster richColors />
      <div style={{ display: "flex" }}>
        {isLoggedIn && (
          <Fragment>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={isOpenAlertDailyInsight}
              onClose={handleOpenDailyInsight}
            >
              <Alert severity="info" variant="filled">
                This is a success message!
              </Alert>
            </Snackbar>
            {/* <Header handleDrawerOpen={handleDrawerOpen} />
            <DrawerContainer open={open} /> */}
            <Navigater />
          </Fragment>
        )}

        <Routers />
      </div>
    </BrowserRouter>
  );
}

export default App;
