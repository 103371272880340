import React, { useEffect, useMemo,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Avatar, Chip, Container ,Card,Box} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { getBookingByIdCoachee ,updateBooking} from "../../../../actions/book";
import { useNavigate } from "react-router-dom";
import TableBooking from "./tableBooking"
import loading from "../../assets/loading2.gif";
import { MainCoin } from "../../shared/typeCoin";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  color: "#ffffff",
  backgroundColor: "#f1f4f9",

});



const StyledCard = styled(Card)({
  padding: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});



const BookingList = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueSelected, setValueSelected] = React.useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: bookCoachee , isFetching:bookCoacheeLoading} = useSelector((state) => state.bookCoachee);
  const [displaySnackbar, setDisplaySnackbar] = useState({ value: "", mode: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getBookingByIdCoachee());
  }, []);
  const colorStatus = ["warning", "secondary", "error", "success", "default"];

  const handleClickDeleteBooking =async (idBooking,totalPrice)=>{
    const formData = {
      "idBooking":idBooking,
      "idBookingStatus":5,
      "totalPrice":totalPrice

    }
    const result  = await dispatch(updateBooking(formData));
    if(result){
      dispatch(getBookingByIdCoachee());
      if(result.status == 200){
        handleOpenSnackbar("ทำรายการสำเร็จ", "success");

      }else{
        handleOpenSnackbar("เกิดข้อผิดพลาด", "error");

      }
    }else{
      handleOpenSnackbar("เกิดข้อผิดพลาด", "error");
    }
    }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = (value, mode) => {
    setDisplaySnackbar({ value, mode });
    setOpenSnackbar(true);
  };
  const columns = [
    {
      name: "ชื่อ-สกุล(โค้ช)"	,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.profileImageURL}
          />
          <Box flexGrow={1}>
          <Typography style={{ marginLeft: 8 }}>{row.firstname_TH} {row.lastname_TH}</Typography>
    </Box>
        </Box>
      ),
    },
    {
      name: "วันที่จอง",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.bookDate).format("DD/MM/YYYY HH:mm")}
          </Typography>
          
        </Box>
      ),
    },
    {
      name: "วันที่เริ่มเรียน",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.startTime).format("DD/MM/YYYY HH:mm")}
          </Typography>
          
        </Box>
      ),
    },
    {
      name: "วันที่สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
          {dayjs(row.endTime).format("DD/MM/YYYY HH:mm")}
          </Typography>              
        </Box>
      ),
    },
    {
      name: "ประเภท",
      headerTextAlign: "center",
      minWidth: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign:"center"}}>
          <Typography fontSize="14px">
            {row.type}
          </Typography>
        </Box>
      ),
    },
    {
      name: "สถานที่",
      headerTextAlign: "center",
      minWidth: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign:"center"}}>
          <Typography  fontSize="14px">
            {row.place}
          </Typography>
        </Box>
      ),
    },
    {
      name: "สถานะ",
      headerTextAlign: "center",
      minWidth: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign:"center"}}>
          <Chip
                label={row.bookingStatus}
                color={colorStatus[row.idBookingStatus-1]}
              />
        </Box>
      ),
    },
    {
      name: "หมายเหตุ",
      minWidth: "150px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.note}
          </Typography>
        </Box>
      ),
    },
    {
        name: "ราคา(ทั้งหมด)",
        headerTextAlign: "center",
        minWidth: "150px",
        cellRender: (row) => (
          <Box sx={{textAlign:"center", display:"flex",alignItems:"center",justifyContent:"center"}}>
            <MainCoin width={32} />
            <Typography  fontSize="14px" marginLeft={"8px"}>
              x {row.totalPrice}
            </Typography>
          </Box>
        ),
      },
  ];

  return (
    <StyledRoot className={"page"}>
      <Container maxWidth="lg">
        <Typography variant="h4" marginBottom={2} style={{paddingTop:24}}>
          ตารางการจองโค้ช
        </Typography>
        <StyledCard>
        {bookCoacheeLoading?(
           <Box
           style={{
             width: "100%",
             display: "flex",
             justifyContent: "center",
             alignItems:"center",
             paddingTop: "40px",
           }}
         >
           <img width="120" alt="loading" src={loading} />
         </Box>
        ):(
          bookCoachee && (
            <TableBooking
              columns = {columns}
              rows={bookCoachee}
            />
          )
        )}
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default BookingList;
