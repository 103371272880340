import { httpClient } from "./httpClient";

const getCoinType = async () => {
  return (await httpClient.get("coin-type")).data;
};

const getCoinInReward = async (id) => {
  return (await httpClient.get(`coin-in-reward/${id}`)).data;
};

const getCoinOutActivity = async (id) => {
  return (await httpClient.get(`coin-out-activity/${id}`)).data;
};

const getTokenomic = async () => {
  return (await httpClient.get("tokenomic")).data;
};

export {
  getCoinType,
  getCoinInReward,
  getCoinOutActivity,
  getTokenomic,
}
