import { httpClient } from "./httpClient";

const getAllActivity = () => {
  return httpClient.get("activity");
};

const getAllActivityOpen = () => {
  return httpClient.get("activity-open");
};

const getActivity = (id) => {
  return httpClient.get("activity/" + id);
};

const addActivity = (formData) => {
  return httpClient.post("activity", formData);
};

const updateActivity = (idActivity, formData) => {
  return httpClient.put(`activity/${idActivity}`, formData);
};

const updateActivityStatus = (formData) => {
  return httpClient.patch("activity-status", formData);
};

const getActivityRegister = () => {
  return httpClient.get("activity-register");
};

const addActivityRegister = (formData) => {
  return httpClient.post("activity-register", formData);
};

const updateActivityParticipantStatus = (formData) => {
  return httpClient.patch("activity-participant-status", formData);
};

const deleteActivityRegister = (id) => {
  return httpClient.delete("activity-register", { data: id });
};

const getActivityParticipant = (id) => {
  return httpClient.get("activity-participant/" + id);
};

const getActivityHot = () => {
  return httpClient.get("activity-hot");
};

const updateRewardActivityRegister = (idActivity) => {
  return httpClient.patch(`activity/${idActivity}/reward`);
};

const updateActivityInteresting = (idActivity, formData) => {
  return httpClient.put(`activity-interesting/${idActivity}`, formData);
};

export default {
  getAllActivity,
  getAllActivityOpen,
  getActivity,
  addActivity,
  updateActivity,
  updateActivityStatus,
  getActivityHot,
  getActivityRegister,
  addActivityRegister,
  updateActivityParticipantStatus,
  deleteActivityRegister,
  getActivityParticipant,
  updateRewardActivityRegister,
  updateActivityInteresting,
};
