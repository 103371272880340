import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { SpecialIcon } from "../../shared/typeCoin";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from "@mui/material";
import React from "react";

const WrapCoin = styled("div")({
  display: "flex",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WalletCoin({ wallet, openWallet, handleCloseWallet }) {
  return (
    <Dialog
      open={openWallet}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseWallet}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"เหรียญคงเหลือ"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} paddingLeft={2}>
          {wallet.map((item) => (
            <Grid item xs={12} sm={6} key={item.idCoinType}>
              <WrapCoin>
                <SpecialIcon idCoinType={item.idCoinType} width={40} />
                <Typography marginLeft={1}>
                  {`${item.coinName}: ${item.amount}`}
                </Typography>
              </WrapCoin>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseWallet}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WalletCoin;
