import {
  ACTIVITY_FETCHING,
  ACTIVITY_FAILED,
  ACTIVITY_SUCCESS,
  ACTIVITY_ITEM_FETCHING,
  ACTIVITY_ITEM_FAILED,
  ACTIVITY_ITEM_SUCCESS,
  ACTIVITY_REGISTER_FETCHING,
  ACTIVITY_REGISTER_FAILED,
  ACTIVITY_REGISTER_SUCCESS,
  ACTIVITY_PARTICIPANT_FETCHING,
  ACTIVITY_PARTICIPANT_FAILED,
  ACTIVITY_PARTICIPANT_SUCCESS,
  ACTIVITY_HOT_FETCHING,
  ACTIVITY_HOT_FAILED,
  ACTIVITY_HOT_SUCCESS,
} from "./types";

import ActivityService from "../services/activity.service";
import { logout } from "./auth";

export const getAllActivity = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_FETCHING,
    });
    const res = await ActivityService.getAllActivity();
    if (res) {
      dispatch({
        type: ACTIVITY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_FAILED,
    });
    console.log(err);
  }
};

export const getAllActivityOpen = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_FETCHING,
    });
    const res = await ActivityService.getAllActivityOpen();
    if (res) {
      dispatch({
        type: ACTIVITY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_FAILED,
    });
  }
};

export const getActivity = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_ITEM_FETCHING,
    });
    const res = await ActivityService.getActivity(id);
    if (res) {
      dispatch({
        type: ACTIVITY_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_ITEM_FAILED,
    });
  }
};

export const addActivity = (formData) => async (dispatch) => {
  try {
    const res = await ActivityService.addActivity(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_FAILED,
    });
  }
};

export const updateActivity = (idActivity, formData) => async (dispatch) => {
  try {
    const res = await ActivityService.updateActivity(idActivity, formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_FAILED,
    });
  }
};

export const updateActivityStatus = (formData) => async (dispatch) => {
  try {
    const res = await ActivityService.updateActivityStatus(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_FAILED,
    });
  }
};

export const getActivityRegister = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_REGISTER_FETCHING,
    });
    const res = await ActivityService.getActivityRegister();
    if (res) {
      dispatch({
        type: ACTIVITY_REGISTER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REGISTER_FAILED,
    });
  }
};

export const addActivityRegister = (formData) => async (dispatch) => {
  try {
    const res = await ActivityService.addActivityRegister(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REGISTER_FAILED,
    });
  }
};

export const updateActivityParticipantStatus = (formData) => async (dispatch) => {
  try {
    const res = await ActivityService.updateActivityParticipantStatus(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REGISTER_FAILED,
    });
    console.log(err);
  }
};

export const deleteActivityRegister = (id) => async (dispatch) => {
  try {
    const res = await ActivityService.deleteActivityRegister(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REGISTER_FAILED,
    });
    console.log(err);
  }
};

export const getActivityParticipant = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_PARTICIPANT_FETCHING,
    });
    const res = await ActivityService.getActivityParticipant(id);
    if (res) {
      dispatch({
        type: ACTIVITY_PARTICIPANT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_PARTICIPANT_FAILED,
    });
  }
};

export const updateRewardActivityRegister = (idActivity) => async (dispatch) => {
  try {
    const res = await ActivityService.updateRewardActivityRegister(idActivity);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_REGISTER_FAILED,
    });
    console.log(err);
  }
};

export const getActivityHot = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVITY_HOT_FETCHING,
    });
    const res = await ActivityService.getActivityHot();
    if (res) {
      dispatch({
        type: ACTIVITY_HOT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ACTIVITY_HOT_FAILED,
    });
    console.log(err);
  }
};

export const updateActivityInteresting = (idActivity, formData) => async (dispatch) => {
  try {
    const res = await ActivityService.updateActivityInteresting(idActivity, formData);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};
