import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import ProfileIconHeader from "./profileIconHeader";
import UniLogo from "./assets/UNI_HR_Logo_RGB_Full_Color.png";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/auth";
import { getUserProfile } from "../../../actions/user";
import { Box, Divider, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

const drawerWidth = 240;

const Grow = styled("div")({
  flexGrow: 1,
});
const ChipNotification = styled(Chip)({
  borderRadius: 6,
  fontSize: 11,
  fontWeight: 600,
  height: 20,
  position: "absolute",
  right: 28,
  top: 8,
  background: "#ff4d4f",
  "& .MuiChip-label": {
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
  },
});
const MenuWidth = styled(Menu)({
  "& .MuiPaper-root": {
    width: 200,
  },
});
const BtnLogin = styled(Button)({
  color: "#FFFFFF",
  borderColor: "#FFFFFF",
});

const AppBarWrap = styled(AppBar, {
  shouldForwardProp: (props) => props !== "open",
})(({ theme, open }) =>
  open
    ? {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        backgroundColor: "#fdfdfd",
        boxShadow: "none",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }
);

const Header = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    handleMenuClose();
    queryClient.removeQueries();
    dispatch(logout());
  };

  const handleClickProfile = () => {
    handleMenuClose();
    navigate(`/profile`);
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <MenuWidth
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    > 
      {userProfile && (
        <Box style={{padding: "8px 16px" }}>
          <Typography
            variant="h6"
            style={{
              fontSize: 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${userProfile.firstname_TH} ${userProfile.lastname_TH}`}
          </Typography>
          <Typography
            color="text.secondary"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
            }}
          >
          {userProfile && userProfile.email}
          </Typography>
        </Box>
      )}
      <Divider />
      <MenuItem
        component={Link}
        to="/"
        style={{ display: "flex", justifyContent: "space-between" }}
        onClick={handleMenuClose}
      >
        แจ้งเตือน
        <ChipNotification label="1" />
      </MenuItem>
      <MenuItem component={Link} to="/profile" onClick={handleClickProfile}>
        ข้อมูลส่วนตัว
      </MenuItem>
      <MenuItem component={Link} to="/landing" onClick={logOut}>
        ลงชื่อออก
      </MenuItem>
    </MenuWidth>
  );

  return (
    <AppBarWrap open={props.open}>
      <Toolbar>
        {userProfile ? (
          <Fragment>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              edge="start"
              sx={props.open ? { display: "none" } : { color: "#0000008a" }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <div style={{ width: "112px", height: "63px" }}>
                <img alt={"uni-culutre-logo"} src={UniLogo} style={{ height: "100%", width: "100%" }} />
              </div>
            </Link>
            <Grow />
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              size="large"
            >
              <ProfileIconHeader
                alt={`${userProfile.firstname_EN} ${userProfile.lastname_EN}`}
                src={userProfile.imageProfile}
              />
            </IconButton>
            {renderMenu}
          </Fragment>
        ) : (
          <BtnLogin variant="outlined" component={Link} to="/login">
            Log In
          </BtnLogin>
        )}
      </Toolbar>
    </AppBarWrap>
  );
};

export default Header;
// export default withRouter(Header);
