import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Utils from "../../../../../utils";
import { SpecialIcon } from "../../../shared/typeCoin";
import { Link } from "react-router-dom";
import {
  Typography,
  Divider,
  Chip,
  Stack,
  Link as FileLink,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import MoraleCard from "./moraleCards";

const pageSize = 9;
const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const MoraleList = ({ value }) => {
  console.log(Array.isArray(value))
  console.log(value)
  if (!value) {
    return (
      <StyledRoot className={"page"}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0, 
          }}
        >
          <CircularProgress />
        </Box>
      </StyledRoot>
    );
  }

  // Handle empty array
  if (Array.isArray(value) && value.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          width: '100%',
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No morale questions available
        </Typography>
      </Box>
    );
  }
  const items = Array.isArray(value) ? value : [value];
  
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%', 
      padding: 1
    }}>
      <Grid container spacing={2} padding={1}>
        {items.map((item, index) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index} 
              sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center' 
              }}
            >
              <MoraleCard value={item} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MoraleList;
