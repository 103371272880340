import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Tab, Tabs, styled } from "@mui/material";
import HeaderPage from "../../../shared/header/headerPage";
import AssignMissionQuota from "./assignMissionQuota";
import SendHeartQuota from "./sendHeartQuota";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoinQuota } from "../../../../../actions/user";
import LoadingIcon from "../../../shared/general/loadingIcon";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CoinQuota = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <HeaderPage textLabel={"โควต้าเหรียญรางวัล"} />
      </WrapHeader>
      <StyledDivider />
      <Container>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="โควต้าส่งหัวใจ" {...a11yProps(0)} />
          <Tab label="โควต้าเหรียญภารกิจ" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <SendHeartQuota />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AssignMissionQuota />
        </CustomTabPanel>
      </Container>
    </StyledRoot>
  );
};

export default CoinQuota;
