import React, { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useOrganization } from "../../../../quries/company";

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: '#282828',
  '& .MuiOutlinedInput-root': {
    color: '#ffffff',
  },
  'fieldset': {
    borderColor: '#ffffff',
  },
  '& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused': {
    color: '#ffffff',
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
})

const SelectAffiliateCompanyDark = ({ value, onChange, multiple }) => {
  const organization = useOrganization();

  useEffect(() => {
    if (organization.isSuccess) {
      onChange(organization.data[0]);
    }
  }, [organization.isSuccess]);

  if (organization.isPending) {
    return <CircularProgress />;
  }

  return (
    <StyledAutocomplete
      fullWidth
      multiple={multiple}
      options={organization.data}
      getOptionLabel={(option) => option.companyName || ""}
      isOptionEqualToValue={(option, value) =>
        option.idCompany === value.idCompany
      }
      renderInput={(params) => <TextField {...params} label="Company" />}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      noOptionsText="ไม่พบข้อมูล"
      disableClearable
    />
  );
};

export default SelectAffiliateCompanyDark;
