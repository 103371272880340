import React, { useEffect ,useState} from "react";
import { styled } from "@mui/material/styles";
import {loading2} from "../../../assets/loading2.gif" 

import { 
    Typography,
    Divider,Card
    } from "@mui/material";


const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
        padding: 24,
    },
});


const CardExperience = (props) =>{
    const {experience,isLoading} = props;
    return (
        <StyledCard>
            <Typography variant="h5" gutterBottom>
             Experience
            </Typography>
            <Divider />
            <Typography className="field-label" sx={{marginTop:"10px"}}>
                {experience}
            </Typography>
        </StyledCard>
    )
}
export default CardExperience;