import React, { memo, useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  useMarkerRef,
  MapControl,
  ControlPosition,
} from "@vis.gl/react-google-maps";
import { Grid, Stack } from "@mui/material";
import { PlaceAutocompleteClassic } from "./autocomplete-custom";
import CurrentPositionControl from "./currentPositionControl";

const MapComponent = ({ lat, lng, setFieldValue, error }) => {
  const [position, setPosition] = useState(null);
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const currentPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
        if (lat && lng) {
          setPosition({ lat: Number(lat), lng: Number(lng) });
        } else {
          setPosition(currentPosition);
        }
        return position
      },
    (error) => console.log(error), 
    { enableHighAccuracy: true });
    
  }, [lat, lng]);

  const [markerRef, marker] = useMarkerRef();

  const onPlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setFieldValue({ lat, lng });
    setPosition({ lat, lng });
  };

  const onMapClick = (e) => {
    const lat = e.detail.latLng.lat
    const lng = e.detail.latLng.lng
    setFieldValue({ lat, lng })
    setPosition({ lat, lng });
  };

  return (
    <>
      {position && (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Map
            style={{ width: "100%", height: "400px" }}
            defaultCenter={position}
            streetViewControl={false}
            fullscreenControl={false}
            mapTypeControl={false}
            mapId={"aa5ada192cdfb19e"}
            zoom={zoom}
            onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
            onClick={onMapClick}
          >
            <AdvancedMarker ref={markerRef} position={position} />
            <MapControl position={ControlPosition.BLOCK_START_INLINE_CENTER}>
              <Stack
                direction="row"
                alignItems="center"
                marginTop="8px"
                bgcolor="#ffffff"
              >
                <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect} />
                <CurrentPositionControl zoom={zoom} setZoom={setZoom} />
              </Stack>
            </MapControl>
          </Map>
        </APIProvider>
      )}
    </>
  );
};

export default memo(MapComponent);
