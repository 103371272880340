import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Typography,
  Stack,
  Button,
  CardContent,
  CardMedia,
  Card,
  Grid,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/1353249.svg";
import { MainCoin } from "../../shared/typeCoin";
import pdfImage from "../../shared/image/pdf.png";
//icon
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { addAnnouncementReward, getUserAnnouncement } from "../../../../actions/announcement";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
  minWidth: 400,
  height: "100vh",
  backgroundColor: "#f5f5f5",
});

const StyledDivider = styled(Divider)({
  margin: "10px 0",
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const Annount = ({
  topic,
  startDate,
  endDate,
  coin,
  file,
  fileType,
  idAnnouncement,
  rewardDate,
}) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const image = fileType === "application/pdf" ? pdfImage : file;
  const [readDetail, setReadDetail] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleReadDetail = () => {
    setReadDetail(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleSubmit = async () => {
    const res = await dispatch(
      addAnnouncementReward({
        idAnnouncement,
        coin,
        idEmployees: userProfile.idEmployees,
      })
    );
    if (res) {
      setOpenAlert(true);
      dispatch(getUserAnnouncement());
    }
  };

  return (
    <>
      <Card elevation={3}>
        <CardMedia
          component="img"
          style={{
            width: "100%",
            height: 240,
            padding: 8,
            objectFit: "contain",
          }}
          image={image}
          alt="Announcement"
        />
        <CardContent>
          <Typography variant="h5">{`ชื่อประกาศ : ${topic}`}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {`${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(endDate).format("DD/MM/YYYY")}`}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            marginTop={2}
          >
            {coin > 0 && (
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography>{coin}</Typography>
                <MainCoin width={32} />
              </Stack>
            )}
            <Stack direction={"row"} spacing={1}>
              <Button
                variant="outlined"
                color="warning"
                onClick={handleSubmit}
                disabled={!readDetail || rewardDate !== null}
              >
                {rewardDate ? "รับรางวัลแล้ว" : "รับรางวัล"}
              </Button>
              <Button
                variant="outlined"
                target="_blank"
                href={file}
                onClick={handleReadDetail}
              >
                รายละเอียด
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Snackbar
        open={openAlert}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" onClose={handleClose}>
          <AlertTitle>สำเร็จ</AlertTitle>
          รับเหรียญรางวัลเสร็จสิ้น
        </Alert>
      </Snackbar>
    </>
  );
};

export default function EnterAnnouncement() {
  const dispatch = useDispatch();
  const { result: announcement } = useSelector((state) => state.announcement);

  useEffect(() => {
    dispatch(getUserAnnouncement());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <HeaderPage textLabel={"ประกาศ"} icon={iconHeader} />
      </WrapHeader>
      <StyledDivider />
      <Grid container spacing={2}>
        {announcement &&
          announcement.map((item) => (
            <Grid item xs={12} md={6}>
              <Annount {...item} />
            </Grid>
          ))}
      </Grid>
    </StyledRoot>
  );
}
