import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import InputField from "../../../../../shared/inputForm/inputField";
import SwitchInput from "../../../../../shared/inputForm/switchInput";
import { useUpdateStoreRewardItem } from "../../../../../../../quries/reward";
import { useLocation } from "react-router-dom";

const DialogConfigStoreUniReward = ({ open, handleClose, data }) => {
  const { state } = useLocation();
  const updateStoreUniRewardCost = useUpdateStoreRewardItem({
    idReward: data.idReward,
    idUniRewardItem: state.idUniRewardItem 
  });

  const validationSchema = yup.object().shape({
    isActive: yup.boolean().required("required"),
    onShelf: yup
      .number()
      .integer()
      .min(1)
      .max(data.quantity - data.redeem, "จำนวนเกินที่คงเหลือ")
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .required("required"),
  });

  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    defaultValues: {
      isActive: Boolean(data.isActive),
      onShelf: data.onShelf || "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    const formData = { ...values, idOrderRewardItem: data.idOrderRewardItem };
    updateStoreUniRewardCost.mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"จัดการรายการของรางวัล"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <SwitchInput
              control={control}
              name="isActive"
              label={"สถานะการแลก"}
            />
            <InputField
              control={control}
              name="onShelf"
              type="number"
              label="จำนวนเปิดให้แลก"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            {"ยกเลิก"}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogConfigStoreUniReward;
