import { httpClient } from "./httpClient";

const getAllAnnouncement = () => {
  return httpClient.get("announcement");
};

const addAnnouncement = (formData) => {
  return httpClient.post("announcement", formData);
};

const getAnnouncementReward = () => {
  return httpClient.get("announcement-reward");
};

const addAnnouncementReward = (formData) => {
  return httpClient.post("announcement-reward", formData);
};

const getUserAnnouncement = () => {
  return httpClient.get("announcement-user");
};

export default {
  getAllAnnouncement,
  addAnnouncement,
  getAnnouncementReward,
  addAnnouncementReward,
  getUserAnnouncement,
};
