import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Typography,
  Button,
  Grid,
  Stack,
  Link,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/1353249.svg";
import pdfImage from "../../shared/image/pdf.png";
//icon
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch, useSelector } from "react-redux";
import { getAllAnnouncement } from "../../../../actions/announcement";
import { MainCoin } from "../../shared/typeCoin";
import { getAllEmployees } from "../../../../actions/user";
import DialogDetailAnnouncement from "../../user/announcement/dialogDetailAnnouncement";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0px auto",
  height: '100vh',
  minWidth: 400,
  backgroundColor: "#f5f5f5",
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
});

const StyledDivider = styled(Divider)({
  margin: "10px 0",
});

const WrapDetail = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    alignItems: "flex-start",
    marginLeft: 120,
  },
});

const WrapCardAction = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "16px",
});

const BorderLinearProgress = styled(LinearProgress)(({ theme, scale }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: scale,
  },
}));

const scaleColor = (value) => {
  if(value >= 70) {
    return '#4caf50'
  } else if(value >= 50) {
    return '#ffeb3b'
  } else {
    return '#f44336'
  }
}

const Annount = ({
  topic,
  startDate,
  endDate,
  coin,
  numOfRead,
  file,
  fileType,
  userLength,
}) => {
  const image = fileType === "application/pdf" ? pdfImage : file;
  const value =  Math.ceil(numOfRead / userLength * 100)

  return (
    <Card elevation={3}>    
      <CardMedia
        component="img"
        style={{ width: "100%", height: 240, padding: 8, objectFit: 'contain' }}
        image={image}
        alt="Announcement"
      />
      <CardContent>
        <Typography variant="h5">{`ชื่อประกาศ : ${topic}`}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {`${dayjs(startDate).format("DD/MM/YYYY")} - ${dayjs(
            endDate
          ).format("DD/MM/YYYY")}`}
        </Typography>
        <WrapDetail>
          <Typography>{`${numOfRead}/${userLength}`}</Typography>
          <BorderLinearProgress
            variant="determinate"
            value={value}
            scale={scaleColor(value)}
          />
        </WrapDetail>
        <WrapCardAction>
          {coin > 0 && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography>{coin}</Typography>
              <MainCoin width={32} />
            </Stack>
          )}
          <Link href={file} target="_blank" download>
            <Button variant="outlined">รายละเอียด</Button>
          </Link>
        </WrapCardAction>
      </CardContent>
    </Card>
  );
};

export default function Announcement() {
  // const history = useHistory();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { result: announcement } = useSelector((state) => state.announcement);
  const { result: users } = useSelector((state) => state.users);

  const handleClickCreateAnnouncement = () => {
    navigate("/admin/create-announcement")
  };

  useEffect(() => {
    dispatch(getAllAnnouncement());
    dispatch(getAllEmployees());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <WrapHeader>
        <HeaderPage textLabel={"ประกาศ"} icon={iconHeader} />
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCreateAnnouncement}
          >
            สร้างประกาศ
          </Button>
        </div>
      </WrapHeader>
      <StyledDivider />
      <Grid container spacing={2}>
        {announcement &&
          users &&
          announcement.map((item) => (
            <Grid item xs={12} md={6}>
              <Annount {...item} userLength={users.length} />
            </Grid>
          ))}
      </Grid>
    </StyledRoot>
  );
}
