import React from "react";
import { Chip, Stack, styled } from "@mui/material";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FlightIcon from "@mui/icons-material/Flight";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const rewardTypeList = [
  { idRewardType: 0, rewardType: "all", icon: <DensitySmallIcon /> },
  { idRewardType: 1, rewardType: "food", icon: <FastfoodIcon /> },
  { idRewardType: 2, rewardType: "shopping", icon: <ShoppingCartIcon /> },
  { idRewardType: 3, rewardType: "entertainment", icon: <QueueMusicIcon /> },
  { idRewardType: 4, rewardType: "lifestyle", icon: <ColorLensIcon /> },
  { idRewardType: 5, rewardType: "travel", icon: <FlightIcon /> },
  { idRewardType: 6, rewardType: "health", icon: <LocalHospitalIcon /> },
];
const StyledChip = styled(Chip)({
  padding: 8,
  height: 36,
  borderRadius: 18,
});

function RewardTypeFilter({ rewardType, handleChangeRewardType }) {
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      spacing={3}
      style={{ overflowX: "auto", scrollbarWidth: "none" }}
    >
      {rewardTypeList.map((item) => {
        return (
          <StyledChip
            clickable
            variant="outlined"
            key={item.idRewardType}
            label={item.rewardType}
            icon={item.icon}
            color={rewardType === item.idRewardType ? "secondary" : "default"}
            onClick={() => handleChangeRewardType(item.idRewardType)}
          />
        );
      })}
    </Stack>
  );
}

export default RewardTypeFilter;