import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

// Styled Autocomplete component
const StyledAutocomplete = styled(Autocomplete)({
  minWidth: "400px",
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
  },
  fieldset: {
    borderColor: "#ffffff",
  },
  "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
    color: "#ffffff",
  },
});

// Function to get unique employees based on first and last names
const getUniqueEmployees = (data) => {
  const seen = new Set();
  return data.filter((employee) => {
    const name = `${employee.firstname_TH} ${employee.lastname_TH}`;
    if (seen.has(name)) {
      return false;
    }
    seen.add(name);
    return true;
  });
};

// Function to determine the feeling based on score
const feelingScore = (score) => {
  if (score === 100) {
    return "veryhappy";
  } else if (score >= 80) {
    return "happy";
  } else if (score >= 60) {
    return "littlehappy";
  } else if (score >= 40) {
    return "littlebad";
  } else if (score >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

// Function to determine the score color based on feeling
const getScoreColor = (score) => {
  switch (feelingScore(score)) {
    case "veryhappy":
      return { color: "green" };
    case "happy":
      return { color: "lightgreen" };
    case "littlehappy":
      return { color: "yellowgreen" };
    case "littlebad":
      return { color: "yellow" };
    case "bad":
      return { color: "orange" };
    case "verybad":
      return { color: "red" };
    default:
      return { color: "white" };
  }
};

// Function to get the top 3 topics based on average score
const getTop3Strengths = (filterData) => {
  const topicStrengths = [];

  // Extract questionTopics from the filterData and push their topic and averageScore
  filterData.forEach((row) => {
    row.questionTopics.forEach((item) => {
      topicStrengths.push({
        topic: item.questionTopic, // Use questionTopic here
        averageScore: item.averageScore,
      });
    });
  });

  // Sort by the average score and get the top 3 topics
  const top3Topics = topicStrengths
    .sort((a, b) => b.averageScore - a.averageScore)
    .slice(0, 3);

  return top3Topics;
};

const Top3StrengthDailyTable = ({ filterData, exportFile }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);

  // Get unique employees and top 3 topics
  const uniqueEmployees = getUniqueEmployees(filterData);
  const top3Topics = getTop3Strengths(filterData);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <PersonIcon sx={{ color: "#ffffff" }} />
          <Typography variant="h5" color={"#ffffff"}>
            จุดแข็ง 3 อันดับแรก
          </Typography>
        </div>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          backgroundColor: "#000000",
          border: "1px solid #2e2e2e",
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                }}
              >
                Topic
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                }}
              >
                Average Score (%)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {top3Topics.map((row) => (
              <TableRow
                key={row.topic}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderColor: "#2e2e2e",
                    whiteSpace: "normal",
                  }}
                >
                  <Typography color={"#ffffff"}>{row.topic}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: getScoreColor(row.averageScore),
                    borderColor: "#2e2e2e",
                  }}
                >
                  {row.averageScore}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Top3StrengthDailyTable;
