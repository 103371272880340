import { ANALYTIC_FETCHING, ANALYTIC_FAILED, ANALYTIC_SUCCESS } from "./types";
import AnalyticService from "../services/analytic.service";

// export const getAnalytic = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: ANALYTIC_FETCHING,
//     });
//     const res = await AnalyticService.getAnalytic(id);
//     if (res) {
//       dispatch({
//         type: ANALYTIC_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: ANALYTIC_FAILED,
//     });
//     console.log(err);
//   }
// };

export const getAnalyticManager = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ANALYTIC_FETCHING,
    });
    const res = await AnalyticService.getAnalyticManager(id);
    if (res) {
      dispatch({
        type: ANALYTIC_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ANALYTIC_FAILED,
    });
    console.log(err);
  }
};
