import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Container, Divider, Grid } from "@mui/material";

import HeaderPage from "../../shared/header/headerPage";
import ActivityIcon from "./assets/protest.png";
import CardActivity from "../../shared/cardActivity";
import {
  getActivityRegister,
  getAllActivityOpen,
} from "../../../../actions/activity";
import LoadingIcon from "../../shared/general/loadingIcon";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

export default function Activity() {
  const dispatch = useDispatch();
  const { result: activity, isFetching } = useSelector((state) => state.activity);

  // Onload
  useEffect(() => {
    dispatch(getAllActivityOpen());
    dispatch(getActivityRegister());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"กิจกรรมทั้งหมด"} icon={ActivityIcon} />
      <StyledDivider />
      <Container>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          <Grid container spacing={2}>
            {activity && activity.map((value) => (
              <Grid key={value.idActivity} item xs={12} sm={6} md={4} xl={3}>
                <CardActivity value={value} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </StyledRoot>
  );
}
