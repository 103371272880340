import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnswerScale,
  updateAnswerScale,
} from "../../../../../actions/questionTopic";
import { toast } from "sonner";
import LoadingIcon from "../../../shared/general/loadingIcon";

const AnswerScale = ({ selectCompany }) => {
  const dispatch = useDispatch();
  const { result: answerScale, isFetching } = useSelector((state) => state.answerScale);
  const [scale, setScale] = useState("");

  useEffect(() => {
    if (selectCompany) {
      dispatch(getAnswerScale(selectCompany.idCompany))
    }
  }, [selectCompany])

  useEffect(() => {
    if (answerScale) {
      setScale(answerScale.answerScale)
    }
  }, [answerScale])

  const handleChange = async (event) => {
    const res = await dispatch(updateAnswerScale({
      idCompany: selectCompany.idCompany,
      answerScale: event.target.value,
    }))
    
    if (res && res.status === 200) {
      setScale(event.target.value)
      toast.success("สำเร็จ")
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  }
  
  if (isFetching) {
    return <LoadingIcon />
  }

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <Typography variant="h6">ระดับตัวเลือกของคำตอบ</Typography>
      </div>
      <Box sx={{ maxWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel>{"ระดับ"}</InputLabel>
          <Select
            value={scale}
            label={"ระดับ"}
            onChange={handleChange}
          >
            <MenuItem value={5}>{"5 ระดับ"}</MenuItem>
            <MenuItem value={6}>{"6 ระดับ"}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default AnswerScale;
