import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import dayjs from "dayjs";
import {
  useCoinInReward,
  useCoinOutActivity,
} from "../../../../quries/coin";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransferCoinOutCommu,
  getTransferCoinOutMission,
  getTransferCoinOutMoraleDaily,
} from "../../../../actions/transfer";
import { getAnnouncementReward } from "../../../../actions/announcement";
import TableCoin from "./tableCoin";
import { MainCoin } from "../../shared/typeCoin";
import HDCoin from "./assets/HDCoin.png";
import { useCoinType } from "../../../../quries/coin";

const StyledTagHeader = styled(Typography)({
  color: "white",
  position: "absolute",
  top: 22,
  left: 84,
  fontSize: 18,
});

const WrapCard = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginBottom: 48,
  ["@media only screen and (max-width:600px)"]: {
    justifyContent: "flex-end",
  },
  "& .label-card": {
    width: 200,
    ["@media only screen and (max-width:600px)"]: {},
  },
});

const StyledCard = styled(Card)({
  width: 400,
});

const StyledCardContent = styled(CardContent)({
  padding: "0 !important",
  backgroundColor: "#f8bd28",
});

const StyledTextUpdate = styled(Typography)({
  lineHeight: 1,
  padding: 8,
  paddingBottom: 0,
  color: "#ffffff",
});

const WrapCardTodayCount = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  height: 140,
  paddingTop: 26,
});

const WrapCardYearCount = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  padding: 8,
  marginTop: 8,
  position: "relative",
  backgroundColor: "#dca416",
});

const tagYear = {
  padding: "4px 8px",
  lineHeight: 1,
  height: 90,
  fontSize: 18,
  position: "absolute",
  top: 150,
  left: "50%",
  marginLeft: -40,
  textAlign: "center",
  border: 0,
  width: 80,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const StyledTagYear = styled(Paper)({
  ...tagYear,
  backgroundColor: "#dca416",
});

const WrapHeaderSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledIconButton = styled(IconButton)({
  color: "white",
  "&:hover": {
    backgroundColor: "#ffffff42",
  },
});

const WrapSumMonth = styled("div")({
  borderLeft: "4px solid #d8dbe0",
  borderRadius: 4,
  padding: "0 16px",
  width: "100%",
});

const WrapHeaderSumMonth = styled("div")({
  display: "flex",
  alignItems: "center",
});

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MainTransfer = () => {
  const [filterDate, setFilterDate] = useState(dayjs());

  const dispatch = useDispatch();
  const { result: transferCoinOutMission } = useSelector(
    (state) => state.transferCoinOutMission
  );
  const { result: transferCoinOutCommu } = useSelector(
    (state) => state.transferCoinOutCommu
  );
  const { result: transferCoinOutMoraleDaily } = useSelector(
    (state) => state.transferCoinOutMoraleDaily
  );
  const { result: transferCoinOutAnnouncement } = useSelector(
    (state) => state.transferCoinOutAnnouncement
  );

  const { data: coinType } = useCoinType((data) =>
    data.find((item) => item.coinType === "main")
  );

  const coinInReward = useCoinInReward({
    idCoinType: coinType?.idCoinType,
  });

  const coinOutActivity = useCoinOutActivity({
    idCoinType: coinType?.idCoinType,
  });

  const sumCoinOut = (unit) => {
    let result = 0;
    if (
      coinOutActivity.data &&
      transferCoinOutMission &&
      transferCoinOutCommu &&
      transferCoinOutMoraleDaily &&
      transferCoinOutAnnouncement
    ) {
      const sumYearCoinOutActivity = coinOutActivity.data.reduce(
        (prev, curr) => {
          return dayjs(filterDate).isSame(curr.rewardDate, unit)
            ? curr.coin + prev
            : prev;
        },
        0
      );

      const sumYearCoinOutMission = transferCoinOutMission.reduce(
        (prev, curr) => {
          return dayjs(filterDate).isSame(curr.transactionDate, unit)
            ? curr.coin + prev
            : prev;
        },
        0
      );

      const sumYearCoinOutCommu = transferCoinOutCommu.reduce((prev, curr) => {
        return dayjs(filterDate).isSame(curr.transactionDate, unit)
          ? curr.coin + prev
          : prev;
      }, 0);

      const sumYearCoinOutMoraleDaily = transferCoinOutMoraleDaily.reduce(
        (prev, curr) => {
          return dayjs(filterDate).isSame(curr.transactionDate, unit)
            ? curr.coin + prev
            : prev;
        },
        0
      );

      const sumYearCoinOutAnnouncement = transferCoinOutAnnouncement.reduce(
        (prev, curr) => {
          return dayjs(filterDate).isSame(curr.transactionDate, unit)
            ? curr.coin + prev
            : prev;
        },
        0
      );

      result =
        sumYearCoinOutActivity +
        sumYearCoinOutMission +
        sumYearCoinOutCommu +
        sumYearCoinOutMoraleDaily +
        sumYearCoinOutAnnouncement;
    }
    return result;
  };

  const sumDayCoinOut = sumCoinOut("day");
  const sumMonthCoinOut = sumCoinOut("month");
  const sumYearCoinOut = sumCoinOut("year");

  const sumCoinIn = (unit) => {
    return coinInReward.data?.reduce((prev, curr) => {
      return dayjs(filterDate).isSame(curr.transactionDate, unit)
        ? curr.coin + prev
        : prev;
    }, 0);
  };

  const sumDayCoinIn = sumCoinIn("day");
  const sumMonthCoinIn = sumCoinIn("month");
  const sumYearCoinIn = sumCoinIn("year");

  const handleChangeMonth = (action) => {
    if (action === 1) {
      if (dayjs().isSame(filterDate.add(1, "month"), "month")) {
        setFilterDate(dayjs());
      } else {
        setFilterDate((prev) => prev.add(1, "month"));
      }
    } else if (action === -1) {
      setFilterDate((prev) => prev.subtract(1, "month"));
    }
  };

  useEffect(() => {
    dispatch(getTransferCoinOutMission());
    dispatch(getTransferCoinOutCommu());
    dispatch(getTransferCoinOutMoraleDaily());
    dispatch(getAnnouncementReward());
  }, []);

  if (coinInReward.isPending) {
    return <CircularProgress />;
  }

  return (
    <div>
      <WrapCard>
        <Stack spacing={4} alignItems={"center"}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: -35, left: -50 }}>
              {/* <img src={coinType.data.imageURL} alt="coin" className={`label-card`} /> */}
              <img src={HDCoin} alt="coin" className={`label-card`} />
              <StyledTagHeader variant="subtitle1">
                {dayjs(filterDate).format("D MMM YYYY")}
              </StyledTagHeader>
            </div>
            <StyledCard>
              <StyledCardContent>
                <StyledTextUpdate variant="body2" align="right">
                  Updated: {dayjs(filterDate).format("D-MM-YYYY HH:mm")}
                </StyledTextUpdate>
                <WrapCardTodayCount>
                  <div style={{ width: "100%" }}>
                    <Typography
                      variant="h1"
                      align="center"
                      style={{ lineHeight: 1, color: "#131313d6" }}
                    >
                      {sumDayCoinOut}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{
                        lineHeight: 1,
                        color: "rgba(45, 45, 45, 0.65)",
                      }}
                    >
                      แจกออก
                    </Typography>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ width: 2, marginBottom: 16 }}
                  />
                  <div style={{ width: "100%" }}>
                    <Typography
                      variant="h1"
                      align="center"
                      style={{ lineHeight: 1, color: "#131313d6" }}
                    >
                      {sumDayCoinIn}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{
                        lineHeight: 1,
                        color: "rgba(45, 45, 45, 0.65)",
                      }}
                    >
                      แลกคืน
                    </Typography>
                  </div>
                </WrapCardTodayCount>
                <WrapCardYearCount>
                  <Typography variant="h5">{sumYearCoinOut}</Typography>
                  <Typography variant="h5">{sumYearCoinIn}</Typography>
                </WrapCardYearCount>
                <StyledTagYear variant="outlined">
                  <div>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: "#2D2D2DA6",
                        paddingBottom: 2,
                      }}
                    >
                      ALL YEAR
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: 1,
                        fontSize: 16,
                        color: "#2D2D2DCC",
                      }}
                    >
                      {dayjs(filterDate).format("YYYY")}
                    </Typography>
                  </div>
                  <Divider />
                  <div>
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: 1.2,
                        fontSize: 16,
                        color: "#2D2D2DCC",
                      }}
                    >
                      {sumYearCoinOut - sumYearCoinIn}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: "#2D2D2DA6",
                        paddingBottom: 2,
                      }}
                    >
                      ยังไม่ได้แลก
                    </Typography>
                  </div>
                </StyledTagYear>
              </StyledCardContent>
            </StyledCard>
          </div>
          {/* <Typography color={"white"} fontSize={24}>
            {`มูลค่า ${utils.numberWithCommas(coinValue.toFixed(2))}฿`}
          </Typography> */}
        </Stack>
      </WrapCard>
      {transferCoinOutMission &&
        transferCoinOutCommu &&
        transferCoinOutMoraleDaily &&
        transferCoinOutAnnouncement && (
          <div style={{ marginTop: 36, marginBottom: 20 }}>
            <WrapHeaderSection>
              <Tooltip title="เดือนก่อน" aria-label="labelBeforeMonth">
                <StyledIconButton
                  onClick={() => handleChangeMonth(-1)}
                  aria-label="beforeMonth"
                  size="small"
                >
                  <KeyboardArrowLeftIcon fontSize="inherit" />
                </StyledIconButton>
              </Tooltip>
              <Typography
                variant="h6"
                style={{
                  textTransform: "uppercase",
                  color: "white",
                  margin: "0 10px",
                }}
              >
                {monthNames[filterDate.month()]} {filterDate.year()}
              </Typography>
              <Tooltip title="เดือนหน้า" aria-label="labelNextMonth">
                <span>
                  <StyledIconButton
                    onClick={() => handleChangeMonth(1)}
                    aria-label="nextMonth"
                    size="small"
                    disabled={dayjs().isSame(filterDate, 'month')}
                  >
                    <KeyboardArrowRightIcon fontSize="inherit" />
                  </StyledIconButton>
                </span>
              </Tooltip>
            </WrapHeaderSection>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 44,
                }}
              >
                <WrapSumMonth>
                  <WrapHeaderSumMonth>
                    <MainCoin width={24} />
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#ffffff99" }}
                    >
                      &nbsp;แจกออก
                    </Typography>
                  </WrapHeaderSumMonth>
                  <div>
                    <ArrowDropDownIcon
                      style={{ color: "#cf483d", fontSize: 32 }}
                    />
                    <Typography variant="h5" style={{ color: "#ffffffde" }}>
                      {sumMonthCoinOut}
                    </Typography>
                  </div>
                </WrapSumMonth>
                <WrapSumMonth>
                  <WrapHeaderSumMonth>
                    <MainCoin width={24} />
                    <Typography
                      variant="subtitle1"
                      style={{ color: "#ffffff99" }}
                    >
                      &nbsp;แลกคืน
                    </Typography>
                  </WrapHeaderSumMonth>
                  <div>
                    <ArrowDropUpIcon
                      style={{ color: "#4caf50", fontSize: 32 }}
                    />
                    <Typography variant="h5" style={{ color: "#ffffffde" }}>
                      {sumMonthCoinIn}
                    </Typography>
                  </div>
                </WrapSumMonth>
              </div>
              <TableCoin
                rows={coinInReward.data}
                idCoinType={coinType.idCoinType}
                transferName={"แลกคืน"}
                transferType={"IN"}
              />
              <TableCoin
                rows={coinOutActivity.data}
                idCoinType={coinType.idCoinType}
                transferName={"แจกออก activity"}
                transferType={"OUT"}
              />
              <TableCoin
                rows={transferCoinOutMission}
                idCoinType={coinType.idCoinType}
                transferName={"แจกออก mission"}
                transferType={"OUT"}
              />
              <TableCoin
                rows={transferCoinOutCommu}
                idCoinType={coinType.idCoinType}
                transferName={"แจกออก communication"}
                transferType={"OUT"}
              />
              <TableCoin
                rows={transferCoinOutMoraleDaily}
                idCoinType={coinType.idCoinType}
                transferName={"แจกออก morale daily"}
                transferType={"OUT"}
              />
              <TableCoin
                rows={transferCoinOutAnnouncement}
                idCoinType={coinType.idCoinType}
                transferName={"แจกออก announcement"}
                transferType={"OUT"}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default MainTransfer;
