import React from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { Box, IconButton } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";

const CurrentPositionControl = () => {
  const map = useMap();

  const resetToCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      map.panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  return (
    <Box bgcolor={"#ffffff"}>
      <IconButton size="small" color="primary" onClick={resetToCurrentPosition}>
        <MyLocationIcon />
      </IconButton>
    </Box>
  );
};

export default CurrentPositionControl;
