import {
  COMMUNICATION_FETCHING,
  COMMUNICATION_FAILED,
  COMMUNICATION_SUCCESS,
} from "../actions/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case COMMUNICATION_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case COMMUNICATION_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case COMMUNICATION_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
