import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "./types";

import AuthService from "../services/auth.service";

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      // const message =
      //   (error.response &&
      //     error.response.data &&
      //     error.response.data.message) ||
      //   error.message ||
      //   error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject(error);
    }
  );
};

export const changePassword = (formData, id) => async () => {
  try {
    const res = await AuthService.changePassword(formData, id);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
