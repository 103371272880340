import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../../utils";
import TableCoin from "./tableCoin";
import HDHeart from "./assets/HDHeart.png";
import { HeartCoin } from "../../shared/typeCoin";
import { getTransferHeartOut } from "../../../../actions/transfer";
import { useCoinInReward } from "../../../../quries/coin";
import { useCoinType } from "../../../../quries/coin";
import TableHeartOut from "./tableHeartOut";

const StyledTagHeader = styled(Typography)({
  color: "white",
  position: "absolute",
  top: 22,
  left: 84,
  fontSize: 18,
});

const WrapCard = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginBottom: 48,
  ["@media only screen and (max-width:600px)"]: {
    justifyContent: "flex-end",
  },
  "& .label-card": {
    width: 200,
    ["@media only screen and (max-width:600px)"]: {},
  },
});

const StyledCard = styled(Card)({
  width: 400,
});

const StyledCardContent = styled(CardContent)({
  padding: "0 !important",
  backgroundColor: "#f8bd28",
});

const StyledTextUpdate = styled(Typography)({
  lineHeight: 1,
  padding: 8,
  paddingBottom: 0,
  color: "#ffffff",
});

const WrapCardTodayCount = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  height: 140,
  paddingTop: 26,
});

const WrapCardYearCount = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  padding: 8,
  marginTop: 8,
  position: "relative",
  backgroundColor: "#aa291f",
});

const tagYear = {
  padding: "4px 8px",
  lineHeight: 1,
  height: 90,
  fontSize: 18,
  position: "absolute",
  top: 150,
  left: "50%",
  marginLeft: -40,
  textAlign: "center",
  border: 0,
  width: 80,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const StyledTagYear = styled(Paper)({
  ...tagYear,
  backgroundColor: "#aa291f",
});

const WrapHeaderSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledIconButton = styled(IconButton)({
  color: "white",
  "&:hover": {
    backgroundColor: "#ffffff42",
  },
});

const WrapSumMonth = styled("div")({
  borderLeft: "4px solid #d8dbe0",
  borderRadius: 4,
  padding: "0 16px",
  width: "100%",
});

const WrapHeaderSumMonth = styled("div")({
  display: "flex",
  alignItems: "center",
});

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const HeartTransfer = () => {
  const dispatch = useDispatch();
  const [filterDate, setFilterDate] = useState(dayjs());
  const [today] = useState(dayjs());

  const { data: coinType } = useCoinType((data) =>
    data.find((item) => item.coinType === "heart")
  );

  const heartCoinIn = useCoinInReward({
    idCoinType: coinType?.idCoinType,
  });
  const { result: transferHeartOut } = useSelector(
    (state) => state.transferHeartOut
  );

  const handleChangeMonth = (action) => {
    if (action === 1) {
      if (dayjs().isSame(filterDate.add(1, "month"), "month")) {
        setFilterDate(dayjs());
      } else {
        setFilterDate((prev) => prev.add(1, "month"));
      }
    } else if (action === -1) {
      setFilterDate((prev) => prev.subtract(1, "month"));
    }
  };

  const sumHeartOut = (unit) => {
    return transferHeartOut?.reduce((prev, curr) => {
      return dayjs(filterDate).isSame(curr.transactionDate, unit)
        ? curr.coin + prev
        : prev;
    }, 0);
  };

  const sumDayHeartOut = sumHeartOut("day") || 0;
  const sumMonthHeartOut = sumHeartOut("month") || 0;
  const sumYearHeartOut = sumHeartOut("year") || 0;

  const sumHeartIn = (unit) => {
    return heartCoinIn.data?.reduce((prev, curr) => {
      return dayjs(filterDate).isSame(curr.transactionDate, unit)
        ? curr.coin + prev
        : prev;
    }, 0);
  };

  const sumDayHeartIn = sumHeartIn("day") || 0;
  const sumMonthHeartIn = sumHeartIn("month") || 0;
  const sumYearHeartIn = sumHeartIn("year") || 0;

  // Onload
  useEffect(() => {
    dispatch(getTransferHeartOut());
  }, []);

  if (heartCoinIn.isPending) {
    return <CircularProgress />
  }

  return (
    <div>
      <WrapCard>
        <Stack spacing={4} alignItems={"center"}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: -35, left: -50 }}>
              <img src={HDHeart} alt="HDHeart" className={`label-card`} />
              <StyledTagHeader variant="subtitle1">
                {dayjs(today).format("D MMM YYYY")}
              </StyledTagHeader>
            </div>
            <StyledCard>
              <StyledCardContent style={{ backgroundColor: "#cf483d" }}>
                <StyledTextUpdate variant="body2" align="right">
                  Updated: {dayjs(today).format("D-MM-YYYY HH:mm")}
                </StyledTextUpdate>
                <WrapCardTodayCount>
                  <div style={{ width: "100%" }}>
                    <Typography
                      variant="h1"
                      align="center"
                      style={{ lineHeight: 1, color: "#ffffff" }}
                    >
                      {sumDayHeartOut}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ lineHeight: 1, color: "#ffffff" }}
                    >
                      แจกออก
                    </Typography>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ width: 2, marginBottom: 16 }}
                  />
                  <div style={{ width: "100%" }}>
                    <Typography
                      variant="h1"
                      align="center"
                      style={{ lineHeight: 1, color: "#ffffff" }}
                    >
                      {sumDayHeartIn}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ lineHeight: 1, color: "#ffffff" }}
                    >
                      แลกคืน
                    </Typography>
                  </div>
                </WrapCardTodayCount>
                <WrapCardYearCount>
                  <Typography variant="h5" style={{ color: "white" }}>
                    {sumYearHeartOut}
                  </Typography>

                  <Typography variant="h5" style={{ color: "white" }}>
                    {sumYearHeartIn}
                  </Typography>
                </WrapCardYearCount>
                <StyledTagYear variant="outlined">
                  <div>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: "#ffffffb0",
                        paddingBottom: 2,
                      }}
                    >
                      ALL YEAR
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: 1,
                        fontSize: 16,
                        color: "#ffffffb0",
                      }}
                    >
                      {dayjs(filterDate).format("YYYY")}
                    </Typography>
                  </div>
                  <Divider />
                  <div>
                    <Typography
                      variant="body1"
                      style={{
                        lineHeight: 1.2,
                        fontSize: 16,
                        color: "#ffffffb0",
                      }}
                    >
                      {sumYearHeartOut - sumYearHeartIn}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: 12,
                        color: "#ffffffb0",
                        paddingBottom: 2,
                      }}
                    >
                      ยังไม่ได้แลก
                    </Typography>
                  </div>
                </StyledTagYear>
              </StyledCardContent>
            </StyledCard>
          </div>
          {/* <Typography color={"white"} fontSize={24}>
            {`มูลค่า ${utils.numberWithCommas(heartValue.toFixed(2))}฿`}
          </Typography> */}
        </Stack>
      </WrapCard>
      {heartCoinIn.data && transferHeartOut && (
        <div style={{ marginTop: 36, marginBottom: 20 }}>
          <WrapHeaderSection>
            <Tooltip title="เดือนก่อน" aria-label="labelBeforeMonth">
              <StyledIconButton
                onClick={() => handleChangeMonth(-1)}
                aria-label="beforeMonth"
                size="small"
              >
                <KeyboardArrowLeftIcon fontSize="inherit" />
              </StyledIconButton>
            </Tooltip>
            <Typography
              variant="h6"
              style={{
                textTransform: "uppercase",
                color: "white",
                margin: "0 10px",
              }}
            >
              {monthNames[filterDate.month()]} {filterDate.year()}
            </Typography>
            <Tooltip title="เดือนหน้า" aria-label="labelNextMonth">
              <span>
                <StyledIconButton
                  onClick={() => handleChangeMonth(1)}
                  aria-label="nextMonth"
                  size="small"
                  disabled={dayjs().isSame(filterDate, 'month')}
                >
                  <KeyboardArrowRightIcon fontSize="inherit" />
                </StyledIconButton>
              </span>
            </Tooltip>
          </WrapHeaderSection>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 44,
              }}
            >
              <WrapSumMonth>
                <WrapHeaderSumMonth>
                  <HeartCoin width={24} />
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#ffffff99" }}
                  >
                    &nbsp;แจกออก
                  </Typography>
                </WrapHeaderSumMonth>
                <div>
                  <ArrowDropDownIcon
                    style={{ color: "#cf483d", fontSize: 32 }}
                  />
                  <Typography variant="h5" style={{ color: "#ffffffde" }}>
                    {sumMonthHeartOut}
                  </Typography>
                </div>
              </WrapSumMonth>
              <WrapSumMonth>
                <WrapHeaderSumMonth>
                  <HeartCoin width={24} />
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#ffffff99" }}
                  >
                    &nbsp;แลกคืน
                  </Typography>
                </WrapHeaderSumMonth>
                <div>
                  <ArrowDropUpIcon style={{ color: "#4caf50", fontSize: 32 }} />
                  <Typography variant="h5" style={{ color: "#ffffffde" }}>
                    {sumMonthHeartIn}
                  </Typography>
                </div>
              </WrapSumMonth>
            </div>
            <TableCoin
              rows={heartCoinIn.data}
              idCoinType={coinType.idCoinType}
              transferName={"แลกคืน"}
              transferType={"IN"}
            />
            <TableHeartOut
              rows={transferHeartOut}
              idCoinType={coinType.idCoinType}
              transferName={"แจกออก"}
              transferType={"OUT"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeartTransfer;
