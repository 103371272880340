import React from "react";
import Typography from "@mui/material/Typography";

const DashboardPage = () => {
  return (
    <div className="page dashboard-page">
      <Typography>Dashboard Manager</Typography>
    </div>
  );
};

export default DashboardPage;
