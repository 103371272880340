import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ConfettiRedeem = () => {
  const [dimenSion, setDimenSion] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const detectSize = () => {
    setDimenSion({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [dimenSion]);

  return <Confetti width={dimenSion.width} height={dimenSion.height} />;
}

export default ConfettiRedeem;
