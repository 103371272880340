import {
  COACH_HISTORY_FETCHING,
  COACH_HISTORY_FAILED,
  COACH_HISTORY_SUCCESS,
  COACHEE_HISTORY_FETCHING,
  COACHEE_HISTORY_FAILED,
  COACHEE_HISTORY_SUCCESS,
} from "./types";

import coachHistoryService from "../services/history.service";

export const getCoachHistory = (idCoach) => async (dispatch) => {
  try {
    dispatch({
      type: COACH_HISTORY_FETCHING,
    });
    const res = await coachHistoryService.getCoachHistory(idCoach);
    if (res) {
      dispatch({
        type: COACH_HISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACH_HISTORY_FAILED,
    });
    console.log(err);
  }
};

export const getCoacheeHistory = (idCoachee) => async (dispatch) => {
  try {
    dispatch({
      type: COACHEE_HISTORY_FETCHING,
    });
    const res = await coachHistoryService.getCoacheeHistory(idCoachee);
    if (res) {
      dispatch({
        type: COACHEE_HISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: COACHEE_HISTORY_FAILED,
    });
    console.log(err);
  }
};
