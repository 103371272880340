import { combineReducers } from "redux";
import auth from "./auth";
import userProfile from "./user-profile";
import coachProfile from "./coach-profile";
import users from "./user";

import course from "./course";
import department from "./department";
import courseItem from "./course-item";
import heartTransfer from "./heartTransfer";
import question from "./question";
import moraleQuestions from "./moraleQuestions";
import moraleQuestionItem from "./moraleQuestion-item";
import moraleUser from "./morale-user";
import moraleKPI from "./morale-kpi";
import moraleKPIItem from "./morale-kpi-item";
import moraleCompare from "./morale-compare";
import moraleKpiYear from "./morale-kpi-year";
import moraleKpiYearItem from "./morale-kpi-year-item";
import reward from "./reward";
import rewardBestSeller from "./reward-best-seller";
import myreward from "./my-reward";
import rewardItem from "./reward-item";
import activity from "./activity";
import activityItem from "./activity-item";
import activityHot from "./activity-hot";
import activityRegister from "./activity-register";
import alertDailyInsight from "./alert-daily-insight";
import coachee from "./coachee";
import coachItem from "./coach-item";
import coachPopular from "./coach-popular";
import coachRecord from "./coach-record";
import coachReview from "./coach-review";
import coachReviewItem from "./coach-review-item";
import coach from "./coach";
import coachHistory from "./coach-history";
import coacheeHistory from "./coachee-history";
import favoriteCoach from "./favorite-coach";
import coachFix from "./coach-fix";
import coachIsRegistered from "./coach_registered";
import coacheeProfile from "./coachee-profile";
import category from "./category";
import book from "./book";
import bookCoach from "./book-coach";
import bookCoachee from "./book-coachee";
import assessment from "./assessment";
import assessmentItem from "./assessment-Item";
import assessmentAnswer from "./assessmentAnswer";
import appraisee from "./appraisee";
import appraiseeItem from "./appraisee-item";
import appraiseeUser from "./appraisee-user";
import dataTopic from "./dataTopic";
import specialCoin from "./specialCoin";
import notification from "./notification";
import redeemTransaction from "./redeemTransaction";
import mission from "./mission";
import poll from "./poll";
import answerScale from "./answer-scale";
import questionTopic from "./question-topic"
import moraleDailyQuestion from "./moraleDailyQuestion";
import moraleDaily from "./moraleDaily";
import moraleDailyItem from "./moraleDaily-item";
import moraleDailyUser from "./moraleDaily-user";
import moraleDailyCurrent from "./moraleDaily-current";
import feedback from "./feedback";
import activityParticipant from "./activity-participant";
import heartLeaderboard from "./heart-leaderboard";
import coinLeaderboard from "./coin-leaderboard";
import managerHeartLeaderboard from "./manager-heart-leaderboard";
import heartRank from "./heart-rank";
import transferHeartOut from "./transfer-heart-out";

import transferCoinOutAnnouncement from "./transfer-coin-out-announcement";
import transferCoinOutCommu from "./transfer-coin-out-commu";
import transferCoinOutMission from "./transfer-coin-out-mission";
import transferCoinOutMoraleDaily from "./transfer-coin-out-morale-daily";
import heartSendMonth from "./heart-send-month";
import heartReceiveYear from "./heart-receive-year";
import announcement from "./announcement";
import communication from "./communication";
import analytic from "./analytic";
import companySendCoin from "./company-send-coin";

import uniReward from "./uniReward";
import uniRewardItem from "./uniRewardItem";
import uniRewardCart from "./uniRewardCart";
import uniRewardOrder from "./uniRewardOrder";

import companyReward from "./companyReward";
import tokenomic from "./tokenomic";

import affiliateOrganization from "./affiliate-organization";
import userCoin from "./user-coin";
import userCoinQuota from "./user-coin-quota"

const appReducer = combineReducers({
  auth,
  userProfile,
  coachProfile,
  users,
  course,
  department,
  courseItem,
  heartTransfer,
  question,
  moraleQuestions,
  moraleQuestionItem,
  moraleUser,
  moraleCompare,
  moraleKPI,
  moraleKPIItem,
  moraleKpiYear,
  moraleKpiYearItem,
  reward,
  rewardBestSeller,
  rewardItem,
  myreward,
  activity,
  activityItem,
  activityHot,
  activityRegister,
  alertDailyInsight,
  coachee,
  coachItem,
  coachPopular,
  coachRecord,
  coachReview,
  coachReviewItem,
  coach,
  coachHistory,
  coacheeHistory,
  coachFix,
  coacheeProfile,
  coachIsRegistered,
  favoriteCoach,
  category,
  book,
  bookCoach,
  bookCoachee,
  assessment,
  assessmentItem,
  assessmentAnswer,
  appraisee,
  appraiseeItem,
  appraiseeUser,
  dataTopic,
  specialCoin,
  notification,
  redeemTransaction,
  mission,
  poll,
  answerScale,
  questionTopic,
  moraleDailyQuestion,
  moraleDaily,
  moraleDailyItem,
  moraleDailyUser,
  moraleDailyCurrent,
  feedback,
  activityParticipant,
  heartLeaderboard,
  coinLeaderboard,
  managerHeartLeaderboard,
  heartRank,
  // transferHeartIn,
  transferHeartOut,
  // transferCoinIn,
  // transferCoinOutActivity,
  transferCoinOutAnnouncement,
  transferCoinOutCommu,
  transferCoinOutMission,
  transferCoinOutMoraleDaily,
  
  heartSendMonth,
  heartReceiveYear,
  announcement,
  communication,
  analytic,
  companySendCoin,

  uniReward,
  uniRewardItem,
  uniRewardCart,
  uniRewardOrder,

  companyReward,
  tokenomic,
  affiliateOrganization,
  userCoin,
  userCoinQuota,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;