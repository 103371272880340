import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import "date-fns";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import {
  Divider,
  IconButton,
  Alert,
  Select,
  MenuItem,
  AlertTitle,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Menu,
  FormHelperText,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BackspaceIcon from "@mui/icons-material/Backspace";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import ActivityIcon from "./assets/calendar.png";
import HeaderPage from "../../../shared/header/headerPage";

import { useDispatch, useSelector } from "react-redux";
import {
  getActivity,
  updateActivity,
  updateActivityStatus,
} from "../../../../../actions/activity";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadFile from "../../../shared/uploadFile/uploadFile";
import MapComponent from "./mapComponents/map";
import { useCoinBudget, useCoinType } from "../../../../../quries/coin";
import InputField from "../../../shared/inputForm/inputField";
import SelectEmployees from "../../../shared/general/selectEmployees";
import SelectAffiliateCompany from "../../../shared/general/selectAffiliateCompany";
import { getAllEmployees } from "../../../../../actions/user";
import { getAffiliateOrganization } from "../../../../../actions/company";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapForm = styled("div")({
  margin: "0px auto",
  width: "80%",
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function EditActivity() {
  const navigate = useNavigate();
  const { state: { idActivity }} = useLocation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganization } = useSelector((state) => state.affiliateOrganization);
  const { result: activity, isFetching } = useSelector((state) => state.activityItem);
  const coinType = useCoinType()
  const coinBudget = useCoinBudget({ idCompany: userProfile?.idCompany });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    navigate("/admin/manage/activity");
  };

  const handleOpenAlert = (text) => {
    setDisplayName(text);
    setOpenAlert(true);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required("Name is required"),
    detail: yup.string().max(200).required("Detail is required"),
    activityType: yup.string(),
    link: yup
      .string()
      .nullable()
      .when("activityType", {
        is: (activityType) => activityType === "online",
        then: yup.string().max(100).required("Link is require"),
      }),
    location: yup.string().when("activityType", {
      is: (activityType) => activityType === "onsite",
      then: yup.string().max(100).nullable().required("Location is require w"),
    }),
    coordinate: yup.object().when("activityType", {
      is: (activityType) => activityType === "onsite",
      then: yup.object().shape({
        lat: yup.number().nullable().required("โปรดเลือกพิกัดในแผนที่"),
        lng: yup.number().nullable().required("โปรดเลือกพิกัดในแผนที่"),
      }),
    }),
    target: yup.string().required("โปรดเลือกผู้เข้าร่วมกิจกรรม"),
    register: yup.number().when("target", {
      is: (target) => target === "company" || target === "department",
      then: yup.number().min(1).integer().required("Register is require"),
    }),
    openRegisterDate: yup.date(),
    closeRegisterDate: yup
      .date()
      .when("openRegisterDate", (openRegisterDate, schema) => {
        if (openRegisterDate) {
          return schema.min(
            dayjs(openRegisterDate).add(1, "day"),
            "Close date has to be after open date"
          );
        }
        return schema;
      })
      .when("endDate", (endDate, schema) => {
        if (endDate) {
          return schema.max(endDate, "Close date has to be before end date");
        }
        return schema;
      }),
    startDate: yup
      .date()
      .when("openRegisterDate", (openRegisterDate, schema) => {
        if (openRegisterDate) {
          return schema.min(
            openRegisterDate,
            "Start date has to be after than open register date"
          );
        }
        return schema;
      }),
    endDate: yup.date().when("startDate", (startDate, schema) => {
      if (startDate) {
        return schema.min(
          startDate,
          "End date has to be after than start date"
        );
      }
      return schema;
    }),
    coins: yup.array().of(
      yup.object({
        idCoinType: yup.string().required("Please select coin type"),
        amount: yup.number()
          .typeError("Amount must be a number")
          .min(1)
          .integer()
          .required("Please enter coin amount"),
      })
    ).min(1),
    organizer: yup.string().max(100).required("Organizer is required"),
    contact: yup.string().max(100).required("Contact is required"),
    image: yup.mixed(),
    file: yup.mixed(),
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      companyList: [
        {
          company: "",
          department: [],
        },
      ],
      selectedPerson: [],
      selectedCompany: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyList",
  });

  const coins = useFieldArray({
    control,
    name: "coins",
  });

  const watchTarget = watch("target");
  const selectdCompanyList = watch("companyList").map((item) => item.company);

  const handleEditActivity = async (values) => {
    values.selectedCompany = [...values.selectedCompany].map(
      (item) => item.idCompany
    );

    values.selectedPerson = [...values.selectedPerson].map(
      (item) => item.idEmployees
    );

    values.selectedDepartment = values.companyList
      .map((company) => company.department.map((dep) => dep.idDepartment))
      .flat();

    delete values.companyList

    const formData = new FormData();
    for (let key in values) {
      if (Array.isArray(values[key]) || key === "coordinate") {
        formData.append(key, JSON.stringify(values[key]));
      } else if (
        (values[key] !== null && values[key] !== "") ||
        key === "file"
      ) {
        formData.append(key, values[key]);
      }
    }

    const res = await dispatch(updateActivity(values.idActivity, formData));
    if (res) {
      handleOpenAlert(`ได้ทำการแก้ไขกิจกรรม ${values.name}`);
    }
  };

  const handleUpdateActivityStatus = async (status) => {
    const res = await dispatch(
      updateActivityStatus({ idActivity: activity.idActivity, status })
    );
    if (res) {
      const displayAlertText =
        status === "close" ? "ปิดกิจกรรมเรียบร้อย" : "ยกเลิกกิจกรรมแล้ว";
      handleOpenAlert(displayAlertText);
      navigate("/admin/manage/activity");
    }
  };

  useEffect(() => {
    dispatch(getActivity(idActivity));
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, [idActivity])

  useEffect(() => {
    if (activity) {
      reset({ 
        ...activity,
        startDate: dayjs(activity.startDate),
        endDate: dayjs(activity.endDate),
        openRegisterDate: dayjs(activity.openRegisterDate),
        closeRegisterDate: dayjs(activity.closeRegisterDate),
      })
    }
  }, [activity])

  if (coinType.isPending || coinBudget.isPending || isFetching) {
    return <CircularProgress />
  }

  return (
    <StyledRoot className={"page"}>
      {activity && (
        <>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"flex-end"}
            rowGap={1}
          >
            <HeaderPage textLabel={"แก้ไขกิจกรรม"} icon={ActivityIcon} />
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Options
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => handleUpdateActivityStatus("close")}
                disableRipple
              >
                <RemoveCircleIcon />
                ปิดรับสมัครเข้าร่วมกิจกรรม
              </MenuItem>
              <MenuItem
                onClick={() => handleUpdateActivityStatus("cancel")}
                disableRipple
              >
                <CloseIcon />
                ยกเลิกกิจกรรม
              </MenuItem>
            </StyledMenu>
          </Stack>
          <StyledDivider />
          <WrapForm>
            <form onSubmit={handleSubmit(handleEditActivity)}>
              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="ชื่อกิจกรรม"
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="detail"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="รายละเอียดกิจกรรม"
                        fullWidth
                        multiline
                        rows={2}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Controller
                    name="activityType"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>ประเภทกิจกรรม</FormLabel>
                        <RadioGroup
                          {...field}
                          row
                          onChange={(event, value) => {
                            if (value === "onsite") {
                              setValue("link", "");
                            } else {
                              setValue("location", "");
                            }
                            setValue("activityType", value);
                          }}
                        >
                          <FormControlLabel
                            value="onsite"
                            control={<Radio />}
                            label="Onsite"
                          />
                          <FormControlLabel
                            value="online"
                            control={<Radio />}
                            label="Online"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Grid>
                {watch("activityType") === "onsite" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Controller
                        name="location"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label="สถานที่จัดกิจกรรม"
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="coordinate"
                        control={control}
                        render={({ field: { onChange } }) => (
                          <MapComponent
                            lat={activity.lat}
                            lng={activity.lng}
                            setFieldValue={onChange}
                            error={errors?.coordinate}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    display="flex"
                    alignItems="flex-end"
                  >
                    <Controller
                      name="link"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Link กิจกรรม"
                          fullWidth
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={5}>
                  <Controller
                    name="organizer"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="ชื่อผู้จัดกิจกรรม"
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Controller
                    name="contact"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="การติดต่อ"
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    name="register"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="จำนวนที่เปิดรับสมัคร"
                        type="number"
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="target"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl error={Boolean(fieldState.error)}>
                        <FormLabel>ผู้เข้าร่วมกิจกรรม</FormLabel>
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="company"
                            control={<Radio />}
                            label="เลือกบริษัท"
                          />
                          <FormControlLabel
                            value="department"
                            control={<Radio />}
                            label="เลือกกลุ่มงาน"
                          />
                          <FormControlLabel
                            value="person"
                            control={<Radio />}
                            label="เลือกพนักงาน"
                          />
                        </RadioGroup>
                        {fieldState.error && (
                          <FormHelperText>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                {watchTarget === "company" && (
                  <Grid item xs={12}>
                    <Controller
                      name={`selectedCompany`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <Typography fontWeight={600} fontSize={14} marginBottom={1} color="text.third">
                            ค้นหาบริษัท
                          </Typography>
                          <SelectAffiliateCompany
                            {...field}
                            handleChange={field.onChange}
                            multiple={true}
                          />
                          {fieldState.error && (
                            <Typography color="error" marginTop={1}>
                              {fieldState.error.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                )}
                {watchTarget === "department" && (
                  <Grid item xs={12} sm={12}>
                    <Stack spacing={1} alignItems={"flex-end"}>
                      {fields.map((field, index) => (
                        <Stack
                          key={field.id}
                          width={"100%"}
                          alignItems="flex-end"
                          spacing={2}
                        >
                          <Controller
                            name={`companyList.${index}.company`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <FormControl
                                fullWidth
                                error={Boolean(fieldState.error)}
                              >
                                <InputLabel>เลือกบริษัท</InputLabel>
                                <Select {...field} label="เลือกบริษัท">
                                  {affiliateOrganization.map((option) => (
                                    <MenuItem
                                      key={option.idCompany}
                                      value={option.idCompany}
                                      disabled={selectdCompanyList.includes(
                                        option.idCompany
                                      )}
                                    >
                                      {option.companyName}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {fieldState.error && (
                                  <FormHelperText>
                                    {fieldState.error.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                          <Controller
                            name={`companyList.${index}.department`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <Autocomplete
                                {...field}
                                fullWidth
                                multiple
                                options={
                                  watch(`companyList.${index}.company`)
                                    ? affiliateOrganization.find(
                                        (item) =>
                                          item.idCompany ===
                                          watch(`companyList.${index}.company`)
                                      )?.department
                                    : []
                                }
                                disabled={!watch(`companyList.${index}.company`)}
                                getOptionLabel={(option) => option.departmentName}
                                isOptionEqualToValue={(option, value) =>
                                  option.idDepartment === value.idDepartment
                                }
                                onChange={(event, value) => {
                                  field.onChange(value);
                                }}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.idDepartment} value={option.idDepartment}>
                                    {option.departmentName}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="เลือหน่วยงาน"
                                    error={Boolean(fieldState.error)}
                                    helperText={
                                      fieldState.error && fieldState.error.message
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                          <IconButton color="error" onClick={() => remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        onClick={() =>
                          append({
                            company: "",
                            department: [],
                          })
                        }
                        startIcon={<AddIcon />}
                      >
                        เพิ่มหน่วยงาน
                      </Button>
                    </Stack>
                  </Grid>
                )}
                {watchTarget === "person" && (
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="selectedPerson"
                      control={control}
                      render={({ fieldState, field }) => (
                        <>
                          <SelectEmployees
                            value={field.value}
                            handleChange={field.onChange}
                            multiple={true}
                          />
                          {fieldState.error && (
                            <Typography color="error" marginTop={1}>
                              {fieldState.error.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="openRegisterDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        label="วันที่เปิดรับสมัคร"
                        minDate={dayjs()}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(fieldState.error),
                            helperText: fieldState.error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="closeRegisterDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        label="วันที่ปิดรับสมัคร"
                        minDate={dayjs(watch("openRegisterDate")).add(1, "day")}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(fieldState.error),
                            helperText: fieldState.error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateTimePicker
                        {...field}
                        label="วันที่เริมกิจกรรม"
                        minDateTime={dayjs(watch("openRegisterDate"))}
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(fieldState.error),
                            helperText: fieldState.error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateTimePicker
                        {...field}
                        label="วันที่สิ้นสุดกิจกรรม"
                        minDateTime={dayjs(watch("startDate"))}
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(fieldState.error),
                            helperText: fieldState.error?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>เหรียญรางวัล</Typography>
                  <Stack spacing={1} marginBottom={1} marginTop={1}>
                    {coins.fields.map((field, index) => (
                      <div
                        key={field.id}
                        style={{ display: "flex", gap: "0.5rem" }}
                      >
                        <FormControl fullWidth error={Boolean(errors?.coins?.[index]?.idCoinType)}>
                          <InputLabel>{"เหรียญ"}</InputLabel>
                          <Controller
                            control={control}
                            name={`coins.${index}.idCoinType`}
                            render={({ field }) => (
                              <Select {...field} label={"เหรียญ"}>
                                {coinType.data
                                  .filter((item) => item.coinType !== "heart")
                                  .map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item.idCoinType}
                                      disabled={watch("coins")?.some((coin) => coin.idCoinType === item.idCoinType)}
                                    >
                                      {item.coinName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                          <FormHelperText>
                            {errors?.coins?.[index]?.idCoinType?.message}
                          </FormHelperText>
                        </FormControl>
                        <InputField
                          control={control}
                          name={`coins.${index}.amount`}
                          type={"number"}
                          label={"จำนวน"}
                        />
                        <IconButton
                          type="button"
                          color="error"
                          onClick={() => coins.remove(index)}
                          disabled={coins.fields.length === 1}
                        >
                          <BackspaceIcon />
                        </IconButton>
                      </div>
                    ))}
                  </Stack>
                  {coins.fields.length < 2 && (
                    <Button
                      type="button"
                      variant="outlined"
                      startIcon={<AddCircleIcon />}
                      onClick={() => coins.append({ idCoinType: "", amount: "" })}
                      disabled={coins.fields.length === 2}
                    >
                      เพิ่ม
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="image"
                    control={control}
                    render={({ field }) => (
                      <UploadFile
                        {...field}
                        error={errors.image}
                        acceptFileType={"image/jpeg, image/png"}
                        acceptFileTypeLabel={"*.jpeg and *.png images"}
                        name={"รูปภาพหน้าปกกิจกรรม"}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <UploadFile
                        {...field}
                        error={errors.file}
                        acceptFileType={
                          "image/jpeg, image/png, application/pdf"
                        }
                        acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf"}
                        name={"ไฟล์รายละเอียดกิจกรรม"}
                      />
                    )}
                  />
                </Grid>
                {errors.coordinate && (
                  <Grid item xs={12}>
                    <Typography color="error" textAlign="end">
                      {errors.coordinate.lat.message}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      component={Link}
                      to={"/admin/manage/activity"}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={isSubmitting}
                    >
                      บันทึก
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </WrapForm>
        </>
      )}

      <Dialog open={openAlert} onClose={handleCloseAlert}>
        <Alert severity="success" onClose={handleCloseAlert}>
          <AlertTitle>สำเร็จ</AlertTitle>
          {displayName}
        </Alert>
      </Dialog>
    </StyledRoot>
  );
}

export default EditActivity;
