import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputField from "../../../../../shared/inputForm/inputField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BackspaceIcon from "@mui/icons-material/Backspace";
import "./tiptap.css";
import { useCoinType } from "../../../../../../../quries/coin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import DateInputField from "../../../../../shared/inputForm/dateInputField";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";
import SwitchInput from "../../../../../shared/inputForm/switchInput";
import { useUpdateUniRewardItemCoin } from "../../../../../../../quries/reward";
import LoadingIcon from "../../../../../shared/general/loadingIcon";

function DialogConfig({ open, handleClose, selectReward }) {
  const { state } = useLocation()

  const updateUniRewardItemCoin = useUpdateUniRewardItemCoin({
    idUniReward: state?.idUniReward,
  });

  const { data: coinType, isPending } = useCoinType();

  const validationSchema = yup.object().shape({
    startDate: yup.date().nullable(),
    endDate: yup.date().when("startDate", (startDate) => {
      return yup.date().nullable().min(
        startDate,
        "End date must be later than start date"
      );
    }),
    coins: yup.array().of(
      yup.object({
        idCoinType: yup.string().required("Please select coin type"),
        amount: yup.number()
          .typeError("Amount must be a number")
          .min(1)
          .integer()
          .required("Please enter coin amount"),
      })
    )
    .min(1),
  });

  const defaultValues = {
    coins:
      selectReward.coins.length > 0
        ? selectReward.coins.map((item) => ({
            idCoinType: item.idCoinType,
            amount: item.amount,
          }))
        : [{ idCoinType: "", amount: "" }],
    startDate: selectReward.startDate ? dayjs(selectReward.startDate) : dayjs(),
    endDate: selectReward.endDate
      ? dayjs(selectReward.endDate)
      : dayjs().add(1, "day"),
    isSetDate: selectReward.startDate || selectReward.endDate ? true : false,
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "coins",
  });

  const handleConfigReward = (values) => {
    const formData = {
      idUniRewardItem: selectReward?.idUniRewardItem,
      coins: values.coins,
      startDate: values.isSetDate ? values.startDate : null,
      endDate: values.isSetDate ? values.endDate : null,
    }

    updateUniRewardItemCoin.mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  }

  if (isPending) {
    return <LoadingIcon />
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>รายละเอียดเหรียญรางวัล</DialogTitle>
      <form onSubmit={handleSubmit(handleConfigReward)}>
        <DialogContent>
          <DialogContentText>
            เพิ่มเงื่อนไขเหรียญให้กับการแลกรางวัลและกำหนดวันที่แลกของรางวัล
          </DialogContentText>
          <Stack marginTop={2} spacing={2}>
            <SwitchInput
              control={control}
              name={"isSetDate"}
              label={"กำหนดวันที่แลก"}
            />
            {watch("isSetDate") && (
              <Stack direction={"row"} spacing={1}>
                <DateInputField
                  control={control}
                  name={"startDate"}
                  label={"วันที่เริ่ม"}
                />
                <DateInputField
                  control={control}
                  name={"endDate"}
                  label={"วันที่สิ้นสุด"}
                  minDate={dayjs(watch("startDate"))}
                />
              </Stack>
            )}
            {fields.map((field, index) => (
              <Stack
                key={field.id}
                direction={"row"}
                alignItems={"flex-start"}
                spacing={1}
              >
                <Controller
                  control={control}
                  name={`coins.${index}.idCoinType`}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth error={Boolean(fieldState.error)}>
                      <InputLabel>{"เหรียญ"}</InputLabel>
                      <Select {...field} label={"เหรียญ"}>
                        {coinType.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.idCoinType}
                            disabled={watch("coins")?.some((coin) => coin.idCoinType === item.idCoinType)}
                          >
                            {item.coinName}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.error && (
                        <FormHelperText>{fieldState.error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                <InputField
                  control={control}
                  name={`coins.${index}.amount`}
                  type={"number"}
                  label={"จำนวน"}
                />
                {/* <IconButton
                  type="button"
                  color="error"
                  onClick={() => remove(index)}
                  disabled={fields.length === 1}
                >
                  <BackspaceIcon />
                </IconButton> */}
              </Stack>
            ))}
          </Stack>
          {/* <Button
            type="button"
            startIcon={<AddCircleIcon />}
            onClick={() => append({ idCoinType: "", amount: "" })}
            disabled={fields.length === coinType.length}
          >
            เพิ่ม
          </Button> */}
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogConfig;
