import { httpClient } from "./httpClient";

const getCoachHistory = (idCoach) => {
  return httpClient.get("coach-history/" + idCoach);
};

const getCoacheeHistory = (idCoachee) => {
  return httpClient.get("coachee-history/" + idCoachee);
};

export default {
  getCoachHistory,
  getCoacheeHistory,
};
