import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import dayjs from "dayjs";
import { SpecialIcon } from "../../shared/typeCoin";
import { DatePicker } from "@mui/x-date-pickers";

const WrapHeaderSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const WrapSumMonthTop = styled("div")({
  borderRight: "4px solid #d8dbe0",
  borderRadius: 4,
  padding: "0 16px",
});

const WrapHeaderSumMonth = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledIconButton = styled(IconButton)({
  color: "white",
  "&:hover": {
    backgroundColor: "#ffffff42",
  },
});

const StyledFormControlLabelRadio = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: "white",
  },
});

const WrapHeaderSectionFilterDate = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
});

const StyledTableContainer = styled(TableContainer)({
  height: "100%",
  backgroundColor: "transparent",
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "white",
    borderBottom: "1px solid #404040",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "white",
    borderBottom: "1px solid #404040",
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}));

const StyledTablePagination = styled(TablePagination)({
  color: "white",
  "& .MuiTablePagination-selectLabel": {
    color: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "white",
  },
  "& .MuiIconButton-root.Mui-disabled": {
    color: "white",
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
});

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const TableHeartOut = ({ rows, coinType, transferName, transferType }) => {
  const [filterDate, setFilterDate] = useState(dayjs());
  const [selectedView, setSelectedView] = useState("allMonth");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeView = (event) => {
    setSelectedView(event.target.value);
  };

  const handleChangeMonth = (action) => {
    if (action === 1) {
      if (dayjs().isSame(filterDate.add(1, "month"), "month")) {
        setFilterDate(dayjs());
      } else {
        setFilterDate((prev) => prev.add(1, "month"));
      }
    } else if (action === -1) {
      setFilterDate((prev) => prev.subtract(1, "month"));
    }
  };

  const rowsFilter = () => {
    const result = rows.filter((value) => {
      if (selectedView === "allMonth") {
        if (dayjs(filterDate).isSame(value.transactionDate, "month")) {
          return value;
        }
      } else {
        if (dayjs(filterDate).isSame(value.transactionDate, "day")) {
          return value;
        }
      }
    });
    return result;
  };

  return (
    <>
      {rows && (
        <div>
          <div style={{ marginTop: 36, marginBottom: 20 }}>
            <WrapHeaderSection>
              <WrapSumMonthTop>
                <WrapHeaderSumMonth>
                  <SpecialIcon idCoinType={coinType} width={24} />
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#ffffff99" }}
                  >
                    &nbsp;{transferName}
                  </Typography>
                </WrapHeaderSumMonth>
              </WrapSumMonthTop>
              <Tooltip
                title="เดือนก่อน"
                aria-label="labelBeforeMonth"
                sx={{ paddingLeft: 1 }}
              >
                <StyledIconButton
                  onClick={() => handleChangeMonth(-1)}
                  aria-label="beforeMonth"
                  size="small"
                >
                  <KeyboardArrowLeftIcon fontSize="inherit" />
                </StyledIconButton>
              </Tooltip>
              <Typography
                variant="h6"
                style={{
                  textTransform: "uppercase",
                  color: "white",
                  margin: "0 10px",
                }}
              >
                {monthNames[filterDate.month()]} {filterDate.year()}
              </Typography>
              <Tooltip title="เดือนหน้า" aria-label="labelNextMonth">
                <span>
                  <StyledIconButton
                    onClick={() => handleChangeMonth(1)}
                    aria-label="nextMonth"
                    size="small"
                    disabled={dayjs().isSame(filterDate, 'month')}
                  >
                    <KeyboardArrowRightIcon fontSize="inherit" />
                  </StyledIconButton>
                </span>
              </Tooltip>
            </WrapHeaderSection>
          </div>
          <WrapHeaderSectionFilterDate>
            <FormControl component="fieldset">
              <RadioGroup
                style={{ color: "white" }}
                row
                aria-label="view"
                name="view"
                value={selectedView}
                onChange={handleChangeView}
              >
                <StyledFormControlLabelRadio
                  value="allMonth"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": { color: "#f50057" },
                      }}
                    />
                  }
                  label="ทั้งเดือน"
                />
                <StyledFormControlLabelRadio
                  value="day"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": { color: "#f50057" },
                      }}
                    />
                  }
                  label="วันที่"
                />
              </RadioGroup>
            </FormControl>
            {selectedView === "day" && (
              <StyledDatePicker
                value={filterDate}
                minDate={filterDate.startOf("month")}
                maxDate={dayjs()}
                onChange={(newValue) => setFilterDate(newValue)}
              />
            )}
          </WrapHeaderSectionFilterDate>
          <StyledTableContainer component={Paper} elevation={0}>
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ backgroundColor: "#282933" }}>
                <TableRow>
                  <StyledTableCell>วันที่</StyledTableCell>
                  <StyledTableCell>รายการ</StyledTableCell>
                  <StyledTableCell align="right">จำนวน</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsFilter()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {dayjs(row.transactionDate).format("D MMM YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {transferType === "OUT" ? (
                          "ส่งหัวใจ"
                        ) : (
                          <Typography color={"white"}>{row.name}</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {transferType === "OUT" ? (
                          <ArrowDropDownIcon style={{ color: "#cf483d" }} />
                        ) : (
                          <ArrowDropUpIcon style={{ color: "#4caf50" }} />
                        )}
                        {row.coin}&nbsp;
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <StyledTablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowsFilter().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TableHeartOut;
