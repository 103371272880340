import {
  FAVORITE_COACH_FETCHING,
  FAVORITE_COACH_FAILED,
  FAVORITE_COACH_SUCCESS,
} from "./types";

import favoriteCoachService from "../services/favorite-coach.service";

export const getFavoriteCoach = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: FAVORITE_COACH_FETCHING,
    });
    const res = await favoriteCoachService.getFavoriteCoach(idEmployees);
    if (res) {
      dispatch({
        type: FAVORITE_COACH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: FAVORITE_COACH_FAILED,
    });
    console.log(err);
  }
};

export const addFavoriteCoach = (formData) => async (dispatch) => {
  try {
    const res = await favoriteCoachService.addFavoriteCoach(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: FAVORITE_COACH_FAILED,
    });
    console.log(err);
  }
};

export const deleteFavoriteCoach = (payload) => async (dispatch) => {
  try {
    const res = await favoriteCoachService.deleteFavoriteCoach(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: FAVORITE_COACH_FAILED,
    });
    console.log(err);
  }
};
