// import axios from "axios";
import { httpClient } from "./httpClient";

// const API_URL = process.env.REACT_APP_API_URL + "auth/";

// const login = (username, password) => {
//   console.log('login service')
//   return httpClient
//     .post(process.env.REACT_APP_API_URL + "auth/signin", {
//       username,
//       password,
//     })
//     .then(async (response) => {
//       // alert(JSON.stringify(response.data))
//       if (response.data.accessToken) {
//         localStorage.setItem("user", JSON.stringify(response.data));
//         // if (username === "user") {
//         //   localStorage.setItem("notification", "null");
//         // }
//         if (response.data.roles === "ROLE_USER" || response.data.roles === "ROLE_MANAGER") {
//           localStorage.setItem("notification", "null");
//         }
//       }

//       return response.data;
//     });
// };

const login = (username, password) => {
  return httpClient
    .post("auth/signin", { username, password })
    .then(async (response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
        // if (
        //   response.data.idRole === 1 ||
        //   response.data.idRrole === 2 ||
        //   response.data.idRrole === 3 ||
        //   response.data.idRrole === 4
        // ) {
        //   localStorage.setItem("notification", "null");
        // }
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("notification");
};

const changePassword = (formData, id) => {
  return httpClient.post(`/change-password/${id}`, formData);
};

export default {
  login,
  logout,
  changePassword,
};
