import React, { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  styled,
  CardMedia,
  FormHelperText,
  Alert,
  Snackbar,
  Slide,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import UniLogo from "./assets/UNI_HR_Logo_RGB_Full_Color.png";
import { login } from "../../actions/auth";
import { Navigate, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fi } from "date-fns/locale";
import { toast } from "sonner";

const StyledRoot = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
})

const StyledCard = styled(Card)({
  width: 345,
  marginTop: 100,
  borderRadius: 20,
})

const StyledVersion = styled(Typography)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  marginTop: 10,
})

const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: 10,
  // height: 48,
  // color: theme.palette.getContrastText(red[500]),
  // backgroundColor: "#46cbe2",
  // "&:hover": {
  //   backgroundColor: "#247785",
  // },
}));

const StyledLoginWithMicrosoftButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginTop: 10,
  // height: 48,
  // color: "#FFFFFF",
  // backgroundColor: "#349ffc",
  // "&:hover": {
  //   backgroundColor: "#349ffc",
  // },
}));

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};


const PasswordField = ({ isSubmitting, value, onChange, error, helperText }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%", marginTop: 8 }}
      error={error}
      fullWidth
      margin="normal"
    >
      <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
      <OutlinedInput
        disabled={isSubmitting}
        label="รหัสผ่าน"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText error id="accountId-error">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const LoginPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const msalInstance = new PublicClientApplication({
  //   auth: {
  //     clientId: "230e85f8-d4fa-4dbe-8bb8-d9e2b00dcaa0",
  //     redirectUri: "http://localhost:3000",
  //   },
  //   cache: {
  //     cacheLocation: "sessionStorage",
  //     storeAuthStateInCookie: true,
  //   },
  // });

  // const startLogin = async (e) => {
  //   e.preventDefault();

  //   var loginRequest = {
  //     scopes: ["230e85f8-d4fa-4dbe-8bb8-d9e2b00dcaa0/.default"], // optional Array<string>
  //   };

  //   try {
  //     const loginResponse = msalInstance.loginPopup({
  //       scopes: ["user.read"],
  //       prompt: "select_account",
  //     });

  //     const account = (await loginResponse).account;
  //     const username = (await loginResponse).account.username;
  //     const token = (await loginResponse).accessToken;

  //     console.log(account);

  //     // const resJson = {
  //     //   token: token,
  //     //   user: username
  //     // };

  //     // dispatch({
  //     //   type: "LOGIN",
  //     //   payload: resJson
  //     // })
  //     console.log("Login successfully");
  //   } catch (err) {
  //     console.log("Authentication Failed.....");
  //     console.log(err);
  //   }
  // };

  const validate = yup.object({
    username: yup.string(), 
    password: yup.string(), 
    showPassword: yup.boolean(),
  })

  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    defaultValues: { username: "", password: "", showPassword: false },
    resolver: yupResolver(validate)
  })

  const handleLogin = async (values) => {
    try {
      await dispatch(login(values.username, values.password))
      navigate("/", { replace: true })
    } catch (err) {
      if (err.status === 401) {
        toast.error("ชื่อผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง")
      } else {
        toast.error("เกิดข้อผิดพลาด")
      }
    }
  }

  const { user: currentUser } = useSelector((state) => state.auth);
  if(currentUser) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <StyledRoot>
      <StyledCard raised>
        <CardMedia
          component="img"
          height="240"
          alt="uni logo"
          image={UniLogo}
        />
        <CardContent>
          <Typography variant="h4">เข้าสู่ระบบ</Typography>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Stack gap={1} marginTop={2} marginBottom={2}>
              <Controller
                control={control}
                name="username"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="รหัสผู้ใช้"
                    autoFocus
                    disabled={isSubmitting}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState }) => (
                  <PasswordField
                    value={field.value}
                    onChange={field.onChange}
                    isSubmitting={isSubmitting}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            </Stack>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait..." : "เข้าสู่ระบบ"}
            </Button>
          </form>
        </CardContent>
      </StyledCard>
    </StyledRoot>
  );
};

export default LoginPage;
