import React from "react";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";

function InputField({ control, name, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({ name, control });

  return (
    <TextField
      {...field}
      {...props}
      fullWidth
      error={Boolean(error)}
      helperText={error?.message}
    />
  );
}

export default InputField;
