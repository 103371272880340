import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import DialogEditQuota from "./dialogEdit";
import {
  getAllCoinQuota,
  updateSendHeartQuota,
} from "../../../../../actions/user";
import SelectEmployees from "../../../shared/general/selectEmployees";
import { useOrganization } from "../../../../../quries/company";
import LoadingIcon from "../../../shared/general/loadingIcon";

const SendHeartQuota = () => {
  const organization = useOrganization();
  const dispatch = useDispatch();
  const { result: userCoinQuota, isFetching } = useSelector(
    (state) => state.userCoinQuota
  );

  const [open, setOpen] = useState(false);
  const [companySelected, setCompanySelected] = useState(null);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });

  const columns = [
    {
      field: "fullname",
      headerName: "ชื่อ-นามสกุล",
      headerClassName: "theme-header",
      type: "string",
      flex: 3,
      sortable: false,
      disableExport: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Avatar alt={"userImage"} src={params.row.imageURL} />
          <Typography>{`${params.row.firstname_TH} ${params.row.lastname_TH}`}</Typography>
        </div>
      ),
    },
    {
      field: "divisionName",
      headerName: "ฝ่าย",
      headerClassName: "theme-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "departmentName",
      headerName: "ส่วน",
      headerClassName: "theme-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "sectionName",
      headerName: "แผนก",
      headerClassName: "theme-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "positionName",
      headerName: "ตำแหน่ง",
      headerClassName: "theme-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "sendHeartQuota",
      headerName: "โควต้า",
      type: "number",
      headerClassName: "theme-header",
      flex: 1,
    },
    {
      field: "sendHeartBalance",
      headerName: "คงเหลือ",
      type: "number",
      headerClassName: "theme-header",
      flex: 1,
    },
  ];

  const handleEditQuota = async (data) => {
    const res = await dispatch(
      updateSendHeartQuota(data.type, {
        value: data.value,
        selectedPerson: data.selectedPerson,
      })
    );
    if (res) {
      dispatch(getAllCoinQuota(companySelected.idCompany));
    }
  };

  const handleOpenEdit = () => {
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
  };

  const handleChangeEmployee = (value) => {
    setEmployeeSelected(value);
    setCompanySelected(null);
    setDepartmentSelected({
      departmentName: "All Department",
      idDepartment: 0,
    });
    setSectionSelected({
      sectionName: "All Section",
      idSection: 0,
    });
  };

  const rows = userCoinQuota?.filter((item) => {
    if (employeeSelected) {
      return item.idEmployees === employeeSelected.idEmployees;
    }

    const filterDepartment = departmentSelected.idDepartment
      ? departmentSelected.idDepartment === item.idDepartment
      : true;

    const filterSection = sectionSelected.idSection
      ? sectionSelected.idSection === item.idSection
      : true;

    return filterDepartment && filterSection;
  });

  useEffect(() => {
    if (organization.data) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.data]);

  useEffect(() => {
    if (companySelected) {
      dispatch(getAllCoinQuota({ idCompany: companySelected.idCompany }));
    }
  }, [companySelected]);

  return (
    <div>
      {isFetching || !userCoinQuota ? (
        <LoadingIcon />
      ) : (
        <>
          <Grid container alignItems={"center"} spacing={1} marginBottom={4}>
            <Grid item xs={12} sm={12} md={3}>
              <Autocomplete
                disableClearable
                value={companySelected}
                options={organization.data}
                onChange={(event, newValue) => {
                  setCompanySelected(newValue);
                  setEmployeeSelected(null);
                  setDepartmentSelected({
                    departmentName: "All Department",
                    idDepartment: 0,
                  });
                  setSectionSelected({
                    sectionName: "All Section",
                    idSection: 0,
                  });
                }}
                getOptionLabel={(option) => option.companyName}
                isOptionEqualToValue={(option, value) =>
                  option.idCompany === value.idCompany
                }
                renderInput={(params) => (
                  <TextField {...params} label="Company" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                disableClearable
                value={departmentSelected}
                options={
                  companySelected
                    ? [
                        { departmentName: "All Department", idDepartment: 0 },
                        ...companySelected.department,
                      ]
                    : []
                }
                disabled={!companySelected || employeeSelected}
                onChange={(event, newValue) => setDepartmentSelected(newValue)}
                getOptionLabel={(option) => option.departmentName}
                isOptionEqualToValue={(option, value) =>
                  option.idDepartment === value.idDepartment
                }
                renderInput={(params) => (
                  <TextField {...params} label="Department" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                disableClearable
                value={sectionSelected}
                options={
                  companySelected
                    ? [
                        { sectionName: "All Section", idSection: 0 },
                        ...companySelected.section,
                      ]
                    : []
                }
                disabled={!companySelected || employeeSelected}
                onChange={(event, newValue) => setSectionSelected(newValue)}
                getOptionLabel={(option) => option.sectionName}
                isOptionEqualToValue={(option, value) =>
                  option.idSection === value.idSection
                }
                renderInput={(params) => (
                  <TextField {...params} label="Section" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectEmployees
                value={employeeSelected}
                handleChange={handleChangeEmployee}
              />
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            startIcon={<SettingsIcon />}
            onClick={handleOpenEdit}
            sx={{ marginBottom: 1 }}
          >
            ตั้งค่า
          </Button>
          <DataGrid
            getRowId={(row) => row.idEmployees}
            rows={rows || []}
            columns={columns}
            rowHeight={64}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            sx={{
              "& .theme-header": {
                color: "#637381",
                backgroundColor: "#f4f6f8",
              },
              ".MuiDataGrid-cell": {
                borderButtom: "0.1px solid #f2f2f2",
              },
              borderRadius: "16px",
              boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              maxHeight: "600px",
            }}
          />
        </>
      )}
      {open && (
        <DialogEditQuota
          open={open}
          handleClose={handleCloseEdit}
          handleEdit={handleEditQuota}
          title={"ตั้งค่าโควต้าหัวใจ"}
        />
      )}
    </div>
  );
};

export default SendHeartQuota;
