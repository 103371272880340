import { MISSION_FETCHING, MISSION_FAILED, MISSION_SUCCESS } from "./types";
import missionService from "../services/mission.service";

// export const getAllMissions = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: MISSION_FETCHING,
//     });
//     const res = await missionService.getAllMissions();
//     if (res) {
//       dispatch({
//         type: MISSION_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: MISSION_FAILED,
//     });
//     console.log(err);
//   }
// };

export const getMissionByUserId = () => async (dispatch) => {
  try {
    dispatch({
      type: MISSION_FETCHING,
    });
    const res = await missionService.getMissionByUserId();
    if (res) {
      dispatch({
        type: MISSION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
    console.log(err);
  }
};

export const getMissionByCreateId = () => async (dispatch) => {
  try {
    dispatch({
      type: MISSION_FETCHING,
    });
    const res = await missionService.getMissionByCreateId();
    if (res) {
      dispatch({
        type: MISSION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
  }
};

export const addMission = (formData) => async (dispatch) => {
  try {
    const res = await missionService.addMission(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
  }
};

export const deleteMission = (id) => async (dispatch) => {
  try {
    const res = await missionService.deleteMission(id);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
    console.log(err);
  }
};

export const updateMission = (id, formData) => async (dispatch) => {
  try {
    const res = await missionService.updateMission(id, formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
    console.log(err);
  }
};

export const approveMission = (formData) => async (dispatch) => {
  try {
    const res = await missionService.approveMission(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MISSION_FAILED,
    });
    console.log(err);
  }
};
