import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Youtube from "@tiptap/extension-youtube";
import "./tiptap.css"

const TiptapDetail = ({ detail }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Youtube.configure({
        height: 300,
        width: 300,
      }),
    ],
    editable: false,
    content: detail,
  });

  return <EditorContent editor={editor} />
};

export default TiptapDetail;
