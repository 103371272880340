import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Container,Card, Stack} from "@mui/material";
import dayjs from "dayjs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Meeting from "../../meet";
import RecordDetail from "./RecordDetail"
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { MainCoin } from "../../../shared/typeCoin";
import moment from 'moment';



const StyledCard = styled(Card)({
    height:"100%",
    width:"100%",
    borderRadius: "16px",
    padding:"24px",
    justifyContent: "start",
});


const CardAppointmentCoach = (props)=>{
    const {record,setSnackBarConfig,setModeSnackbar,setDisplaySnackbar,tag} = props;
    const [openForm, setOpenForm] = React.useState(false);
    const [openDetailRecord, setOpenDetailRecord] = React.useState(false);
    const isRecord =( tag === "coach") ? record.isRecordCoach : record.isRecordCoachee;
    console.log(isRecord);


    return (
        <StyledCard>
            <div style={{marginBottom:"20px"} }>
                <Typography className="field-label" color="text.secondary" gutterBottom>
                    วันที่จอง
                </Typography>
                <Typography className="field-label" color="text" gutterBottom>
                {dayjs(record.bookDate).format("DD/MM/YYYY")}
                </Typography>
                <Typography className="field-label" color="text.secondary" gutterBottom>
                    วันที่เข้าโค้ช
                </Typography>
                <Typography className="field-label" color="text" gutterBottom>
                    {dayjs(record.startTime).format("DD/MM/YYYY")} ({dayjs(record.startTime).format("HH:mm")} - {dayjs(record.endTime).format("HH:mm")})
                </Typography>
                <Typography className="field-label" color="text.secondary" gutterBottom>
                    หมายเหตุ
                </Typography>
                <Typography className="field-label" color="text.secondary" gutterBottom>
                    {record.note??"-"}
                </Typography>
                <Typography className="field-label" color="text.secondary" gutterBottom>
                    ยอดรวม
                </Typography>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                    <MainCoin width={32}/>
                    <Typography className="field-label" color="text" gutterBottom>
                        x {record.totalPrice}
                    </Typography>
                </Stack>
            </div>
            <div>
            {
                     

                    isRecord === 0 ? (
                        <ButtonBlue
                            variant="outlined"
                            color="primary"
                            type="submit"
                            onClick={() => setOpenForm(true)}
                        >
                            บันทึกแบบฟอร์ม
                        </ButtonBlue>
                    ) : (
                        <ButtonBlue
                            variant="outlined"
                            color="primary"
                            type="submit"
                            onClick={() => setOpenDetailRecord(true)}
                        >
                            ดูประวัติการบันทึก
                        </ButtonBlue>
                    )
                }

            </div>
                <Dialog
                    open={openForm}
                    maxWidth={"lg"}
                    fullWidth={true}
                    onClose={()=>{
                        setOpenForm(false);
                    }}
                >
                    <DialogContent>
                        <Meeting setOpenForm={setOpenForm} setSnackBarConfig={setSnackBarConfig} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar} idBooking={record.bookingId} tag={tag}/>
                    </DialogContent>
             </Dialog>
                <Dialog
                    open={openDetailRecord}
                    maxWidth={"lg"}
                    fullWidth={true}
                    onClose={()=>{
                        setOpenDetailRecord(false)
                    }}
                >
                    <DialogContent>
                        <RecordDetail record={record}/>
                    </DialogContent>
             </Dialog>
        </StyledCard> 
    )
}

export default CardAppointmentCoach;
