import {
  REDEEM_TRANSACTIONS_FETCHING,
  REDEEM_TRANSACTIONS_FAILED,
  REDEEM_TRANSACTIONS_SUCCESS,
  MY_REWARD_FETCHING,
  MY_REWARD_FAILED,
  MY_REWARD_SUCCESS,
} from "./types";

import redeemTransactionService from "../services/redeemTransaction.service";

export const getCompanyRedeemTransaction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REDEEM_TRANSACTIONS_FETCHING,
    });
    const res = await redeemTransactionService.getCompanyRedeemTransaction(id);
    if (res) {
      dispatch({
        type: REDEEM_TRANSACTIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REDEEM_TRANSACTIONS_FAILED,
    });
    console.log(err);
  }
};

export const addRedeemTransaction = (formData) => async (dispatch) => {
  try {
    const res = await redeemTransactionService.addRedeemTransaction(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: REDEEM_TRANSACTIONS_FAILED,
    });
    console.log(err);
  }
};

export const updateRedeemTransaction = (formData) => async (dispatch) => {
  try {
    const res = await redeemTransactionService.updateRedeemTransaction(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: REDEEM_TRANSACTIONS_FAILED,
    });
    console.log(err);
  }
};

export const cancelRedeemTransaction = (formData) => async (dispatch) => {
  try {
    const res = await redeemTransactionService.cancelRedeemTransaction(formData);
    if (res) {
      return res
    }
  } catch (err) {
    dispatch({
      type: REDEEM_TRANSACTIONS_FAILED,
    });
    console.log(err);
  }
};

export const getMyRedeemReward = () => async (dispatch) => {
  try {
    dispatch({
      type: MY_REWARD_FETCHING,
    });
    const res = await redeemTransactionService.getMyRedeemReward();
    if (res) {
      dispatch({
        type: MY_REWARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MY_REWARD_FAILED,
    });
  }
};
