import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Avatar,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MainCoin } from "../../shared/typeCoin";
import { getBookingByIdCoach } from "../../../../actions/book";

const WrapDataGrid = styled("div")({
  height: 640,
});

const StyledDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
  },
  "& .super-app-theme--header": {
    backgroundColor: "#f4f6f8",
    color: "#637381",
  },
});
  
const StyledPeopleIcon = styled(PeopleIcon)({
  fontSize: "80px",
  color: "#ffffff",
  // borderRadius: 6,
  // padding: "5px",
  // margin: 20,
  // backgroundColor: "#EE86E6",
});

const StyledAccountBalanceWalletIcon = styled(AccountBalanceWalletIcon)({
  fontSize: "60px",
  borderRadius: 6,
  padding: "5px",
  margin: 20,
  backgroundColor: "#87DF93",
});

const StyledChipTime = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  color: "#ffffff",
  backgroundColor: theme.palette.warning.main,
  // border: `1px solid ${theme.palette.info.main}`
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: "left",
  "& .icon": {
    color: "inherit",
  },
  color: theme.palette.success.main,
  border: `1px solid ${theme.palette.success.main}`,
}));

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: bookCoach } = useSelector((state) => state.bookCoach);

  useEffect(() => {
    dispatch(getBookingByIdCoach(currentUser.id));
  }, []);

  const bookDashboard = useMemo(() => {
    if(bookCoach) {
      return bookCoach.filter((item) => item.bookingStatus === 'approve' || item.bookingStatus === 'success')
    }
  }, [bookCoach]);

  const sumCoin = () => {
    return bookDashboard.reduce(
      (acc, curr) =>
        curr.bookingStatus === "success" ? acc + curr.totalPrice : acc,
      0
    );
  };

  const sumMember = () => {
    const s = new Set();
    bookDashboard.forEach((element) => {
      s.add(element.idEmployees);
    });
    return s.size;
  };

  const rowsData = () => {
    return bookDashboard.map((item) => ({
      id: item.idBooking,
      name: `${item.firstname_TH} ${item.lastname_TH}`,
      telephoneMobile: item.telephoneMobile,
      email: item.emailAddressBusiness, 
      date: item.startTime,
      startTime: item.startTime,
      endTime: item.endTime,
      status: item.bookingStatus,
      image: "1.jpg",
    }));
  };

  const columns = [
    {
      field: "name",
      headerName: "ชื่อนาม-สกุล",
      flex: 3,
      minWidth: 320,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={`${process.env.REACT_APP_API_URL}image/profile/${params.row.image}`}
            />
            <Typography style={{ marginLeft: 8 }}>{params.row.name}</Typography>
          </div>
        );
      },
    },
    {
      field: "telephoneMobile",
      headerName: "เบอร์มือถือ",
      minWidth: 120,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "วันที่เรียน",
      type: "dateTime",
      flex: 1,
      minWidth: 140,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.value),
      renderCell: (params) => dayjs(params.row.date).format("DD MMM YYYY"),
    },
    {
      field: "startTime",
      headerName: "เวลาเรียน",
      type: "dateTime",
      flex: 1,
      minWidth: 130,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => new Date(params.value),
      renderCell: (params) => {
        return (
          <StyledChipTime
            label={`${dayjs(params.row.startTime).format("HH:mm")} - ${dayjs(
              params.row.endTime
            ).format("HH:mm")} `}
          />
        );
      },
      filterable: false,
    },
    {
      field: "status",
      headerName: "สถานะ",
      flex: 1,
      minWidth: 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <StyledChip
            className={params.value}
            icon={<DoneIcon className={"icon"} />}
            label={params.value}
            variant={"outlined"}
          />
        );
      },
      sortable: false,
    },
    {
      field: "actions",
      type: 'actions',
      headerName: "จัดการ",
      flex: 1,
      minWidth: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            disabled={params.row.status === "success"}
            onClick={() => {
              navigate(`/coaching/save-coaching/${params.row.id}`);
            }}
          >
            Save
          </Button>
        );
      },
    },
  ];

  return (
    <div className={"page"}>
      {bookCoach && bookDashboard && (
        <Container maxWidth="lg">
          <Typography variant="h4">Coach dashboard</Typography>
          <Grid
            container
            spacing={4}
            marginTop={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6}>
              <Paper elevation={5} style={{ backgroundColor: "#6A2C70", borderRadius: 16, padding: 24 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                  <StyledPeopleIcon />
                  <div>
                    <Typography variant="h5" style={{ fontWeight: 700, color: "#ffffff" }}>
                      {bookDashboard ? sumMember() : 0}
                    </Typography>
                    <Typography variant="subtitle1" color={"text.secondary"}>Members</Typography>
                  </div>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={5} style={{ backgroundColor: "#112D4E", borderRadius: 16, padding: 24 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                  <MainCoin width={80} />
                  <div>
                    <Typography variant="h5" style={{ fontWeight: 700, color: "#ffffff" }}>
                      {bookDashboard ? sumCoin() : 0}
                    </Typography>
                    <Typography variant="subtitle1" color={"text.secondary"}>Total Earned</Typography>
                  </div>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <WrapDataGrid>
                <StyledDataGrid
                  rows={rowsData()}
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 100]}
                />
              </WrapDataGrid>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default Dashboard;
