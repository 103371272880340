import React from "react";
import {
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import UploadRewardImage from "../uploadReward/uploadRewardImage";

function NestedVariationField({ nestIndex, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `variation[${nestIndex}].options`,
  });

  const variation = useWatch({
    control,
    name: `variation[${nestIndex}].options`,
  });

  return (
    <>
      {fields.map((item, index) => (
        <Grid key={item.id} item xs={12}>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            spacing={1}
            marginBottom={1}
          >
            <Controller
              name={`variation[${nestIndex}].options[${index}].name`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="ใส่ข้อมูลของตัวเลือก"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {nestIndex === 0 && (
              <Controller
                name={`variation[${nestIndex}].options[${index}].image`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadRewardImage
                    {...field}
                    // acceptFileType={"image/jpeg, image/png"}
                    error={error}
                    indexImage={index}
                    title={variation[index]?.name}
                  />
                )}
              />
            )}
            <IconButton
              color="error"
              disabled={fields.length === 1}
              // onClick={() => update(index)}
              onClick={() => remove(index)}
            >
              <BackspaceIcon />
            </IconButton>
          </Stack>
          <Divider />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          disabled={fields.length === 20}
          onClick={() => append({ name: "", image: null })}
        >
          {`เพิ่มตัวเลือก (${fields.length}/20)`}
        </Button>
      </Grid>

      {/* {nestIndex === 0 && (
        <SwitchInput
          name="addOptionImage"
          control={control}
          label={"เพิ่มรูปภาพ"}
        />
      )} */}
      {/* {nestIndex === 0 && addOptionImage && (
        <Grid container spacing={2}>
          {variation.map((item, index) => {
            return (
              <Grid item key={item.index}>
                <Controller
                  name={`variation[${nestIndex}].option[${index}].image`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <UploadFile
                      {...field}
                      // acceptFileType={"image/jpeg, image/png"}
                      error={error}
                      indexImage={index}
                      title={item.name}
                    />
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      )} */}
    </>
  );
}

export default NestedVariationField;
