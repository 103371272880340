import { httpClient } from "./httpClient";

const getAllCoach = () => {
  return httpClient.get("coach");
};

const getCoach = (idCoach) => {
  return httpClient.get("coach/" + idCoach);
};

const getCoachPopular = () => {
  return httpClient.get("coach-popular");
};

const addCoach = (formData) => {
  return httpClient.post("coach", formData);
};

const updateCoach = (formData) => {
  return httpClient.patch("coach", formData);
};

const getCoacheeByIdCoach = (idCoach) => {
  return httpClient.get("coach/coachee/list");
};
const getFixCoach = (idCoach) => {
  return httpClient.get("fix-coach");
};

const getCoachByIdCategory = (idCategory)=>{
  return httpClient.get(`coach-by-category/${idCategory}`);
}

const getProfileCoacheeByIdCoachee = (idCoachee)=>{
  return httpClient.get("coachee/"+idCoachee);
}

const getCoachIsRegistered = ()=>{
  return httpClient.get("coach-registered");
}

export default {
  getAllCoach,
  getCoach,
  getCoachPopular,
  addCoach,
  updateCoach,
  getCoacheeByIdCoach,
  getFixCoach,
  getCoachByIdCategory,
  getProfileCoacheeByIdCoachee,
  getCoachIsRegistered
};
