import React, { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { read, utils } from 'xlsx';
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { addCompanySendCoin, getCompanySendCoin } from "../../../../actions/companySendCoin";

const WrapUpload = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  minHeight: 200,
});


const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: 'rgba(0, 0, 0, .03)',
});

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const coinType = {
  'SCGC': 2,
  'Pony': 3,
  'Unicorn': 4,
  'Centaur': 5,
  'CSR': 6,
  'Caring': 7,
  'Do It Professionally': 8,
  'Obsession': 9,
  'Fast Move': 10,
  'Outperform': 11,
}

const coinTypeName = Object.keys(coinType)

const expectedColumns = ['ชื่อ (ภาษาอังกฤษ)', 'นามสกุล (ภาษาอังกฤษ)', 'ชนิดเหรียญ', 'จำนวน', 'รายละเอียด'];

const DialogExcelSendCoin = ({ users, openExcel, handleCloseExcel }) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [data, setData] = useState(null);
  const [errorText, setErrorText] = useState('');

  const handleDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryData = event.target.result;
      const workbook = read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = utils.sheet_to_json(sheet, { header: 1 });

      const actualColumns = rows[0];
      if (!expectedColumns.every((column) => actualColumns.includes(column))) {
        setErrorText('CSV file is missing one or more required columns');
        return;
      }

      const validateName = rows
        .slice(1)
        .some((row) =>
          users.every(
            (user) =>
              user.firstname_TH.toLowerCase() !== row[0].toLowerCase() ||
              user.lastname_TH.toLowerCase() !== row[1].toLowerCase()
          )
        );
      if (validateName) {
        setErrorText("Employee name is error");
        return;
      }

      const validateCointype = rows
        .slice(1)
        .some((row) => !(coinTypeName.includes(row[2])));
      if (validateCointype) {
        setErrorText("Coin type is error")
        return;
      }

      const validateCoinAmount = rows
        .slice(1)
        .some((row) => row[3] < 1 || !(Number.isInteger(row[3])));
      if (validateCoinAmount) {
        setErrorText("Coin amount is error");
        return;
      }

      const maxLength = 200;
      const validateLength = rows
        .slice(1)
        .some((row) => row[4].length > maxLength);
      if (validateLength) {
        setErrorText("Detail length is error");
        return;
      }

      setData(rows);
      setErrorText("");
    };
    reader.readAsBinaryString(acceptedFiles[0]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const handleReset = () => {
    acceptedFiles.splice(0)
    setData(null)
    handleCloseExcel()
  }

  const handleSubmit = async () => {
    if (data !== null) {
      const formData = data.slice(1).map((item) => {
        const temp = [];
        temp.push(
          users.find(
            (user) => user.firstname_TH === item[0] && user.lastname_TH === item[1]
          ).idEmployees
        );
        return [...temp, coinType[item[2]], item[3], item[4]];
      });
      
      const res = await dispatch(
        addCompanySendCoin({
          data: formData,
          idSender: userProfile.idEmployees,
        })
      );
      if (res) {
        handleReset()
        dispatch(getCompanySendCoin());
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={openExcel} onClose={handleReset}>
      <DialogTitle>ส่งเหรียญ</DialogTitle>
      <DialogContent>
        <WrapUpload>
          <section className="container">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack alignItems={"center"}>
                <UploadFileIcon fontSize="large" />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <em>(Only .xlsx and .csv will be accepted)</em>
              </Stack>
            </div>
          </section>
        </WrapUpload>
        <aside>
          <Typography marginTop={2} gutterBottom>{`Accepted file: ${acceptedFiles.length > 0 ? acceptedFiles[0].name : ''}`}</Typography>
          {errorText && 
            <Typography color={"error"} alignSelf={"end"} gutterBottom>{`(${errorText})`}</Typography>
          }
        </aside>
        <Accordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>รายละเอียด</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <Typography>ชื่อ Column</Typography>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {expectedColumns.map((e, index) => (
                      <TableCell key={index} style={{ borderStyle: "solid", borderColor: "black" }}>{e}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <Typography marginTop={4}>ชนิดเหรียญ</Typography>
            <Grid container paddingLeft={2}>
              {coinTypeName.map((e, index) => (
                <Grid item key={index} xs={12} sm={4}>
                  <Typography color="text.third">{e}</Typography>
                </Grid>
              ))}
            </Grid>
            <Typography marginTop={4}>*จำนวนเหรียญ ต้องเป็นจำนวนเต็มบวก</Typography>
            <Typography>*รายละเอียดมีความยาวไม่เกิน 200 ตัวอักษร</Typography>
          </StyledAccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleReset}>
          ยกเลิก
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          color="success"
          onClick={handleSubmit}
          disabled={
            errorText !== "" || acceptedFiles.length === 0 || data === null
          }
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogExcelSendCoin;
