import {
  Container,
  Button,
  Radio,
  RadioGroup,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../../editCss/editor.css";
import GrowForm from "./growForm";
import NormalForm from "./normalForm";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  minWidth: 350,
  height: "100vh",
  color: "#ffffff",
  backgroundColor: "#f1f4f9",

});




const Meeting = (props) => {
  const {setOpenForm,setSnackBarConfig,setModeSnackbar,setDisplaySnackbar,idBooking,tag} = props;
  const [selectedTypeForm, setSelectedTypeForm] = React.useState('typeA');

  const handleChange = (event) => {
    setSelectedTypeForm(event.target.value);
  };
  return (
    <Container maxWidth="lg" >
    <Grid item xs={12} display={"flex"} paddingBottom={"20px"} paddingTop={"20px"}>
      <FormControl>
        <FormLabel>เลือกแบบฟอร์ม</FormLabel>
        <RadioGroup row value={selectedTypeForm} onChange={handleChange}>
          <FormControlLabel value="typeA" control={<Radio/>} label="GROW Form"/>
          <FormControlLabel value="typeB" control={<Radio/>} label="Normal Form"/>
        </RadioGroup>
      </FormControl>
    </Grid>
    {selectedTypeForm=="typeA" ? (
    <GrowForm setOpenForm={setOpenForm} setSnackBarConfig={setSnackBarConfig} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar} idBooking={idBooking} tag={tag}/>
    ):<NormalForm setOpenForm={setOpenForm} setSnackBarConfig={setSnackBarConfig} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar} idBooking={idBooking} tag={tag}/>}
  </Container>
  )

};

export default Meeting;
