import React, { Fragment, useEffect } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import InputField from "../../../shared/inputForm/inputField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
  },
}));

function TableField({ control }) {
  const { replace } = useFieldArray({
    control,
    name: "attributes",
  });

  const attributes = useWatch({
    control,
    name: "attributes",
  });

  const variation = useWatch({
    control,
    name: "variation",
  });

  useEffect(() => {
    const temp = [];
    if (variation) {
      variation[0]?.options.forEach((option_1) => {
        if (variation[1]) {
          return variation[1]?.options.forEach((option_2) =>
            temp.push({
              option1: option_1.name,
              option2: option_2.name,
              price: "",
              // quantity: "",
              sku: "",
            })
          );
        } else {
          temp.push({
            option1: option_1.name,
            price: "",
            // quantity: "",
            sku: "",
          });
        }
      });
      replace(temp);
    }
  }, [replace, variation]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          // "& .MuiTableCell-root": {
          //   border: "1px solid black",
          // },
        }}
      >
        <TableHead>
          <TableRow>
            {variation?.map((row) => (
              <StyledTableCell align="center" key={row.name}>
                {row.name}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">รหัส SKU</StyledTableCell>
            <StyledTableCell align="center">ราคา</StyledTableCell>
            <StyledTableCell align="center">VAT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variation[0]?.options?.map((option_1, index_1) => (
            <Fragment key={index_1}>
              <TableRow
              >
                <TableCell
                  align="center"
                  style={{ minWidth: 160 }}
                  rowSpan={variation[1] ? variation[1].options.length + 1 : 2}
                >
                  {option_1.name}
                </TableCell>
              </TableRow>
              {variation[1] ? (
                variation[1].options.map((option_2, index_2) => {
                  const index = index_1 * variation[1].options.length + index_2;
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        {option_2.name}
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <InputField
                          control={control}
                          name={`attributes.${index}.sku`}
                          type={"text"}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <InputField
                          control={control}
                          name={`attributes.${index}.price`}
                          type={"number"}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }} align="center">
                        <Controller 
                          control={control}
                          name={`attributes.${index}.vat`}
                          render={({ field, fieldState }) => (
                            <FormControl fullWidth>
                              <InputLabel>VAT</InputLabel>
                              <Select
                                {...field}
                                label="VAT"
                                error={Boolean(fieldState.error)}
                              >
                                <MenuItem value={0}>{"ไม่มี"}</MenuItem>
                                <MenuItem value={7}>{"7%"}</MenuItem>
                              </Select>
                              {Boolean(fieldState.error) && (
                                <FormHelperText error>
                                  {fieldState?.error?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <InputField
                      control={control}
                      name={`attributes.${index_1}.sku`}
                      type={"text"}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <InputField
                      control={control}
                      name={`attributes.${index_1}.price`}
                      type={"number"}
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: 160 }} align="center">
                    <Controller 
                      control={control}
                      name={`attributes.${index_1}}.vat`}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <Select
                            {...field}
                            error={Boolean(fieldState.error)}
                          >
                            <MenuItem value={0}>{"ไม่มี"}</MenuItem>
                            <MenuItem value={7}>{"7%"}</MenuItem>
                          </Select>
                          {Boolean(fieldState.error) && (
                            <FormHelperText error>
                              {fieldState?.error?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableField;