import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Typography,
  Chip,
  Link as FileLink,
  Grid,
  Card,
  CardContent,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { useNavigate } from "react-router-dom";
import defaultImage from "./assets/placeholder.png";
import Status from "./assets/checked.png"
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Autocomplete from "@mui/material/Autocomplete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {deleteMorale, updateMorale, addMoraleParticipant, getAllMorale, deleteMoraleQuestion} from "../../../../../actions/moraleQuestion";
import {getAllQuestionTopic} from "../../../../../actions/questionTopic";


const StyledQuestionStatusPublished = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "myColor",
})(({ myColor }) => ({
  fontSize: 12,
  textAlign: "right",
  paddingTop: 5,
  textTransform: "uppercase",
  color: '#000',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  marginRight: '10px',
  width: '120px'
}));


const MoraleCard = ({ value }) => {
  console.log('MoraleCard value:', value); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueSelected, setValueSelected] = useState(null);
  const [page, setPage] = React.useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('draft');
  

  // State for editing morale
  const [moraleName, setMoraleName] = useState("");
  const [moraleCode, setMoraleCode] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [moraleType, setMoraleType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [valueTarget, setValueTarget] = useState("company");
  const fixedOptionsDepartment = [];
  const fixedOptionsPerson = [];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const [modeSnackbar, setModeSnackbar] = useState("info")
  const [openPublic, setOpenPublic] = useState(false);

  const handleChange = (event, value) => {setPage(value);};
  const handleCloseMenu = () => {setAnchorEl(null);};
  const handleClickQuestionPage = (id) => {
    navigate("/admin/manage/morale-question-page/" + id);
  };

  const { result: moraleQuestions, isFetchingMorale } = useSelector((state) => state.moraleQuestions );
  const { result: questionTopics, isFetching, isError } = useSelector((state) => state.questionTopic );
  const { result: department } = useSelector((state) => state.department);
  const { result: person } = useSelector((state) => state.users);
  const questionTypes = ["Multiple choice", "Open question"];
  const idCompany = 1;
  useEffect(() => {
    dispatch(getAllQuestionTopic(idCompany));
  }, [dispatch, idCompany]);

  useEffect(() => {
    console.log('moraleQuestion:', moraleQuestions);
  }, [moraleQuestions]);

  useEffect(() => {
    console.log('questionTopics:', questionTopics);
  }, [questionTopics]);

  useEffect(() => {
    // Populate the state with the current values when opening the edit dialog
    if (openEditDialog) {
      
      setMoraleName(value.moraleName);
      setMoraleCode(value.code);
      setDescriptionText(value.description);
      setMoraleType(value.moraleType);
      setStartDate((value.startDate).toLocaleString().slice(0,16));
      setEndDate((value.endDate).toLocaleString().slice(0,16));
     // Restructure the questions data
    const restructuredQuestions = value.questionTopic.flatMap(topic => 
      topic.questionList.map(question => ({
        topic: topic.questionTopic,
        idQuestion: question.idQuestion,
        questionStyle: question.questionType,
        questionText: question.question
      }))
    );

    setQuestions(restructuredQuestions);
    console.log('Restructured Questions:', restructuredQuestions);
    }
  }, [openEditDialog, value]);

  const numberOfResponse = (id) => {
    return moraleQuestions
      .find((question) => question.idMorale === id)
      .participantList.reduce(
        (prev, curr) => (curr.isComplete ? prev + 1 : prev),
        0
      );
  };

  const numberOfQuestion = value.questionTopic.reduce((accumulator, topic) => accumulator + topic.questionList.length, 0 );

  const handleMenuClose = () => {setAnchorEl(null);};
  const handleDuplicateQuestion = (index) => {
    const duplicatedQuestion = { ...questions[index] };
    setQuestions([...questions, duplicatedQuestion]);
    handleMenuClose();
  };

  const handleDeleteQuestion = async(question,index) => {
    if (question.idQuestion) {
      // This is an existing question in the database, call backend to delete it
      await dispatch(deleteMoraleQuestion(question.idQuestion));
    }
    setQuestions(questions.filter((_, i) => i !== index));
    handleMenuClose();
    
  };

const handleOpenEditDialog = () => {setOpenEditDialog(true)};

const handleCloseEditDialog = () => {setOpenEditDialog(false)};

const handleSaveEdit = async () => {
  try {
    await dispatch(updateMorale({
      id: value.idMorale,
      moraleName,
      moraleCode,
      descriptionText,
      moraleType,
      startDate,
      endDate,
      questions,
    }));
    setOpenEditDialog(false);
    // Optionally, show a success message or refresh the data
  } catch (error) {
    console.error("Failed to update morale:", error);
  }
};
const handleSave = () => {
  if (moraleName.length > 0) {
    setOpenStatusDialog(true); // Open the Draft/Publish selection dialog
  }
};
const handleStatusSelect = (status) => {
  setSelectedStatus(status); 
  setOpenStatusDialog(false);
  handleAddQuestion(status);  
};
const handleClose = () => {
  
  setOpenEditDialog(false);
  dispatch(getAllMorale());
};


const handleAddQuestion = async (status) => { // connect will database for create question in selected topic
  const res = await dispatch(
    updateMorale({  idMorale: value.idMorale,moraleName,moraleCode,descriptionText,moraleStatus: status, moraleType, startDate, endDate,
      questions: questions.map(q => ({
        ...q,
        idQuestion: q.idQuestion,
        questionTopic: q.topic,
        questionType: q.questionStyle,
        questionText: q.questionText,
      }))
    }),
  );
  
  if (res ) {  // Ensure moraleId was returned
    const moraleId = value.idMorale; // Adjust this line based on how your dispatch is structured
    console.log("Morale and questions added successfully with ID:", moraleId);

    // Add participants based on the moraleId and status
    if (status === "published") {
      const participantResponse = await dispatch(
        addMoraleParticipant({
          moraleId, // Pass moraleId for publishing
          startDate,
          endDate,
          valueTarget,
          selectedDepartment: selectedDepartment.map((dept) => dept.idDepartment),
          selectedPerson: selectedPerson.map((person) => person.idEmployees),
        })
      );

      if (participantResponse.error) {
        throw new Error('Failed to add participants: ' + participantResponse.error);
      }
    }
    // Refresh morale list after adding
    dispatch(getAllMorale());
    handleClose();
    
  } else {
    console.error("Failed to retrieve moraleId or add morale.");
  }
};
const handleQuestionChange = (index, field, value) => {
  const updatedQuestions = [...questions];
  updatedQuestions[index][field] = value; // Update specific question field
  setQuestions(updatedQuestions);
};

const createQuestion = () => {
  const newQuestion = {
    topic: "",
    questionStyle: "",
    questionText: "",
  };
  setQuestions([...questions, newQuestion]);
};

const handleDeleteMorale = async () => {
  if (value.moraleStatus === 'draft') {
    try {
      await dispatch(deleteMorale(value.idMorale));  // Call API to delete
      setOpenDeleteDialog(false);
      setOpenSuccessDialog(true);
      setTimeout(() => {
          setOpenSuccessDialog(false);
          //navigate(-1);  
      }, 3000);
      
      
    } catch (error) {
      console.error('Failed to delete morale:', error);
    }
} else {
    console.log("Cannot delete a published morale.");
}
dispatch(getAllMorale());
};

const handleChangeValueTarget = (event) => {
  setValueTarget(event.target.value);
};
const handleClickOpenPublic = () => {
  setOpenPublic(true);
};
const handleClosePublic = () => {
  setOpenPublic(false);
};
const handleOpenSnackbar = (value, mode) => {
  setModeSnackbar(mode)
  setDisplaySnackbar(value)
  setOpenSnackbar(true);
};



  return (
    
    <Card sx={{   
      width: '100%',
      height:'100%',
      borderRadius: '12px', 
      boxShadow: 3,  
      display: 'flex',
      flexDirection: 'column',
    }}>
      <CardContent
      >
        <Box
          sx={{
            backgroundColor: 'gray',  
            height: '250px',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '10px'
          }}      
        >
           <img
            src={value.picture && value.picture.trim() !== '' ? value.picture : defaultImage} // Conditional rendering for the image
            alt="Morale"
            style={{
              width: '100%', 
              height: '100%', 
              objectFit: 'cover', 
            }}
            />
        </Box>
        <Box onClick={() => handleClickQuestionPage(value.idMorale)}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box sx={{height:'60px'}}>
                <Typography variant="h6" component="div" fontWeight="bold" style={{ lineHeight: 1 }}>
                  แบบสอบถาม {value.moraleName} 
                </Typography>
              </Box>
              <Box sx={{height:'40px'}}>
              <Typography variant="body2" style={{ lineHeight: 1 }}>
                รายละเอียด: {value.description}
              </Typography>
              </Box>
              <Typography variant="body2">
                จำนวนข้อ: {numberOfQuestion} ข้อ
              </Typography>
              <Typography variant="body2">
                Start: {dayjs(value.startDate).format('DD MMM YYYY HH:MM')} {/* Start Date */}
              </Typography>
              <Typography variant="body2">
                End: {dayjs(value.endDate).format('DD MMM YYYY HH:MM')} {/* End Date */}
              </Typography>
              <Chip 
                label={value.moraleType} 
                variant="outlined"
                sx={{
                  width: '90px', height:'25px', mr:'8px', borderRadius:'5px', mt:'5px'
                }} 
              />   
              <Chip 
                label={value.moraleStatus === 'published' ? 'published' : 'draft'} 
                color="primary" 
                sx={{
                  backgroundColor: value.moraleStatus === 'published' ? '#2a265f':'#f8de55' ,
                  color: value.moraleStatus === 'published' ? '#fff':'#000', 
                  width: '90px', height:'25px', mr:'8px', borderRadius:'5px', mt:'5px'
                }} 
              />          
            </Grid>
     
            <Grid item xs={3} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
              {/* Date Circle */}
              <Box
                sx={{
                  // backgroundColor: '#ff5722',  // Color for the circle
                  backgroundColor: value.moraleStatus === 'published' ? '#2a265f':'#f8de55' ,
                  color: 'white',
                  borderRadius: '50%',
                  width: '60px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >  
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" color= {value.moraleStatus === 'published' ? 'white':'#000'}>
                    {dayjs(value.startDate).format('DD')} {/* Event Day (e.g., 19) */}
                  </Typography>
                  <Typography variant="h6" color={value.moraleStatus === 'published' ? 'white':'#000'} sx={{mt:-1}}>
                    {dayjs(value.startDate).format('MMM')} {/* Event Month (e.g., Oct) */}
                  </Typography>
                </Box>
              </Box>
            </Grid>
        </Grid>
        </Box>
        <Grid container>
          {value.moraleStatus === "published" && (
            <div style={{ marginTop: 30, width:'100%' }}>
              <LinearProgress
                style={{width:'100%', height:'10px'}}
                variant="determinate"
                value={(numberOfResponse(value.idMorale) * 100) / value.participantList.length}
              />
              <StyledQuestionStatusPublished
                myColor={value.moraleStatus === "draft" ? "#3C3C3C" : "#FFFFFF"}
              >
                {`${numberOfResponse(value.idMorale)} จาก ${value.participantList.length} ผู้ตอบ`}
              </StyledQuestionStatusPublished>
            </div>
          )}
          {value.moraleStatus === "draft" && ( 
            <Box sx={{mt:2, display: 'flex', flexDirection: 'row', width:'100%' }}>
            <Chip 
              label='Delete'
              variant='outlined'
              sx={{
                // backgroundColor:'#f43548',
                // color:  '#fff',
                width:'100%', height:'40px', mr:'14px', borderRadius:'10px', mt:'5px'
              }} 
              onClick={() => setOpenDeleteDialog(true)}
            />   
            <Chip 
              label='Edit'
              color="primary" 
              sx={{
                backgroundColor:'#177ef4',
                color:  '#fff', 
                width:'100%', height:'40px', borderRadius:'10px', mt:'5px'
              }} 
              // onClick={() => handleClickQuestionPage(value.idMorale)}
              onClick={handleOpenEditDialog}
            /> 
            </Box>  
          )}
        </Grid>   
      </CardContent>
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} maxWidth="xl" PaperProps={{ style: { width: '800px' } }}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
            <Typography>
                Are you sure you want to delete <strong>{value.moraleName}</strong>? 
                This action cannot be undone.
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} variant='outlined'  
              sx={{
                  width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
              }} >
              Cancel
          </Button>
          <Button onClick={handleDeleteMorale} color='error'
              sx={{
                  backgroundColor:'#f43548',
                  '&:hover': {
                      backgroundColor: '#f43548', // Darker shade on hover
                  },
                  color:  '#fff',
                  width: '100px', height:'30px', mr:'8px', borderRadius:'5px', mt:'5px'
              }} >
              Delete
          </Button>
        </DialogActions>
      </Dialog> 
      <Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}  maxWidth="xl" >
        <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
        <CheckCircleIcon color="success" sx={{ fontSize: 100 }} />
        <Typography variant="h6" color="success" mt={2}>
            Delete Success
        </Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="lg" fullWidth sx={{ '& .MuiDialog-paper': { width: '100%',  } }}>
        <DialogTitle>
          <Typography variant="h4" sx={{pt:2, pl:2, fontWeight:600}}>Edit Morale</Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" gap={3} sx={{ pl: 2, pr: 2 }}>
            <Box flex={2} gap={2}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="subtitle2" style={{ marginBottom: '5px', color: 'gray', fontSize: '12px' }}>TITLE</Typography>
                <TextField fullWidth placeholder="Morale Name" variant="standard" value={moraleName} onChange={(e) => setMoraleName(e.target.value)} InputProps={{ sx: { fontSize: '22px', pt: 3, ml: -3.5 } }} sx={{ width: 250, '& .MuiInput-underline:before': { borderBottom: 'none' }, '& .MuiInput-underline:after': { borderBottom: 'none' }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }} />
                <Typography variant="subtitle2" style={{ marginBottom: '5px', color: 'gray', fontSize: '12px' }}>CODE</Typography>
                <TextField fullWidth placeholder="Morale Code" variant="standard" value={moraleCode} onChange={(e) => setMoraleCode(e.target.value)} InputProps={{ sx: { fontSize: '22px', pt: 3, ml: -4 } }} sx={{ width: 180, '& .MuiInput-underline:before': { borderBottom: 'none' }, '& .MuiInput-underline:after': { borderBottom: 'none' }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }} />
              </Box>
              <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '5px', color: 'gray', fontSize: '12px' }}>DESCRIPTION</Typography>
              <TextField fullWidth placeholder="In this morale is about..." variant="standard" value={descriptionText} onChange={(e) => setDescriptionText(e.target.value)} sx={{ width: 470, '& .MuiInput-underline:before': { borderBottom: 'none' }, '& .MuiInput-underline:after': { borderBottom: 'none' }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }} />
              <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '5px', color: 'gray', fontSize: '12px' }}>MORALE TYPE</Typography>
              <Box display="flex" spacing={2}>
                <Box item>
                  <StyledButton variant={moraleType === "quarter" ? "contained" : "outlined"} onClick={() => setMoraleType("quarter")} sx={{ backgroundColor: moraleType === "quarter" ? '#EC5B6A' : 'transparent', color: moraleType === "quarter" ? 'white' : 'grey', borderColor: moraleType === "quarter" ? 'transparent' : 'lightgray', '&:hover': { backgroundColor: moraleType === "quarter" ? '#EC5B6A' : 'transparent', borderColor: moraleType === "quarter" ? 'transparent' : 'gray' } }}>
                    Quarter
                  </StyledButton>
                </Box>
                <Box item>
                  <StyledButton variant={moraleType === "year" ? "contained" : "outlined"} onClick={() => setMoraleType("year")} sx={{ backgroundColor: moraleType === "year" ? '#EC5B6A' : 'transparent', color: moraleType === "year" ? 'white' : 'gray', borderColor: moraleType === "year" ? 'transparent' : 'lightgray', '&:hover': { backgroundColor: moraleType === "year" ? '#EC5B6A' : 'transparent', borderColor: moraleType === "year" ? 'transparent' : 'gray' } }}>
                    Year
                  </StyledButton>
                </Box>
              </Box>
              <Box display="flex" gap={3} sx={{ mt: 3.5 }}>
                <TextField label="Start date and time" type="datetime-local"  value={(startDate)} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} InputProps={{ sx: { borderRadius: '10px', fontSize: '14px', height: '45px' } }} />
                <TextField label="End date and time" type="datetime-local" value={endDate} onChange={(e) => setEndDate (e.target.value)} InputLabelProps={{ shrink: true }} InputProps={{ sx: { borderRadius: '10px', fontSize: '14px', height: '45px' } }} />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 2, pr:2, mb:-2}} spacing={2} >
            <Box sx={{ display: 'flex', alignItems: 'center', pt:0.6}}>
              <Typography variant="h6" sx ={{ ml: 1, fontSize:'32'}}>Edit question</Typography>
            </Box> 
          </Box>
          <Box display="grid"  sx={{pl:2}}>
            {questions.map((question, index) => (
              <Box key={index} display="flex" sx={{ pr:2}}>
              <Paper elevation={3} sx={{ mt: 3, pl: 4, pr: 4, pb: 2, width: '100%' }}>
                <Box display="flex" alignItems="flex-end" justifyContent="space-between" >
                  <Typography variant="h6">Question {index + 1}</Typography>
                  <Box display="flex" gap={2}>
                  <TextField
                    select
                    variant="standard"
                    label={question.topic ? " " : "Select topic"}
                    value={question.topic}
                    onChange={(e) => handleQuestionChange(index, 'topic', e.target.value)}
                    InputLabelProps={{
                      sx: { textAlign: 'right', right: 30, left: 'unset' },
                    }}
                    sx={{
                      width: 280,
                      '& .MuiInput-underline:before': { borderBottom: 'none' },
                      '& .MuiInput-underline:after': { borderBottom: 'none' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '& .MuiSelect-select': { textAlign: 'right' },
                    }}
                  >
                    {isFetching ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : isError ? (
                      <MenuItem disabled>Error loading topics</MenuItem>
                    ) : (
                      questionTopics?.map((topicItem) => (
                        <MenuItem key={topicItem.idQuestionTopic} value={topicItem.questionTopic} sx={{ textAlign: 'right' }}>
                          {topicItem.questionTopic}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                  <TextField
                    select
                    label={question.questionStyle ? " " : "Select type"}
                    variant="standard"
                    value={question.questionStyle}
                    onChange={(e) => handleQuestionChange(index, 'questionStyle', e.target.value)}
                    InputLabelProps={{
                      sx: { textAlign: 'right', right: 30, left: 'unset' },
                    }}
                    sx={{
                      width: 150,
                      '& .MuiInput-underline:before': { borderBottom: 'none' },
                      '& .MuiInput-underline:after': { borderBottom: 'none' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '& .MuiSelect-select': { textAlign: 'right' },
                    }}
                  >
                    {questionTypes.map((option) => (
                      <MenuItem key={option} value={option} sx={{ textAlign: 'right' }}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap={2} >
                  <TextField
                    placeholder="Question Text"
                    fullWidth
                    value={question.questionText}
                    onChange={(e) => handleQuestionChange(index, 'questionText', e.target.value)}
                    variant="outlined"
                    sx={{ flexGrow: 1,}}
                  />
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ mx: 1 , mr:-1}}>
                    <Tooltip title="Duplicate">
                      <IconButton onClick={() => handleDuplicateQuestion(index)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDeleteQuestion(question, index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>  
                </Box>
              </Paper>
              </Box> 
            ))}  
            <Box display="flex" justifyContent="flex-start" mt={3}>
              <Button variant="outlined" onClick={createQuestion} color="primary"
                sx={{width:150, color:'#EC5B6A', borderColor:'#EC5B6A',
                  '&:hover': {
                  borderColor: '#EC5B6A', 
                },borderRadius: '10px',}}>
                Add Question
              </Button>
            </Box>  
          </Box>
        </DialogContent>
        <DialogActions >
          <Button variant="outlined" color="error" onClick={handleCloseEditDialog} sx={{ mb:'20px',  width: '100px', color: 'gray', borderColor: 'gray', '&:hover': { borderColor: 'darkgrey', backgroundColor: 'rgba(128, 128, 128, 0.1)' }, borderRadius: '10px' }}>
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleSave} sx={{ mb:'20px', mr:'40px', width: '100px', backgroundColor: '#EC5B6A', '&:hover': { backgroundColor: '#EC5B6A' }, borderRadius: '10px' }}>
            Save
          </Button>
        </DialogActions>
        <Dialog 
            PaperProps={{
              sx: {borderRadius: '10px', height:'360px', width:'550px', pl:'40px', pr:'40px' }     
            }} 
            open={openStatusDialog} onClose={() => setOpenStatusDialog(false)}>
            <DialogTitle sx={{ textAlign: 'center', mt:'20px' }}>Select Status</DialogTitle>
            <Box 
              display="flex" 
              justifyContent="center" 
            >
              <img
                src={Status}
                width={100}
                height={100}
                style={{
                  objectFit: 'cover', // Ensures the image maintains aspect ratio while filling the width/height
                }}
              />
            </Box>
            <DialogContent sx={{ textAlign: 'center'}} >
              <DialogContentText>
                Please select whether you want to save the morale as a draft or publish it.
              </DialogContentText>
              <Box display="flex" gap={2} justifyContent="center" mt={4}>
                <Button variant="outlined" onClick={() => handleStatusSelect('draft')}
                   sx={{width:150, color:'gray', borderColor:'gray',
                    '&:hover': {
                    borderColor: 'darkgrey', 
                    backgroundColor: 'rgba(128, 128, 128, 0.1)',
                  },borderRadius: '10px',}}>
                  Save as Draft
                </Button>
                <Button variant="contained" color="success" onClick={handleClickOpenPublic}
                  sx={{width:150, backgroundColor:'#EC5B6A', '&:hover': {
                    backgroundColor: '#EC5B6A', 
                  }, borderRadius: '10px',}}>
                  Publish
                </Button>
              </Box>
            </DialogContent>
            <Dialog open={openPublic} onClose={handleClosePublic} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{"เผยแพร่"}</DialogTitle>
              <DialogContent>
                
                <FormControl component="fieldset">
                  <FormLabel component="legend">กลุ่มเป้าหมาย</FormLabel>
                  <RadioGroup aria-label="valueTarget" name="valueTarget" value={valueTarget} onChange={handleChangeValueTarget} row>
                    <FormControlLabel value="company" control={<Radio />} label="ทั้งบริษัท" />
                    <FormControlLabel value="department" control={<Radio />} label="รายหน่วยงาน" />
                    <FormControlLabel value="person" control={<Radio />} label="รายบุคคล" />
                  </RadioGroup>
                </FormControl>
                {valueTarget === "department" && (
                  <Autocomplete multiple id="fixed-tags-demo-department" value={selectedDepartment} onChange={(event, newValue) => {
                    setSelectedDepartment([...fixedOptionsDepartment, ...newValue.filter(option => fixedOptionsDepartment.indexOf(option) === -1)]);
                  }} options={department.filter(item => item.idDepartment)} getOptionLabel={(option) => option.departmentName}
                    renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                      <Chip label={option.departmentName} {...getTagProps({ index })} disabled={fixedOptionsDepartment.indexOf(option) !== -1} />
                    ))}
                    renderInput={(params) => <TextField style={{ width: "100%" }} {...params} label="ค้นหาหน่วยงาน" variant="outlined" placeholder="หน่วยงาน" />}
                  />
                )}
                {valueTarget === "person" && (
                  <Autocomplete multiple id="fixed-tags-demo-person" value={selectedPerson} onChange={(event, newValue) => {
                    setSelectedPerson([...fixedOptionsPerson, ...newValue.filter(option => fixedOptionsPerson.indexOf(option) === -1)]);
                  }} options={person} getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                    renderOption={(props, option) => <li {...props} key={option.idEmployees}>{`${option.firstname_TH} ${option.lastname_TH}`}</li>}
                    renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                      <Chip label={`${option.firstname_TH} ${option.lastname_TH}`} {...getTagProps({ index })} disabled={fixedOptionsPerson.indexOf(option) !== -1} />
                    ))}
                    renderInput={(params) => <TextField style={{ width: "100%" }} {...params} label="ค้นหาพนักงาน" variant="outlined" placeholder="พนักงาน" />}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClosePublic} color="primary">Cancel</Button>
                <Button onClick={() => handleStatusSelect("published")} color="primary" >Publish</Button>
              </DialogActions>
            </Dialog>
          </Dialog>
      </Dialog>
    </Card>     
  );
};

export default MoraleCard;
