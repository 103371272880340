import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Button,
  Typography,
  Grid,
  Container,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { getCategory } from "../../../../actions/category";
import { addCoach } from "../../../../actions/coach";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../shared/inputForm/inputField";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiChip-root": {
    // backgroundColor: "#27A4FF",
    color: "white",
    fontWeight: 600,
    backgroundImage: "linear-gradient(to right, #6d83ff, #77aaff);",
  },
});

const FormRegisterCoach = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
  }, []);

  const validationSchema = Yup.object().shape({
    coachProfile: Yup.string().max(200).required("Please enter profile"),
    coachExperience: Yup.string().max(200).required("Please enter experience"),
    price: Yup.number().min(0).integer().required("Please enter price"),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      coachProfile: "",
      coachExperience: "",
      price: "",
    },
    resolver: yupResolver(validationSchema)
  })

  const handleCoachRegister = async (values) => {
    const res = await dispatch(
      addCoach({
        ...values,
        idCoach: currentUser.id,
      })
    );
    if (res) {
      navigate("/coaching");
    }
  };

  return (
    <div className={`page`}>
      <Container maxWidth="md">
        <Typography variant="h4" textAlign={"center"}>
          Coach register
        </Typography>
        <Paper style={{ padding: 20, marginTop: 16 }}>
          <form onSubmit={handleSubmit(handleCoachRegister)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputField 
                  control={control}
                  name="coachProfile"
                  label="Coach profile"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  control={control}
                  name="coachExperience"
                  label="Coach experience"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField 
                  control={control}
                  name="price"
                  label="Price"
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  สมัคร
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default FormRegisterCoach;
