import { httpClient } from "./httpClient";

const getCompanyRewards = () => {
  return httpClient.get(`company-reward`);
};

const getCompanyReward = (idCompanyReward) => {
  return httpClient.get(`company-reward/${idCompanyReward}`);
};

const addCompanyReward = (formData) => {
  return httpClient.post("company-reward", formData);
};

const updateCompanyReward = (idCompanyReward, formData) => {
  return httpClient.put(`company-reward/${idCompanyReward}`, formData);
};

export default {
  getCompanyRewards,
  getCompanyReward,
  addCompanyReward,
  updateCompanyReward,
};
