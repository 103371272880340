import React from "react";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
//Translator TH-EN
import TableCustom from "../../shared/tableCustom";
const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));


const TableBooking = (props) =>{
    const { columns, rows} = props;

    return (
        <Box>
          <TableCustom
            columns = {columns}
            rows = {rows?? []}
          
          />
            {/* <TableContainer style={{minHeight:400}}>
                <Table>
                    <TableHead>
                        <TableRow>
                        {columns.map((c,index) => (
                            <StyledCellHeader
                                key={index}
                                sx={{
                                textAlign: c.headerTextAlign
                                    ? c.headerTextAlign
                                    : undefined,
                                minWidth: c.minWidth ? c.minWidth : undefined,
                                width: c.width ? c.width : undefined,
                                maxWidth: c.maxWidth ? c.maxWidth : undefined,
                                }}
                            >
                                {c.name}
                            </StyledCellHeader>
                        ))}
                        </TableRow>
                    </TableHead>
                   {
                    rows.length == 0? (
                      <div>
                        <Typography align="center">ไม่มีข้อมูล</Typography>
                      </div>
                    ):(
                      <TableBody>
                      {
                          rows.map((r,rIndex)=>{
                            return (
                              <TableRow key={rIndex}>
                              {columns.map((c, cIndex) => (
                              <StyledCellContent
                              key={cIndex}
                              style={{
                                  textAlign: c.cellTextAlign
                                  ? c.cellTextAlign
                                  : undefined,
                                  fontSize: c.cellFontSize ? c.cellFontSize : undefined,
                                  minWidth: c.minWidth ? c.minWidth : undefined,
                                  width: c.width ? c.width : undefined,
                                  maxWidth: c.maxWidth ? c.maxWidth : undefined,
                              }}
                              >
                              {c.cellRender ? (
                                   c.cellRender(r)
                              ) : c.fieldKey ? (
                                  <Typography fontSize="inherit">
                                  {r[c.fieldKey]}
                                  </Typography>
                              ) : null}
                              </StyledCellContent>
                          ))}
                          </TableRow>
                            )
                          })
                      }
                  </TableBody>
                    )
                   }
                </Table>
            </TableContainer> */}
        </Box>
    )
}

export default TableBooking;
