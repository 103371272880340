import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FilterListIcon from "@mui/icons-material/FilterList";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CardActionArea from '@mui/material/CardActionArea';
import { useNavigate } from "react-router";
import ButtonBlue from "../../shared/general/ButtonBlue";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  Avatar,
  IconButton,
  CardActions,
} from "@mui/material";

import HeaderPage from "../../shared/header/headerPage";
import { MainCoin } from "../../shared/typeCoin";
import MoraleQuestionIcon from "./assets/question.png";
import dayjs from "dayjs";
import { DialogDetail } from "../../manager/mission/missionAssign";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMissionByUserId, updateMission } from "../../../../actions/mission";
import DateFilter from "../../shared/dateFilter";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../shared/inputForm/inputField";
import UploadFile from "../../shared/uploadFile/uploadFile";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  minWidth: 350,
  marginBottom:"5%",
  height: "100vh",
  color: "#ffffff",
  backgroundColor: "#778899",
});

 const StyledCard = styled(Card)({
  width: "100%",
  height: "100%",
  borderRadius: 8,
  position: "relative",
});

 const StyledTitle = styled(Typography)({
  fontSize: "24px",
});

 const StyledCardContent = styled(CardContent)({
  color: "#FFFFFF",
  display: "flex",
  width: "100%",
  height: "100%",
  borderRadius: 8,
  flexDirection: "column",
});

const WrapFromDetail = styled("div")({
  color: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  "& Button": {
    margin: 8,
  },
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
  backgroundColor: "#ffffff",
});

const WrapCardButton = styled("div")({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  "& Button": {
    margin: 8,
  },
});

const WrapStatus = styled("div")({
  display: "flex",
  alignItems: "center",
  "& div": {
    margin: 8,
  },
});

const ButtonSubmit = styled(Button)({
  marginLeft: 16,
});

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const WrapCardAction = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const colorStatus = ["warning", "warning", "secondary", "success", "error"];

function Mymission() {
  const [openFilter, setOpenFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [currentMission, setCurrentMission] = useState(null);
  const [dateFiiter, setDateFiiter] = useState(null);
  const navigate = useNavigate();

  const clearCurrentMission = () => {
    setCurrentMission(null);
  };

  const dispatch = useDispatch();
  const { result: missionList } = useSelector((state) => state.mission);

  //
  useEffect(() => {
    dispatch(getMissionByUserId());
  }, []);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleClose = () => {
    setFileSelected([]);
    setOpen(false);
    clearCurrentMission();
  };

  const onSubmit = async (values) => {  //
    // useEffect(() => {
    //   dispatch(getMissionByUserId());
    // }, []);
    values.idMissionStatus = 2
    const formData = new FormData()
    for(const key in values) {
      formData.append(key, values[key])
    }

    const res = await dispatch(updateMission(currentMission.idMission, formData));
    if (res) {
      dispatch(getMissionByUserId());
    }
    handleClose();
  };

  const validationSchema = yup.object().shape({
    detail: yup.string().max(200).required("Please enter Detail"),
    keyLearning: yup.string().max(200).required("Please enter Key Success"),
    file: yup.mixed().nullable(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      detail: "",
      keyLearning: "",
      file: null,
    },
    resolver: yupResolver(validationSchema)
  })

  const handleClickNavigateHistoryMyMission = () => {
    navigate("/my-mission-history");
  };
  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex" ,justifyContent:"space-between"}}>
        <HeaderPage
          textLabel={"ภารกิจของฉัน"}
          icon={MoraleQuestionIcon}
          color={"#ffffff"}
        />
        {/* <Button
         variant="contained"
         color="primary"
         onClick={handleClickNavigateHistoryMyMission}
        >
        ประวัติภารกิจของฉัน
        </Button> */}
        <ButtonBlue
              variant="contained"
              startIcon={<AccessTimeIcon />}
              color="primary"
              onClick={handleClickNavigateHistoryMyMission}
              style={{ marginLeft: 16 }}
            >
              ประวัติภารกิจของฉัน
            </ButtonBlue>
      </div>
      <StyledDivider />
      <WrapFilter>
        <FilterIconButton aria-label="filter" onClick={toggleDrawer} size="large" >
            <FilterListIcon fontSize="large" />
          </FilterIconButton>
      </WrapFilter>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <DateFilter setFilterDate={setDateFiiter}/>
      </Drawer>
      <DialogDetail onClose={clearCurrentMission} mission={currentMission} />
      {missionList && missionList.length === 0 && (
        <Typography fontSize={64} textAlign={"center"} color={"white"}>
          No mission
        </Typography>
      )}
      <Grid 
        container
        spacing={4}
        paddingLeft={"5%"} 
        paddingRight={"5%"}
      >
      {missionList &&
        missionList
          .filter((item) =>
            dateFiiter
              ? dayjs(item.missionDate).isSame(dateFiiter, "month")
              : item
          )
          .map((value, index) => {
            return (
              <Grid item xs={12} sm={6} md={6} xl={6}>
                 <StyledCard variant="outlined">
                  <CardActionArea
                   
                   onClick={() => setCurrentMission(value)}
                  >
                  <StyledCardContent>
                    <div>
                      <StyledTitle gutterBottom>
                        <FormatQuoteIcon
                          fontSize={"large"}
                          style={{ transform: "scale(-1, 1)" }}
                        />
                        {value.missionTitle}
                        <FormatQuoteIcon fontSize={"large"} />
                        <Typography fontSize={"16px"} gutterBottom>
                        {value.missionDetail}
                      </Typography>
                      </StyledTitle>
                    </div>
                    <WrapStatus>
                    <Typography
                        mt={2}
                        fontSize={"16px"}
                        color="textSecondary"
                        gutterBottom
                    
                      >{"สถานะ:"}
                      </Typography>
                      <Chip
                            label={value.missionStatus}
                            color={colorStatus[value.idMissionStatus]}
                          />
                    </WrapStatus>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" ,}}>
                      <Typography
                        fontSize={"16px"}
                        color="textSecondary"
                      >{"วันสิ้นสุด:"}
                      </Typography>
                      <Typography
                        fontSize={"16px"}
                        gutterBottom
                      >
                        {dayjs(value.missionDate).format("D MMM YYYY ")}
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" ,}}>
                      <Typography
                        fontSize={"16px"}
                        color="textSecondary"
                        
                      >{"มอบหมายโดย:"}
                      </Typography>
                      <div style={{display:"flex",gap:"8px",alignItems:"center"}}>
                      <Avatar alt="employee" src={value.assigner_imageURL} />
                        <Typography
                          gutterBottom
                        >
                          {`${value.assigner_firstname_TH} ${value.assigner_lastname_TH}`}
                        </Typography>
                      </div>
                    </div>
                    <WrapCardAction>
                      <Stack
                        width={"100%"}
                        style={{justifyContent:"space-between"}}
                        direction={{ xs: "row", xl: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 6 }}
                      >     
                       <div style={{display:"flex",alignItems:"center"}}>
                      <MainCoin width={40} mr/>
                      <Typography margin={2}>{value.reward}</Typography>
                    </div>                   
                        <WrapCardButton>
                          <CardActions>
                            {value.missionStatus === "in progress"&& (
                              <Button
                              variant="contained"
                              color="success"
                              onClick={() => {
                                setCurrentMission(value);
                                setOpen(true);
                              }}
                              >
                                Submit
                              </Button>
                            )}
                          </CardActions>
                        </WrapCardButton>
                        
                      </Stack>
                    </WrapCardAction>
                  </StyledCardContent>
                  </CardActionArea>

                </StyledCard>
              </Grid>
            );
          })}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper
              style={{
                padding: 20,
                margin: "auto",
                marginTop: 30,
                marginBottom: 30,
                maxWidth: 800,
              }}
            >
              <Grid container spacing={3} marginBottom={2}>
                <Grid item xs={12}>
                  <InputField 
                    control={control} 
                    name={"detail"}
                    label="Detail"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField 
                    control={control} 
                    name={"keyLearning"}
                    label="Key Learning"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <UploadFile
                        {...field}
                        acceptFileType={"image/jpeg, image/png, application/pdf"}
                        acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf"}
                        name={"ไฟล์รายละเอียด"}
                        multiple={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="error" onClick={handleClose}>
                  Close
                </Button>
                <ButtonSubmit variant="contained" color="success" type="submit">
                  Submit
                </ButtonSubmit>
              </div>
            </Paper>
          </form>
        </DialogContent>
      </Dialog>
    </StyledRoot>
  );
}

export default Mymission;