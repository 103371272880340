import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Button,
  Stack,
  Container,
  Grid,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RedeemIcon from "./assets/buy.png";
import HeaderPage from "../../shared/header/headerPage";
import RedeemTransactionGrid from "./redeemTransactionGrid";
import { useOrganization } from "../../../../quries/company";
import SelectEmployees from "../../shared/general/selectEmployees";
import { utils, writeFileXLSX } from "xlsx";
import { useRedeemTransactions } from "../../../../quries/redeemTransaction";
import { useSelector } from "react-redux";
import LoadingIcon from "../../shared/general/loadingIcon";
import dayjs from "dayjs";
import DateFilter from "../../shared/dateFilter";
import { DatePicker } from "@mui/x-date-pickers";
import DateInputField from "../../shared/inputForm/dateInputField";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 400,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const redeemStatus = {
  1: "กำลังตรวจสอบ",
  2: "รอมารับรางวัล",
  3: "รับรางวัลแล้ว",
  4: "ยกเลิกรายการ",
};

export default function RedeemTransaction() {
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentName: "All Department",
    idDepartment: 0,
  });
  const [sectionSelected, setSectionSelected] = useState({
    sectionName: "All Section",
    idSection: 0,
  });
  const [filterDate, setFilterDate] = useState(dayjs());
  const [redeemStatusSelected, setRedeemStatusSelected] = useState(0);

  const organization = useOrganization();

  const redeemTransaction = useRedeemTransactions({
    idCompany: companySelected?.idCompany
  });

  const handleChangeEmployee = (value) => {
    setEmployeeSelected(value);
    setCompanySelected(null);
    setDepartmentSelected({
      departmentName: "All Department",
      idDepartment: 0,
    });
    setSectionSelected({
      sectionName: "All Section",
      idSection: 0,
    });
  };

  const exportFile = useCallback(() => {
    let data = [...redeemTransaction.data];
    if (companySelected) {
      data = data.filter((emp) => emp.idCompany === companySelected.idCompany);
    }

    const exportData = data.map((emp) => ({
      employeeID: emp.employeeID,
      firstname_TH: emp.firstname_TH,
      lastname_TH: emp.lastname_TH,
      companyName: emp.companyName,
      divisionName: emp.divisionName,
      departmentName: emp.departmentName,
      sectionName: emp.sectionName,
      positionName: emp.positionName,
      name: emp.name,
      price: emp.price,
      quantity: emp.quantity,
      totalValue: emp.price * emp.quantity,
      redeemDate: dayjs(emp.redeemDate).format("DD-MM-YYYY HH:mm"),
      receivedDate: emp.receivedDate ? dayjs(emp.receivedDate).format("DD-MM-YYYY") : "ยังไม่กำหนด",
      participantStatus: redeemStatus[emp.idRedeemStatus],
    }));

    const ws = utils.json_to_sheet(exportData);
    utils.sheet_add_aoa(
      ws,
      [
        [
          "รหัสพนักงาน",
          "ชื่อ",
          "นามสกุล",
          "บริษัท",
          "ฝ่าย",
          "ส่วน",
          "แผนก",
          "ตำแหน่ง",
          "ชื่อของรางวัล",
          "มูลค่า",
          "จำนวน",
          "มูลค่ารวม",
          "วันที่แลกรางวัล",
          "วันที่ได้รับรางวัล",
          "สถานะ",
        ],
      ],
      { origin: "A1" }
    );
    ws["!cols"] = [
      { wch: 10 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(wb, "รายการแลกของรางวัล.xlsx");
  }, [companySelected]);

  const dataFilter = redeemTransaction.data?.filter((item) => {
    if (employeeSelected) {
      return item.idEmployees === employeeSelected.idEmployees
    }

    const filterRedeemDatee = filterDate 
      ? filterDate.isSame(item.redeemDate ,"month") 
      : true

      // if (employeeSelected) {
      //   return item.idEmployees === filterDate.isSame(item.receivedDate ,"month")
      // }

    const filterDepartment = departmentSelected.idDepartment 
      ? departmentSelected.idDepartment === item.idDepartment 
      : true;

    const filterSection = sectionSelected.idSection 
      ? sectionSelected.idSection === item.idSection
      : true;

    const filterStatus = redeemStatusSelected !== 0
      ? redeemStatusSelected === item.idRedeemStatus
      : true
    
    return filterDepartment && filterSection &&  filterRedeemDatee && filterStatus;
  });

  useEffect(() => {
    if (organization.data) {
      setCompanySelected(organization.data[0])
    }
  }, [organization.isPending])


  return (
    <StyledRoot className={"page"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HeaderPage textLabel={"รายการแลกของรางวัล"} icon={RedeemIcon} />
      </Stack>
      <StyledDivider />
      {organization.isPending || redeemTransaction.isPending ? (
        <LoadingIcon />
      ) : (
        <Container>
          <Grid container alignItems={"center"} spacing={1} marginBottom={4}>
            <Grid item xs={12} sm={12} md={4}>
              <Autocomplete
                disableClearable
                value={companySelected}
                options={organization.data}
                onChange={(event, newValue) => {
                  setCompanySelected(newValue);
                  setEmployeeSelected(null);
                  setDepartmentSelected({
                    departmentName: "All Department",
                    idDepartment: 0,
                  });
                  setSectionSelected({
                    sectionName: "All Section",
                    idSection: 0,
                  });
                }}
                getOptionLabel={(option) => option.companyName}
                isOptionEqualToValue={(option, value) =>
                  option.idCompany === value.idCompany
                }
                renderInput={(params) => (
                  <TextField {...params} label="Company" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disableClearable
                value={departmentSelected}
                options={
                  companySelected
                    ? [
                        { departmentName: "All Department", idDepartment: 0 },
                        ...companySelected.department,
                      ]
                    : []
                }
                disabled={!companySelected || employeeSelected}
                onChange={(event, newValue) => setDepartmentSelected(newValue)}
                getOptionLabel={(option) => option.departmentName}
                isOptionEqualToValue={(option, value) =>
                  option.idDepartment === value.idDepartment
                }
                renderInput={(params) => (
                  <TextField {...params} label="Department" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                disableClearable
                value={sectionSelected}
                options={
                  companySelected
                    ? [
                        { sectionName: "All Section", idSection: 0 },
                        ...companySelected.section,
                      ]
                    : []
                }
                disabled={!companySelected || employeeSelected}
                onChange={(event, newValue) => setSectionSelected(newValue)}
                getOptionLabel={(option) => option.sectionName}
                isOptionEqualToValue={(option, value) =>
                  option.idSection === value.idSection
                }
                renderInput={(params) => (
                  <TextField {...params} label="Section" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectEmployees
                value={employeeSelected}
                handleChange={handleChangeEmployee}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                sx={{ width: "100%" }}
                slotProps={{ actionBar: { actions: ["clear"] } }}
                value={filterDate}
                onChange={(newValue) => setFilterDate(newValue)}
                label={"เลือกเดือนและปี"}
                views={["year", "month"]}
                minDate={dayjs().subtract(1, "year").startOf("year")}
                maxDate={dayjs()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel>{"สถานะ"}</InputLabel>
                <Select
                  label={"สถานะ"}
                  value={redeemStatusSelected}
                  onChange={(e) => setRedeemStatusSelected(e.target.value)}
                >
                  <MenuItem value={0}>{"ทั้งหมด"}</MenuItem>
                  <MenuItem value={1}>{"กำลังตรวจสอบ"}</MenuItem>
                  <MenuItem value={2}>{"รอมารับรางวัล"}</MenuItem>
                  <MenuItem value={3}>{"รับรางวัลแล้ว"}</MenuItem>
                  <MenuItem value={4}>{"ยกเลิกรายการ"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                onClick={exportFile}
                startIcon={<FileDownloadIcon />}
              >
                Export
              </Button>
            </Grid>
          </Grid>
          <RedeemTransactionGrid data={dataFilter} />
        </Container>
      )}
    </StyledRoot>
  );
}
