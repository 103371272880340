import React, { useState, useEffect } from "react";
import 'date-fns';
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';

// Components
import Paper from '@mui/material/Paper';
import { Typography, Divider } from "@mui/material";
import { Fragment } from "react";

const StyledImageRewardItem = styled('div')({
  height: 100,
  minWidth: 200,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center"
})

export default function RewardTable({ rewards, handleClickOpenEdit, handleClickOpenDelete }) {

  const EditIcon = () => {
    return (
      <img src={`${process.env.PUBLIC_URL}/images/assets/edit.png`} width={24} />
    );
  }

  const DeleteIcon = () => {
    return (
      <img src={`${process.env.PUBLIC_URL}/images/assets/delete.png`} width={24} />
    );
  }

  useEffect(() => {

  }, []);

  return (
    <Fragment>
      {/* <MaterialTable
        title=""
        columns={[
          {
            title: 'ของรางวัล', field: 'name',
            width: 500,
            cellStyle: {
              width: 500,
              minWidth: 500
            },
            headerStyle: {
              width: 500,
              minWidth: 500
            },
          },
          {
            title: 'เหรียญ', field: 'price',
            width: 50,
            cellStyle: {
              width: 50,
              minWidth: 50
            },
            headerStyle: {
              width: 50,
              minWidth: 50
            },
          },
          {
            title: 'คงเหลือ', field: 'currentQuantity',
            width: 50,
            cellStyle: {
              width: 50,
              minWidth: 50
            },
            headerStyle: {
              width: 50,
              minWidth: 50
            },
          },
          {
            title: 'ช่วงเวลา', field: 'startDate',
            width: 200,
            cellStyle: {
              width: 200,
              minWidth: 200
            },
            headerStyle: {
              width: 200,
              minWidth: 200
            },
            render: (item, rows) => (
              <div>
                <Typography style={{ fontSize: 14 }} variant="subtitle1">{dayjs(item.startDate).format("D/M/YYYY")}{" - "}{dayjs(item.expiryDate).format("D/M/YYYY")}</Typography>
              </div>
            ),
          },
        ]}
        data={rewards}
        actions={[
          {
            icon: 'add',
            tooltip: 'เพิ่มรางวัล',
            isFreeAction: true,
            onClick: (event) => alert("You want to add a new row")
          },
          {
            icon: () => <EditIcon />,
            tooltip: 'แก้ไขรางวัล',
            iconProps: { style: {} },
            onClick: (event, rowData) => handleClickOpenEdit(rowData)
          },
          rowData => ({
            icon: () => <DeleteIcon />,
            tooltip: 'ลบรางวัล',
            iconProps: { style: { color: "rgb(244, 67, 54)" } },
            onClick: (event, rowData) => handleClickOpenDelete(rowData),
          })
        ]}
        options={{
          actionsColumnIndex: -1
        }}
        detailPanel={rowData => {
          return (
            <div style={{ padding: 10, display: "flex" }}>
              <StyledImageRewardItem
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/rewards/${rowData.image})`, }}>
              </StyledImageRewardItem>
              <div>
                <div style={{ display: "flex" }}>
                  <Typography style={{ fontSize: 14, minWidth: 100 }}>ราคา (บาท)</Typography>
                  <Typography color="textSecondary" style={{ fontSize: 14 }}>{rowData.amount}</Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography style={{ fontSize: 14, minWidth: 100 }}>รายละเอียด</Typography>
                  <Typography color="textSecondary" style={{ fontSize: 14 }}>{rowData.detail}</Typography>
                </div>
              </div>
            </div>
          )
        }}
      /> */}
    </Fragment>
  );
}
