import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  styled,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";

import dayjs from "dayjs";

const currentDate = dayjs();
const WrapDrawerFilter = styled("div")({
  marginTop: 64,
  padding: 16,
  width: 300,
  "& .wrapBtnReset": {
    marginTop: 16,
    "& Button": {
      width: "100%",
      marginBottom: 8,
    },
  },
});

const AssessmentFilter = ({ setFilterAssessment }) => {
  const { result: appraiseeList, isFetching } = useSelector(
    (state) => state.appraisee
  );
  const [filteredAppraisees, setFilteredAppraisees] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    assessmentType: { open: true, close: true },
    status: { ongoing: true, finished: true },
  });

  const handleSearch = () => {
    if (!appraiseeList || isFetching) return; // Safety check

    const filteredAppraisees = appraiseeList.filter((item) => {
      // filter based on assessment type (open or close)
      const isOpen = filterOptions.assessmentType.open && item.type === "open";
      const isClose = filterOptions.assessmentType.close && item.type === "close";

      // filter based on status (ongoing or finished)
      const isOngoing =
        filterOptions.status.ongoing &&
        currentDate.isAfter(dayjs(item.startDate)) &&
        currentDate.isBefore(dayjs(item.endDate)) === "ongoing";

      const isFinished =
        filterOptions.status.finished && currentDate.isAfter(dayjs(item.endDate)) === "finished";

      return (isOpen || isClose) && (isOngoing || isFinished);
    });

    setFilteredAppraisees(filteredAppraisees); // Update the filtered list
    setFilterAssessment(filterOptions); // Pass the filter options to parent
  };

  const handleReset = () => {
    setFilterOptions({
      assessmentType: { open: true, close: true },
      status: { ongoing: true, finished: true },
    });

    setFilteredAppraisees([]);
  };

  // const handleChange = (event) => {
  //   setFilterOptions((prevOptions) => {
  //     const newOptions = {
  //       ...prevOptions,
  //       assessmentType: {
  //         ...prevOptions.assessmentType,
  //         [event.target.name]: event.target.checked,
  //       },
  //     };
  //     return newOptions;
  //   });
  // };

  // Handle change for assessmentType
  const handleAssessmentTypeChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      assessmentType: {
        ...prevOptions.assessmentType,
        [name]: checked,
      },
    }));
  };

  // Handle change for status
  const handleStatusChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      status: {
        ...prevOptions.status,
        [name]: checked,
      },
    }));
  };

  return (
    <WrapDrawerFilter>
      <Stack spacing={2}>
        {/* <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">ประเภทคำถาม</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.assessmentType.close}
                  onChange={handleAssessmentTypeChange}
                  name="close"
                  color="primary"
                />
              }
              label="คำถามปลายปิด" // Closed questions
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.assessmentType.open}
                  onChange={handleAssessmentTypeChange}
                  name="open"
                  color="primary"
                />
              }
              label="คำถามปลายเปิด" // Open questions
            />
          </FormGroup>
        </FormControl> */}

        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">สถานะการประเมิน</FormLabel>{" "}
          {/* Assessment Status */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.status.ongoing}
                  onChange={handleStatusChange}
                  name="ongoing"
                  color="primary"
                  data-type="status"
                />
              }
              label="กำลังดำเนินการ" // Ongoing
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterOptions.status.finished}
                  onChange={handleStatusChange}
                  name="finished"
                  color="primary"
                  data-type="status"
                />
              }
              label="เสร็จสิ้นแล้ว" // Finished
            />
          </FormGroup>
        </FormControl>

        <div className={`wrapBtnReset`}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Stack>
    </WrapDrawerFilter>
  );
};

export default AssessmentFilter;
