import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const FilterOrder = ({ setFilterValue }) => {
  const [filterOrder, setFilterOrder] = useState("");

  const handleChangeFilter = (event) => {
    setFilterOrder(event.target.value);
  };

  const handleClickSearch = () => {
    setFilterValue(filterOrder);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <TextField
        label="ค้นหารหัสคำสั่งซื้อ บริษัท ผู้ซื้อ"
        value={filterOrder}
        onChange={handleChangeFilter}
      />
      <Button
        variant="contained"
        startIcon={<SearchIcon />}
        onClick={handleClickSearch}
      >
        ค้นหา
      </Button>
    </div>
  );
};

export default FilterOrder;
