import React, { useState } from "react";
import {
  Container,
  Divider,
  styled,
} from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import CoinTransaction from "./coinTransaction";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  backgroundColor: "#212121",
  minHeight: "100dvh",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  marginTop: "0.5rem",
  marginBottom: "1rem",
  backgroundColor: "#ffffff",
});

const StyledDatePicker = styled(DatePicker)({
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "& .Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
  },
  "& .MuiIconButton-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
});

const Transaction = () => {
  const [filterDate, setFilterDate] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs(),
  })

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"รายการเหรียญ"} color={"#ffffff"} />
      <StyledDivider />
      <Container>
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <StyledDatePicker
            label="Start Date"
            value={filterDate.startDate}
            onChange={(newValue) => {
              setFilterDate((prev) => ({ ...prev, startDate: newValue }));
            }}
            minDate={dayjs().subtract(1, 'year')}
            maxDate={dayjs(filterDate.endDate)}
          />
          <StyledDatePicker
            label="End Date"
            value={filterDate.endDate}
            onChange={(newValue) => {
              setFilterDate((prev) => ({ ...prev, endDate: newValue }));
            }}
            minDate={dayjs(filterDate.startDate)}
            maxDate={dayjs()}
          />
        </div>
        <CoinTransaction
          startDate={dayjs(filterDate.startDate).format("YYYY-MM-DD")}
          endDate={dayjs(filterDate.endDate).format("YYYY-MM-DD")} 
        />
      </Container>
    </StyledRoot>
  );
};

export default Transaction;
