import "./styles.css";
import React, { useState } from "react";
import Image from "@tiptap/extension-image";
import Youtube from "@tiptap/extension-youtube";
// import ListItem from "@tiptap/extension-list-item";
// import TextStyle from '@tiptap/extension-text-style'
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import InputUrl from "./inputUrl";

const MenuBar = ({ editor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type, setType] = useState("");

  if (!editor) {
    return null;
  }

  const openInput = (event, type) => {
    setAnchorEl(event.currentTarget);
    setType(type);
  };

  const closeInput = () => {
    setAnchorEl(null);
  };

  const addImage = (url) => {
    // const url = window.prompt("URL");

    // if (url) {
    //   editor.chain().focus().setImage({ src: url }).run();
    // }

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
    closeInput();
  };

  const addYoutubeVideo = (url) => {
    // const url = prompt("Enter YouTube URL");

    // if (url) {
    //   editor.commands.setYoutubeVideo({ src: url });
    // }

    if (url) {
      editor.commands.setYoutubeVideo({ src: url });
    }
    closeInput();
  };

  return (
    <>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <FormatBoldIcon />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <FormatItalicIcon />
      </button>
      {/* <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        paragraph
      </button> */}
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        <FormatSizeIcon />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <FormatListBulletedIcon />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <FormatListNumberedIcon />
      </button>
      {/* <button type="button" value={"image"} onClick={(e) => openInput(e)}>
        <InsertPhotoIcon />
      </button> */}
      <button type="button" value={"youtube"} onClick={(e) => openInput(e)}>
        <YouTubeIcon />
      </button>
      <InputUrl
        anchorEl={anchorEl}
        handleClose={closeInput}
        handleUrl={type === "image" ? addImage : addYoutubeVideo}
      />
      {/* <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </button>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </button> */}
    </>
  );
};

const TipTap = ({ detail, onChange }) => {
  const editor = useEditor({
    extensions: [
      //   TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Youtube.configure({
        controls: false,
        width: 640,
        height: 480,
      }),
      // Image,
    ],
    content: detail,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  return (
    <div className="text-editor">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default TipTap;
