import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

function DialogSuccess({ open, handleClose, title, content, handleConfirm }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>ยกเลิก</Button>
        <Button variant="contained" onClick={handleConfirm}>ยืนยัน</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSuccess;