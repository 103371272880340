import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EastIcon from '@mui/icons-material/East';
import dayjs from "dayjs";
import { SpecialIcon } from "../../shared/typeCoin";
import {
  useCompanyCoinInTransaction,
  useCompanyCoinOutTransaction,
} from "../../../../quries/coin";

const Detail = ({ data }) => {
  if (data.idHeartTransfer) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>
            {"ส่งหัวใจ"}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item display={"flex"} alignItems={"center"} gap={"8px"}>
          <Avatar alt="employee" src={data.sender_imageURL} />
          <Typography color={"#ffffff"}>
            {`${data.sender_firstname_TH} ${data.sender_lastname_TH}`}
          </Typography>
          <EastIcon sx={{ color: "#ffffff"}} />
          <Avatar alt="employee" src={data.receiver_imageURL} />
          <Typography color={"#ffffff"}>
            {`${data.receiver_firstname_TH} ${data.receiver_lastname_TH}`}
          </Typography>
        </Grid>
      </>
    );
  } else if (data.idActivity) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>
            {"กิจกรรม"}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} gap={"8px"}>
          <Avatar alt="employee" src={data.imageURL} />
          <Typography color={"#ffffff"}>
            {`${data.firstname_TH} ${data.lastname_TH} - ${data.name}`}
          </Typography>
        </Grid>
      </>
    );
  } else if (data.idCompanySendCoin) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>
            {"บริษัทส่งเหรียญ"}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} gap={"8px"}>
          <Avatar alt="employee" src={data.imageURL} />
          <Typography color={"#ffffff"}>
            {`${data.receiver_firstname_TH} ${data.receiver_lastname_TH} - ${data.detail}`}
          </Typography>
        </Grid>
      </>
    );
  } else if (data.idMoraleDaily) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>
            {"สุขภาพใจรายวัน"}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} gap={"8px"}>
          <Avatar alt="employee" src={data.imageURL} />
          <Typography color={"#ffffff"} textOverflow={"ellipsis"}>
            {`${data.firstname_TH} ${data.lastname_TH} - ${data.question}`}
          </Typography>
        </Grid>
      </>
    );
  } else if (data.idCommunication) {
    return (
      <>
        <Grid item xs={12} md={1.5}>
          <Typography fontWeight={600} color={"#ffffff"}>
            {"ข้อเสนอแนะ"}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: "#ffffff" }}
        />
        <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} gap={"8px"}>
          <Avatar alt="employee" src={data.imageURL} />
          <Typography color={"#ffffff"}>
            {`${data.firstname_TH} ${data.lastname_TH} - ${data.detail}`}
          </Typography>
        </Grid>
      </>
    );
  } else {
    return null;
  }
};

const CoinOut = ({ item }) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={6} md={1}>
        <Chip
          sx={{ fontSize: "1rem" }}
          label={item.amount}
          icon={<RemoveIcon />}
          color={"error"}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <SpecialIcon idCoinType={item.idCoinType} width={40} />
        <Typography variant="h6" color={"#ffffff"}>
          {item.coinName}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} sm={6} md={2}>
        <Typography color={"#ffffff"}>
          {dayjs(item.transactionDate).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Detail data={item} />
    </Grid>
  );
};

const CoinIn = ({ item }) => {
  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={6} md={1}>
        <Chip
          sx={{ fontSize: "1rem" }}
          label={item.amount}
          icon={<AddIcon />}
          color="success"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        display={"flex"}
        alignItems={"center"}
        gap={1}
      >
        <SpecialIcon idCoinType={item.idCoinType} width={40} />
        <Typography variant="h6" color={"#ffffff"}>
          {item.coinName}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} sm={6} md={2}>
        <Typography color={"#ffffff"}>
          {dayjs(item.transactionDate).format("YYYY-MM-DD HH:mm")}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} md={1.5}>
        <Typography fontWeight={600} color={"#ffffff"}>
          {"แลกรางวัล"}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      <Grid item xs={12} md={2.5} display={"flex"} alignItems={"center"} gap={"8px"}>
        <Avatar alt="employee" src={item.imageURL} /> 
        <Typography color={"#ffffff"}>
          {`${item.firstname_TH} ${item.lastname_TH}`}
        </Typography>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ backgroundColor: "#ffffff" }}
      />
      {item.options && (
        <Grid item xs={12} md={2.5} display={"flex"} gap={1}>
          <Typography color={"#ffffff"}>{item.name}</Typography>
          <Typography color={"#ffffff"}>
            {item.options.map((option) => option.value).join(", ")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const CoinTransaction = ({ idCompany, startDate, endDate, idEmployees, selectTransaction }) => {
  const coinIn = useCompanyCoinInTransaction({ idCompany, startDate, endDate });
  const coinOut = useCompanyCoinOutTransaction({ idCompany, startDate, endDate });

  if (coinIn.isPending || coinOut.isPending) {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <CircularProgress />
      </div>
    );
  }

  let data = [...coinIn?.data, ...coinOut?.data]
  if (idEmployees) {
    data = data.filter(transaction => transaction.idEmployees === idEmployees)
  }
  if (selectTransaction !== "all") {
    if (selectTransaction === "activity") {
      data = data.filter(transaction => transaction.idActivity)
    } else if (selectTransaction === "sendHeart") {
      data = data.filter(transaction => transaction.idHeartTransfer)
    } else if (selectTransaction === "moraleDaily") {
      data = data.filter(transaction => transaction.idMoraleDaily)
    } else if (selectTransaction === "communication") {
      data = data.filter(transaction => transaction.idCommunication)
    } else if (selectTransaction === "companySendCoin") {
      data = data.filter(transaction => transaction.idCompanySendCoin)
    }
  }

  return (
    <Stack spacing={2} marginTop={4}>
      {data.length > 0 ? (
        data.map((item) => (
          <Card sx={{ backgroundColor: "#000000", borderRadius: "1rem" }}>
            <CardContent>
              {item.type === "in" ? (
                <CoinIn item={item} />
              ) : (
                <CoinOut item={item} />
              )}
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="h5" color={"#ffffff"}>ไม่พบข้อมูล</Typography>
      )}
    </Stack>
  );
};

export default CoinTransaction;
