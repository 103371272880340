import { httpClient } from "./httpClient";

const getAllAssessment = () => {
  return httpClient.get("assessment");
};

const getAnswerByUserId = (idAssessment, assignTo) => {
  return httpClient.get(
    `assessment/${idAssessment}/answer?assessment=${idAssessment}&assign_to=${assignTo}`
  );
};

const getAssessment = (idAssessment) => {
  return httpClient.get('assessment/' + idAssessment);
};

const addAssessment = (formData) => {
  return httpClient.post(`assessment`, formData);
};

const updateAssessment = (formData) => {
  return httpClient.patch(`assessment`, formData);
};

const deleteAssessment = (idAssessment) => {
  return httpClient.delete(`assessment/` + idAssessment);
};

const updateAssessmentQuestion = (formData) => {
  return httpClient.patch('assessment-question', formData);
};

const getAssessmentAnswer = () => {
  return httpClient.get("assessment-answer");
}

export default {
  getAllAssessment,
  getAssessment,
  getAnswerByUserId,
  addAssessment,
  updateAssessment,
  deleteAssessment,
  updateAssessmentQuestion,
  getAssessmentAnswer,
};
