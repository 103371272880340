import { ALERT_DAILY_INSIGHT_OPEN,
  ALERT_DAILY_INSIGHT_CLOSE, } from "../actions/types";

const initialState = {
  isOpen: false,
};

export default function reducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case ALERT_DAILY_INSIGHT_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case ALERT_DAILY_INSIGHT_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
