import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';

import AddIcon from "@mui/icons-material/Add";

import HeaderPage from "./../../shared/header/headerPage";

import iconHeader from "./assets/teamwork.svg";
import iconAddHeader from "./assets/add-employee.svg";
import iconEditHeader from "./assets/edit-employee.svg";

import EmployeeList from "./employeeList";
import EmployeeAddNew from "./employeeAddNew";

import "./index.css";

import { Button, Divider, IconButton } from "@mui/material";

import { Fragment } from "react";

const WrapHeader = styled('div')({
  marginTop: 16,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ["@media only screen and (max-width: 600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:768px)"]: {
    "& .MuiTypography-root": {
      fontSize: 34,
    },
  },
  ["@media only screen and (min-width:992px)"]: {},
})

const WrapBtnAddNew = styled('div')({
  ["@media only screen and (max-width: 600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
})

const WrapBtnIconAddNew = styled('div')({
  ["@media only screen and (max-width: 600px)"]: {},
  ["@media only screen and (min-width:600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
})

const StyledDivider = styled(Divider)({
  margin: "10px 0",
})

const EmployeesPage = () => {
  const [mode, setMode] = useState("list");

  return (
    <div className="page">
      <WrapHeader>
        {mode === "list" && (
          <Fragment>
            <HeaderPage textLabel={"รายชื่อพนักงาน"} icon={iconHeader} />
            <WrapBtnAddNew>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setMode("add")}
              >
                Create Employee
              </Button>
            </WrapBtnAddNew>
            <WrapBtnIconAddNew>
              <IconButton aria-label="add" size="large">
                <AddIcon />
              </IconButton>
            </WrapBtnIconAddNew>
          </Fragment>
        )}
        {mode === "add" && (
          <HeaderPage textLabel={"เพิ่มพนักงานใหม่"} icon={iconAddHeader} />
        )}
        {mode === "edit" && (
          <HeaderPage textLabel={"แก้ไขข้อมูล"} icon={iconEditHeader} />
        )}
      </WrapHeader>
      <StyledDivider />
      {mode === "list" && <EmployeeList />}
      {mode === "add" && <EmployeeAddNew />}
    </div>
  );
};

export default EmployeesPage;
