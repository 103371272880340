
import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./component/DialogConfirmReject";
import CardRequest from "./component/CardRequest";
import {
  getHeartTransferWaitingApprove,
  approveTransferHeart
} from "../../../../actions/heartTransfer";



const StyledButtonCancel = styled(ButtonBlue)({
    backgroundColor: "#E793B8",
    borderRadius: "8px",
    color: "#FFFFFF",
    marginRight: 8,
    width: 130,
    "&:hover": {
      backgroundColor: "#DC6384",
    },
  });


const RequestSendHeartTransfer = (props)=>{
    const mobileResponsive = useMediaQuery("(max-width:600px)");
    const [selectedRows, setSelectedRows] = useState([]);
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
    const { result: heartTransfer } = useSelector((state) => state.heartTransfer);
    const dispatch = useDispatch();
    const { setSnackBarConfig, setDisplaySnackbar, setModeSnackbar} = props;

    const handleCancel =()=>{
      setSelectedRows([]);
    }
    const handleClickAll =()=>{
      setSelectedRows(heartTransfer);

    }
  const columns = [
    {
      name: "ชื่อ-สกุล(ผู้ส่ง)"	,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.sender_imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{row.sender_firstname_TH} {row.sender_lastname_TH}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: "ชื่อ-สกุล(ผู้รับ)",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.receiver_imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{row.receiver_firstname_TH} {row.receiver_lastname_TH}</Typography>
            {/* <Typography color="text.third" fontSize="14px">ตำแหน่ง 1
            </Typography> */}
          </Box>
        </Box>
      ),
    },
    {
      name: "จำนวนหัวใจ",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {row.value}
          </Typography>
          
        </Box>
      ),
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.detail}
          </Typography>
        </Box>
      ),
    },
    {
        name: "ส่งเมื่อ",
        minWidth: "200px",
        cellRender: (row) => (
          <Box>
            <Typography>
              {dayjs(row.transferDate).format("DD/MM/YYYY HH:mm")}
            </Typography>
          </Box>
        ),
      }
  ];

  const handleApprovalRequest = async(approve,comment)=>{
    let heartTransfers = [];
    for(const req of selectedRows){
      const data = {
        idHeartTransfer:req.idHeartTransfer,
        idSender:req.idSender,
        idReceiver:req.idReceiver,
        value:req.value,
        idCoinType:req.idCoinType,
      }
      heartTransfers.push(data); 
    }
    let formData = {
      heartTransfers:heartTransfers,
      approve :approve,
      managerLV1_comment:comment
      
    }
    setIsOpenRejectDialog(false);
    const result = await dispatch(approveTransferHeart(formData));
    if (result) {
      setSelectedRows([]);
      dispatch(getHeartTransferWaitingApprove(new Date().getMonth() + 1))
      if (result.status === 200) {
        setSnackBarConfig(true);
        setDisplaySnackbar("Successfully")
        setModeSnackbar("success")
        
      } else {
        setSnackBarConfig(true);
        setModeSnackbar("error");
        setDisplaySnackbar("Unsuccessful")


      }
    } else {
      setSnackBarConfig(true);
      setModeSnackbar("error")
      setDisplaySnackbar("Unsuccessful")

    }



  }


  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {Array.isArray(selectedRows) && selectedRows.length > 0 && (
            <StyledButtonCancel onClick={handleCancel} startIcon={<DeleteIcon />}>
              ยกเลิก
            </StyledButtonCancel>
          )}
          {!(
            Array.isArray(selectedRows) &&
            Array.isArray(heartTransfer) &&
            selectedRows.length === heartTransfer.length
          ) && (
            <ButtonBlue variant="contained" startIcon={<DoneAll />} onClick={handleClickAll}>
              เลือกทั้งหมด
            </ButtonBlue>
          )}
        </Box>
      )}

      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            อนุมัติ {
              selectedRows.length > 0
                ? `${selectedRows.length} รายการ`
                : ""
            }
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            ไม่อนุมัติ {
              selectedRows.length > 0
                ? `${selectedRows.length} รายการ`
                : ""
            }
          </ButtonBlue>
        </Box>
      )}
      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={heartTransfer  ?? []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {heartTransfer ? (
            heartTransfer.map((row) => (
              <CardRequest
                key={row.idRequestTime}
                row={row}
                mode={"heartTransfer"}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />            ))
          ) : (
            <div>
              <Typography align="center">ไม่มีข้อมูล</Typography>
            </div>
          )}
        </div>
      )}
      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleApprovalRequest(0,comment);
        }}
      />
    </Box>
  );





}


export default RequestSendHeartTransfer;

