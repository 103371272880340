import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  Card,
  Divider,
  Stack,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";

import { useDispatch, useSelector } from "react-redux";
import { getMoraleDaily } from "../../../../../actions/moraleDaily";
import { getAllEmployees } from "../../../../../actions/user";
import * as dayjs from "dayjs";
import { useParams } from "react-router-dom";
import FeelingIcon from "../../../shared/feelingIcon";

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
});

const WrapSection2 = styled("div")({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: 16,
});

const WrapSection3 = styled("div")({
  marginTop: 30,
});

const StyledCard = styled(Card)({
  marginTop: 40,
  marginBottom: 40,
  padding: 16,
  backgroundColor: "#e6ceff",
  color: "white",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
});

const answerQuestions5Scale = [
  {
    key: "verybad",
    label: "Very Bad",
    value: "0",
  },
  {
    key: "bad",
    label: "Bad",
    value: "25",
  },
  {
    key: "neutral",
    label: "Neutral",
    value: "50",
  },
  {
    key: "happy",
    label: "Happy",
    value: "75",
  },
  {
    key: "veryhappy",
    label: "Very Happy",
    value: "100",
  },
];

const answerQuestions6Scale = [
  {
    key: "verybad",
    label: "Very Bad",
    value: "0",
  },
  {
    key: "bad",
    label: "Bad",
    value: "20",
  },
  {
    key: "littlebad",
    label: "Little Bad",
    value: "40",
  },
  {
    key: "littlehappy",
    label: "Little Happy",
    value: "60",
  },
  {
    key: "happy",
    label: "Happy",
    value: "80",
  },
  {
    key: "veryhappy",
    label: "Very Happy",
    value: "100",
  },
];

const MoraleDailyAnswer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { result: moraleDailyItem } = useSelector((state) => state.moraleDailyItem);
  const { result: answerScale } = useSelector((state) => state.answerScale);
  const [mode, setMode] = React.useState('average');

  const handleChangeMode = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const calPercentScale = (answerType) => {
    const count = moraleDailyItem.participantList.reduce(
      (counter, obj) => (obj.answer === answerType ? (counter += 1) : counter),
      0
    );
    return count
  }

  const calNumOfAnswer = () => {
    const count = moraleDailyItem.participantList.reduce(
      (counter, obj) => (obj.answer ? (counter += 1) : counter),
      0
    );
    return count
  }

  const calScore = () => {
    if (mode === "average") {
      const score = moraleDailyItem.participantList.reduce(
        (counter, obj) => {
          switch (obj.answer) {
            case "Very Bad":
              counter += 0;
              break;
            case "Bad":
              counter += 20;
              break;
            case "Little Bad":
              counter += 40;
              break;
            case "Little Happy":
              counter += 60;
              break;
            case "Happy":
              counter += 80;
              break;
            case "Very Happy":
              counter += 100;
              break;
            default:
              break;
          }
          return counter;
        },
        0
      );
      const average = score / calNumOfAnswer();
      return average;
    } else {
      let count = 0
      const score = moraleDailyItem.participantList.reduce(
        (counter, obj) => { 
          switch (obj.answer) {
            case "Happy":
              counter += 80;
              count++;
              break;
            case "Very Happy":
              counter += 100;
              count++;
              break;
            default:
              break;
          }
          return counter;
        },
        0
      );
      console.log(score)
      console.log(count)
      const bestratio = score / count;
      return bestratio;
    }
  }
  
  const columns = [
    {
      field: "image",
      headerName: "Image",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => <Avatar src={params.row.imageURL} />,
    },
    {
      field: "employeeID",
      headerName: "employee ID",
      minWidth: 200,
      sortable: false,
    },
    {
      field: "firstname_TH",
      headerName: "First name",
      minWidth: 200,
      sortable: false,
    },
    {
      field: "lastname_TH",
      headerName: "Last name",
      minWidth: 200,
      sortable: false,
    },
    {
      field: "answer",
      headerName: "Answer",
      sortable: false,
      minWidth: 200,
    },
  ];


  const rows = () => {
    return moraleDailyItem.participantList.map((item) => {
      return {
        id: item.idEmployees,
        employeeID: item.employeeID,
        imageURL: item.imageURL,
        firstname_TH: item.firstname_TH,
        lastname_TH: item.lastname_TH,
        answer: item.answer || 'ไม่มีข้อมูล',
      };
    });
  };

  useEffect(() => {
    dispatch(getMoraleDaily(id));
  }, []);

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage
          textLabel={"Morale Daily Answer"}
          icon={NotificationIcon}
        />
      </WrapHeader>
      <StyledDivider />
      {moraleDailyItem && (
        <>
          <StyledCard>
            <Grid container marginBottom={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="h6">
                  {`Morale id : ${moraleDailyItem.idMoraleDaily}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="h6">
                  {`Question : ${moraleDailyItem.question}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="h6">
                  {`Start date : ${dayjs(moraleDailyItem.startDate).format(
                    "YYYY-MM-DD HH:mm"
                    )}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Typography variant="h6">
                  {`End date : ${dayjs(moraleDailyItem.endDate).format(
                    "YYYY-MM-DD HH:mm"
                    )}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"}>
              {answerQuestions6Scale.map((item) => (
                <Grid item xs={6} md={4} lg={2} key={item.key}>
                  <Stack direction={'row'} spacing={1}>
                    <div style={{ width: 40, height: 40 }}>
                      <FeelingIcon feeling={item.key} />
                    </div>
                    <div>
                      <Typography>{item.label}</Typography>
                      <Typography>
                        {`${(calPercentScale(item.label) * 100) /calNumOfAnswer()
                          ? Math.round((calPercentScale(item.label) * 100) /calNumOfAnswer())
                          : 0
                        }%`}
                        </Typography>
                    </div>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </StyledCard>
          <WrapSection2>
            <Typography color={"#3C3C3C"} marginRight={2}>
              {`ประเมินแล้ว ${calNumOfAnswer()}/${moraleDailyItem.participantList.length} คน`}
            </Typography>
            <Typography color={"#3C3C3C"} marginRight={2}>
              {`Score: ${Math.round(calScore())}`}
            </Typography>
            <ToggleButtonGroup
              color="secondary"
              value={mode}
              exclusive
              onChange={handleChangeMode}
              aria-label="Platform"
            >
              <ToggleButton value="average">Average</ToggleButton>
              <ToggleButton value="bestratio">best ratio</ToggleButton>
            </ToggleButtonGroup>
          </WrapSection2>
          <WrapSection3>
            <DataGrid
              autoHeight
              rows={rows()}
              columns={columns}
              pageSize={12}
              rowsPerPageOptions={[12]}
              // experimentalFeatures={{ newEditingApi: true }}
            />
          </WrapSection3>
        </>
      )}
    </StyledRoot>
  );
};

export default MoraleDailyAnswer;
