import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useOrganization = () => {
  return useQuery({
    queryKey: ["organization"],
    queryFn: async () => (await httpClient.get(`affiliate/organization`)).data,
    refetchOnWindowFocus: false,
  });
};

export const useEmploymentType = ({ idCompany }) => {
  return useQuery({
    queryKey: ["employmentType", idCompany],
    queryFn: async () => (await httpClient.get(`company/employmentType/${idCompany}`)).data,
    enabled: !!idCompany,
    refetchOnWindowFocus: false,
  });
};

export const useEmployeeLevel = ({ idCompany }) => {
  return useQuery({
    queryKey: ["employmentLevel", idCompany],
    queryFn: async () => (await httpClient.get(`RightsManage/getLevelGroup`)).data,
    enabled: !!idCompany,
    refetchOnWindowFocus: false,
  });
};

export const useCompanyBudget = ({ idCompany }) => {
  return useQuery({
    queryKey: ["companyBudget"],
    queryFn: async () => (await httpClient.get(`company-budget/${idCompany}`)).data,
    enabled: !!idCompany,
  });
};

export const useCostReward = ({ idCompany }) => {
  return useQuery({
    queryKey: ["costReward"],
    queryFn: async () => (await httpClient.get(`cost-reward/${idCompany}`)).data,
    enabled: !!idCompany,
  });
};

export const useCompanyCoinQuota = ({ idCompany }) => {
  return useQuery({
    queryKey: ["companyCoinQuota", idCompany],
    queryFn: async () => (await httpClient.get(`company/coin-quota`, { params: { idCompany } })).data,
    enabled: !!idCompany,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateCompanyResetCoinBalance = ({ idCompany }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => await httpClient.patch(`company/coin-quota/reset-balance`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companyCoinQuota", idCompany] });
    },
  });
};

export const useUpdateCompanySendHeartQuota = ({ idCompany }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => await httpClient.patch(`company/coin-quota/send-heart`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companyCoinQuota", idCompany] });
    },
  });
};

export const useUpdateCompanyAssignMissionQuota = ({ idCompany }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formData) => await httpClient.patch(`company/coin-quota/assign-mission`, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companyCoinQuota", idCompany] });
    },
  });
};
