import { httpClient } from "./httpClient";

const getAllCoachRecordByIdCoach = (idCoach) => {
  return httpClient.get("coach-record/" + idCoach);
};
const getAllCoacheeRecordByIdCoachee = (idCoachee) => {
  return httpClient.get("coachee-record/" + idCoachee);
};

const addCoachRecord = (formData) => {
  return httpClient.post("coach-record", formData);
};
const addCoacheeRecord = (formData) => {
  return httpClient.post("coachee-record", formData);
};

const updateCoachRecord = (formData) => {
  return httpClient.patch("coach-record", formData);
};

export default {
  getAllCoachRecordByIdCoach,
  getAllCoacheeRecordByIdCoachee,
  addCoachRecord,
  addCoacheeRecord,
  updateCoachRecord,
};
