import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Divider,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAssessmentAnswer } from "../../../../actions/assessment";
import { getAllDataTopic } from "../../../../actions/dataTopic";

const DivRoot = styled('div')({
  display: "flex",
  paddingTop: 20,
  flexDirection: "column",
  margin: "0px auto",
  minWidth: 0,
  marginBottom: 60,
  ["@media only screen and (min-width:992px)"]: {
    maxWidth: 960,
    width: 960,
  },
  ["@media only screen and (min-width:1200px)"]: {
    maxWidth: 1152,
    width: 1152,
  },
})

const StyleDivider = styled(Divider)({
  margin: "10px 0",
})

const IndexQuestion = styled(Typography)({
    position: "absolute",
    fontSize: 34,
    top: 210,
    fontWeight: 600,
    left: -175,
    color: "#d6d6d6",
})

const randOneFour = () => Math.floor(Math.random() * 4) + 1;

export default function UserAnswerAssessment360() {
  const dispatch = useDispatch();
  const { result: questions } = useSelector((state) => state.assessmentAnswer)
  
  useEffect(() => {
    dispatch(getAssessmentAnswer());
  }, []);

  const renderQuestions = (value) => {
    return (
      <Card variant="outlined" style={{  }}>
        <CardContent>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: 230,
            }}
          >
            <div style={{ position: "relative" }}>
              <IndexQuestion>
                #{value.idQuestion}
              </IndexQuestion>
            </div>
            <Typography variant="subtitle1" >{value.questioneText}</Typography>
            <img
              alt="choice"
              width={120}
              src={`${
                process.env.PUBLIC_URL
              }/images/assets/choice${randOneFour()}.png`}
            />
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div style={{ marginLeft: 56, minWidth: 475 }}>
      <DivRoot>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="360-degrees"
            src={`${process.env.PUBLIC_URL}/images/assets/360-degrees-color.svg`}
            width={45}
          />
          <Typography variant="h3" style={{ marginLeft: 10, fontSize: 30 }}>
            ประเมิน 360 องศา
          </Typography>
        </div>
        <StyleDivider />
        <div>
          <Grid container spacing={3}>
            {questions && questions.map((value) => {
              return (
                <Grid item xs={6} sm={4}>
                  {renderQuestions(value)}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </DivRoot>
    </div>
  );
}
