
import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Avatar, Chip, Container, Grid ,Rating,Card, Stack,Snackbar,Alert} from "@mui/material";
const StyledCard = styled(Card)({
    height:"100%",
    width:"100%",
    borderRadius: "16px",
    padding:"24px"
  });
  
const CardReview = (props)=>{
    const {review} = props;

    return(
        <StyledCard>
            <Typography sx={{marginBottom:"10px"}}>
                {review.review}
            </Typography>
        
        <Rating
            value={review.rating}
            readOnly
            style={{ fontSize: 24 }}
            precision={0.5}/>
            <div style={{display:"flex" } }>
                <Avatar src={review.profileImageURL} />
                <div style={{marginLeft:"10px"}}>
                <Typography  >
                    {review.firstname_TH} {review.lastname_TH}
                </Typography>
                <Typography className="field-label" color="text.secondary" >
                    {review.positionName}
                </Typography>
                </div>
            </div>
        </StyledCard>
    )
}
export default CardReview;