import { httpClient } from "./httpClient";

const getAllCommunication = () => {
  return httpClient.get("communication");
};

const addCommunication = (formData) => {
  return httpClient.post("communication", formData);
};

const updateCommunication = ({ idCommunication, ...formData }) => {
  return httpClient.patch(`communication/${idCommunication}`, formData);
};

const updateRewardCommunication = (formData) => {
  return httpClient.patch("communication-reward", formData);
};

const addCommunicationReply = ({ idCommunication, ...formData }) => {
  return httpClient.post(`communication/${idCommunication}/reply`, formData);
};

const addLikeCommunication = (idCommunication) => {
  return httpClient.post(`communication/${idCommunication}/like`);
};

const deleteLikeCommunication = (idCommunication) => {
  return httpClient.delete(`communication/${idCommunication}/like`);
};

export default {
  getAllCommunication,
  addCommunication,
  updateCommunication,
  updateRewardCommunication,
  addCommunicationReply,
  addLikeCommunication,
  deleteLikeCommunication,
};
