import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import loading from "../../assets/loading2.gif";
import Box from "@mui/material/Box";
import HeaderPage from "../../shared/header/headerPage";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
} from "@mui/material";

import {  getCoachByIdCategory } from "../../../../actions/coach";
import {  getCategory } from "../../../../actions/category";
import { NormalCard ,AbsoluteCard} from "../../shared/Cards";
import CardCoach from "./component/card_coach";
import {coach} from "./assets/coach.jpg"
import accounting from "./assets/accounting.jpg";
import admin from "./assets/admin.jpg";
import business from "./assets/business.jpg";
import construction from "./assets/construction.jpg";
import csr from "./assets/csr.jpg";
import digital from "./assets/digital.jpg";
import energy from "./assets/energy.jpg";
import finance from "./assets/finance.jpg";
import ga from "./assets/ga.jpg";
import hr from "./assets/hr.jpg";
import legal from "./assets/legal.jpg";
import logistic from "./assets/logistic.jpg";
import maintenance from "./assets/maintenance.jpg";
import marketing from "./assets/marketing.jpg";
import procurement from "./assets/procurement.jpg";
import production from "./assets/production.jpg";
import qa from "./assets/qa.jpg";
import research from "./assets/research.jpg";
import safety from "./assets/safety.jpg";
import secretary from "./assets/secretary.jpg";
import startup from "./assets/startup.jpg";
import supply from "./assets/supply.jpg";
import technology from "./assets/technology.jpg";



const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    margin: "0px auto",
    minWidth: 350,
    height: "100%",
    color: "#ffffff",
    backgroundColor: "#f1f4f9",

  });
  

  const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(5, 0),
  }));
  const StyledCard = styled(Card)({
    padding: 16,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });

function CoachByCategories(){
    const dispatch = useDispatch();
    const { idJobGroup } = useParams();
    const [visibleItems, setVisibleItems] = useState(8); 
    const itemsToShow = 8;


    const { result: coach,isFetching:coachLoading } = useSelector((state) => state.coach);
    const {result: jobGroup,isFetching:jobGroupLoading} = useSelector((state)=> state.category);

    const [category,setCategory] = useState();
    


    const handleShowMore = () => {
      setVisibleItems(prev => prev + itemsToShow); // เพิ่มจำนวนรายการที่จะแสดง
    };
  
    const handleShowLess = () => {
      setVisibleItems(itemsToShow); // กลับไปแสดงแค่ 4 รายการ
    };
    const jobGroupMock = [
      { id: 1, name: "Production", image: production },
      { id: 2, name: "Technology & Product Development", image: technology },
      { id: 3, name: "Maintenance", image: maintenance },
      { id: 4, name: "Engineering & Construction", image: construction },
      { id: 5, name: "QA/QC/Lab", image: qa },
      { id: 6, name: "Supply chain", image: supply },
      { id: 7, name: "Logistics", image: logistic },
      { id: 8, name: "Procurement", image: procurement },
      { id: 9, name: "HR", image: hr },
      { id: 10, name: "GA & Facility Management", image: ga },
      { id: 11, name: "Admin & Office Support", image: admin },
      { id: 12, name: "Finance", image: finance },
      { id: 13, name: "Accounting", image: accounting },
      { id: 14, name: "Sales & Marketing", image: marketing },
      {
        id: 15,
        name: "Business Development/ Analyst/ Planning",
        image: business,
      },
      { id: 16, name: "Start up & New Business", image: startup },
      { id: 17, name: "Safety & ESG", image: safety },
      { id: 18, name: "Research", image: research },
      { id: 19, name: "Digital & IT", image: digital },
      { id: 20, name: "Secretary", image: secretary },
      { id: 21, name: "Brand & CSR", image: csr },
      { id: 22, name: "Legal & Compliance", image: legal },
      { id: 23, name: "Energy & EV", image: energy },
    ];
    useEffect(()=>{
      console.log("idJobGroup:", idJobGroup); 
      setCategory(idJobGroup);
      dispatch(getCoachByIdCategory(idJobGroup))
    },[idJobGroup]);

    useEffect(()=>{
      dispatch(getCategory());
    },[])

    return (
        <StyledRoot className={"page"}>
          <Grid>
          <div style={{
            background: "linear-gradient(to right, rgb(242, 208, 226), rgb(230, 217, 239), rgb(215, 236, 241))",
            height:"300px",
            width:"100%",
            marginLeft:"auto",
            borderRadius:"16px",
            padding:"50px",
            marginBottom:"24px"
          }}>
             <Typography variant="h5" gutterBottom style={{ paddingTop: 8 }}>
            Category 
            </Typography>
            <Typography variant="h3" gutterBottom style={{ paddingTop: 8 }}>
              {jobGroup && jobGroup.length === 0 ? (
                "No Have Category"
              ) : (
                (() => {
                  const foundCategory = jobGroup?.find((val) => val.idCategory == idJobGroup);
                  return foundCategory ? foundCategory.category : "No Have Category";
                })()
              )}
            </Typography>

          </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 24 ,paddingBottom:18}}>
            รายชื่อโค้ช
            </Typography>
          </Grid>
              <Grid
                marginTop={"24px"}
                marginBottom={"30px"}
              >
              <StyledCard>
                   <Grid
                    container
                    spacing={2}
                    padding={"24px"}
                    justifyContent="start"
                    >
                  {coachLoading?
                    (
                <Box
                   style={{
                     width: "100%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems:"center",
                     paddingTop: "40px",
                   }}
                 >
                   <img width="120" alt="loading" src={loading} />
                 </Box>

                    ): coach&& coach.length == 0?(
                      <div style={{height:"100%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",padding:"5%"}}>
                       ไม่มีโค้ชในกลุ่มงานนี้
                        </div>):
                      (coach&& coach.map((val) => (
                        <Grid item xs={12} sm={6} md={4} xl={3}>
                          <CardCoach
                          value={val}
                          tagType={"popular-coach"}
                          key={val.idEmployees}
                                            />
                        </Grid>
                      ))  
                    )
                  }
                   </Grid>
                </StyledCard>
              </Grid>
              <Typography variant="h4" gutterBottom style={{ paddingTop: 24,paddingBottom:18 }}>
                เลือกตามกลุ่มงานอื่นๆ
                </Typography>
                <Grid 
                  container
                  item xl={12}
                  spacing={2}
                  width={"100%"}
                  justifyContent="start"
                  alignSelf={"center"}
              >
                {jobGroupLoading?(
                    <div></div>
                  ):(
                    jobGroup&& jobGroup.slice(0,visibleItems).map((val) => (
                      <Grid item xs={12} sm={6} md={3} lg={2} key={val.id}>
                        <AbsoluteCard value={val} image={jobGroupMock[val.idCategory-1].image} tagType={"position-card"} navigateTo={`/coaching/coach-by-categories/${val.idCategory}`}/>
                      </Grid>
                    ))
                  )
                  }
              </Grid>
                {jobGroup && jobGroup.length > itemsToShow && (
                  <div style={{ textAlign: "center", marginTop: 16 ,padding:"24px"}}>
                    {visibleItems < jobGroup.length ? (
                      <Button variant="outlined" onClick={handleShowMore} style={{borderRadius:"16px"}}>
                        ดูเพิ่มเติม
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={handleShowLess} style={{borderRadius:"16px"}}>
                        ดูน้อยลง
                      </Button>
                    )}
                  </div>
                )}
        </StyledRoot>
    )

}

export default CoachByCategories;