import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Divider, Typography, Paper, Button, Avatar, Grid, CircularProgress, Stack, Container } from "@mui/material";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

import HeaderPage from "../../shared/header/headerPage";
import iconHeader from "./assets/360-degrees-color.svg";
import tagClose from "./assets/tagClose.png";
import tagOpen from "./assets/tagOpen.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getAppraiseeUser } from "../../../../actions/appraisee";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

const StyledDivider = styled(Divider)({
  margin: "10px 0",
})

const StyledImg = styled('img')({
  position: "absolute",
  top: -7,
  left: 14,
})

const WrapSectionTop = styled('div')({
  marginLeft: 80,
  marginRight: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiAvatar-root": {
    width: 80,
    height: 80,
  },
  "& .wrapAmountQuestion": {
    // marginLeft: "auto",
    textAlign: "start",
    ["@media only screen and (max-width:600px)"]: {
      margin: 0,
      width: 200,
      textAlign: "start",
    },
  },
  "& .inner-section-top": {
    "& .MuiTypography-root": {
      textAlign: "left",
      width: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ["@media only screen and (max-width:600px)"]: {
        textAlign: "center",
      },
    },
  },
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    alignItems: "flex-end",
    margin: 0,
  },
})

const WrapButton = styled('div')({
  padding: 8,
  display: "flex",
  justifyContent: "flex-end",
  "& .MuiButtonBase-root": {
    marginLeft: "auto",
  },
})

const WrapButtonSuccess = styled('div')({
    padding: 8,
    display: "flex",
    justifyContent: "flex-end",
    ["@media only screen and (max-width:600px)"]: {
      justifyContent: "center",
    },
})

const WrapCardList = styled('div')({
  marginBottom: 16,
  display: "flex",
  justifyContent: "center",
})

const CardAssessment = ({ appraisee }) => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <Paper elevation={3} style={{ borderRadius: 8 }}>
      <div>
        <div style={{ position: "relative" }}>
          <StyledImg
            alt="user"
            width={50}
            src={appraisee.assessmentType === "open" ? tagClose : tagOpen}
          />
        </div>
        <WrapSectionTop>
          <Grid container padding={1}>
            <Grid item xs={12} md={6}>
              <Avatar alt={"appraisee"} src={appraisee.imageURL} />
              <Typography variant="h5">{`${appraisee.firstname_TH} ${appraisee.lastname_TH}`}</Typography>
              <Typography fontSize={14} color="textSecondary">
                {appraisee.positionName}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {appraisee.departmentName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">
                {`แบบประเมิน: ${appraisee.assessmentName}`}
              </Typography>
              <Typography fontSize={14} color="textSecondary">
                {`คำอธิบาย: ${appraisee.assessmentDescription}`}
              </Typography>
              <div className={`wrapAmountQuestion`}>
                <Typography>{`จำนวน: ${appraisee.questionList.length} ข้อ`}</Typography>
                <Typography>{`Start date: ${dayjs(appraisee.startDate).format("YYYY-MM-DD HH:mm")}`}</Typography>
                <Typography>{`End date: ${dayjs(appraisee.endDate).format("YYYY-MM-DD HH:mm")}`}</Typography>
              </div>
            </Grid>
          </Grid>
        </WrapSectionTop>      
      </div>
      <Divider />
      {appraisee.sampleSize.find((item) => item.idAppraiser === userProfile.idEmployees).isComplete ? (
        <WrapButtonSuccess>
          <LibraryAddCheckIcon style={{ marginRight: 4, color: "#00b74a" }} />
          <Typography fontSize={16} style={{ color: "#00b74a" }}>
            ตอบแบบประเมินเรียบร้อย
          </Typography>
        </WrapButtonSuccess>
      ) : dayjs().isBefore(dayjs(appraisee.endDate)) ? (
        <WrapButton>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"/assessment360/question"}
            state={{ idAppraisee: appraisee.idAppraisee }}
          >
            ตอบแบบประเมิน
          </Button>
        </WrapButton>
      ) : (
        <WrapButtonSuccess>
          <LibraryAddCheckIcon style={{ marginRight: 4, color: "#f50057" }} />
          <Typography fontSize={16} style={{ color: "#f50057" }}>
            หมดเวลาตอบ
          </Typography>
        </WrapButtonSuccess>
      )}
    </Paper>
  );
};

export default function Assessment360User() {
  const dispatch = useDispatch()
  const { result: appraiseeUser, isFetching } = useSelector((state) => state.appraiseeUser);

  useEffect(() => {
    dispatch(getAppraiseeUser())
  }, []);

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"ประเมิน 360 องศา"} icon={iconHeader} />
      <StyledDivider />
      {isFetching || !appraiseeUser ? (
        <CircularProgress />
      ) : (
        <Container>
          <Stack spacing={4}>
            {appraiseeUser.map((item) => (
              <CardAssessment key={item.idAppraisee} appraisee={item} />
            ))}
          </Stack>
        </Container>
      )}
    </StyledRoot>
  );
}
