import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import HeaderPage from "../../shared/header/headerPage";
import MoraleQuestionIcon from "./assets/question.png";
import VoteDialog from "./VoteDialog";
import BgPage from "./assets/pexels-gradienta-7130536.jpg";
import ProgressChart from "../../admin/manage/poll/ProgressChart";
import DateFilter from "../../shared/dateFilter";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getPoll } from "../../../../actions/poll";

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 40,
});

const WrapPoll = styled("div")({
  display: "block",
  margin: "0px auto",
  width: "100%",
  ["@media only screen and (min-width:1200px)"]: {
    width: "60%",
  },
});

const StyledCard = styled(Card)({
  width: "100%",
  marginBottom: 40,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const WrapCardButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  "& Button": {
    margin: 8,
  },
});

const WrapStatus = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  minHeight: "100vh",
  backgroundImage: `url(${BgPage})`,
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const MyPoll = () => {
  const dispatch = useDispatch();
  const [dateFiiter, setDateFiiter] = useState(dayjs());
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPoll, setCurrentPoll] = useState(null);
  const { result: poll } = useSelector((state) => state.poll);

  useEffect(() => {
    dispatch(getPoll());
  }, []);

  const clearCurrentPoll = () => {
    setCurrentPoll(null);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const filterPoll = () => {
    return poll.filter((item) =>
      dateFiiter ? dayjs(item.endDate).isSame(dateFiiter, "month") : item
    );
  };

  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex", color: "#000000" }}>
        <HeaderPage
          textLabel={"โหวตโพล"}
          icon={MoraleQuestionIcon}
          color={"#000000"}
        />
      </div>
      <StyledDivider />
      <WrapFilter>
        <FilterIconButton
          aria-label="filter"
          onClick={toggleDrawer}
          size="large"
        >
          <FilterListIcon fontSize="large" />
        </FilterIconButton>
      </WrapFilter>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <DateFilter setFilterDate={setDateFiiter} />
      </Drawer>
      <VoteDialog poll={currentPoll} onClose={clearCurrentPoll} />
      <WrapPoll>
        {poll && filterPoll().length === 0 ? (
          <Typography variant="h4" textAlign="center">ไม่มีข้อมูลของเดือนนี้</Typography>
        ) : (
          poll &&
          filterPoll().map((value) => (
            <StyledCard variant="outlined" key={value.idPoll}>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  {value.poll}
                </Typography>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <ProgressChart
                      chartData={value.questionList}
                      sumScore={value.voted}
                      selectedQuestion={value.idPollQuestion}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent={"space-around"}
                    >
                      <Typography color="textSecondary">
                        {`End : ${dayjs(value.endDate).format("D MMM YYYY ")}`}
                      </Typography>
                      <WrapStatus>
                        <Typography color="textSecondary" marginRight={1}>
                          Status :
                        </Typography>
                        {dayjs().isAfter(value.endDate) ? (
                          <Chip label="สิ้นสุดวันโหวตแล้ว" color="error" />
                        ) : value.status === "open" ? (
                          <Chip label="open" color="success" />
                        ) : (
                          <Chip label="close" color="error" />
                        )}
                      </WrapStatus>
                      {value.status === "open" &&
                        value.idPollQuestion === null && (
                          <WrapCardButton>
                            <Button
                              fullWidth
                              variant="contained"
                              color="info"
                              onClick={() => setCurrentPoll(value)}
                            >
                              Vote
                            </Button>
                          </WrapCardButton>
                        )}
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          ))
        )}
      </WrapPoll>
    </StyledRoot>
  );
};

export default MyPoll;
