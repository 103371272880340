import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../../../shared/inputForm/inputField";
import "./tiptap.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";
import { useAddCompanyRewardVariation } from "../../../../../../../quries/companyReward";

function DialogVariation({ open, handleClose }) {
  const { state } = useLocation();
  const addCompanyRewardItem = useAddCompanyRewardVariation({
    idCompanyReward: state.idCompanyReward,
  });
  
  const validationSchema = yup.object().shape({
    idVariation: yup.number().required(),
    value: yup.string().max(255).required(),
  });

  const defaultValues = {
    idVariation: "",
    value: state.options.option,
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleConfigReward = (values) => {
    const formData = {
      idCompanyReward: state.idCompanyReward,
      idVariation: values.idVariation,
      value: values.value,
    }

    addCompanyRewardItem.mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>เพิ่มค่าตัวเลือกของรางวัล</DialogTitle>
      <form onSubmit={handleSubmit(handleConfigReward)}>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              control={control}
              name={"idVariation"}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={Boolean(fieldState.error)}>
                  <InputLabel>{"ตัวเลือก"}</InputLabel>
                  <Select {...field} label={"ตัวเลือก"}>
                    {state.options.map((item) => (
                      <MenuItem key={item.idVariation} value={item.idVariation}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <FormHelperText>{fieldState.error.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <InputField control={control} name={"value"} label="ค่าตัวเลือก" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" onClick={handleClose}>
            ยกเลิก
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogVariation;
