import React from "react";
import {
  Card,
  CardHeader,
  Container,
  Typography,
  Grid,
  CssBaseline,
  CardContent,
  Icon,
} from "@mui/material";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import { styled } from "@mui/material/styles";

const StyledCardContent = styled(CardContent)({
  backgroundColor: "#424242",
  borderRadius: "5px",
  position: "relative",
  // "&:hover ::before": {
  //   boxShadow: "0 0 5px #90caf9",
  //   transform: "scale(1.05)",
  // },
  // "&:hover": {
  //   // transform: "scale(1.05)",
  //   boxShadow: "0 0 5px #90caf9",
  // },
});

const StyledHeadText = styled(Typography)({
  color: "#ECF0F1",
  opacity: "0.8",
  fontSize: "1.25rem",
});

const StyledPrimaryText = styled(Typography)({
  fontSize: "3rem",
  color: "#90caf9",
});

const StyledSecondaryText = styled(Typography)({
  color: "#ECF0F1",
  opacity: "0.8",
  fontSize: "1rem",
});

const StyledIcon = styled(Icon)({
  position: "absolute",
  left: 60,
  top: 0,
  width: "100%",
  height: "100%",
  color: "#0d1117",
});

function DisplayStyle({ head, main, end, icon }) {
  const IconProps = icon;
  return (
    <div>
      <CssBaseline />
      <StyledCardContent>
        <Grid container>
          <Grid item xs={8}>
            <StyledHeadText>{head}</StyledHeadText>
            <StyledPrimaryText>{main}</StyledPrimaryText>
            <StyledSecondaryText>{end}</StyledSecondaryText>
          </Grid>
          <Grid item xs={4}>
            <IconProps />
          </Grid>
        </Grid>
      </StyledCardContent>
    </div>
  );
}

export default DisplayStyle;
