import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getPoll, updatePollVote } from "../../../../actions/poll";

const ToggleButtonVote = styled(ToggleButton)(({ selectedcolor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedcolor,
  },
}));

const VoteDialog = (props) => {
  const dispatch = useDispatch();
  const { poll, onClose } = props;
  const showDialog = Boolean(poll);
  const [selected, setSelected] = useState(null);

  const { user: currentUser } = useSelector((state) => state.auth);

  const handleChangeSelected = (event, newSelected) => {
    setSelected(newSelected);
  };

  const handleVote = async () => {
    // const newScore = poll.questionList.map((item) => {
    //   if (item.question === selected) {
    //     item.score += 1;
    //   }
    //   return item;
    // });

    const res = await dispatch(
      updatePollVote({ 
        idPollQuestion: selected, 
        idPoll: poll.idPoll, 
        idEmployees: currentUser.id,
      })
      // updatePoll({ selectedScale: newScore, id: poll.id , votedId: [...poll.votedId ,currentUser.id]})
    );
    if (res) {
      dispatch(getPoll());
      onClose();
    }
  };

  return (
    <>
      {showDialog && (
        <Dialog open={showDialog} onClose={onClose} fullWidth>
          <DialogTitle>{poll.question}</DialogTitle>
          <DialogContent>
            <ToggleButtonGroup
              orientation="vertical"
              fullWidth
              exclusive
              value={selected}
              onChange={handleChangeSelected}
            >
              {poll.questionList.map((item, index) => {
                return (
                  <ToggleButtonVote
                    key={index}
                    value={item.idPollQuestion}
                    selectedcolor={"#FFADAD"}
                  >
                    {item.question}
                  </ToggleButtonVote>
                );
              })}
            </ToggleButtonGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="success" onClick={handleVote}>confirm</Button>
            <Button variant="contained" color="error" onClick={onClose}>close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default VoteDialog;
