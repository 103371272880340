import pptxgen from "pptxgenjs";
import { sortByAverage, sortByPositivePerception } from "./Report";

const dataDetail = (morale, idQuestionTopicList) => {
    const temp = morale.moraleTopicAvgAndBest.filter((value) => {
      return idQuestionTopicList.includes(value.idQuestionTopic)
        ? value.moraleQuestionAvgAndDistributionAcross.sort(
            (a, b) => b.positivePerception - a.positivePerception
          )
        : null;
    });
    return temp;
};

const pptxGenerater = (morale, gaugeImage, multiChartImage) => {
    let pptx = new pptxgen();
    let slide = pptx.addSlide();

    const participant = (morale.moraleResponse.participant).toString()
    const response = (morale.moraleResponse.response).toString()
    const percentResponse = Math.round(morale.moraleResponse.response / morale.moraleResponse.participant * 100)

    const scoreGauge = Math.ceil(morale.moraleTopicAvgAndBest.find(
        (value) => value.idQuestionTopic === 1
    ).average);
    const topic = [...morale.moraleTopicAvgAndBest]

    const scoreDistribution = morale.moraleTopicAvgAndBest.find(
        (value) => value.idQuestionTopic === 1
    ).moraleQuestionAvgAndDistributionAcross;

    const highlyEngaged = Math.round(
        (scoreDistribution.reduce((acc, item) => acc + parseFloat(item.positivePerception), 0) / scoreDistribution.length)
    );
    const moderatelyEngaged = Math.round(
        (scoreDistribution.reduce((acc, item) => acc + parseFloat(item.positiveHesitance), 0) / scoreDistribution.length)
    );
    const passive = Math.round(
        (scoreDistribution.reduce((acc, item) => acc + parseFloat(item.negativeHesitance), 0) / scoreDistribution.length)
    );
    const activelyDisengaged = Math.round(
        (scoreDistribution.reduce((acc, item) => acc + parseFloat(item.negitivePerception), 0) / scoreDistribution.length)
    );

    slide.addText(
        morale.moraleName,
        { x:1, y:0.5, w:'80%', h:1, fontSize:36, align:'center', fill:{ color:'D3E3F3' }, color:'008899' }
    );

    slide.addText(
        "2022 - Thai Polyethylene Co., Ltd.",
        { x:0, y:5.3, w:'100%', h:0.33, align:'center', fill:{ color:'E1E1E1' }, color:'A1A1A1' }
    );

    //slide
    let slide2 = pptx.addSlide();
    slide2.addText("Engagement Score", { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
    slide2.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
    slide2.addText("Overall Engagement Score", { x: 0.4, y: 1, fontSize: 14, color: '#5D3FD3' });
    slide2.addImage({ data: gaugeImage, x: 0.5, y: 1.7, w: 4, h: 2 })
    slide2.addShape(pptx.ShapeType.rect, { x: 4.5, y: 1.9, w: 2.2, h: 1, fill: { color: "#F2F2F2" } });
    slide2.addShape(pptx.ShapeType.upArrow, { x: 4.7, y: 2.1, w: 0.2, h: 0.2, fill: { color: '#5D3FD3' } } );
    slide2.addText("7", { x: 5.0 , y: 2.2, fontSize: 20 });
    slide2.addText("points", { x: 5.2 , y: 2.2, fontSize: 12 });
    slide2.addText(scoreGauge, { x:6, y:2.2, fontSize:20 });
    slide2.addText("Thailand Best Employers", { x:4.6, y:2.6, fontSize:10 });
    slide2.addText("Participation Rate", { x: 7, y: 1, fontSize: 14, color: '#5D3FD3' });
    slide2.addShape(pptx.ShapeType.rect, { x: 7, y: 1.5, w: 2.8, h: 0.2, fill: { color: "#F2F2F2" } });
    slide2.addShape(pptx.ShapeType.rect, { x: 7, y: 1.5, w: percentResponse*2.8/100, h: 0.2, fill: { color: '#5D3FD3' } });
    slide2.addText(`${percentResponse}%`, { x:8.1, y:1.6, fontSize: 12, color: "#FFFFFF" });
    slide2.addText("Number of Participants", { x: 7, y: 2.3, fontSize: 14, color: '#5D3FD3' });
    slide2.addText("Completeds", { x: 7.1, y: 2.7, fontSize: 10 });
    slide2.addText("Invited", { x: 7.1, y: 2.9, fontSize: 10 });
    slide2.addText(response, { x: 9, y: 2.7, fontSize: 10 });
    slide2.addText(participant, { x: 9, y: 2.9, fontSize: 10 });
    slide2.addText("External Benchmark", { x: 0.4, y: 4.1, fontSize: 14 });
    slide2.addText("Global", { x: 0.7, y: 4.6, fontSize: 10 });    
    for(let i=0; i<=100; i=i+10) {
        slide2.addShape(pptx.shapes.LINE, { x: 1.3 + (i*8/100), y: 4.5, w: 0, h: 0.3, line: { color: "000000", width: 1 }});
        slide2.addText(`${i}`, { x: 1.15 + (i*8/100), y: 4.9, fontSize: 8 });    
    }
    slide2.addShape(pptx.ShapeType.rect, { x: 1.3, y: 4.5, w: 100*8/100, h: 0.2, fill: { color: '#35a2eb' } });
    slide2.addShape(pptx.ShapeType.rect, { x: 1.3, y: 4.5, w: 76*8/100, h: 0.2, fill: { color: '#ffce57' } });
    slide2.addShape(pptx.ShapeType.rect, { x: 1.3, y: 4.5, w: 54*8/100, h: 0.2, fill: { color: "#ff6383" } });

    slide2.addShape(pptx.ShapeType.downArrow, { x: 1.2 + scoreGauge*8/100, y: 4.2, w: 0.2, h: 0.2, fill: { color: '#5D3FD3' } } );
    slide2.addText(`Bottom Quartile (${0} - ${54}%)`, { x: 1.4, y: 5, w: "30%", h: 0.5, fontSize: 10, color: "#ff6383", bullet: true });
    slide2.addText(`Moderate Zone (${54} - ${76}%)`, { x: 4.1, y: 5, w: "30%", h: 0.5, fontSize: 10, color: '#ffce57', bullet: true });
    slide2.addText(`Top Quartile (${76} - ${100}%)`, { x: 7, y: 5, w: "30%", h: 0.5, fontSize: 10, color: '#35a2eb', bullet: true });

    //slide 3
    let slide3 = pptx.addSlide()
    slide3.addText("Engagement Distribution", { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
    slide3.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
    slide3.addImage({ data: multiChartImage, x: 2.5, y: 1, w: 7, h: 3.5 })
    let rows = [["Thailand Best Employers", `${activelyDisengaged}`, `${passive}`, `${moderatelyEngaged}`, `${highlyEngaged}`]];
    slide3.addTable(rows, { x: 0.55, y: 4.7, w: 9, colW: [2.2, 1.7, 1.7, 1.7, 1.7], align: "center", fill: '#F2F2F2', border: { color: '#ffffff' } });

    //slide 4
    let slide4 = pptx.addSlide()
    slide4.addText("Dimension Ranking", { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
    slide4.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
    
    const dimensionRanking = [
        {
            text: "",
            questionTopic: "",
            options: { fill: "#EEECEF" }
        },
        ...morale.moraleTopicAvgAndBest.map(item => ({...item, options: { fill: "#EEECEF" }}))
    ]

    let rowsSlide4 = [
        [
            ...dimensionRanking
        ],
        dimensionRanking.map((item) => {
            return { text: "" }
        }),
        [
            { text: "Distance From", options: { align: "left", colspan: 13 } },
        ],
        [
            { text: "Thailand Best Employers", options: { align: "left" } },
            ...dimensionRanking.map((item, index) => {
               return { text: `+${index}`, options: { color: "#008000" } }
            }),
        ]
    ];

    const spaceDimension = 7.45/(dimensionRanking.length - 1)
    slide4.addTable(rowsSlide4, { x: 0.4, y: 0.8, w: 9.3, h: 3.72, 
        rowH: [1.26, 1.86, 0.2, 0.4] ,colW: [1.9, ...new Array(dimensionRanking.length - 1).fill(spaceDimension)], 
        align: "center", border: { color: '#5D3FD3' },
        //fill: '#F2F2F2'
    })
    for(let i=1; i<dimensionRanking.length; i++) {
        slide4.addText(dimensionRanking[i].questionTopic, 
            {
                x: 2.3 + ((i - 1) * spaceDimension) + (spaceDimension/2) - 0.6, 
                y: 1.2, 
                w: 1.25, 
                h: 0.5 ,
                rotate: 270, 
                fontSize: 8 
            }
        )
        slide4.addShape(pptx.ShapeType.rect, 
            { 
                x: 2.3 + ((i - 1) *spaceDimension) + (spaceDimension/2) - 0.2, 
                y: 3.92 - (dimensionRanking[i].average*1.18/100) , 
                w: 0.4, 
                h: dimensionRanking[i].average*1.18/100, 
                fill: { color: '#5D3FD3' } 
            }
        );
        slide4.addText(`${Math.round(dimensionRanking[i].average)}%`, 
            { 
                x: 2.3 + ((i - 1) *spaceDimension) + (spaceDimension/2) - 0.2, 
                y: 3.8 - (dimensionRanking[i].average*1.18/100), 
                fontSize: 8 
            }
        )
    }
    slide4.addText("100% 80% 60% 40% 20% 0%", { x: 1.8, y: 2.8, w: 0.5, h: 1, fontSize: 8, lineSpacing: 15, align: "right" })

    //slide 5
    let slide5 = pptx.addSlide()
    slide5.addText("Index Ranking", { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
    slide5.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
    const indexRanking = [
        {
            text: "",
            title: "",
            options: { fill: "#EEECEF" }
        },
        ...morale.moraleTopicAvgAndBest
            .filter(value => [1,2,3,4].includes(value.idQuestionTopic))
            .map(item => ({...item, options: { fill: "#EEECEF" }}))
    ]
    let rowsSlide5 = [
        [
            ...indexRanking
        ],
        [
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
        ],
        [
            { text: "Distance From", options: { align: "left", colspan: 4 } },
        ],
        [
            { text: "Thailand Best Employers", options: { align: "left" } },
            ...indexRanking.map((item, index) => {
               return { text: `+${index}`, options: { color: "#008000" } }
            }),
        ]
    ];
    const spaceIndex = 7.45/(indexRanking.length - 1)
    slide5.addTable(rowsSlide5, { x: 0.4, y: 0.8, w: 9.3, h: 3.72, 
        rowH: [1.26, 1.86, 0.2, 0.4] ,colW: [1.9, ...new Array(indexRanking.length - 1).fill(spaceIndex)], 
        align: "center", border: { color: '#5D3FD3' },
        //fill: '#F2F2F2'
    })
    for(let i=1; i<indexRanking.length; i++) {
        slide5.addText(indexRanking[i].questionTopic, 
            { 
                x: 2.3 + ((i - 1) * spaceIndex) + (spaceIndex/2) - 0.6, 
                y: 1.2, 
                w: 1.25, 
                h: 0.5 ,
                rotate: 270, 
                fontSize: 8 
            }
        )
        slide5.addShape(pptx.ShapeType.rect, 
            { 
                x: 2.3 + ((i - 1) *spaceIndex) + (spaceIndex/2) - 0.4, 
                y: 3.92 - (indexRanking[i].average*1.18/100) , 
                w: 0.8, 
                h: indexRanking[i].average*1.18/100, 
                fill: { color: '#5D3FD3' } 
            }
        );
        slide5.addText(`${Math.round(indexRanking[i].average)}%`, 
            { 
                x: 2.3 + ((i - 1) *spaceIndex) + (spaceIndex/2) - 0.2, 
                y: 3.75 - (indexRanking[i].average*1.18/100), 
                fontSize: 8 
            }
        )
    }
    slide5.addText("100% 80% 60% 40% 20% 0%", { x: 1.8, y: 2.8, w: 0.5, h: 1, fontSize: 8, lineSpacing: 15, align: "right" })
    
    const top10Items = sortByPositivePerception(topic, 'descending').slice(0, 5)
    const bottom10Items = sortByPositivePerception(topic, 'ascending').slice(0, 5)

    const topButtomSlide = (data, slideTitle) => {
        let slide = pptx.addSlide()
        slide.addText(slideTitle, { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
        slide.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
        const dataTable = [
            [
                {
                    text: "Dimension",
                    options: { fill: "#EEECEF" }
                }, 
                {
                    text: "Item",
                    options: { fill: "#EEECEF" }
                },
                {
                    text: "",         
                    options: { fill: "#EEECEF" }
                },
                {
                    text: "Thailand Best Employers",
                    options: { fill: "#EEECEF" }
                },
            ],
        ]
        const favorableScore = [
            {
                name: "positivePerception",
                labels: ["positivePerception"],
                values: [],
            },
            {
                name: "positiveHesitance",
                labels: ["positiveHesitance"],
                values: [],
            },
            {
                name: "negativeHesitance",
                labels: ["negativeHesitance"],
                values: [],
            },
            {
                name: "negitivePerception",
                labels: ["negitivePerception"],
                values: [],
            },
        ];
        
        for(let i=0; i<data.length; i++) {
            const rowContent = []
            rowContent.push({ text: data[i].questionTopic })
            rowContent.push({ text: data[i].question })
            rowContent.push({text: ""})
            rowContent.push({
                text: data[i].positivePerception >= 0 
                ? `+${Math.round(data[i].positivePerception)}` 
                : `-${Math.round(data[i].positivePerception)}`,
                options: { color: data[i].positivePerception >= 0 
                    ? "#00B050"
                    : "#EF433C"
                }
            })

            dataTable.push(rowContent)

            favorableScore[0].values.unshift(data[i].positivePerception)
            favorableScore[1].values.unshift(data[i].positiveHesitance)
            favorableScore[2].values.unshift(data[i].negativeHesitance)
            favorableScore[3].values.unshift(data[i].negitivePerception)
        }
    
        slide.addTable(dataTable, { x: 0.4, y: 0.8, w: 9.2, h: 4.5, 
            rowH: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5] ,colW: [1.27, 3.92, 3.3, 0.71], 
            align: "center", border: { color: '#5D3FD3' }, fontSize: 8, fill: '#F2F2F2'
        })

        const heightChartArea = 0.5 + (0.5 * data.length)
        const heightPlotArea = heightChartArea - 0.5
        const percentPlotArea = heightPlotArea / heightChartArea

        let optsChartBar = {
            x: 5.6,
            y: 0.8,
            w: 3.3,
            h: heightChartArea,
            barGapWidthPct: 70,
            layout: {x: 0.05, y: 1, w: 0.9, h: percentPlotArea},
            chartArea: { fill: { color: "f1f1f1" } },
            plotArea: { fill: { color: "ffffff" } },
            //
            barDir: "bar",
            barGrouping: "percentStacked",
            catAxisLabelFontFace: "Times",
            catAxisLabelFontSize: 8,
            //
            catAxisHidden: true,
            catGridLine: { style: "none" },
            valAxisHidden: true,
            valAxisDisplayUnitLabel: true,
            valGridLine: { style: "none" },
            valAxisMinVal: 0,
            //
            showLegend: true,
            legendPos: "t",
            legendFontSize: 8,
            //
            showValue: true,
            dataLabelColor: "F1F1F1",
            dataLabelFontSize: 8,
            dataLabelFormatCode: '0\;\;\;',
        };

        slide.addChart(pptx.charts.BAR, favorableScore, optsChartBar);
    }

    const dimensionPriorities = sortByAverage(topic, 'ascending').slice(0, 19)
    const dimensionPrioritiesSlide = (data) => {
        const breekPage = Math.ceil(data.length / 9)
        let rank = 1
        for(let i=0; i<breekPage; i++) {
            let slide = pptx.addSlide()
            slide.addText(`Dimension Priorities (page ${i+1} of ${breekPage})`, { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
            slide.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});

            const dataTable = [
                [
                    {
                        text: "Rank",
                        options: { fill: "#EEECEF" }
                    }, 
                    {
                        text: "Dimension",
                        options: { fill: "#EEECEF" }
                    },
                    {
                        text: "Item",         
                        options: { fill: "#EEECEF" }
                    },
                    {
                        text: "% Favorable",
                        options: { fill: "#EEECEF" }
                    },
                    {
                        text: "Thailand Best Employers",
                        options: { fill: "#EEECEF" }
                    },
                ],
            ]
        
            const dataSplit = data.slice(i*9, (i+1)*9)
            for(let j=0; j<dataSplit.length; j++) {
                const rowContent = []
                rowContent.push({ text: `${rank++}`})
                rowContent.push({ text: dataSplit[j].questionTopic })
                rowContent.push({ text: dataSplit[j].question })
                rowContent.push({ text: "" })
                rowContent.push({ text: "" })
                
                
                dataTable.push(rowContent)
            }
            slide.addTable(dataTable, { x: 0.4, y: 0.8, w: 9.2, h: 4, 
                rowH: [0.57, ...Array(dataSplit.length).fill(0.4)] ,colW: [0.52, 1.48, 4.23, 1.48, 1.48], 
                align: "center", border: { color: '#5D3FD3' }, fontSize: 8, fill: '#F2F2F2', 
                // autoPage: true, autoPageRepeatHeader: true,
            })
        
            slide.addShape(pptx.shapes.LINE, { x: 8.8, y: 1.38, w: 0, h: 0.4*dataSplit.length, line: { color: "000000", width: 0.5 }});
            
            for(let j=0; j<dataSplit.length; j++) {
                slide.addShape(pptx.ShapeType.rect, { x: 6.7, y: 1.45 + (0.4*j), w: (dataSplit[j].average / 100), h: 0.2, fill: { color: '#ff6383' } });
                slide.addText(`${Math.round(dataSplit[j].average)}%`, { x: 7.7, y: 1.5 + (0.4*j)
                    ,w: 0.5 ,h: 0.1, fontSize: 8, color: '#000000' 
                })
                slide.addShape(pptx.ShapeType.rect, { x: 8.8, y: 1.45 + (0.4*j), w: 0.7*(dataSplit[j].average / 100), h: 0.2, fill: { color: '#ff6383' } });
                slide.addText(`${Math.round(dataSplit[j].average)}`, { x: 9.35, y: 1.5 + (0.4*j)
                    ,w: 0.5 ,h: 0.1, fontSize: 8, color: '#000000' 
                })
            }
        }
    
    }

    //Create Table Slide
    const tableSlide = (data, slideTitle, secondTitlecolor) => {
        for(let i=0; i<data.length; i++) {
            let slide = pptx.addSlide()
            slide.addText(slideTitle, { x: 0.4, y: 0.4, fontSize: 20, color: '#5D3FD3' });
            slide.addShape(pptx.shapes.LINE, { x: 0.4 , y: 0.6, w: 9.2, h: 0, line: { color: "000000", width: 1 }});
            slide.addText(`${data[i].questionTopic} ${Math.round(data[i].average)}%`, { x: 0.7, y: 1, fontSize: 20, color: secondTitlecolor });
            
            const dataTable = [
                [  
                    {
                        text: "Item",         
                        options: { fill: "#EEECEF" }
                    },
                    {
                        text: "", 
                    },
                    {
                        text: "Thailand Best Employers",
                        options: { fill: "#EEECEF" }
                    },
                ],
            ]
            const favorableScore = [
                {
                    name: "positivePerception",
                    labels: ["positivePerception"],
                    values: [],
                },
                {
                    name: "positiveHesitance",
                    labels: ["positiveHesitance"],
                    values: [],
                },
                {
                    name: "negativeHesitance",
                    labels: ["negativeHesitance"],
                    values: [],
                },
                {
                    name: "negitivePerception",
                    labels: ["negitivePerception"],
                    values: [],
                },
            ];

            for(let j=0; j<data[i].moraleQuestionAvgAndDistributionAcross.length; j++) {
                const rowContent = []
                rowContent.push({text: data[i].moraleQuestionAvgAndDistributionAcross[j].question})
                rowContent.push({text: ""})
                rowContent.push({
                    text: data[i].moraleQuestionAvgAndDistributionAcross[j].positivePerception >= 0 
                    ? `+${Math.round(data[i].moraleQuestionAvgAndDistributionAcross[j].positivePerception)}` 
                    : `-${Math.round(data[i].moraleQuestionAvgAndDistributionAcross[j].positivePerception)}`,
                    options: { color: data[i].moraleQuestionAvgAndDistributionAcross[j].positivePerception >= 0 
                        ? "#00B050"
                        : "#EF433C"
                    }
                })

                dataTable.push(rowContent)

                favorableScore[0].values.unshift(data[i].moraleQuestionAvgAndDistributionAcross[j].positivePerception)
                favorableScore[1].values.unshift(data[i].moraleQuestionAvgAndDistributionAcross[j].positiveHesitance)
                favorableScore[2].values.unshift(data[i].moraleQuestionAvgAndDistributionAcross[j].negativeHesitance)
                favorableScore[3].values.unshift(data[i].moraleQuestionAvgAndDistributionAcross[j].negitivePerception)
            }

            slide.addTable(dataTable, { x: 0.4, y: 1.4, w: 9.2,
                rowH: [0.55, ...Array(dataTable.length - 1).fill(0.4)] ,colW: [3.83, 4.56, 0.81], 
                align: "center", valign:"middle", border: { color: '#5D3FD3' }, fontSize: 8, fill: '#F2F2F2', 
                autoPage: true, autoPageRepeatHeader: true
            })
        
            const heightChartArea = 0.55 + (0.4 * data[i].moraleQuestionAvgAndDistributionAcross.length)
            const heightPlotArea = heightChartArea - 0.55
            const percentPlotArea = heightPlotArea / heightChartArea
        
            let optsChartBar = {
                x: 4.23,
                y: 1.4,
                w: 4.56,
                h: heightChartArea,
                barGapWidthPct: 70,
                layout: {x: 0.05, y: 1, w: 0.9, h: percentPlotArea},
                chartArea: { fill: { color: "f1f1f1" } },
                plotArea: { fill: { color: "ffffff" } },
                //
                barDir: "bar",
                barGrouping: "percentStacked",
                catAxisLabelFontFace: "Times",
                catAxisLabelFontSize: 8,
                //
                catAxisHidden: true,
                catGridLine: { style: "none" },
                valAxisHidden: true,
                valAxisDisplayUnitLabel: true,
                valGridLine: { style: "none" },
                valAxisMinVal: 0,
                //
                showLegend: true,
                legendPos: "t",
                legendFontSize: 8,
                //
                showValue: true,
                dataLabelColor: "F1F1F1",
                dataLabelFontSize: 8,
                dataLabelFormatCode: '0\;\;',
            };

            slide.addChart(pptx.charts.BAR, favorableScore, optsChartBar);
        }
    } 

    topButtomSlide(top10Items, "Top 10 Items")
    topButtomSlide(bottom10Items, "Bottom 10 Items")
    dimensionPrioritiesSlide(dimensionPriorities)

    const engagementDetails = dataDetail(morale, [1])
    const indexDetails = dataDetail(morale, [2, 3, 4])
    const idDimensionDetails = morale.moraleTopicAvgAndBest.reduce((prev, curr) => {
        return ![1,2,3,4].includes(curr.idQuestionTopic) ? [...prev, curr.idQuestionTopic] : prev
      }, [])
    const dimensionDetails = dataDetail(morale, idDimensionDetails)

    tableSlide(engagementDetails, "Engagement Details", "#FFC000")
    tableSlide(indexDetails, "Index Details", "#00B0F0")
    tableSlide(dimensionDetails, "Dimension Details", "#92D050")

    //save
    pptx.writeFile({ fileName: `${morale.moraleName}.pptx` });
};

export default pptxGenerater;