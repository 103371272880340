import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useLocation, useNavigate } from "react-router-dom";
import { SpecialIcon } from "../../shared/typeCoin";
import utils from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import WalletCoin from "./walletCoin";
import HeaderPage from "../../shared/header/headerPage";
import { addRedeemTransaction } from "../../../../actions/redeemTransaction";
import DialogRedeemSuccess from "./dialogRedeemSuccess";
import { useRewardsActive } from "../../../../quries/reward";
import TiptapDetail from "../../shared/reward/tiptapDetail";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledButton = styled(Button)({
  "& .MuiButton-startIcon": {
    margin: 0,
  },
});

const WrapCoin = styled("div", {
  shouldForwardProp: (prop) => prop !== "showAmount",
})(({ showAmount }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: 8,
}));

function RewardDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { result : userProfile } = useSelector((state) => state.userProfile);
  const { isPending, data: reward } = useRewardsActive({
    idCompany: userProfile?.idCompany,
    select: (data) => data.find(item => item.idReward === state?.idReward),
  });

  const [select, setSelect] = useState({
    option1: null,
    option2: null,
  });

  const [selectItem, setSelectItem] = useState(null);
  const [selectImage, setImgae] = useState(null);
  const [selectAmount, setSelectAmount] = useState(1);
  const [openWallet, setOpenWallet] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenWallet = () => {
    setOpenWallet(true)
  };

  const handleCloseWallet = () => {
    setOpenWallet(false)
  };

  const handleOpenDialog = () => {
    setOpenDialog(true)
  };

  const handleCloseDialog = () => {
    navigate('/my-rewards')
  };

  const handleSelectItem = (optionIndex, x) => {
    const selectUpdated = { ...select, [`option${optionIndex}`]: x };
    const selectedItem = reward.items.find(
      (item) =>
        item.options[0]?.idVariationOption ===
          selectUpdated.option1?.idVariationOption &&
        item.options[1]?.idVariationOption ===
          selectUpdated.option2?.idVariationOption
    );
    handleChangeImage(selectedItem?.imageURL);
    setSelect(selectUpdated);
    setSelectItem(selectedItem);
    setSelectAmount(1);
  };

  const handleIncreaseAmount = () => {
    setSelectAmount((prev) => prev + 1);
  };

  const handleDecreaseAmount = () => {
    setSelectAmount((prev) => prev - 1);
  };

  const handleChangeImage = (urlImage) => {
    setImgae(urlImage);
  };

  // const checkCoinSelect = useMemo(() => {
  //   console.log('checkCoinSelect');
  //   return selectItem?.coins.some((coin) => {
  //     return userProfile.wallet.some(
  //       (item) =>
  //         item.amount < coin.amount * (selectAmount + 1) &&
  //         item.idCoinType === coin.idCoinType
  //     );
  //   });
  // }, [selectItem, selectAmount]);

  const checkCoinSelect = (buttonType) => {
    const itemAmount = buttonType === "addAmount" ? selectAmount + 1 : selectAmount;
    return selectItem?.coins.some((coin) => {
      return userProfile.wallet.some(
        (item) =>
          item.amount < coin.amount * itemAmount &&
          item.idCoinType === coin.idCoinType
      );
    });
  };

  const handleRedeemReward = async () => {
    const formData = {
      idReward: selectItem.idReward,
      quantity: selectAmount,
    };

    const res = await dispatch(addRedeemTransaction(formData));
    if (res) {
      handleOpenDialog();
    }
  };

  // useEffect(() => {
  //   if(state?.idUniReward) {
  //     dispatch(getActiveUniReward(userProfile.idCompany, state.idUniReward));
  //   } else if(state?.idCompanyReward) {
  //     dispatch(getActiveCompanyReward(userProfile.idCompany, state.idCompanyReward));
  //   } else {
  //     return navigate('/reward', { replace: true });
  //   }
  // }, [dispatch, state?.idUniReward, state?.idCompanyReward]);

  if (isPending) {
    return <CircularProgress color="secondary" />
  }

  return (
    <StyledRoot className={"page"}>
      {reward && (
        <Container>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <HeaderPage textLabel={"แลกของรางวัล"} />
            <Button
              variant="outlined"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={handleOpenWallet}
            >
              กระเป๋าเหรียญรางวัล
            </Button>
          </Stack>
          <StyledDivider />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={5}>
              <Box
                sx={{ border: "solid", borderWidth: 1, borderColor: "#bdbdbd" }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt={reward.name}
                  src={selectImage ? selectImage : reward.imageURL}
                />
              </Box>
              <ImageList
                //   sx={{ width: 500, height: 450 }}
                cols={5}
                //   rowHeight={164}
              >
                {[{ image: reward.imageURL }, ...reward.images].map((item, index) => (
                  <Box
                    sx={{
                      border: "solid",
                      borderWidth: 1,
                      borderColor: "#bdbdbd",
                    }}
                    onClick={() => handleChangeImage(item.imageURL)}
                    key={index}
                  >
                    <ImageListItem key={item.idUniRewardImage}>
                      <img
                        src={item.imageURL}
                        alt={item.idUniRewardImage}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box>
                ))}
              </ImageList>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Typography variant="h4" marginBottom={8}>
                {reward.name}
              </Typography>
              {/* {reward.options.map((item, index) => (
                <Stack
                  key={item.idVariation}
                  direction={"row"}
                  marginBottom={4}
                >
                  <Typography width={80}>{item.name}</Typography>
                  <Stack direction={"row"} spacing={2}>
                    {item.option.map((x) => (
                      <Chip
                        key={x.idVariationOption}
                        label={x.value}
                        onClick={() => handleSelectItem(index + 1, x)}
                        color={
                          x.idVariationOption ===
                          select[`option${index + 1}`]?.idVariationOption
                            ? "warning"
                            : "default"
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              ))} */}
              {Boolean(selectItem?.isActive) && (
                <Typography variant="h6" marginBottom={4}>
                  {`คงเหลือ: ${selectItem?.quantity ?? ""}`}
                </Typography>
              )}
              <Stack
                direction={"row"}
                alignItems={"center"}
                marginBottom={4}
                spacing={2}
              >
                {selectItem?.coins?.map((item, index) => (
                  <>
                    <WrapCoin>
                      <SpecialIcon idCoinType={item.idCoinType} width={40} />
                      <Typography
                        variant="h6"
                        style={{ marginLeft: 8, fontSize: 24 }}
                      >
                        {utils.numberWithCommas(item.amount)}
                      </Typography>
                    </WrapCoin>
                    <Divider orientation="vertical" flexItem />
                    {/* {selectItem?.coins.length - 1 > index && <Divider orientation="vertical" flexItem />} */}
                  </>
                ))}
              </Stack>
              <Stack direction={"row"} spacing={4} marginBottom={4} >
                <ButtonGroup aria-label="outlined primary button group">
                  <StyledButton
                    startIcon={<RemoveIcon fontSize="inherit" />}
                    size="small"
                    disabled={selectAmount <= 1}
                    onClick={() => handleDecreaseAmount()}
                  ></StyledButton>
                  <Button disableRipple>{selectAmount}</Button>
                  <StyledButton
                    startIcon={<AddIcon fontSize="inherit" />}
                    size="small"
                    onClick={() => handleIncreaseAmount()}
                    disabled={
                      !reward.isActive ||
                      reward.quantity > 0
                      // checkCoinSelect("addAmount")
                    }
                  ></StyledButton>
                </ButtonGroup>
                <Button
                  variant="contained"
                  onClick={handleRedeemReward}
                  disabled={
                    !reward.isActive ||
                    reward.quantity === 0 ||
                    checkCoinSelect("redeem")
                  }
                >
                  แลกของรางวัล
                </Button>
              </Stack>
              {/* <Stack direction={"row"} spacing={4} marginBottom={4} >
                <ButtonGroup aria-label="outlined primary button group">
                  <StyledButton
                    startIcon={<RemoveIcon fontSize="inherit" />}
                    size="small"
                    disabled={selectAmount <= 1}
                    onClick={() => handleDecreaseAmount()}
                  ></StyledButton>
                  <Button disableRipple>{selectAmount}</Button>
                  <StyledButton
                    startIcon={<AddIcon fontSize="inherit" />}
                    size="small"
                    onClick={() => handleIncreaseAmount()}
                    disabled={
                      !selectItem ||
                      selectAmount >= selectItem?.quantity ||
                      !Boolean(selectItem?.isActive)
                      // checkCoinSelect("addAmount")
                    }
                  ></StyledButton>
                </ButtonGroup>
                <Button
                  variant="contained"
                  onClick={handleRedeemReward}
                  disabled={
                    !selectItem || 
                    !Boolean(selectItem?.isActive) ||
                    selectItem?.quantity === 0 || 
                    checkCoinSelect("redeem")
                  }
                >
                  แลกของรางวัล
                </Button>
              </Stack> */}
              {checkCoinSelect("redeem") && (
                <Alert severity="error">
                  <AlertTitle sx={{ margin: 0 }}>เหรียญคงเหลือไม่เพียงพอ!</AlertTitle>
                </Alert>
              )}
              {selectItem?.quantity === 0 && (
                <Alert severity="warning">
                  <AlertTitle sx={{ margin: 0 }}>ของรางวัลหมด</AlertTitle>
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <TiptapDetail detail={reward.detail} />
            </Grid>
          </Grid>
          {openWallet && (
            <WalletCoin
              wallet={userProfile?.wallet}
              openWallet={openWallet}
              handleCloseWallet={handleCloseWallet}
            />
          )}
          {openDialog && (
            <DialogRedeemSuccess
              image={selectItem.imageURL}
              openDialog={openDialog}
              handleCloseDialog={handleCloseDialog}
            />
          )}
        </Container>
      )}
    </StyledRoot>
  );
}

export default RewardDetail;
