import {
    Grid,
    Card,
    FormHelperText,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,convertToRaw } from "draft-js";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { addCoacheeRecord ,addCoachRecord,getAllCoacheeRecordByIdCoachee,getAllCoachRecordByIdCoach} from "../../../../actions/coachRecord";
const StyledCard = styled(Card)({
    padding: 24,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
        padding: 24,
    },
});

const NormalForm = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [error, setError] = useState(""); 
    const location = useLocation();
    const {setOpenForm,setSnackBarConfig,setModeSnackbar,setDisplaySnackbar,idBooking,tag} = props;
    const dispatch = useDispatch();

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // ป้องกันการส่งฟอร์มแบบปกติ
        if (!editorState.getCurrentContent().hasText()) {
            setError("กรุณากรอกข้อมูล"); // ตั้งข้อความผิดพลาด
            return; // หยุดการส่งข้อมูล
        }  

        onSubmit()
        setError(""); // ล้างข้อความผิดพลาด
    };
    const onSubmit = async(data) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(rawContentState)
        console.log(htmlContent);
        const formData = {
            normalform:htmlContent,
            recordType:0,
            idBooking:idBooking
        }
        console.log(formData);     
        setOpenForm(false);  
        const result = await dispatch(tag === "coach" ? addCoachRecord(formData) : addCoacheeRecord(formData));
        if(tag === "coach"){
            const { idCoachee } = location.state || {};
            dispatch(getAllCoacheeRecordByIdCoachee(idCoachee));
        }else{
            const { idCoach } = location.state || {};
            dispatch(getAllCoachRecordByIdCoach(idCoach))
        }
        if (result && result.status === 200) {
            setSnackBarConfig(true);
            setDisplaySnackbar("Successfully");
            setModeSnackbar("success");
        } else {
            setSnackBarConfig(true);
            setDisplaySnackbar("Unsuccessful");
            setModeSnackbar("error");
        }
      };
    return (
        <StyledCard>
            <form onSubmit={handleSubmit}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorStyle={{
                        height: "300px",
                        border: "1px solid #637381",
                        padding: "16px",
                        borderRadius: "8px",
                    }}
                    onEditorStateChange={onEditorStateChange}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
                <Grid paddingTop={"20px"} display={"flex"} justifyContent={"end"} item xs={12} container>
                    <Button variant="contained" color="primary" type="submit">
                        บันทึก
                    </Button>
                </Grid>
            </form>
        </StyledCard>
    );
};

export default NormalForm;
