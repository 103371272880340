import React, { useEffect, useState,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMissionByUserId } from "../../../../actions/mission";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HeaderPage from "../../shared/header/headerPage";
import { styled } from "@mui/material/styles";
import MoraleQuestionIcon from "./assets/question.png";
import { DialogDetail } from "../../manager/mission/missionAssign";
import { useNavigate } from "react-router";
import Tab from "@mui/material/Tab";
import MyMissionCard from "./component/my_mission_card"
import ButtonBlue from "../../shared/general/ButtonBlue";
import AssignmentIcon from '@mui/icons-material/Assignment';

import {
    Divider,
    Grid,
    Typography,
    Tabs,
  } from "@mui/material";
const StyledRoot = styled("div")({
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    margin: "0px auto",
    padding: 16,
    minWidth: 350,
    minHeight: "100vh",
    color: "#ffffff",
    backgroundColor: "#778899",
  });

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  );
}
  

  const StyledDivider = styled(Divider)({
    width: "100%",
    margin: "10px 0",
    backgroundColor: "#ffffff",
  });
  const WrapTabs = styled("div")({
    borderRadius: 8,
    display:"flex",
    direction:"row",
    padding:"0% 5%",
    paddingBottom:"2%"
    // justifyContent:"start"
  });
  const WrapStatus = styled("div")({
    display: "flex",
    alignItems: "center",
    "& div": {
      margin: 8,
    },
  });
  const colorStatus = ["warning", "warning", "secondary", "success", "error"];


function MissionHistory(){
    const dispatch = useDispatch();
    const {result:missionList} = useSelector((state)=> state.mission);
    const [currentMission, setCurrentMission] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const moment = require('moment');
    const filteredMissions = useMemo(() => {
      if (!missionList) {
        return [];
      }
  
      if (tabValue === 0) {
        return missionList.filter((e) => e.idMissionStatus === 2);
      } else {
        return missionList.filter((e) => moment(new Date()).isAfter(e.missionDate) && e.idMissionStatus !== 2);
      }
    }, [missionList, tabValue]);
    const handleChangetabValue = (event,value)=>{
      setTabValue(value);
    }
    const navigate = useNavigate();
    const clearCurrentMission = () => {
        setCurrentMission(null);
      };
  

      const handleClickNavigateMyMission = () => {
        navigate("/my-mission");
      };
    useEffect(()=>{
        dispatch(getMissionByUserId());
        setTabValue(0)
    },[])
    return(
        <StyledRoot className={"page"} >
            <div style={{display:"flex" , justifyContent:"space-between"}}>
            <HeaderPage
                textLabel={"ประวัติภารกิจของฉัน"}
                icon={MoraleQuestionIcon}
                color={"#ffffff"}
                />
                 {/* <Button
                    variant="contained"
                    color="primary"
         onClick={handleClickNavigateMyMission}
        >
        ภารกิจของฉัน
        </Button> */}
                <ButtonBlue
              variant="contained"
              startIcon={<AssignmentIcon />}
              color="primary"
              onClick={handleClickNavigateMyMission}
              style={{ marginLeft: 16 }}
            >
              ภารกิจของฉัน
            </ButtonBlue>
            </div>
            <StyledDivider />
            <DialogDetail onClose={clearCurrentMission} mission={currentMission} />
            <WrapTabs>
          <Tabs value={tabValue} onChange={handleChangetabValue} style={{backgroundColor:"#ffffff"}} aria-label="basic tabs example" >
            <Tab sx={{ width: "50%" }} label="สำเร็จ" />
            <Tab sx={{ width: "50%" }} label="ไม่สำเร็จ"  />
          </Tabs>
        </WrapTabs>
        <CustomTabPanel value={tabValue} index={0}>
        {filteredMissions && filteredMissions.length === 0 && (
            <Typography fontSize={64} textAlign={"center"} color={"white"}>
            No mission
            </Typography>
        )}
        <Grid container spacing={4} paddingLeft={"5%"} paddingRight={"5%"}>
            {filteredMissions &&
            filteredMissions
                .filter((item) => item)
                .map((value, index) => (
                <MyMissionCard
                    key={index}
                    value={value}
                    setCurrentMission={setCurrentMission}
                    colorStatus={colorStatus}
                />
                ))}
        </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
        {filteredMissions && filteredMissions.length === 0 && (
            <Typography fontSize={64} textAlign={"center"} color={"white"}>
            No mission
            </Typography>
        )}
        <Grid container spacing={4} paddingLeft={"5%"} paddingRight={"5%"}>
            {filteredMissions &&
            filteredMissions
                .filter((item) => item)
                .map((value, index) => (
                <MyMissionCard
                    key={index}
                    value={value}
                    setCurrentMission={setCurrentMission}
                    colorStatus={colorStatus}
                />
                ))}
        </Grid>
        </CustomTabPanel>
            
        </StyledRoot>
    )
}
export default MissionHistory;
