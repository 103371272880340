import React from "react";
import { Doughnut } from "react-chartjs-2";

const bgColorChart = {
  id: "bgColorChart",
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext("2d");
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const gaugeNeedle = {
  id: "gaugeNeedle",
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      width,
      height,
      config,
      data,
      chartArea: { top, bottom, left, right },
    } = chart;

    ctx.save();
    const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);

    const needleValue = data.datasets[0].needleValue;
    const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
    const cx = (right - left) / 2;
    const cy = bottom - top;
    //gauge
    ctx.translate(cx, cy);
    ctx.rotate(angle);
    ctx.beginPath();
    ctx.moveTo(0, -8);
    ctx.lineTo(chart.innerRadius, 0);
    ctx.lineTo(0, 8);
    ctx.fillStyle = options.needleColor;
    ctx.fill();

    //dot
    ctx.translate(-cx, -cy);
    ctx.beginPath();
    ctx.arc(cx, cy, 10, 0, 10);
    ctx.fillStyle = options.needleDotColor;
    ctx.fill();
    ctx.restore();
  },
};

const GaugeAnalytic = (props) => {
  return (
    <Doughnut
      options={{
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        rotation: 3.14159265359,
        circumference: 3.14159265359,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: `${props.score}%`,
                color: "rgb(68, 68, 68)",
                font: {
                  size: 40,
                  weight: "bold",
                },
              },
            ],
          },
          gaugeNeedle: {
            needleColor: "rgba(144, 144, 144, 0.4)",
            needleDotColor: "rgba(144, 144, 144, 1)",
          },
        },
      }}
      plugins={[bgColorChart, gaugeNeedle]}
      data={{
        datasets: [
          {
            data: [100],
            backgroundColor: ["rgba(255, 99, 132, 1)"],
            needleValue: props.score,
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};

export default GaugeAnalytic;
