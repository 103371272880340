import { POLL_FETCHING, POLL_FAILED, POLL_SUCCESS } from "../actions/types";
import pollService from "../services/poll.service";

export const getAllPolls = () => async (dispatch) => {
  try {
    dispatch({ type: POLL_FETCHING });
    const res = await pollService.getAllpolls();
    if (res) {
      dispatch({
        type: POLL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: POLL_FAILED });
    console.log(error);
  }
};

export const getPoll = () => async (dispatch) => {
  try {
    dispatch({ type: POLL_FETCHING });
    const res = await pollService.getPoll();
    if (res) {
      dispatch({
        type: POLL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: POLL_FAILED });
    console.log(error);
  }
};

export const addPoll = (formData) => async (dispatch) => {
  try {
    const res = await pollService.addPoll(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({ type: POLL_FAILED });
    console.log(error);
  }
};

export const updatePollVote = (formData) => async (dispatch) => {
  try {
    const res = await pollService.updatePollVote(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({ type: POLL_FAILED });
  }
};

export const updatePollStatus = (formData) => async (dispatch) => {
  try {
    const res = await pollService.updatePollStatus(formData);
    if (res) {
      return res
    }
  } catch (error) {
    dispatch({ type: POLL_FAILED });
    console.log(error);
  }
};
