import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HeaderPage from "../../../../../shared/header/headerPage";
import { StyledDividerHeader } from "../../../../../shared/dividerHeader";
import TipTap from "../../../../../shared/tiptapEditor";
import SwitchInput from "../../../../../shared/inputForm/switchInput";
import UpdateRewardImage from "../../../../../shared/uploadReward/updateRewardImage";
import { useDispatch, useSelector } from "react-redux";
import {
  getUniReward,
  updateUniReward,
} from "../../../../../../../actions/uniReward";
import { useNavigate, useParams } from "react-router-dom";
import VariationField from "../../../../../shared/formReward/variationField";

import { DevTool } from "@hookform/devtools";
import TableExistVariation from "../../../../../uniTeam/reward/edit/tableExistVariation";
import { getCompanyReward, updateCompanyReward } from "../../../../../../../actions/companyReward";
import InputField from "../../../../../shared/inputForm/inputField";
import { useRewardType } from "../../../../../../../quries/reward";
import { toast } from "sonner";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

const StyledCard = styled(Card)({
  borderRadius: 8,
});

const defaultValues = {
  name: "",
  detail: null,
  idRewardType: "",
  images: [null, null, null, null],
  mainImage: { image: '' },
  rewardManager: "",
  contact: "",
};

function EditCompanyReward() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rewardType = useRewardType();

  const { result: rewardItem } = useSelector((state) => state.rewardItem);

  yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required(),
    idRewardType: yup.number().required(),
    rewardManager: yup.string().max(100).required(),
    contact: yup.string().max(100).required(),
    mainImage: yup
      .mixed()
      .test(
        "required",
        "โปรดเพิ่มรูปภาพหลัก",
        (value) => value?.imageURL
      ),
    images: yup.array(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const images = getValues("images");

  const handleEditUniReward = async (data) => {
    delete data.items
    const formData = new FormData();
    for (const property in data) {
      if (property === "images") {
        data[property]
          .forEach((file) => formData.append(property, file));
        formData.append('imagesReward', JSON.stringify(data[property]));
      } else if (Array.isArray(data[property])) {
        formData.append(property, JSON.stringify(data[property]));
      } else {
        formData.append(property, data[property]);
      }
    }

    const res = await dispatch(updateCompanyReward(data.idCompanyReward, formData));
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
      navigate(`/admin/manage/reward`);
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  useEffect(() => {
    if (rewardItem) {
      reset({
        ...rewardItem,
        mainImage: { imageURL: rewardItem.imageURL },
        images: [
          ...rewardItem.images,
          ...Array(4 - rewardItem.images.length).map(item => null),
        ],
      });
    }
  }, [rewardItem]);

  useEffect(() => {
    dispatch(getCompanyReward(id));
  }, []);

  return (
    <StyledRoot className={"page"}>
      <DevTool control={control} />
      <HeaderPage textLabel={"แก้ไขรายละเอียดสินค้า"} />
      <StyledDividerHeader />
      {rewardItem && rewardType.data && (
        <Container maxWidth="lg">
          <Alert severity="warning" style={{ marginBottom: 16 }}>
            <AlertTitle>คำเตือน</AlertTitle>
            การแก้ไขข้อมูลรางวัลจะส่งผลเปลี่ยนแปลงต่อข้อมูลรางวัลใน
            <strong>ประวัติการแลกรางวัล</strong>
          </Alert>
          <form onSubmit={handleSubmit(handleEditUniReward)}>
            <Stack spacing={2}>
              <StyledCard elevation={4}>
                <CardHeader title="ข้อมูลสินค้า" />
                <CardContent>
                  <Grid container spacing={2} marginTop={0}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="ชื่อสินค้า"
                            fullWidth
                            error={Boolean(fieldState.error)}
                            helperText={fieldState?.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name={"idRewardType"}
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl fullWidth>
                            <InputLabel>ประเภทของรางวัล</InputLabel>
                            <Select
                              {...field}
                              label="ประเภทของรางวัล"
                              error={Boolean(fieldState.error)}
                            >
                              {rewardType.data.map((item) => (
                                <MenuItem value={item.idRewardType}>
                                  {item.rewardType}
                                </MenuItem>
                              ))}
                            </Select>
                            {Boolean(fieldState.error) && (
                              <FormHelperText error>
                                {fieldState?.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>รายละเอียด</InputLabel>
                      <Controller
                        name="detail"
                        control={control}
                        render={({ field }) => (
                          <TipTap
                            detail={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name="rewardManager"
                        control={control}
                        label="ผู้ดูแลรางวัล"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField 
                        name="contact"
                        control={control}
                        label="การติดต่อ"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>
              <StyledCard elevation={4}>
                <CardHeader title={"รูปภาพสินค้า"} />
                <CardContent>
                  <Typography>{"ขนาดไฟล์ภาพต้องไม่เกิน 500 KB"}</Typography>
                  <ImageList cols={5}>
                    <Grid item xs={2}>
                      <Controller
                        name={'mainImage'}
                        control={control}
                        render={({ field, fieldState }) => (
                          <UpdateRewardImage
                            {...field}
                            acceptFileType={"image/jpeg, image/png, image/webp"}
                            error={fieldState.error}
                            title={"รูปภาพหลัก"}
                            id={"idCompanyReward"}
                          />
                        )}
                      />
                    </Grid>
                    {images &&
                      images.map((field, index) => (
                        <Grid item xs={2} key={index}>
                          <Controller
                            name={`images[${index}]`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <UpdateRewardImage
                                {...field}
                                acceptFileType={"image/jpeg, image/png, image/webp"}
                                error={fieldState.error}
                                title={ "รูปภาพเสริม"}
                                id={"idCompanyRewardImage"}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                  </ImageList>
                </CardContent>
              </StyledCard>
              {/* {watch("openVariation") && (
                <StyledCard>
                  <CardHeader
                    title={"ข้อมูลการขาย *(หากปิดจะทำการลบข้อมูลตัวเลือกของสินค้า)"}
                    action={
                      <SwitchInput
                        name="openVariation"
                        control={control}
                        label={"เพิ่มตัวเลือกสินค้า"}
                      />
                    }
                  />
                  <CardContent>
                    <Typography gutterBottom>
                      ตัวเลือกสินค้าที่มีอยู่แล้ว
                    </Typography>
                    <TableExistVariation items={rewardItem.items} />
                    <VariationField {...{ control, errors, items: rewardItem.items }} />
                  </CardContent>
                </StyledCard>
              )} */}
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                <Button type="button" variant="outlined">
                  ยกเลิก
                </Button>
                <Button type="submit" variant="contained" disabled={isSubmitting}>
                  บันทึกการแก้ไข
                </Button>
              </Stack>
            </Stack>
          </form>
        </Container>
      )}
    </StyledRoot>
  );
}

export default EditCompanyReward;