import {
  BOOK_FETCHING,
  BOOK_FAILED,
  BOOK_SUCCESS,
  BOOK_COACHEE_FETCHING,
  BOOK_COACHEE_FAILED,
  BOOK_COACHEE_SUCCESS,
  BOOK_COACH_FETCHING,
  BOOK_COACH_FAILED,
  BOOK_COACH_SUCCESS,
  // BOOK_COACH_ALL_FETCHING,
  // BOOK_COACH_ALL_FAILED,
  // BOOK_COACH_ALL_SUCCESS,
  // BOOK_COACH_APPROVE_FETCHING, 
  // BOOK_COACH_APPROVE_FAILED,
  // BOOK_COACH_APPROVE_SUCCESS 
} from "./types";

import BookService from "../services/book.service";

export const addBooking = (formData) => async (dispatch) => {
  try {
    const res = await BookService.addBooking(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: BOOK_FAILED,
    });
    console.log(err);
  }
};

export const updateBooking = (formData) => async (dispatch) => {
  try {
    const res = await BookService.updateBooking(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: BOOK_FAILED,
    });
    console.log(err);
  }
};

export const getBookingByIdCoachee = () => async (dispatch) => {
  try {
    dispatch({
      type: BOOK_COACHEE_FETCHING,
    });
    const res = await BookService.getBookingByIdCoachee();
    if (res) {
      dispatch({
        type: BOOK_COACHEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BOOK_COACHEE_FAILED,
    });
    console.log(err);
  }
};

export const getBookingByIdCoach = () => async (dispatch) => {
  try {
    dispatch({
      type: BOOK_COACH_FETCHING,
    });
    const res = await BookService.getBookingByIdCoach();
    if (res) {
      dispatch({
        type: BOOK_COACH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BOOK_COACH_FAILED,
    });
    console.log(err);
  }
};

export const getBookingRequestApprove = ()=> async(dispatch)=>{
  try {
    dispatch({
      type: BOOK_COACH_FETCHING,
    });
    const res = await BookService.getBookingRequestApprove();
    if (res) {
      dispatch({
        type: BOOK_COACH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BOOK_COACH_FAILED,
    });
    console.log(err);
  }
}
export const getBookingCoach = ()=> async(dispatch)=>{
  try {
    dispatch({
      type: BOOK_COACH_FETCHING,
    });
    const res = await BookService.getBookingCoach();
    if (res) {
      dispatch({
        type: BOOK_COACH_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BOOK_COACH_FAILED,
    });
    console.log(err);
  }
}



// export const getAllBookingByIdCoach = (idCoach) => async (dispatch) => {
//   try {
//     dispatch({
//       type: BOOK_COACH_ALL_FETCHING,
//     });
//     const res = await BookService.getAllBookingByIdCoach(idCoach);
//     if (res) {
//       dispatch({
//         type: BOOK_COACH_ALL_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: BOOK_COACH_ALL_FAILED,
//     });
//     console.log(err);
//   }
// };

// export const getApproveBookingByIdCoach = (idCoach) => async (dispatch) => {
//   try {
//     dispatch({
//       type: BOOK_COACH_APPROVE_FETCHING,
//     });
//     const res = await BookService.getApproveBookingByIdCoach(idCoach);
//     if (res) {
//       dispatch({
//         type: BOOK_COACH_APPROVE_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: BOOK_COACH_APPROVE_FAILED,
//     });
//     console.log(err);
//   }
// };
