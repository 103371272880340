import React from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const MainTab = ({ handleClick, selectedFilter }) => {
  return (
    <List>
      {Object.keys(selectedFilter).map((key) => (
        <ListItem disablePadding key={key}>
          <ListItemButton onClick={() => handleClick(selectedFilter[key].label, key)}>
            <ListItemText primary={selectedFilter[key].label} />
            <NavigateNextIcon />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default MainTab;
