import React, { useCallback, useState } from "react";
import { styled, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";

const StyledImageDropZone = styled("div")({
  "& .dropzone-announcement": {
    width: "100%",
    height: 144,
    margin: "auto",
    textAlign: "center",
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
});

const UploadFile = (props) => {
  const { onChange, acceptFileType, acceptFileTypeLabel, name, error, multiple } = props;
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0])
    setMyFiles([...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop,
    multiple: multiple,
  });

  const handleRemove = () => {
    onChange("")
    setMyFiles([]);
  };

  const acceptedFileItems = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton aria-label="delete" color="error" onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </li>
  ));

  return (
    <StyledImageDropZone className={!!error ? 'error' : ""}>
      <aside>
        <h4>{name}</h4>
        <ul>{acceptedFileItems}</ul>
      </aside>
      {acceptedFileItems.length === 0 && (
        <div {...getRootProps({ className: "dropzone-announcement" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only {acceptFileTypeLabel} will be accepted)</em>
        </div>
      )}
      {error && <p style={{ color: "#d32f2f" }}>{error?.message}</p>}
    </StyledImageDropZone>
  );
};

export default UploadFile