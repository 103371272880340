import { useQuery } from "@tanstack/react-query";
import { httpClient } from "../services/httpClient";

export const useMoraleCompany = ({ idCompany }) => {
  return useQuery({
    queryKey: ["morale", "company", idCompany],
    queryFn: async () =>
      (await httpClient.get(`morale/company/${idCompany}`)).data,
    enabled: !!idCompany,
    placeholderData: [],
  });
};

export const useMoraleKPI = ({ idMorale, query }) => {
  return useQuery({
    queryKey: ["morale", "kpi", idMorale, query],
    queryFn: async () =>
      (await httpClient.get(`morale-kpi/${idMorale}`, { params: query })).data,
    enabled: !!idMorale,
    refetchOnWindowFocus: false,
  });
};

// export const useMoraleDailyKPI = ({ query }) => {
//   return useQuery({
//     queryKey: ["morale", "kpi", "daily", query],
//     queryFn: async () =>
//       (await httpClient.get("morale-kpi/daily", { params: query })).data,
//     enabled: !!query,
//     refetchOnWindowFocus: false,
//   });
// };
