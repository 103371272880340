import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TablePagination,
} from "@mui/material";

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const Questions = (props) => {
  const { questionList } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {questionList
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <Fragment key={row.idAssessment_Question}>
            <ListItem>
              <ListItemAvatar>
                <Avatar alt={row.question}>
                  {row.isChoice === 1 ? (
                    <i className="far fa-check-circle"></i>
                  ) : (
                    <i className="far fa-comment"></i>
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${row.question}`} />
            </ListItem>
            <StyledDivider variant="inset" />
          </Fragment>
        ))}

      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={questionList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Questions;
