import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const FilterActivityName = ({ handleSetFilter }) => {
  const [filterActivity, setFilterActivity] = React.useState("");
  return (
    <FormControl variant="outlined">
      <InputLabel>{"ค้นหากิจกรรม"}</InputLabel>
      <OutlinedInput
        fullWidth
        label={"ค้นหากิจกรรม"}
        value={filterActivity}
        onChange={(event) => setFilterActivity(event.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => handleSetFilter(filterActivity)}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default FilterActivityName;
