import React from "react";
import { styled } from "@mui/material/styles";

const WrapContent = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const UnauthorizedPage = () => {
  return (
      <WrapContent className={'page'}>
          <img width={600} alt="unauthorized" src={`${process.env.PUBLIC_URL}/assets/background/error401.png`} />
      </WrapContent>
  )
}

export default UnauthorizedPage;