import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  Typography,
  Snackbar,
  Alert,
  Autocomplete,
  Stack,
  Tabs,
  Box,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Drawer from "@mui/material/Drawer";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";

import AutocompleteListUsers from "./../../shared/autocompleteListUsers";
import Hacker from "./assets/hacker.png";
import BgPage from "./assets/feedback.png";
import feedbackHeaderIcon from "./assets/feedback-text.png";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import { addFeedback, getFeedback } from "../../../../actions/feedback";
import SelectEmployees from "../../shared/general/selectEmployees";

const StyledCard = styled(Card)({
  borderRadius: 16,
  marginBottom: 20,
});

const StyledTitle = styled(Typography)({
  color: "#424242",
});

const StyledCardContent = styled(CardContent)(({ anonymous }) => {
  return anonymous === "true"
    ? {
        display: "flex",
        flexDirection: "row-reverse",
        ["@media only screen and (max-width:600px)"]: {
          flexDirection: "column",
        },
      }
    : {
        display: "flex",
        ["@media only screen and (max-width:600px)"]: {
          flexDirection: "column",
        },
      };
});


const StyledFromFeedback = styled("div", {
  shouldForwardProp: (prop) => prop !== "anonymous"
})(({ anonymous }) => ({
  display: "flex", flexDirection: anonymous === "true" ? "row-reverse": "row"
}));

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  margin: "0px auto",
  padding: 16,
  marginTop: 60,
  minWidth: 350,
  minHeight: "calc(100vh - 60px)",
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${BgPage})`,
});

const AddFeedbackButton = styled(Button)({
  marginRight: 8,
  fontSize: 16,
  backgroundColor: "#562B2B",
  "&:hover": {
    backgroundColor: "#341A1A",
  },
});

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#562B2B",
  "&:hover": {
    backgroundColor: "#341A1A",
  },
});

const WrapTabs = styled("div")({
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  );
}

const Feedback = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [dateFeedback, setDateFeedback] = useState(null);
  const [idUserFeedback, setIdUserFeedback] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackType, setfeedbackType] = useState("identify");
  const [tabValue, setTabValue] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: feedbackList } = useSelector((state) => state.feedback);

  useEffect(() => {
    let isCancelled = false

    const fetchData = async () => {
      try {
        dispatch(getFeedback())
      } catch (e) {
        if (!isCancelled) {
          console.log(e);
        }
      }
    };
    
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleChangeFeedback = (event) => {
    setFeedback(event.target.value);
  };

  const handleChangeFeedbackType = (event) => {
    setfeedbackType(event.target.value);
  };

  const handleChangetabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleAddFeedback = async () => {
    const pass = feedback.trim().length > 0
    if(pass && selectedEmployee) {
      const res = await dispatch(
        addFeedback({
          idReceiver: selectedEmployee.idEmployees,
          idSender: userProfile.idEmployees,
          feedback,
          feedbackType,
        })
      );
      if (res) {
        dispatch(getFeedback())
        handleClose();
        setTabValue("send");
      }
    } else {
      setOpenSnackbar(true);
    }
  };

  return (
    <StyledRoot className={'page'}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          alt="feedback-icon"
          src={feedbackHeaderIcon}
          width={250}
        />
      </div>
      {open &&
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New feedback</DialogTitle>
          <DialogContent>
            <SelectEmployees handleChange={handleSelectEmployee} />
            <TextField
              margin="normal"
              multiline
              rows={5}
              fullWidth
              id="feedback-to-friend"
              label="Feedback"
              variant="outlined"
              value={feedback}
              onChange={handleChangeFeedback}
              inputProps={{ maxLength: 200 }}
            />
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">From</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={feedbackType}
                onChange={handleChangeFeedbackType}
              >
                <FormControlLabel 
                  value="identify" 
                  control={<Radio />} 
                  label="Me" 
                />
                <FormControlLabel
                  value="anonymous"
                  control={<Radio />}
                  label="Anonymous"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddFeedback}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Stack 
        direction="row"
        justifyContent={"space-between"} 
        alignItems={"center"}
        marginTop={2}
        marginBottom={2}
        flexWrap={"wrap"}
      >
        <WrapTabs>
          <Tabs value={tabValue} onChange={handleChangetabValue} aria-label="basic tabs example">
            <Tab sx={{ width: "50%" }} label="receive" {...a11yProps(0)} />
            <Tab sx={{ width: "50%" }} label="send" {...a11yProps(1)} />
          </Tabs>
        </WrapTabs>
        <div>
          <AddFeedbackButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            New Feedback
          </AddFeedbackButton>
          <FilterIconButton
            aria-label="filter"
            onClick={toggleDrawer}
            size="large"
            >
            <FilterListIcon fontSize="large" />
          </FilterIconButton>
        </div>
      </Stack>
      <CustomTabPanel value={tabValue} index={0}>
        {feedbackList &&
          feedbackList
            .filter((item) =>
              idUserFeedback.length > 0
                ? (idUserFeedback.includes(item.idReceiver) || idUserFeedback.includes(item.idSender))
                : item
            )
            .filter((item) => 
              dateFeedback 
                ? dayjs(item.feedbackDate).isSame(dateFeedback, "day")  
                : item
            )
            .map((value) => {
              return (
                <Fragment key={value.idFeedback}>
                  <StyledCard variant="outlined">
                    <StyledCardContent
                      anonymous={value.feedbackType === "anonymous" ? "true" : "false"}
                    >
                      <div style={{ paddingRight: 16 }}>
                        <img
                          src={
                            value.feedbackType === "anonymous"
                              ? `${Hacker}`
                              : value.sender_imageURL
                          }
                          alt="friend"
                          width={100}
                          style={{ borderRadius: 45 }}
                        />
                      </div>
                      <div>
                        <StyledTitle gutterBottom>
                          <FormatQuoteIcon
                            fontSize={"large"}
                            style={{ transform: "scale(-1, 1)" }}
                          />
                          {value.feedback}
                          <FormatQuoteIcon fontSize={"large"} />
                        </StyledTitle>
                        <StyledFromFeedback
                          anonymous={value.feedbackType === "anonymous" ? "true" : "false"}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14, padding: "0 4px" }}
                            color="textSecondary"
                          >
                            {`—`}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14 }}
                            color="textSecondary"
                          >
                            {value.feedbackType === "anonymous"
                              ? ` Anonymous, `
                              : ` ${value.sender_firstname_TH} ${value.sender_lastname_TH}, `}
                            {dayjs(value.feedbackDate).format(
                              "D MMM YYYY HH:mm"
                            )}
                          </Typography>
                        </StyledFromFeedback>
                      </div>
                    </StyledCardContent>
                  </StyledCard>
                </Fragment>
              );
          })}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
      {feedbackList &&
        feedbackList
          .filter((item) =>
            idUserFeedback.length > 0
              ? (idUserFeedback.includes(item.idReceiver) || idUserFeedback.includes(item.idSender))
              : item
          )
          .filter((item) => 
            dateFeedback 
              ? dayjs(item.feedbackDate).isSame(dateFeedback, "day")  
              : item
          )
          .map((value) => {
            return (
              <Fragment key={value.idFeedback}>
                <StyledCard variant="outlined">
                  <StyledCardContent anonymous={"false"}>
                    <div style={{ paddingRight: 16 }}>
                      <img
                        src={value.receiver_imageURL}
                        alt="friend"
                        width={100}
                        style={{ borderRadius: 45 }}
                      />
                    </div>
                    <div>
                      <StyledTitle gutterBottom>
                        <FormatQuoteIcon
                          fontSize={"large"}
                          style={{ transform: "scale(-1, 1)" }}
                        />
                        {value.feedback}
                        <FormatQuoteIcon fontSize={"large"} />
                      </StyledTitle>
                      <StyledFromFeedback anonymous={false}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: 14, padding: "0 4px" }}
                          color="textSecondary"
                        >
                          {`—`}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: 14 }}
                          color="textSecondary"
                        >
                          {` ${value.receiver_firstname_TH} ${value.receiver_lastname_TH}, `}
                          {dayjs(value.feedbackDate).format(
                            "D MMM YYYY HH:mm"
                          )}
                        </Typography>
                      </StyledFromFeedback>
                    </div>
                  </StyledCardContent>
                </StyledCard>
              </Fragment>
            );
          })}
      </CustomTabPanel>
      {openFilter && (
        <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
          <AutocompleteListUsers 
            setIdUser={setIdUserFeedback} 
            setDate={setDateFeedback}
            />
        </Drawer>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          โปรดกรอกข้อมูลให้ครบ
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
};

export default Feedback;
