import { httpClient } from "./httpClient";

const getHeartTransfer = () => {
  return httpClient.get("heart-transfer");
};

const addHeartTransfer = (formData) => {
  return httpClient.post("heart-transfer", formData);
};

const updateHeartTransfer = (formData) => {
  return httpClient.patch("heart-transfer", formData);
};

const getHeartReceiveYear = () => {
  return httpClient.get("heart-receive-year");
};

export default {
  getHeartTransfer,
  addHeartTransfer,
  updateHeartTransfer,
  getHeartReceiveYear,
};
