import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
} from "@mui/material";

import tableIcon from "./assets/table.png";

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  fontWeight: "bold",
  padding: "16px",
  borderColor: "#2e2e2e",
});

const StyledTableRow = styled(TableRow)({
  backgroundColor: "#282828",
  padding: "16px",
  borderColor: "#2e2e2e",
});

const DailyDepartmentSectionTable = ({ filterData }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);

  // Calculate average scores by department and section
  const departmentScores = filterData.reduce((acc, cur) => {
    const deptKey = cur.departmentName;
    if (!acc[deptKey]) {
      acc[deptKey] = { sumScore: 0, count: 0 };
    }
    if (cur.averageScore) {
      acc[deptKey].sumScore += cur.averageScore;
      acc[deptKey].count += 1;
    }
    return acc;
  }, {});

  const sectionScores = filterData.reduce((acc, cur) => {
    const sectionKey = cur.sectionName;
    if (!acc[sectionKey]) {
      acc[sectionKey] = { sumScore: 0, count: 0 };
    }
    if (cur.averageScore) {
      acc[sectionKey].sumScore += cur.averageScore;
      acc[sectionKey].count += 1;
    }
    return acc;
  }, {});

  const departmentScoresArray = Object.keys(departmentScores).map((key) => ({
    department: key,
    averageScore: Math.round(
      departmentScores[key].sumScore / departmentScores[key].count
    ),
  }));

  const sectionScoresArray = Object.keys(sectionScores).map((key) => ({
    section: key,
    averageScore: Math.round(
      sectionScores[key].sumScore / sectionScores[key].count
    ),
  }));

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={tableIcon}
            alt="Table Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" color={"#ffffff"}>
            คะแนนเฉลี่ยตามแผนก
          </Typography>
        </div>
      </div>

      {/* Table for Department */}
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          backgroundColor: "#000000",
          border: "1px solid #2e2e2e",
          marginBottom: "16px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "white",
                }}
              >
                Department
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "white",
                }}
              >
                Average Score (%)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departmentScoresArray.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.department}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.averageScore}%
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={tableIcon}
            alt="Table Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" color={"#ffffff"}>
            คะแนนเฉลี่ยตามหน่วยงาน
          </Typography>
        </div>
      </div>

      {/* Table for Section */}
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          backgroundColor: "#000000",
          border: "1px solid #2e2e2e",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "white",
                }}
              >
                Section
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "white",
                }}
              >
                Average Score (%)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sectionScoresArray.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{row.section}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.averageScore}%
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DailyDepartmentSectionTable;
