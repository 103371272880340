import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { Card, Divider, styled, Typography, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { HeartCoin } from "../../../shared/typeCoin";

const StyledCard = styled(Card)(({ selected }) => ({
  padding: "8px 16px 24px",
  marginBottom: 16,
  position: "relative",
  borderRadius: 8,
  backgroundColor: selected ? "#E8FFF8" : "#FFFFFF",
  "& .top-card": {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  "& .bottom-card": {
    display: "flex",
    justifyContent: "space-between",
    "& .date-time": {
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .icon-expand": {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 20%)",
    color: "#C4C4C4",
    fontSize: 32,
  },
  "& .topic": {
    marginRight: 8,
  },
  "& .text": {
    fontSize: 16,
  },
  "& .text-reason": {
    fontSize: 14,
    color: "#757575",
  },
}));

const StyledDivider = styled(Divider)({
  borderBottom: "1px solid #C4C4C4",
  marginBottom: 8,
});

const CardRequest = (props) => {
  const { row, mode, selectedRows, setSelectedRows } = props;
  
  const [openExpand, setOpenExpand] = useState(false);

  const handleOpenCloseExpand = () => {
    setOpenExpand(!openExpand);
  };

  const handleClickCard = (_, row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };

  const isSelected = (row) => {
    return selectedRows.indexOf(row) !== -1;
  };
  const thaiMonths = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];
  return (
    <StyledCard
      onClick={
       (e)=> handleClickCard(e, row)
      }
      selected={isSelected(row)}
    >
      <div
        className="top-card"
      >
        <div>
        <Typography color="text.third" fontSize="14px">
            ผู้ส่ง
          </Typography>
          <Typography style={{ fontWeight: 700, fontSize: 16 }}>
            {row.sender_firstname_TH} {row.sender_lastname_TH}
          </Typography>     
        </div>
        <div>
        <Typography color="text.third" fontSize="14px">
            จำนวนหัวใจ
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          {Array.from({ length: row.value }).map((_, index) => (
            <HeartCoin key={index} width={20} />
          ))}
        </div>
        </div>
      </div>
      <StyledDivider />
      <div>
        <Typography color="text.third" fontSize="14px">
            ผู้รับ
          </Typography>
          <Typography style={{ fontWeight: 700, fontSize: 16 }}>
            {row.receiver_firstname_TH} {row.receiver_lastname_TH}
          </Typography>
          
        </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "center", textAlign: "center" }}
            onClick={handleOpenCloseExpand}
          >
            {openExpand ? (
              <KeyboardArrowUpRoundedIcon className="icon-expand" />
            ) : (
              <KeyboardArrowDownRoundedIcon className="icon-expand" />
            )}
            {openExpand && 
              <Fragment>
                <StyledDivider style={{ marginTop: 8 }} />
                  <div style={{ display: "flex" }}>
                    <Typography className="topic text-reason">
                     เหตุผล:
                    </Typography>
                    <Typography className="text-reason">
                      {row && (row.detail) ? row.detail: "-" }

                    </Typography>
                  </div>
                  </Fragment>}
          </div>
    </StyledCard>
  );
};

export default CardRequest;
