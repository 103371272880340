import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TextField,
  Grid,
  Card,
  CardActions,
  Select,
  CardContent,
  MenuItem,
  Checkbox,
  Slider,
  Divider,
  Chip,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Snackbar,
  Alert,
  Container,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from '@mui/icons-material/ListAlt';

import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";

import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addMoraledailyQuestion,
  updateMoraleQuestion,
  getAllMoraleDailyQuestion,
  getMoraleDailyCurrent,
} from "../../../../../actions/moraleDaily";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";
import { getDepartment } from "../../../../../actions/department";
import DialogPublish from "./dialogPublish";
import { toast } from "sonner";

const WrapHeader = styled('div')({
  display: "flex",
 
  justifyContent: "space-between",
  // ["@media only screen and (max-width:600px)"]: {
  //   flexDirection: "column",
  //   "& .wrap-button-add": {
  //     marginTop: 8,
  //   },
  // },
})

const StyledButton = styled(Button)({
  margin: 4,
  color: '#000000',
  backgroundColor: "#ffffff",
  ":hover": {
    backgroundColor: "#bdbdbd",
  }
})

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
})

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

const StyledCard = styled(Card)({
  height: '520px',
  width:'100%',
  display: "flex",
  flexDirection: "column",
  border: '10px solid ',
});

const WrapCardHeaderInsight = styled('div')({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
})

const WrapCardHeaderInsight2 = styled('div')({
  display: "flex",
  padding: 5,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  ["@media only screen and (min-width:600px)"]: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    marginLeft: "auto",
  },
  ["@media only screen and (max-width:599px)"]: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    marginRight: "auto",
  },
})

const WrapCardHeaderInsightImage = styled('div')({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923", 
  color: "#EA8900",
})

const StyledDivider = styled(Divider)({
  width: "100%", 
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
})

const DialogDelete = (props) => {
  const dispatch = useDispatch()
  const handleDeleteQuestion = async () => {
    const res = await dispatch(
      updateMoraleQuestion({
        idMoraledailyQuestion: props.idQuestion, 
        isActive: 0
      })
    );
    if(res) {
      dispatch(getAllMoraleDailyQuestion());
    }
    props.onClose()
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title-delete-group-name"
      aria-describedby="alert-dialog-description-delete-group-name"
    >
      <DialogTitle id="alert-dialog-title-delete-group-name">
        {"achieve"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          คุณแน่ใจว่าจะเก็บ?{" "}
          <span style={{ color: "#f44336" }}>{props.displayDelete}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {" "}
          ยกเลิก{" "}
        </Button>
        <Button onClick={handleDeleteQuestion} color="primary">
          {" "}
          เก็บ{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF"
  };
  const idModTen = id % 10
  return bgCard[idModTen]
}

const Notification = () => {
  const navigate = useNavigate();
  const [questionType, setQuestionType] = React.useState("choice");
  const [open, setOpen] = React.useState(false);
  const [idQuestionTopic, setIdQuestionTopic] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [checkAnonymous, setCheckAnonymous] = React.useState(false);
  const [reward, setReward] = React.useState(1);

  const [displayDelete, setDisplayDelete] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [idQuestion, setIdQuestion] = React.useState(null)
  const [selectQuestionStatus, setSelectQuestionStatus] = React.useState(1);

  const [openPublic, setOpenPublic] = React.useState(false);
  const [selectTopic, setSelectTopic] = React.useState(null);

  const handleQuestionStatus = (event, newQuestionStatus) => {
    if (newQuestionStatus !== null) {
      setSelectQuestionStatus(newQuestionStatus);
    }
  };

  const handleChangeAnonymous = (event) => {
    setCheckAnonymous(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenPublic = (id) => {
    setIdQuestion(id)
    setOpenPublic(true)
  };

  const handleClosePublic = () => {
    setOpenPublic(false);
  };

  const handleChange = (event) => {
    setQuestionType(event.target.value);
  };

  const handleChangeIdQuestionTopic = (event) => {
    setIdQuestionTopic(event.target.value);
  };

  const imgInsight = (id) => {
    const idModTen = id % 10
    switch (idModTen) {
      case 0:
        return H1;
      case 1:
        return H2;
      case 2:
        return H3;
      case 3:
        return H4;
      case 4:
        return H5;
      case 5:
        return H6;
      case 6:
        return H7;
      case 7:
        return H8;
      case 8:
        return H9;
      case 9:
        return H10;
      default:
        break;
    }
  };

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const handleScoreChange = (event, newValue) => {
    setReward(newValue);
  };

  const handleChangeTopic = (topic) => {
    setSelectTopic(topic);
  };

  const handleClickMoraleDaily = () => {
    navigate("/admin/manage/morale-daily");
  };

  const marks = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  const dispatch = useDispatch()
  const { result: moraleDailyQuestion } = useSelector(state => state.moraleDailyQuestion)
  // const { result: moraleDailyCurrent } = useSelector((state) => state.moraleDailyCurrent);
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    dispatch(getAllMoraleDailyQuestion())
    // dispatch(getMoraleDailyCurrent())
    dispatch(getAllQuestionTopic())
    dispatch(getDepartment());
  }, []);

  const handleClickeActive = async (id) => {
    const res = await dispatch(
      updateMoraleQuestion({
        idMoraledailyQuestion: id,
        isActive: 1
      })
    )
    if(res) {
      console.log('success')
      dispatch(getAllMoraleDailyQuestion())
    }
  }

  const handleSubmit = async () => {
    const formData = {
      questionType,
      idQuestionTopic,
      question,
      reward,
    }

    if (!idQuestionTopic) {
      toast.error("โปรดเลือกหัวข้อคำถาม")
    } else if (question.trim().length === 0) {
      toast.error("โปรดเพิ่มคำถาม")
    } else {
      const res = await dispatch(addMoraledailyQuestion(formData))
      if (res && res.status === 201) {
        toast.success("สำเร็จ")
        dispatch(getAllMoraleDailyQuestion())
        setIdQuestionTopic("")
        setQuestion("")
        handleClose()
      } else {
        toast.error("เกิดข้อผิดพลาด")
      }
    }
  }

  const handleClickDelete = (question, id) => {
    setIdQuestion(id)
    setDisplayDelete("คำถาม : " + question);
    setOpenDelete(true);
  };
  
  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถามทั้งหมด"} icon={NotificationIcon} />
        <div className={`wrap-button-add`}>
          <Stack  direction={'row'} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              เพิ่มคำถาม
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ListAltIcon />}
              onClick={handleClickMoraleDaily}
            >
              คำถามทั้งหมดที่ส่ง
            </Button>
          </Stack>
        </div>
      </WrapHeader>

      {/* <StyledDivider /> */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%', 
        padding: 1
      }}>
      <Container  
        sx={{ 
          marginLeft: 1, 
          maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }, // Adjust maxWidth for different screen sizes
          padding: 2,
        }}>
        <div style={{ marginBottom: "16px" }}>
          <Typography gutterBottom variant="h6">
            สถานะคำถาม
          </Typography>
          
          <Container  sx={{  marginLeft: -3, maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }}}>
            <StyledDivider />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectQuestionStatus} onChange={handleQuestionStatus} exclusive aria-label="text alignment" textColor="primary">
                <Tab label="ใช้งานอยู่" value={1} aria-label="active"/>
                <Tab label="ที่เก็บไว้" value={0} aria-label="achieve"  />
                <Tab label="ยกเลิกการใช้" value={2} aria-label="achieve"/>
              </Tabs>
            </Box>    
          </Container>
        </div>
        {questionTopic && (
          <div style={{ marginBottom: "24px" }}>
            <Typography gutterBottom variant="h6">
              หัวข้อคำถาม
            </Typography>
            <StyledChip
              clickable
              label={"ALL"}
              color={selectTopic === null ? "primary" : "default"}
              onClick={() => handleChangeTopic(null)}
            />
            {questionTopic.map((data, index) => (
              <StyledChip
                key={data.questionTopic}
                clickable
                label={data.questionTopic}
                color={selectTopic === data.idQuestionTopic ? "primary" : "default"}
                onClick={() => handleChangeTopic(data.idQuestionTopic)}
              />
            ))}
          </div>
        )}
        <Grid container spacing={2} 
          sx={{
            display:'flex', 
            alignItems: 'center'
          }}>
          {moraleDailyQuestion && moraleDailyQuestion 
            .filter((data) =>
              selectTopic ? selectTopic === data.idQuestionTopic : true
            )
            .map((data) => {
              return selectQuestionStatus === data.isActive && (
                <Grid item xs={12} sm={6} lg={4} xl={2.4} key={data.idMoraledailyQuestion} 
                  sx={{                
                    display:'flex',
                    justifyContent:'flex-start',
                    
                  }}>
                  <StyledCard elevation={3} sx={{ position: 'relative', borderRadius: '15px',borderColor: getBgCard(data.idQuestionTopic), padding: 2 }}>
                    <div
                      style={{
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        backgroundColor: getBgCard(data.idQuestionTopic), 
                        width: 45, 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'
                      }}>
                    </div>
                    <div style={{
                      position: 'absolute', 
                      top: 0, 
                      left: 0, 
                      backgroundColor: "#FFD923", 
                      color: "#EA8900",
                      borderRadius: '50%', 
                      width: 40, 
                      height: 40, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center'
                    }}>
                      <Typography style={{ fontSize:'40px', color: "#EA8900", fontWeight:'600' }}>{data.reward}</Typography>
                    </div>

                    <Container style={{height:270, alignItems: 'center'}}>
                      <Stack display='flex' direction="column" alignItems="center" spacing={1} >
                        <div style={{ alignContent:'center', paddingTop:25, paddingBottom:10}}>
                          <Typography variant="h6" color="primary" textAlign={"center"} sx={{ lineHeight: 1, fontWeight: 'bold', color: getBgCard(data.idQuestionTopic), fontSize: '28px', textAlign:'center'}} >
                            {data.questionTopic}
                          </Typography>
                        </div>
                        <img
                          alt="questionImg"
                          width={140}
                          height={170}
                          src={imgInsight(data.idQuestionTopic)}
                          style={{ borderRadius: '50%', backgroundColor: '#FFF', }}
                        />
                      </Stack>
                    </Container>

                    <Container  style={{height:150,}} >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',alignContent:'start', }}>
                        <Typography variant="subtitle1" color="textSecondary"textAlign={"left"}>
                          {`คำถาม:`}
                        </Typography>
                        <Typography variant="h5" textAlign={"left"} sx={{ lineHeight: 1.1, fontSize: '18px', alignContent:'start', marginBottom:1 }}>
                          {data.question}
                        </Typography>
                        <Typography 
                          variant="subtitle1" 
                          color="textSecondary"
                          textAlign={"left"}
                        >
                          {`ประเภทคำตอบ: ${data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}`}
                        </Typography>
                      </div>
                    </Container>

                    <Container style={{ justifyContent: "space-around", width:'100%' }}>
                      <div>
                        <CardActions style={{ padding: 2, display: 'flex', flexDirection: 'row', width: '100%'}}>
                          {selectQuestionStatus === 1 ? (
                            <>
                              <Button variant='outlined' color="primary" sx={{ width:'100%', borderRadius:2}}  onClick={() => handleClickDelete(data.question, data.idMoraledailyQuestion)}>
                                เก็บ
                              </Button>
                              <Button variant='contained' color="secondary" sx={{width:'100%', borderRadius:2}} onClick={() => handleOpenPublic(data.idMoraledailyQuestion)}>
                                ส่งคำถาม
                              </Button>
                            </>
                          ) : (
                            <Button variant='contained' color="primary" size="small" onClick={() => handleClickeActive(data.idMoraledailyQuestion)}>
                              เปิดใช้งาน
                            </Button>
                          )}
                        </CardActions>
                      </div>
                    </Container>
                    
                  </StyledCard>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
      </Box>
      {open && (
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 16, padding: '24px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }
          }}
        >
          <DialogTitle id="alert-dialog-title">{"สร้างคำถามใหม่"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      1. ประเภทคำตอบ
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="typeQues"
                        name="typeQues"
                        value={questionType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="choice"
                          control={<Radio />}
                          label="ตัวเลือก"
                        />
                        <FormControlLabel
                          value="text"
                          control={<Radio />}
                          label="ข้อความ"
                        />
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      2. หัวข้อคำถาม
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={idQuestionTopic}
                        onChange={handleChangeIdQuestionTopic}
                      >
                        {questionTopic && questionTopic.map(topic => (
                          <MenuItem value={topic.idQuestionTopic} key={topic.idQuestionTopic}>
                            {topic.questionTopic}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>3. คำถาม</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      value={question}
                      onChange={handleChangeQuestion}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>
                      4. จำนวนเหรียญรางวัล
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Slider
                      defaultValue={1}
                      aria-labelledby="discrete-slider-custom"
                      marks={marks}
                      valueLabelDisplay="auto"
                      min={0}
                      max={10}
                      onChange={handleScoreChange}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  variant="outlined"
                  style={{ height: "100%", backgroundColor: getBgCard(idQuestionTopic), borderColor:getBgCard(idQuestionTopic), borderWidth:10}}
                >
                  <CardContent style={{ paddingBottom: 0, textAlign: "center" }}>
                    <img
                      style={{ marginBottom: 10, padding: 8, borderRadius: 8 }}
                      src={imgInsight(idQuestionTopic)}
                      alt=""
                      width={170}
                      height={200}
                    />
                    {question === "" ? (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ textAlign: "center", color: "#BFBFBF" }}
                      >
                        ... แสดงตัวอย่างคำถาม ....
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ textAlign: "center", color: "#FFFFFF" }}
                      >
                        {question}
                      </Typography>
                    )}
                    {questionType === "choice" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "80%",
                          margin: "auto",
                        }}
                      >
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Very Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Little Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Little Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Very Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <TextField
                            style={{ width: "100%", backgroundColor: "#FFF" }}
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            multiline
                            rows={4}
                          />
                          <FormControlLabel
                            style={{ width: "100%", }}
                            control={
                              <Checkbox
                                checked={checkAnonymous}
                                onChange={handleChangeAnonymous}
                                name="checkedB"
                                color="default"
                              />
                            }
                            label={
                              <span style={{ color: 'white' }}>ส่งโดยไม่ระบุชื่อ</span>
                            }
                            
                          />
                        </div>
                        <div>
                          <StyledButton
                            variant={"contained"}
                            // variant={insight === "hh" ? "outlined" : "contained"}
                          >
                            {" "}
                            <div style={{ display: "flex", width: 100 }}>
                              <Typography variant="button">ส่งคำตอบ</Typography>
                              <StyledCoinChoice>
                                {reward}
                              </StyledCoinChoice>
                            </div>{" "}
                          </StyledButton>
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              ยกเลิก
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDelete && (
        <DialogDelete
          idQuestion={idQuestion}
          open={openDelete}
          onClose={handleCloseDelete}
          displayDelete={displayDelete}
        />
      )}
      {openPublic && (
        <DialogPublish
          open={openPublic}
          handleClose={handleClosePublic} 
          idQuestion={idQuestion} 
        />
      )}
    </StyledRoot>
  );
};

export default Notification;
