import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const FilterTab = ({ keyFilter, selectedFilter, handleCheckFilter }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {selectedFilter[keyFilter].itemList.map((item) => {
        const labelId = `checkbox-list-label-${item.text}`;
        return (
          <ListItem
            key={item.text}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleCheckFilter(keyFilter, item.value)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.text} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FilterTab;
