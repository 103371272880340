import { httpClient } from "./httpClient";

const getCompanySendCoin = () => {
  return httpClient.get("company-send-coin");
};

const addCompanySendCoin = (formData) => {
  return httpClient.post("company-send-coin", formData);
};

const deleteCompanySendCoin = (id) => {
  return httpClient.delete(`company-send-coin/${id}`);
};

export default {
  getCompanySendCoin,
  addCompanySendCoin,
  deleteCompanySendCoin,
};
