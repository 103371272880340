import { httpClient } from "./httpClient";

const getAnalytic = (id) => {
  return httpClient.get("analytic/" + id);
};

const getAnalyticManager = (id) => {
  return httpClient.get("analytic-manager/" + id);
};

export default {
  getAnalytic,
  getAnalyticManager,
};
