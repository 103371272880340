import React, { useCallback, useState } from "react";
import * as Yup from "yup";

import {
  Grid,
  Typography,
  Button,
  Paper,
  styled,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { addCommunication } from "../../../../actions/communication";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../shared/inputForm/inputField";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  marginTop: 60,
  minWidth: 400,
});

const StyledImageDropZone = styled("div")({
  "& .dropzone-announcement": {
    width: "100%",
    height: 144,
    margin: "auto",
    textAlign: "center",
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
});

const UploadComponent = (props) => {
  const { setFieldValue, fieldName, acceptFileType, acceptFileTypeLabel, name } = props;
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFieldValue(fieldName, acceptedFiles[0]);
    setMyFiles([...acceptedFiles]);
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop,
    maxFiles: 1,
  });

  const handleRemove = () => {
    setFieldValue(fieldName, "");
    setMyFiles([]);
  };

  const acceptedFileItems = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <IconButton aria-label="delete" color="error" onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </li>
  ));

  return (
    <StyledImageDropZone>
      <aside>
        <h4>{name}</h4>
        <ul>{acceptedFileItems}</ul>
      </aside>
      {acceptedFileItems.length === 0 && (
        <div {...getRootProps({ className: "dropzone-announcement" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only {acceptFileTypeLabel} will be accepted)</em>
        </div>
      )}
    </StyledImageDropZone>
  );
};

export default function CreateBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    detail: Yup.string().max(180).required("Please enter detail"),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { detail: "", file: "" },
    resolver: yupResolver(validationSchema)
  })

  const handleAddCommunication = async (values) => {
    const formData = new FormData()
    for(const key in values) {
      formData.append(key, values[key])
    }
    formData.append('createBy', currentUser.id)
    const res = await dispatch(addCommunication(formData));
    if (res) {
      navigate("/communication");
    }
  };

  return (
    <StyledRoot className={'page'}>
      <div>
        <Typography variant="h4" align="center">
          สร้างข้อเสนอแนะ
        </Typography>
      </div>
      <form onSubmit={handleSubmit(handleAddCommunication)}>
        <Paper
          style={{
            padding: 20,
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
            maxWidth: 800,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField
                control={control}
                name="detail"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <UploadComponent
                setFieldValue={setValue}
                fieldName={"file"}
                acceptFileType={"image/jpeg, image/png, application/pdf" }
                acceptFileTypeLabel={"*.jpeg and *.png images, *.pdf" }
                name={"แนบไฟล์รายละเอียด"}
              />
            </Grid>
          </Grid>
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Paper>
      </form>
    </StyledRoot>
  );
}
