import React, { useState } from "react";
import { Box, Container, Divider, styled, Tab, Tabs } from "@mui/material";
import HeaderPage from "../../shared/header/headerPage";
import SelectAffiliateCompany from "../../shared/general/selectAffiliateCompany";
import CoinQuota from "./coinQuota/coinQuota";
import QuestionConfig from "./question/question";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SettingPage = () => {
  const [selectCompany, setSelectCompany] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCompany = (newValue) => {
    setSelectCompany(newValue);
  };

  return (
    <div className="page">
      <HeaderPage textLabel={"ข้อมูลระบบ"} />
      <StyledDivider />
      <Container>
        <SelectAffiliateCompany
          value={selectCompany}
          handleChange={handleChangeCompany}
          defaultSelect={true}
        />
        {selectCompany && (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 2 }}>
              <Tabs
                value={value}
                onChange={handleChange}
              >
                <Tab label="โควต้าเหรียญ" {...a11yProps(0)} />
                <Tab label="จัดการคำถาม" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <CoinQuota selectCompany={selectCompany} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <QuestionConfig selectCompany={selectCompany} />
            </CustomTabPanel>
          </>
        )}
      </Container>
    </div>
  );
};

export default SettingPage;
