import { httpClient } from "./httpClient";

const getAllDataTopic = () => {
  return httpClient.get("data-topic");
};

const getDataTopic = (id) => {
  return httpClient.get("data-topic/" + id);
};

export default { getAllDataTopic, getAllDataTopic };
