import React, { useState } from "react";
import "date-fns";
import { styled } from "@mui/material/styles";
// Components
import { Typography, Divider } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// Icon
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import HeaderPage from "../../../shared/header/headerPage";
import RewardIcon from "./assets/gifts.png";
import RewardCards from "./rewardCards";
import RewardTable from "./rewardTable";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
})

export default function Reward() {
  const [alignment, setAlignment] = useState("card");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <StyledRoot className={'page'}>
      <div>
        <HeaderPage textLabel={"จัดการของรางวัล"} icon={RewardIcon} />
        <StyledDivider />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Typography style={{ paddingRight: 5 }}>มุมมอง</Typography>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          size="small"
          aria-label="text alignment"
        >
          <ToggleButton value="card" aria-label="left aligned">
            <ViewModuleIcon />
          </ToggleButton>
          <ToggleButton value="table" aria-label="centered">
            <TableChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <RewardCards />
    </StyledRoot>
  );
}
