import React from "react";
import "date-fns";
import { styled } from "@mui/material/styles";
import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import { Fragment } from "react";
import utils from "../../../../../utils";
import { Link } from "react-router-dom";
import { useCompanyRewardByCompany, useUniRewardByCompany } from "../../../../../quries/reward";

const WrapImageRewardItem = styled("div")({
  height: 100,
  width: 150,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

const CardRewardDetail = ({ idRewardType, searchReward, rewards }) => {
  const uniRewards = useUniRewardByCompany((data) =>
    data.filter(
      (item) => item.idRewardType === idRewardType || idRewardType === 0
    )
  );
  const companyRewards = useCompanyRewardByCompany((data) =>
    data.filter(
      (item) => item.idRewardType === idRewardType || idRewardType === 0
    )
  );

  if (uniRewards.isPending || companyRewards.isPending) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <Fragment>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12}>
          <Typography variant="h6">ของรางวัล UNI</Typography>
        </Grid>
        {uniRewards.data
          ?.filter((value, index) => {
            return idRewardType === 0 || value.idRewardType === idRewardType;
          })
          .filter((value) => {
            return value.name
              .toLowerCase()
              .includes(searchReward.toLowerCase());
          })
          .map((value, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper style={{ padding: 10, borderRadius: 8 }}>
                  <Typography variant="h6">{value.name}</Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      {`คงเหลือ: ${utils.numberWithCommas(value.balanceQuantity)}`}
                    </Typography>
                    <WrapImageRewardItem
                      style={{
                        backgroundImage: `url(${value.imageURL})`,
                      }}
                    ></WrapImageRewardItem>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ width: "100%" }}
                      startIcon={<InfoIcon />}
                      component={Link}
                      to={"uni-reward/detail"}
                      state={{ ...value }}
                    >
                      รายละเอียด
                    </Button>
                  </div>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">ของรางวัลบริษัท</Typography>
        </Grid>
        {companyRewards.data
          ?.filter((value, index) => {
            return idRewardType === 0 || value.idRewardType === idRewardType;
          })
          .filter((value) => {
            return value.name
              .toLowerCase()
              .includes(searchReward.toLowerCase());
          })
          .map((value, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper style={{ padding: 10, borderRadius: 8 }}>
                  <Typography variant="h6">{value.name}</Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      {`คงเหลือ: ${utils.numberWithCommas(value.balanceQuantity)}`}
                    </Typography>
                    <WrapImageRewardItem
                      style={{
                        backgroundImage: `url(${value.imageURL})`,
                      }}
                    ></WrapImageRewardItem>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ width: "100%" }}
                      startIcon={<InfoIcon />}
                      component={Link}
                      to={"company-reward/detail"}
                      state={{ ...value }}
                    >
                      รายละเอียด
                    </Button>
                  </div>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Fragment>
  );
};

export default CardRewardDetail