import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Badge, Container, Grid, MenuItem, Tab, Tabs,Snackbar,Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import {
  getHeartTransferWaitingApprove,
} from "../../../../actions/heartTransfer";
import {
  getBookingRequestApprove
} from "../../../../actions/book";
import useMediaQuery from "@mui/material/useMediaQuery";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RequestSendHeartTransfer from "./requestSendHeartTransfer"
import RequestCoaching from "./requestCoaching"
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import loading from "../../assets/loading2.gif";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  
const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
      backgroundColor: "#919eab14",
      height: 56,
      padding: "0px 12px",
      borderRadius: 8,
      "& .MuiFilledInput-input": {
        padding: "7px 4px",
      },
      "&.Mui-focused": {
        backgroundColor: "#919eab14",
      },
      "& .MuiInputAdornment-root": {
        width: 32,
        marginTop: "0!important",
        fontSize: 24,
        "& button": {
          color: "#919EAB",
        },
      },
      "&:hover": {
        backgroundColor: "#919eab29",
        "&:before": {
          border: "none !important",
        },
      },
      "&::after": {
        border: "none",
      },
      "&::before": {
        border: "none",
      },
    },
  });
  
  const StyledBoxSearch = styled(Box)({
    "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
    },
    "& label": {
      display: "none",
    },
  });
  
  const StyledCard = styled(Card)({
    padding: 16,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 20,
    "& .MuiCardContent-root": {
      padding: 24,
    },
  });
  
  const StyledDivTab = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      "& .numberOfList": {
        marginTop: 16,
      },
    },
  }));
  
  const StyledRoot = styled("div")(({ theme }) => ({
    background: "#FFFFFF !important",
    "& .displayFlexEnd": {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "8px 0",
      },
    },
  }));
  
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -24,
      top: 8,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      backgroundColor: "#e46a76",
    },
  }));

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  




const RequestListApprove  = ()=>{
    const [approveTabsValue, setApproveTabsValue] = React.useState(0);
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [modeSnackbar, setModeSnackbar] = useState("info");
    const [displaySnackbar, setDisplaySnackbar] = useState("");
    const { isFetching:isFetchingHeartTransfer,result: heartTransfer } = useSelector((state) => state.heartTransfer);
    const { isFetching:isFetchingBookingCoach,result: bookingCoach } = useSelector((state) => state.bookCoach);





    const handleApproveTabsChange = (_, newValue) => {
        setApproveTabsValue(newValue);
      };
      const { result: userProfile } = useSelector((state) => state.userProfile);

      
    const mobile = useMediaQuery("(max-width:600px)");

    const handleApproveTabsChangeSelect = (event) => {
      setApproveTabsValue(event.target.value);
    };
    const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenSnackbar(false);
    };
  


    useEffect(()=>{
        dispatch(getHeartTransferWaitingApprove(new Date().getMonth() + 1))
        // dispatch(getBookingRequestApprove())
      },[]
    )


    return (
        <StyledRoot className="page">
            <Container maxWidth="lg" paddingBottom="40">
            <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
            รายการที่รออนุมัติ
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
          {/* <ButtonBlue
              variant="contained"
              startIcon={<AccessTimeIcon />}
              color="primary"
              onClick={()=>{}}
              style={{ marginLeft: 16 }}
            >
              ประวัติการอนุมัติ
            </ButtonBlue> */}
          </Grid>
        </Grid>
        <StyledCard>
            <StyledDivTab>
                {mobile ?(
                  <TextFieldTheme
                  value={approveTabsValue}
                  onChange={handleApproveTabsChangeSelect}
                  select
                  
                  >
                    <MenuItem value={0}>
                      <StyledBadge
                          badgeContent={heartTransfer? heartTransfer.length:0}
                          color="secondary"
                          max={999}>
                        การส่งหัวใจ
                        </StyledBadge>
                    </MenuItem>
                  
                  </TextFieldTheme>
                ):(
                    <Tabs
                    value={approveTabsValue}
                        onChange={handleApproveTabsChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                        sx={{
                        "& .MuiTab-root.Mui-selected": {
                            color: "#46cbe2",
                        },
                        }}
                        TabIndicatorProps={{
                        style: {
                            backgroundColor: "#46cbe2",
                        },
                        }}
                    >
                        <Tab
                        style={{ minWidth: 240 }}
                        {...a11yProps(0)}
                            label={
                                <StyledBadge
                                  badgeContent={heartTransfer? heartTransfer.length:0}
                                  color="secondary"
                                  max={999}
                                  >
                                  การส่งหัวใจ
                                  </StyledBadge>
                            }
                           />
                    </Tabs>
                )}
            </StyledDivTab>
           {(userProfile && <Box style={{ width: "100%" }}>
              {isFetchingHeartTransfer ? (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px",
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box>
              ) : (
                <TabPanel value={approveTabsValue} index={0}>
                  <RequestSendHeartTransfer setSnackBarConfig={setOpenSnackbar} setModeSnackbar={setModeSnackbar} setDisplaySnackbar={setDisplaySnackbar} />
                </TabPanel>
              )}
            </Box>)}

        </StyledCard>
            </Container>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                sx={{ width: "100%" }}
                severity={modeSnackbar}
              >
          {displaySnackbar}
        </Alert>
      </Snackbar>
        </StyledRoot>
    );

}


export default RequestListApprove;

