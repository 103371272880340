import {
  Button,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  IconButton,
  Typography,
  CardMedia,
  Checkbox,
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Stack,
  ListItemButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getUserProfile } from "../../../../actions/user";
import { useSelector, useDispatch } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getCategory } from "../../../../actions/category";
import { getAllCoach } from "../../../../actions/coach";
import {
  addFavoriteCoach,
  deleteFavoriteCoach,
} from "../../../../actions/favoriteCoach";
import CardCoach from "./component/card_coach";

const StyledDrawer = styled(Drawer)({
  width: 240,
  flexShrink: 0,
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
}));


const SearchCoach = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectCategory, setSelectCategory] = useState(null);
  const [companySelected,setCompanySelected] = useState(null);



  const [search, setSearch] = useState("");
  const [favorite, setFavorite] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: coach } = useSelector((state) => state.coach);
  const { result: category } = useSelector((state) => state.category);


  const handleChangeCompanySelected = (value)=>{
    setCompanySelected(value);
  }

  useEffect(() => {
    dispatch(getAllCoach());
    dispatch(getCategory());
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value.toLocaleLowerCase());
  };

  const handleSelectCategory = (event, select, index) => {
    if (select === selectCategory) {
      setSelectCategory(null);
    } else {
      setSelectCategory(select);
    }
  };


  return (
    <div className={`page`}>
      {userProfile && (
        <Container maxWidth="lg">
          <Typography variant="h4">ค้นหาโค้ช</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"row"} spacing={2}>
              <Button
                aria-haspopup="true"
                variant="outlined"
                startIcon={<FilterAltIcon />}
                onClick={handleClick}
              >
                Job group
              </Button>
              <Button
                startIcon={<FavoriteIcon />}
                variant={favorite ? "contained" : "outlined"}
                color={"error"}
                onClick={() => setFavorite(!favorite)}
              >
                Favorite
              </Button>
            </Stack>
            {/* <SelectAffiliateCompany
                  value={companySelected}
                  handleChange={handleChangeCompanySelected}
              /> */}
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              style={{ margin: 10, float: "right" }}
              onChange={onChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: "16px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <StyledDivider />
          <Grid container spacing={5} marginTop={0}>
            {coach &&
              coach
                .filter((item) => selectCategory==null ? item : item.idJobGroup == selectCategory)
                .filter((item) => {
                  return (
                    (item.firstname_TH + " " + item.lastname_TH)
                      .toLocaleLowerCase()
                      .search(search) != -1
                  );
                })
                .filter((item) => favorite ? item.isFavoriteCoach == 1 : item)
                .map((val) => (
                  <Grid item xs={12} sm={6} md={4} xl={4} key={val.idCoach}>
                    <CardCoach value = {val} navigateTo={"/coaching/profile"}/>
                  </Grid>
                ))}
          </Grid>
        </Container>
      )}
      <StyledDrawer variant="persistent" anchor="right" open={open}>
        <List>
          <ListItem style={{ marginTop: 60 }}>
            <ListItemText primary="Select position" />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="close"
                onClick={handleClick}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <br />
          <ListItemButton
            value={null}
            selected={selectCategory === null}
            onClick={() => setSelectCategory(null)}
          >
            <ListItemText primary={"All"} />
          </ListItemButton>


          <StyledDivider />
          {category &&
            category.map((item, idx) => (
              <div key={idx}>
                <ListItemButton
                  value={item.idJobGroup}
                  selected={selectCategory === item.idJobGroup}
                  onClick={(event) =>
                    handleSelectCategory(event, item.idJobGroup, idx)
                  }
                >
                  <ListItemText primary={item.jobGroupName} />
                </ListItemButton>
                <StyledDivider />
              </div>
            ))}
        </List>
      </StyledDrawer>
    </div>
  );
};

export default SearchCoach;
