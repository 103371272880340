import React, { useState } from "react";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SwitchInput from "../../../shared/inputForm/switchInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import InputField from "../../../shared/inputForm/inputField";
import { useUpdateUniRewardItem } from "../../../../../quries/uniReward";
import UpdateRewardImage from "../../../shared/uploadReward/updateRewardImage";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  minWidth: "80px",
}));

const validationSchema = yup.object().shape({
  image: yup.mixed().required(),
  sku: yup.string().max(20).required(),
  isActive: yup.boolean().required(),
  price: yup
    .number()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .required("required"),
  vat: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .oneOf([0, 7])
    .required("required"),
});

const DialogConfig = ({ open, handleClose, row }) => {
  const { id: idUniReward } = useParams();
  const { mutate } = useUpdateUniRewardItem({
    idUniRewardItem: row.idUniRewardItem,
    idUniReward,
  });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      image: { imageURL: row.imageURL },
      sku: row.sku,
      isActive: Boolean(row.isActive),
      price: row.price,
      vat: row.vat || 7,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    values.image = values.image instanceof File ? values.image : null

    for (const property in values) {
      formData.append(property, values[property]);
    }

    mutate(formData, {
      onSuccess: () => toast.success("สำเร็จ"),
      onError: () => toast.error("เกิดข้อผิดพลาด"),
      onSettled: () => handleClose(),
    });
  };

  const totalPrice =
    Number(watch("price")) +
    Number(watch("price")) * (Number(watch("vat")) / 100);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"แก้ไขราคาสินค้า"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item>
              <Controller
                name={"image"}
                control={control}
                render={({ field, fieldState }) => (
                  <UpdateRewardImage
                    {...field}
                    acceptFileType={"image/jpeg, image/png, image/webp"}
                    error={fieldState.error}
                    title={"รูปภาพ"}
                    id={"idUniRewardItem"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                control={control}
                name="sku"
                label="SKU"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                control={control}
                name="price"
                type="number"
                label="ราคาขาย"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="vat"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel>{"VAT"}</InputLabel>
                    <Select
                      {...field}
                      label="VAT"
                      error={Boolean(fieldState.error)}
                    >
                      <MenuItem value={0}>{"ไม่มี"}</MenuItem>
                      <MenuItem value={7}>{"7%"}</MenuItem>
                    </Select>
                    {Boolean(fieldState.error) && (
                      <FormHelperText error>
                        {fieldState?.error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="ราคารวม"
                value={totalPrice.toFixed(2) || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <Alert severity="warning">หากปิดการขาย สินค้าที่อยู่ในตะกร้าจะถูกลบออก</Alert>
              <SwitchInput
                control={control}
                name={"isActive"}
                label={"สถานะการขาย"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            {"ยกเลิก"}
          </Button>
          <Button type="submit" variant="contained">
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const StatusMenu = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDailog, setOpenDailog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDailog = () => {
    setOpenDailog(true);
    setAnchorEl(null);
  };

  const handleCloseDailog = () => {
    setOpenDailog(false);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
      >
        <MenuItem onClick={handleOpenDailog}>
          {"แก้ไขข้อมูลสินค้า"}
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/uni-team/reward/transaction/${row.idUniRewardItem}`}
        >
          {"ข้อมูลการซื้อขาย"}
        </MenuItem>
      </Menu>
      {openDailog && (
        <DialogConfig
          open={openDailog}
          handleClose={handleCloseDailog}
          row={row}
        />
      )}
    </>
  );
};

const TableReward = ({ reward }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">รูป</StyledTableCell>
            <StyledTableCell align="center">SKU</StyledTableCell>
            <StyledTableCell align="center">ตัวเลือก</StyledTableCell>
            <StyledTableCell align="right">ราคา</StyledTableCell>
            <StyledTableCell align="right">VAT</StyledTableCell>
            <StyledTableCell align="right">ราคาสุทธิ</StyledTableCell>
            <StyledTableCell align="right">จำนวน</StyledTableCell>
            <StyledTableCell align="center">สถานะ</StyledTableCell>
            <StyledTableCell align="center">จัดการ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reward.items.map((item, index) => (
            <TableRow key={item.idUniRewardItem}>
              <StyledTableCell align="center">
                <img src={item.imageURL} alt="product" width={80} />
              </StyledTableCell>
              <StyledTableCell align="center">{item.sku}</StyledTableCell>
              <StyledTableCell align="center">
                {item.options.map((option) => option.value).join(", ")}
              </StyledTableCell>
              <StyledTableCell align="right">{item.price}</StyledTableCell>
              <StyledTableCell align="right">{item.vat ? item.vat : "ไม่มี"}</StyledTableCell>
              <StyledTableCell align="right">
                {item.price +
                  (item.vat ? (item.price * item.vat) / 100 : 0)}
              </StyledTableCell>
              <StyledTableCell align="right">{item.quantity}</StyledTableCell>
              <StyledTableCell align="center">
                <Chip
                  color={item.isActive ? "success" : "error"}
                  label={item.isActive ? "เปิดขาย" : "จัดเก็บ"}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <StatusMenu row={item} />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableReward;
