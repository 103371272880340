import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Utils from "../../../../../utils";
import { SpecialIcon } from "../../../shared/typeCoin";
import { Link } from "react-router-dom";
import {
  Typography,
  Divider,
  Chip,
  Stack,
  Link as FileLink,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaceIcon from "@mui/icons-material/Place";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import WarningIcon from "@mui/icons-material/Warning";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import NavigationIcon from "@mui/icons-material/Navigation";
import Pagination from "@mui/material/Pagination";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useDispatch } from "react-redux";
import { getAllActivity, updateActivityInteresting } from "../../../../../actions/activity";
import { toast } from "sonner";

const StyledCard = styled(Card)({
  width: "100%",
  height: "100%",
  position: "relative",
  borderRadius: 8,
  // marginBottom: 24,
  // ["@media only screen and (min-width:576px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:768px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 24px) / 2)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(2n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:992px)"]: {
  //   // eslint-disable-line no-useless-computed-key
  //   width: "calc((100% - 48px) / 3)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(3n)": {
  //     marginRight: 0,
  //   },
  // },
  // ["@media only screen and (min-width:1200px)"]: {
  //   width: "calc((100% - 72px) / 4)",
  //   "&:nth-child(n)": {
  //     marginRight: 24,
  //   },
  //   "&:nth-child(4n)": {
  //     marginRight: 0,
  //   },
  // },
});

const StyledCardMedia = styled(CardMedia)({
  height: 180,
});

const WrapLabel = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
  "& .labelDesc": {
    fontSize: 14,
    marginRight: "auto",
  },
  "& .labelIcon": {
    marginRight: 4,
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.54)",
  },
  "& .enrollDateTo": {
    marginLeft: "auto",
  },
});

const WrapFollow = styled(Paper)({
  alignSelf: "flex-end",
  marginTop: 8,
  padding: 8,
  width: "100%",
  borderRadius: 16,
  backgroundImage: "linear-gradient(87deg,#172b4d,#1a174d)",
});

const WrapInnerFollow = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const WrapFollowLabel = styled("div")({
  minWidth: 60,
});

const StyledFollowCount = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  color: "#FFFFFF",
});

const StyledFollowLabel = styled(Typography)({
  fontSize: 14,
  color: "#FFFFFF",
});

const StyledDivider = styled(Divider)({
  margin: 10,
  backgroundColor: "#FFFFFF",
});

const StyledIconDate = styled("div")({
  color: "#000000",
  width: 70,
  height: 70,
  lineHeight: 1,
  fontWeight: 700,
  fontSize: 30,
  paddingTop: 13,
  position: "absolute",
  borderRadius: "100%",
  backgroundColor: "#FF5722",
});

const StyledIconButton = styled(IconButton)({
  paddingTop: 0,
  paddingBottom: 0,
});

const StyledMoreIconButton = styled(IconButton)({
  backgroundColor: "rgba(255, 255, 255, 1)",
  ":hover": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
});

const pageSize = 9;

const ActivityCard = ({ data }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueSelected, setValueSelected] = useState(null);
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeInteresting = async (idActivity, status) => {
    const interestingList = data.filter(activity => activity.isInteresting)
    if (interestingList.length >= 12) {
      toast.error("กิจกรรมที่สนใจต้องไม่เกิน 12 กิจกรรม")
    } else {
      const res = await dispatch(
        updateActivityInteresting(idActivity, { isInteresting: !status })
      );
      if (res && res.status === 200) {
        dispatch(getAllActivity());
      }
    }
  }

  const statusActivity = (status) => {
    switch (status) {
      case "open":
        return (
          <Chip
            icon={<PlayCircleIcon />}
            color="success"
            size="small"
            label={status}
          />
        );
      case "close":
        return (
          <Chip
            icon={<RemoveCircleIcon />}
            color="error"
            size="small"
            label={status}
          />
        );
      case "update":
        return (
          <Chip
            icon={<UpdateIcon />}
            color="warning"
            size="small"
            label={status}
          />
        );
      case "cancel":
        return (
          <Chip
            icon={<CloseIcon />}
            color="error"
            size="small"
            label={status}
          />
        );
      case "rewarded":
        return (
          <Chip icon={<DoneIcon />} color="info" size="small" label={status} />
        );
      default:
        return (
          <Chip
            icon={<CloseIcon />}
            color="default"
            size="small"
            label={"no status"}
          />
        );
    }
  };

  const dataSorted = [...data].sort((a, b) => dayjs(b.startDate).isAfter(dayjs(a.startDate)) ? 1 : -1)
  
  return (
    <>
      <Grid container spacing={2} marginBottom={4}>
        {dataSorted &&
          dataSorted
            .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
            .map((value) => {
              return (
                <Grid item xs={12} sm={6} md={4} xl={3} key={value.idActivity}>
                  <StyledCard>
                    <StyledCardMedia image={value.image} title={value.name} />
                    <CardContent
                      style={{
                        paddingTop: 35,
                        paddingBottom: 16,
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        style={{ height: 64, paddingRight: 8 }}
                      >
                        {value.name}
                      </Typography>
                      <WrapLabel>
                        <PeopleIcon className={`labelIcon`} />
                        <Typography
                          color="textSecondary"
                          className={`labelDesc`}
                        >
                          status:
                        </Typography>
                        {statusActivity(value.status)}
                        {value.register === value.participantList.length && (
                          <Chip
                            icon={<WarningIcon />}
                            color="warning"
                            size="small"
                            label={"คนเต็มจำนวน"}
                          />
                        )}
                      </WrapLabel>
                      <WrapLabel>
                        <ScheduleOutlinedIcon className={`labelIcon`} />
                        <Typography color="textSecondary">รับสมัคร:</Typography>
                      </WrapLabel>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        paddingX={1}
                      >
                        <Typography color="textSecondary">{"เริ่ม"}</Typography>
                        <Typography color="textSecondary">
                          {dayjs(value.openRegisterDate).format("DD MMM YYYY HH:mm")}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        paddingX={1}
                        marginBottom={1}
                      >
                        <Typography
                          color="textSecondary"
                          className={`labelDesc`}
                        >
                          {"สิ้นสุด"}
                        </Typography>
                        <Typography color="textSecondary">
                          {dayjs(value.closeRegisterDate).format("DD MMM YYYY HH:mm")}
                        </Typography>
                      </Stack>
                      <WrapLabel>
                        <PlaceIcon className={`labelIcon`} />
                        <Chip
                          size="small"
                          color={value.activityType === "onsite" ? "primary" : "secondary"}
                          label={value.activityType}
                        />
                        <Typography
                          className={`labelDesc`}
                          color="textSecondary"
                          marginLeft={1}
                          flexShrink={0}
                        >
                          สถานที่:{" "}
                        </Typography>
                        {value.activityType === "onsite" ? (
                          <>
                            <Typography
                              color="textSecondary"
                              paddingLeft={2}
                              noWrap
                            >
                              {value.location}
                            </Typography>
                            {value.lat && value.lng && (
                              <StyledIconButton
                                color="primary"
                                href={`https://www.google.com/maps/search/?api=1&query=${value.lat}%2C${value.lng}`}
                                target="_blank"
                              >
                                <NavigationIcon fontSize="small" />
                              </StyledIconButton>
                            )}
                          </>
                        ) : (
                          <FileLink target="_blank" href={value.link}>
                            Link
                          </FileLink>
                        )}
                      </WrapLabel>
                      <WrapLabel>
                        <EventIcon className={`labelIcon`} />
                        <Typography color="textSecondary">วันเวลา: </Typography>
                      </WrapLabel>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        paddingX={1}
                      >
                        <Typography color="textSecondary">{"เริ่ม"}</Typography>
                        <Typography color="textSecondary">
                          {dayjs(value.startDate).format("DD MMM YYYY HH:mm")}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        paddingX={1}
                        marginBottom={1}
                      >
                        <Typography color="textSecondary">
                          {"สิ้นสุด"}
                        </Typography>
                        <Typography color="textSecondary">
                          {dayjs(value.endDate).format("DD MMM YYYY HH:mm")}
                        </Typography>
                      </Stack>
                      <WrapLabel>
                        <DoneAllOutlinedIcon className={`labelIcon`} />
                        <Typography
                          color="textSecondary"
                          className={`labelDesc`}
                        >
                          รางวัล:{" "}
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {value?.coins.map((item, index) => (
                            <>
                              <SpecialIcon idCoinType={item.idCoinType} width={40} />
                              <Typography
                                variant="h6"
                                style={{ marginLeft: 8, fontSize: 24 }}
                              >
                                {Utils.numberWithCommas(item.amount)}
                              </Typography>
                              {value.coins.length > 1 &&
                                value.coins.length - 1 !== index && (
                                  <CloseIcon color="textSecondary" />
                                )}
                            </>
                          ))}
                        </div>
                      </WrapLabel>
                      <WrapFollow>
                        <WrapInnerFollow>
                          <WrapFollowLabel>
                            <StyledFollowCount align="center">
                              {Utils.numberWithCommas(value.register)}
                            </StyledFollowCount>
                            <StyledFollowLabel
                              align="center"
                              color="textSecondary"
                            >
                              รับสมัคร
                            </StyledFollowLabel>
                          </WrapFollowLabel>
                          <StyledDivider orientation="vertical" flexItem />
                          <WrapFollowLabel>
                            <StyledFollowCount align="center">
                              {Utils.numberWithCommas(value.participantList.length)}
                            </StyledFollowCount>
                            <StyledFollowLabel
                              align="center"
                              color="textSecondary"
                            >
                              ลงทะเบียน
                            </StyledFollowLabel>
                          </WrapFollowLabel>
                          <StyledDivider orientation="vertical" flexItem />
                          <WrapFollowLabel>
                            <StyledFollowCount align="center">
                              {Utils.numberWithCommas(value.register - value.participantList.length)}
                            </StyledFollowCount>
                            <StyledFollowLabel
                              align="center"
                              color="textSecondary"
                            >
                              ว่าง
                            </StyledFollowLabel>
                          </WrapFollowLabel>
                        </WrapInnerFollow>
                      </WrapFollow>
                    </CardContent>
                    <div
                      style={{
                        position: "absolute",
                        top: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 8px",
                        width: "100%",
                      }}
                    >
                      <IconButton 
                        sx={{
                          color: "#ffab00",
                          backgroundColor: "#ffffff", 
                          ":hover": { backgroundColor: "#ffffff" }
                        }}
                        onClick={() => handleChangeInteresting(value.idActivity, value.isInteresting)}
                      >
                        {value.isInteresting ? <StarIcon /> : <StarBorderIcon />}
                      </IconButton>
                      <StyledMoreIconButton
                        aria-controls={`more-menu`}
                        aria-haspopup="true"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setValueSelected(value);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledMoreIconButton>
                    </div>
                    {/* <div style={{ position: "absolute", top: 8, right: 8 }}>
                      <StyledMoreIconButton
                        aria-controls={`more-menu`}
                        aria-haspopup="true"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setValueSelected(value);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledMoreIconButton>
                    </div>
                    <div style={{ position: "absolute", top: 8, right: 16 }}>
                      <IconButton color="error">
                        {data.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon/>}
                      </IconButton>
                    </div> */}
                    <div style={{ position: "absolute", top: 140, right: 80 }}>
                      <StyledIconDate>
                        <Typography
                          variant="h6"
                          align="center"
                          color={"white"}
                          style={{ lineHeight: 1 }}
                        >
                          {dayjs(value.startDate).format("D")}
                        </Typography>
                        <Typography
                          variant="h6"
                          align="center"
                          color={"white"}
                          style={{ lineHeight: 1, textTransform: "uppercase" }}
                        >
                          {dayjs(value.startDate).format("MMM")}
                        </Typography>
                      </StyledIconDate>
                    </div>
                  </StyledCard>
                </Grid>
              );
            })}
      </Grid>
      {data.length > 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination count={10} page={page} onChange={handleChange} color="primary" />
        </div>
      )}
      <Menu
        id={`more-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {valueSelected?.file && (
          <MenuItem
            component={FileLink}
            href={valueSelected.file}
            target="_blank"
          >
            ไฟล์รายละเอียดกิจกรรม
          </MenuItem>
        )}
        {valueSelected?.idActivity &&
          (valueSelected?.idActivityStatus === 1 ||
            valueSelected?.idActivityStatus === 3) && (
            <MenuItem
              component={Link}
              to={"/admin/manage/edit-activity"}
              state={{ idActivity: valueSelected.idActivity }}
            >
              แก้ไขกิจกรรม
            </MenuItem>
          )}
        {valueSelected?.idActivity && (
          <MenuItem
            component={Link}
            to={"/admin/manage/activity-register"}
            state={{ idActivity: valueSelected.idActivity }}
          >
            รายชื่อลงทะเบียน
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActivityCard;
