import React, { Fragment, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Box,
  Popper,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import {
  getAllAppraisee,
  getAppraisee,
} from "../../../../../actions/appraisee";
import {
  ConnectingAirportsOutlined,
  ConstructionOutlined,
} from "@mui/icons-material";
import { Card } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import { BarChart } from "@mui/x-charts/BarChart";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
  "& .wrap-questions": {
    marginTop: 24,
    "& .MuiList-root": {
      maxWidth: "100%",
    },
  },
});

const StyledUserProfile = styled(Card)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  width: "300px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "20px",
});

const StyledWrapAssignAndSampleSize = styled("div")({
  padding: 24,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  "& .item": {
    minWidth: 300,
  },
});

const StyledWrapListQuestion = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledBoxSearch = styled(Box)({
  width: 350,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      margin: "0 8px",
      borderRadius: 8,
      paddingLeft: 8,
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledWrapListSearch = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 16,
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledChip = styled(Chip)(({ mode }) => ({
  width: 120,
  marginRight: 16,
  ...(mode === "boss" && {
    borderColor: "#ff3034",
    color: "#ffffff",
    backgroundColor: "#ff3034",
  }),
  ...(mode === "peer" && {
    borderColor: "#ffc700",
    color: "#ffffff",
    backgroundColor: "#ffc700",
  }),
  ...(mode === "project" && {
    borderColor: "#008dd1",
    color: "#ffffff",
    backgroundColor: "#008dd1",
  }),
  ...(mode === "subordinate" && {
    borderColor: "#00bdb1",
    color: "#ffffff",
    backgroundColor: "#00bdb1",
  }),
  ...(mode === "cross function" && {
    borderColor: "#7d55c2",
    color: "#ffffff",
    backgroundColor: "#7d55c2",
  }),
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const calculateProgressPercentage = (appraisee) => {
  if (!appraisee || !appraisee.sampleSize) return 0;

  const totalPeople = appraisee.sampleSize.length;
  const completedCount = appraisee.sampleSize.filter(
    (item) => item.isComplete === 1
  ).length;

  return totalPeople > 0 ? (completedCount / totalPeople) * 100 : 0;
};

const calculateProgress = (appraisee) => {
  if (!appraisee || !appraisee.sampleSize)
    return { completedCount: 0, totalPeople: 0 };

  const totalPeople = appraisee.sampleSize.length;
  const completedCount = appraisee.sampleSize.filter(
    (item) => item.isComplete === 1
  ).length;

  return { completedCount, totalPeople };
};

// const calculateAverageScore = (appraisee) => {
//   if (!appraisee || appraisee.assessmentType !== "close") return 0;

//   let totalScore = 0;
//   let questionCount = 0;

//   appraisee.sampleSize.forEach((sample) => {
//     if (sample.answerList && sample.answerList.length > 0) {
//       sample.answerList.forEach((answer) => {
//         const score = Number(answer.answer);

//         if (!isNaN(score)) {
//           totalScore += score;
//           questionCount += 1;
//         } else {
//           console.warn(`Invalid score for answer:`, answer);
//         }
//       });
//     }
//   });

//   console.log("Total Score:", totalScore);
//   console.log("Question Count:", questionCount);

//   return questionCount > 0 ? totalScore / questionCount : 0;
// };

const calculateAverageScore = (appraisee) => {
  if (!appraisee || appraisee.assessmentType !== "close") return 0;

  let weightedTotalScore = 0;
  let fullScore = 0;

  appraisee.sampleSize.forEach((sample, sampleIndex) => {
    const sampleWeight = sample.ratio; // Ratio of this sample in percentage
    const questionLength = sample.answerList?.length || 0;

    if (questionLength > 0 && !isNaN(sampleWeight)) {
      sample.answerList.forEach((answer, answerIndex) => {
        const score = Number(answer.answer);

        if (!isNaN(score)) {
          const weightedScore = score * (sampleWeight / 100); // Apply sample ratio to each answer
          weightedTotalScore += weightedScore;

          //   console.log(
          //     `Sample #${sampleIndex + 1}, Answer #${answerIndex + 1}:`
          //   );
          //   console.log(`  Score: ${score}`);
          //   console.log(`  Sample Weight: ${sampleWeight}%`);
          //   console.log(
          //     `  Weighted Score (Score * Sample Weight): ${weightedScore}`
          //   );
          // } else {
          //   console.warn(
          //     `Invalid score for answer in Sample #${sampleIndex + 1}, Answer #${
          //       answerIndex + 1
          //     }:`,
          //     answer
          //   );
        }
      });

      // Accumulate the full weighted score based on question length and sample ratio
      fullScore += questionLength * 100 * (sampleWeight / 100);
    } else if (isNaN(sampleWeight)) {
      console.warn(`Invalid ratio for sample:`, sample);
    }
  });

  // console.log("Weighted Total Score:", weightedTotalScore);
  // console.log("Full Score (Weighted):", fullScore);

  // Normalize weighted total score against full score
  return fullScore > 0 ? (weightedTotalScore / fullScore) * 100 : 0;
};

const prepareChartData = (appraisee) => {
  if (!appraisee || !appraisee.sampleSize || !appraisee.questionList)
    return { series: [], xAxis: [] };

  const questions = appraisee.questionList.map((item) => item.question); // Extract questions
  const scores = new Array(questions.length).fill(0);
  const counts = new Array(questions.length).fill(0);

  appraisee.sampleSize.forEach((sample) => {
    if (sample.answerList) {
      sample.answerList.forEach((answer) => {
        const questionIndex = appraisee.questionList.findIndex(
          (question) =>
            question.idAssessment_Question === answer.idAssessment_Question
        );

        if (questionIndex >= 0) {
          const score = Number(answer.answer);
          if (!isNaN(score)) {
            scores[questionIndex] += score;
            counts[questionIndex] += 1;
          }
        }
      });
    }
  });
  const series = [
    {
      data: scores.map((score, index) =>
        counts[index] > 0 ? score / counts[index] : 0
      ),
    },
  ];
  const xAxis = [{ data: questions, scaleType: "band" }];

  return { series, xAxis };
};

const AnswerAssessment360 = () => {
  const navigate = useNavigate();
  const {
    state: { idAppraisee },
  } = useLocation();

  const dispatch = useDispatch();
  const { result: appraisee } = useSelector((state) => state.appraiseeItem);
  const [open, setOpen] = React.useState(false);
  const [selectSampleSize, setSelectSampleSize] = React.useState(null);
  const [selelectQuestion, setSelectQuestion] = React.useState(null);
  const progressValue = calculateProgressPercentage(appraisee);
  const [alignment, setAlignment] = React.useState("questions");

  const [formats, setFormats] = React.useState(() => ["italic"]);
  const [answer, setAnswer] = React.useState("");

  const { completedCount, totalPeople } = calculateProgress(appraisee);

  const averageScore = calculateAverageScore(appraisee);
  const chartData = prepareChartData(appraisee);

  const handleOpenList = (item) => {
    const foundAnswer =
      selectSampleSize.answerList.find(
        (value) => value.idAssessment_Question === item.idAssessment_Question
      )?.answer || "No answer";

    setAnswer(foundAnswer);
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const [openIndices, setOpenIndices] = React.useState([]);

  const handleClick = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // const handleClickOpen = () => {
  //   setOpen(!open);
  // };

  // const handleClose = (value) => {
  //   const findSampleSize = appraisee.sampleSize.find(
  //     (item) => item.idAppraisee_SampleSize === value
  //   );
  //   setSelectSampleSize(findSampleSize);
  //   setOpen(false);
  // };

  useEffect(() => {
    if (idAppraisee) {
      dispatch(getAppraisee(idAppraisee));
    } else {
      navigate("/admin/assessment360", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (appraisee) {
      setSelectSampleSize(appraisee.sampleSize[0]);
    }
  }, [appraisee]);

  return (
    <Fragment>
      <StyledRoot className={`page`}>
        {appraisee && selectSampleSize && (
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
              Answer Assessment 360°
            </Typography>

            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              {/* Avatar and Profile Information */}
              <Grid item xs={12} sm={4} md={4}>
                <Tooltip
                  title={`${appraisee.firstname_TH} ${appraisee.lastname_TH}`}
                >
                  <StyledUserProfile
                    sx={{
                      height: "370px",
                      width: "300px",
                      textAlign: { xs: "center", sm: "right" },
                    }}
                  >
                    <Avatar
                      alt={`${appraisee.username}`}
                      src={`${process.env.REACT_APP_URL}image/profile/${appraisee.image}`}
                      sx={{
                        width: { xs: 150, sm: 200 },
                        height: { xs: 150, sm: 200 },
                        margin: "auto",
                      }}
                    />
                    <Typography variant="h5" sx={{ marginTop: "20px" }}>
                      {`${appraisee.firstname_TH} ${appraisee.lastname_TH}`}
                    </Typography>
                    <Typography color="text.secondary">
                      {appraisee.positionName}
                    </Typography>
                    {appraisee && (
                      <Typography
                        color="text.secondary"
                        sx={{ fontWeight: 500, marginBottom: "15px" }}
                      >
                        {appraisee.departmentName}
                      </Typography>
                    )}
                  </StyledUserProfile>
                </Tooltip>
              </Grid>

              {/* Right Section: Assessment Progress and Charts */}
              <Grid item xs={12} sm={8} md={8}>
                {/* Assessment Progress */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography color="text.secondary" align="left">
                      จำนวนผู้ตอบแบบสอบถาม
                    </Typography>
                    <Typography
                      color="text.secondary"
                      align="right"
                      sx={{ marginLeft: "auto" }}
                    >
                      {completedCount} จาก {totalPeople}
                    </Typography>
                  </Box>

                  <Tooltip title={`${progressValue} %`}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={Number(progressValue)}
                      sx={{ marginTop: "15px", width: "100%" }}
                    />
                  </Tooltip>
                </Box>

                {/* Row for Gauge and Bar Chart */}
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: "20px", marginLeft: "-35px" }}
                >
                  <Grid item xs={12} sm={6}>
                    <StyledUserProfile
                      sx={{
                        width: "100%",
                        height: "295px",
                        marginTop: "0px",
                      }}
                    >
                      <Typography
                        color="text.secondary"
                        align="right"
                        marginTop="10px"
                      >
                        คะแนนรวมทั้งหมด
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: "calc(100% - 40px)", marginTop: "10px" }}
                      >
                        <Gauge
                          width={250}
                          height={250}
                          value={averageScore}
                          startAngle={-110}
                          endAngle={110}
                          sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: { fontSize: 40 },
                            [`& .${gaugeClasses.valueArc}`]: {
                              fill: "#031b88",
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                              fill: theme.palette.text.disabled,
                            },
                          })}
                        />
                      </Stack>
                    </StyledUserProfile>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <StyledUserProfile
                      sx={{
                        width: "100%",
                        height: "295px",
                        marginTop: "0px",
                      }}
                    >
                      <Typography
                        color="text.secondary"
                        align="right"
                        marginTop="10px"
                      >
                        คะแนนเฉลี่ยรายคำถาม
                      </Typography>
                      <BarChart
                        series={chartData.series}
                        height={290}
                        xAxis={chartData.xAxis}
                        margin={{ top: 20, bottom: 30, left: 40, right: 10 }}
                      />
                    </StyledUserProfile>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Paper
              elevation={0}
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                border: `1px solid ${theme.palette.divider}`,
                width: "160px",
                marginLeft: "auto", // Align to the right within a flex container
              })}
            >
              <StyledToggleButtonGroup
                size="small"
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                sx={{
                  width: { xs: "100%", sm: "200px", justifyContent: "center" },
                }}
              >
                <Tooltip title="View by Questions">
                  <ToggleButton
                    value="questions"
                    aria-label="questions"
                    selected={alignment === "questions"} // Use selected prop for proper styling
                    sx={{
                      backgroundColor:
                        alignment === "questions" ? "#EC5B6A" : "transparent", // Change color when selected
                      color: alignment === "questions" ? "white" : "inherit", // Change text color when selected
                      "&.Mui-selected": {
                        // Make sure the selected button is styled correctly
                        backgroundColor: "#EC5B6A",
                        color: "white",
                      },
                    }}
                  >
                    รายคำถาม
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="View by People">
                  <ToggleButton
                    value="people"
                    aria-label="people"
                    selected={alignment === "people"} // Use selected prop for proper styling
                    sx={{
                      backgroundColor:
                        alignment === "people" ? "#EC5B6A" : "transparent", // Change color when selected
                      color: alignment === "people" ? "white" : "inherit", // Change text color when selected
                      "&.Mui-selected": {
                        // Make sure the selected button is styled correctly
                        backgroundColor: "#EC5B6A",
                        color: "white",
                      },
                    }}
                  >
                    รายบุคคล
                  </ToggleButton>
                </Tooltip>
              </StyledToggleButtonGroup>
            </Paper>

            {alignment === "questions" && (
              <div className="wrap-questions" style={{ marginTop: "0px" }}>
                <List
                  style={{
                    width: "100%",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      คำถาม
                    </ListSubheader>
                  }
                >
                  {appraisee &&
                    appraisee.questionList.map((item, index) => (
                      <Fragment key={index}>
                        <ListItem>
                          <ListItemButton
                            onClick={() => handleClick(index)} // Toggle based on question index
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ListItemIcon>
                              <Avatar>{index + 1}</Avatar>
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>{item.question}</Typography>}
                            />
                            {openIndices.includes(index) ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItemButton>
                        </ListItem>

                        <Collapse
                          in={openIndices.includes(index)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {appraisee.sampleSize.map((sample, sampleIndex) => {
                              const findSampleSize = appraisee.sampleSize.find(
                                (item) =>
                                  item.idAppraisee_SampleSize ===
                                  sample.idAppraisee_SampleSize
                              );

                              const foundAnswers =
                                findSampleSize?.answerList
                                  .filter(
                                    (answer) =>
                                      answer.idAssessment_SampleSize ===
                                        item.idAppraisee_SampleSize &&
                                      answer.idAssessment_Question ===
                                        item.idAssessment_Question
                                  )
                                  .map((answer) => answer.answer) || [];

                              return (
                                <ListItem key={sampleIndex}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      pl: 4,
                                    }}
                                  >
                                    {findSampleSize && (
                                      <StyledChip
                                        label={findSampleSize.sampleSizeName}
                                        mode={findSampleSize.sampleSizeName}
                                        sx={{ marginRight: 2 }}
                                      />
                                    )}
                                    <ListItemText
                                      primary={
                                        findSampleSize
                                          ? findSampleSize.idAppraiser
                                            ? `${
                                                findSampleSize.firstname_TH ||
                                                ""
                                              } ${
                                                findSampleSize.lastname_TH || ""
                                              }`.trim() || "No appraiser"
                                            : findSampleSize.email ||
                                              "No email available"
                                          : "No appraiser"
                                      }
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      pl: 4,
                                      marginLeft: "auto",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item.questionType === "text" ? (
                                      // Display text answer if the questionType is "text"
                                      foundAnswers.length > 0 ? (
                                        foundAnswers.map(
                                          (answer, answerIndex) => (
                                            <Typography
                                              sx={{
                                                maxWidth: "600px",
                                                wordWrap: "break-word",
                                                overflow: "hidden",
                                                variant: "body2",
                                              }}
                                            >
                                              {answer}
                                            </Typography>
                                          )
                                        )
                                      ) : (
                                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.action.disabled }}>
                                        ไม่มีคำตอบ
                                      </Typography>
                                      )
                                    ) : item.questionType === "choice" ? (
                                      // Display choice-based answer if the questionType is "choice"
                                      foundAnswers.length > 0 ? (
                                        foundAnswers.map(
                                          (answer, answerIndex) => (
                                            <Typography
                                              key={answerIndex}
                                              variant="body2"
                                            >
                                              {answer}
                                            </Typography>
                                          )
                                        )
                                      ) : (
                                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.action.disabled }}>
                                        ไม่มีคำตอบ
                                      </Typography>
                                      )
                                    ) : (
                                      // If question type is neither "text" nor "choice", render nothing
                                      <Typography variant="body2">
                                        Invalid question type
                                      </Typography>
                                    )}
                                  </Box>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                        <StyledDivider variant="inset" />
                      </Fragment>
                    ))}
                </List>
              </div>
            )}

            {/* Appraiser List */}
            {alignment === "people" && (
              <div className="wrap-questions" style={{ marginTop: "0px" }}>
                <List
                  style={{
                    width: "100%",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      ผู้ประเมิน
                    </ListSubheader>
                  }
                >
                  {appraisee &&
                    appraisee.sampleSize.map((sample, sampleIndex) => {
                      // Find the appraiser information for the current sample
                      const appraiserName = sample.idAppraiser
                        ? `${sample.firstname_TH || ""} ${
                            sample.lastname_TH || ""
                          }`.trim() || "No appraiser"
                        : sample.email || "No email available";

                      const foundAnswers = sample.answerList || []; // Ensure answerList exists

                      return (
                        <Fragment key={sampleIndex}>
                          <ListItem>
                            <ListItemButton
                              onClick={() => handleClick(sampleIndex)} // Change index based on sample
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <ListItemIcon>
                                <Avatar>{sampleIndex + 1}</Avatar>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography>{appraiserName}</Typography>
                                }
                              />
                              <Tooltip title="View answer">คำตอบ</Tooltip>
                              {openIndices.includes(sampleIndex) ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                          </ListItem>

                          <Collapse
                            in={openIndices.includes(sampleIndex)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {appraisee.questionList.map(
                                (question, questionIndex) => {
                                  const answersForQuestion =
                                    foundAnswers
                                      .filter(
                                        (answer) =>
                                          answer.idAssessment_Question ===
                                          question.idAssessment_Question
                                      )
                                      .map((answer) => answer.answer) || [];

                                  return (
                                    <ListItem key={questionIndex}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          pl: 4,
                                        }}
                                      >
                                        <StyledChip
                                          label={question.question} // Display question
                                          mode={question.question}
                                          sx={{ marginRight: 2, width: "auto" }}
                                        />
                                      </Box>

                                      {/* Conditional rendering based on questionType */}
                                      {question.questionType === "open" ? (
                                        // If the questionType is "open", display answers below the question
                                        <Box
                                          sx={{
                                            pl: 4,
                                            marginLeft: "auto",
                                            textAlign: "right",
                                            marginTop: 1, // Add some space between question and answers
                                          }}
                                        >
                                          {answersForQuestion.length > 0 ? (
                                            answersForQuestion.map(
                                              (answer, answerIndex) => (
                                                <Typography
                                                  key={answerIndex}
                                                  variant="body2"
                                                >
                                                  {answer}
                                                </Typography>
                                              )
                                            )
                                          ) : (
                                            <Typography variant="body2" sx={{ color: (theme) => theme.palette.action.disabled }}>
                                            ไม่มีคำตอบ
                                          </Typography>
                                          )}
                                        </Box>
                                      ) : (
                                        <Box
                                          sx={{
                                            pl: 4,
                                            marginLeft: "auto",
                                            textAlign: "right",
                                          }}
                                        >
                                          {answersForQuestion.length > 0 ? (
                                            answersForQuestion.map(
                                              (answer, answerIndex) => (
                                                <Typography
                                                  key={answerIndex}
                                                  variant="body2"
                                                >
                                                  {answer}
                                                </Typography>
                                              )
                                            )
                                          ) : (
                                            <Typography variant="body2" sx={{ color: (theme) => theme.palette.action.disabled }}>
                                            ไม่มีคำตอบ
                                          </Typography>
                                          )}
                                        </Box>
                                      )}
                                    </ListItem>
                                  );
                                }
                              )}
                            </List>
                          </Collapse>
                          <StyledDivider variant="inset" />
                        </Fragment>
                      );
                    })}
                </List>
              </div>
            )}
          </Container>
        )}
        {/* {open && (
        <DialogSelectSample
          open={open}
          onClose={handleClose}
          appraiseeList={appraisee.sampleSize}
          selectSampleSize={selectSampleSize}
        />
      )} */}
      </StyledRoot>
    </Fragment>
  );
};

export default AnswerAssessment360;
