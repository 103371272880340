import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  FormControlLabel,
  Typography,
  TextField,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Stack,
  Container,
  Grid,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import HeaderPage from "../../shared/header/headerPage";
import NotificationIcon from "./assets/notification.svg";
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

import { useDispatch, useSelector } from "react-redux";
import { addMoraleDailyAnswer, getMoraleDailyByUserId } from "../../../../actions/moraleDaily";
import * as dayjs from 'dayjs'

const WrapHeader = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
});

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
});

const WrapSection3 = styled("div")({
  marginTop: 30,
  width: "100%",
});

const StyledCard = styled(Card)({
  height: '580px',
  width:'100%',
  display: "flex",
  flexDirection: "column",
  border: '10px solid ',
});

const WrapCardHeaderInsight = styled("div")({
  display: "flex",
  padding: 5,
  width: "70%",
  borderTopRightRadius: 50,
  borderBottomRightRadius: 50,
  justifyContent: "space-between",
  alignItems: "center",
});

const WrapCardHeaderInsight2 = styled("div")({
  display: "flex",
  padding: 5,
  width: "28%",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,
  alignItems: "center",
  backgroundColor: "#EFEFEF",
  marginLeft: "auto",
});

const WrapCardHeaderInsightImage = styled("div")({
  backgroundColor: "white",
  borderRadius: "50%",
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const StyledCardHeaderInsightIcon = styled(Avatar)({
  width: 35,
  height: 35,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFD923",
  color: "#EA8900",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledDialogContent = styled(DialogContent)({
  width: 580,
  height: '820px',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .MuiTypography-h6": {
    fontSize: 24,
  },
  border: '10px solid ',
})

const WrapAnsChoice = styled('div')({
  display: "flex",
  flexDirection: "column",
  alignContent:'space-between',
  height:'80%'
})

const StyledButtonAns = styled(Button)({
  width: '100%',
  margin: 4,
  border: "none",
  backgroundColor: "#FFFFFF7A",
  height:'80%'
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 320,
})

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF"
  };
  const idModTen = id % 10
  return bgCard[idModTen]
}

const BtnAnsInsight = ({ 
  handleClickAnswer, 
  idMoraleDaily, 
  insightText, 
  reward 
}) => {
  return (
    <StyledButtonAns
      variant={"contained"}
      onClick={() => handleClickAnswer(idMoraleDaily, insightText)}
    >
      <Typography variant="button">{insightText}</Typography>
      <StyledCoinChoice>{reward}</StyledCoinChoice>
    </StyledButtonAns>
  );
};

const MyMoraleDaily = () => {
  const [dataInsight, setDataInsight] = useState(null);
  const [answerText, setAnswerText] = useState();
  const [openDailyInsight, setOpenDailyInsight] = useState(false);
  const [checkAnonymous, setCheckAnonymous] = React.useState(false);

  const colorBGCardHeader = [
    "#F1C232",
    "#6AA84F",
    "#CC0000",
    "#3C78D8",
    "#D64E91",
  ];

  const handleChangeAnonymous = (event) => {
    setCheckAnonymous(event.target.checked);
  };

  const handleOpenDailyInsight = (value) => {
    setDataInsight(value)
    setOpenDailyInsight(true);
  };

  const handleCloseDailyInsight = () => {
    setOpenDailyInsight(false);
  };

  const handleChangeAnswerText = (event) => {
    setAnswerText(event.target.value);
  };

  const [insightAns, setInsightAns] = React.useState([
    {text: "Very Happy",status: false,},
    {text: "Happy",status: false,},
    {text: "Little Happy",status: false,},
    {text: "Little Bad",status: false,},
    {text: "Bad",status: false,},
    {text: "Very Bad",status: false,},
  ]);

  const handleChangeBgInsight = () => {
    switch (dataInsight.idQuestionTopic) {
      case 1: return "#F1C232";
      case 2: return "#6AA84F";
      case 3: return "#CC0000";   
      case 4: return "#3C78D8";
      case 5: return "#D64E91";
      default: return "#FFF";
    }
  };
  const imgInsight = (id) => {
    const idModTen = id % 10
    switch (idModTen) {
      case 0: return H1;
      case 1: return H2;
      case 2: return H3;
      case 3: return H4;
      case 4: return H5;
      case 5: return H6;
      case 6: return H7;
      case 7: return H8;
      case 8: return H9;
      case 9: return H10;
      default: break;
    }
  };

  const dispatch = useDispatch();
  const { result: moraleDailyUser } = useSelector((state) => state.moraleDailyUser);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  
  const handleClickAnswer = async (idMoraleDaily, answer) => {
    const res = await dispatch(
      addMoraleDailyAnswer({ idMoraleDaily, answer })
    );
    if(res) {
      dispatch(getMoraleDailyByUserId());
      handleCloseDailyInsight()
    }
  }

  useEffect(() => {
    dispatch(getMoraleDailyByUserId(userProfile.idEmployees));
  }, [dispatch, userProfile]);
  console.log(moraleDailyUser)
  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"คำถามสุขภาพใจรายวัน"} icon={NotificationIcon} />
      </WrapHeader>
      <StyledDivider />
      <WrapSection3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Grid container spacing={2} 
          sx={{
            display:'flex', 
            alignItems: 'center'
          }}>
          {moraleDailyUser &&
            moraleDailyUser
              .map((data) => {
                return (
                  <Grid item xs={12} sm={6} lg={4} xl={2.4} key={data.idMoraledailyQuestion} 
                  sx={{                
                    display:'flex',
                    justifyContent:'flex-start',
                    
                  }}>
                  <StyledCard elevation={3} sx={{ position: 'relative', borderRadius: '15px',borderColor: getBgCard(data.idQuestionTopic), borderWidth:10, padding: 2 }}>
                    <div
                      style={{
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        backgroundColor: getBgCard(data.idQuestionTopic), 
                        width: 45, 
                        height: 45, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'
                      }}>
                    </div>
                    <div style={{
                      position: 'absolute', 
                      top: 0, 
                      left: 0, 
                      backgroundColor: "#FFD923", 
                      color: "#EA8900",
                      borderRadius: '50%', 
                      width: 40, 
                      height: 40, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center'
                    }}>
                      <Typography style={{ fontSize:'40px', color: "#EA8900", fontWeight:'600' }}>{data.reward}</Typography>
                    </div>

                    <Container style={{height:270, alignItems: 'center'}}>
                      <Stack display='flex' direction="column" alignItems="center" spacing={1} >
                        <div style={{ alignContent:'center', paddingTop:25, paddingBottom:10}}>
                          <Typography variant="h6" color="primary" textAlign={"center"} sx={{ lineHeight: 1, fontWeight: 'bold', color: getBgCard(data.idQuestionTopic), fontSize: '28px', textAlign:'center'}} >
                            {data.questionTopic}
                          </Typography>
                        </div>
                        <img
                          alt="questionImg"
                          width={140}
                          height={170}
                          src={imgInsight(data.idQuestionTopic)}
                          style={{ borderRadius: '50%', backgroundColor: '#FFF', }}
                        />
                      </Stack>
                    </Container>

                    <Container  style={{height:120,  }} >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',alignContent:'start', }}>
                        <Typography variant="subtitle1" color="textSecondary"textAlign={"left"}>
                          {`คำถาม:`}
                        </Typography>
                        <Typography variant="h5" textAlign={"left"} sx={{ lineHeight: 1.1, fontSize: '18px', alignContent:'start', marginBottom:1 }}>
                          {data.question}
                        </Typography>
                      </div>
                    </Container>
                    <Container  style={{height:80 }} >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',alignContent:'start', }}>
                        <Typography 
                          variant="subtitle1" 
                          color="textSecondary"
                          textAlign={"left"}
                        >
                          {`ประเภทคำตอบ: ${data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}`}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`start: ${dayjs(data.startDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="subtitle1"
                        >
                          {`end: ${dayjs(data.endDate).format("YYYY-MM-DD HH:mm")}`}
                        </Typography>
                      </div>
                    </Container>

                    <Container style={{ justifyContent: "space-between", width:'100%' }}>
                      <div>
                      <Stack width={'100%'} direction={"row"} justifyContent={"center"} marginTop={2}>
                          <Button 
                            variant="contained" 
                            onClick={() => handleOpenDailyInsight(data)} 
                            disabled={data.answer}
                            sx={{width:'100%'}}
                          >
                            {`ตอบคำถาม`}
                          </Button>
                        </Stack>   
                      </div>
                    </Container>
                  </StyledCard>
                  </Grid>
                )
              })
          }
          </Grid>
        </div>
      </WrapSection3>
        {dataInsight && (
        <Dialog
          open={openDailyInsight}
          onClose={handleCloseDailyInsight}
          aria-labelledby="alert-dialog-title-insight"
          aria-describedby="alert-dialog-description-insight"
          PaperProps={{
            style: {
              backgroundColor: 'transparent', // Remove background color
              boxShadow: 'none', // Optional: Remove shadow
            },
          }}
        >
      <Grid item xs={12} 
                  sx={{                
                    display:'flex',
                    justifyContent:'flex-start',
                    
                  }}>
          <StyledDialogContent
            style={{ borderColor: getBgCard(dataInsight.idQuestionTopic), borderWidth:10, backgroundColor: '#fff', maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }, borderRadius: '15px',}}
          >
          <div
                      style={{
                        position: 'absolute', 
                        top: 5, 
                        left: 5, 
                        backgroundColor: getBgCard(dataInsight.idQuestionTopic), 
                        width: 85, 
                        height: 85, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'
                      }}>
                    </div>
                    <div style={{
                      position: 'absolute', 
                      top: 10, 
                      left: 10, 
                      backgroundColor: "#FFD923", 
                      color: "#EA8900",
                      borderRadius: '50%', 
                      width: 75, 
                      height: 75, 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center'
                    }}>
                      <Typography style={{ fontSize:'70px', color: "#EA8900", fontWeight:'600' }}>{dataInsight.reward}</Typography>
                    </div>
                    <Container style={{height:120, alignContent:'center'}}>
                    <Typography variant="h4" align="center" color= {getBgCard(dataInsight.idQuestionTopic)} gutterBottom sx={{ lineHeight: 1, fontWeight: 'bold',}}>
                      {dataInsight.questionTopic}
                    </Typography>
                    </Container>
            
            <Container style={{backgroundColor: handleChangeBgInsight(), height:'80%', borderRadius:15, paddingTop:20, paddingBottom:20, justifyContent:'center', alignContent:'space-between'}}>
              <Container style={{height:100, alignContent:'center'}}>
                <Typography variant="h6" align="center" color="#ffffff" gutterBottom>
                  {dataInsight.question}
                </Typography>
              </Container>
            
            {dataInsight.questionType === "choice" ? (
              <WrapAnsChoice>
                {insightAns &&
                  insightAns.map((item, index) => {
                    return (
                      <BtnAnsInsight
                        btnIndex={index}
                        insightText={item.text}
                        insightStatus={item.status}
                        reward={dataInsight.reward}
                        idMoraleDaily={dataInsight.idMoraleDaily}
                        handleClickAnswer={handleClickAnswer}
                      />
                    );
                  })}
                {/* <Link
                  component="button"
                  variant="body2"
                  style={{ color: "#ffffff", marginTop: 16 }}
                  onClick={handleCloseDailyInsight}
                >
                  {"Skip >>"}
                </Link> */}
              </WrapAnsChoice>
            ) : (
              <div>
                <Container style={{height:420, width: "100%", alignContent:'flex-end' }}>
                  <TextField
                    style={{ width: "100%", backgroundColor: "#FFF",  }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    value={answerText}
                    onChange={handleChangeAnswerText}
                    multiline
                    rows={8}
                    inputProps={{ maxLength: 100 }}
                  />
                  <FormControlLabel
                    style={{ width: "100%" }}
                    control={
                      <Checkbox
                        checked={checkAnonymous}
                        onChange={handleChangeAnonymous}
                        name="checkedB"
                        color="default"
                      />
                    }
                    label="ส่งโดยไม่ระบุชื่อ"
                  />
                
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <StyledButtonAns
                    onClick={() => handleClickAnswer(dataInsight.idMoraleDaily, answerText)}
                  >
                    <div style={{ display: "flex", width: 100 }}>
                      <Typography variant="button">ส่งคำตอบ</Typography>
                      <StyledCoinChoice>
                        {dataInsight.reward}
                      </StyledCoinChoice>
                    </div>
                  </StyledButtonAns>
                  {/* <Link
                    component="button"
                    variant="body2"
                    style={{ color: "#ffffff", marginTop: 16 }}
                    onClick={handleCloseDailyInsight}
                  >
                    {"Skip >>"}
                  </Link> */}
                  
                </div>
                </Container>
              </div>
            )}
            </Container>
          </StyledDialogContent>
          </Grid>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default MyMoraleDaily;
